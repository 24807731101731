import { useState } from "react";
import {
  Box,
  Flex,
  HStack,
  Image,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { ReactComponent as LogoLarge } from "../../assets/svg/youwho-logos_text-logo-new-trans.svg";
import { ReactComponent as LogoPill } from "../../assets/svg/youwho-teal_logo-pill.svg";
import colors from "../../config/colors";
import ActivityIndicator from "../rnMimic/ActivityIndicator";
import { getPx } from "../../utils/helpers";
import padding from "../../config/padding";
import { useYouwho } from "../../package/react-youwho";
import AppAvatar from "../AppAvatar";
import AppButton from "../AppButton";
import LoginSignupModal from "../modals/LoginSignupModal";

export default function TopBar({ children }) {
  const { user, login, logout, appStore } = useYouwho();
  const { loginModalObject } = appStore;
  // const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    // <Box
    //   // className="p-sm-3"
    //   minH="600px"
    //   minW="300px"
    //   h="100%"

    //   // backgroundColor="white"
    //   // background="radial-gradient(circle at 20% 100%, rgba(255, 255, 255, 0.1) 0%, rgba(160, 160, 160, 0.3) 30%, rgba(255, 255, 255, 0.3) 100%)"
    // >
    <Flex
      sx={{
        zIndex: 1,
        position: "fixed",
        // width: ["50vw", , , "100vw"],
        // h: ["50px", , "60px"],
        height: [getPx(padding.topBar60), getPx(padding.topBar50)],
        padding: 2,
        top: 0,
        left: 0,
        right: 0,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "white",
        boxShadow: "0px 1px 5px 1px rgba(0,0,0,0.1)",
        borderTopWidth: 1,
        borderColor: colors.grayD,
      }}
    >
      <Flex flexDirection={["row"]}>
        <Flex display={["none", , "flex"]}>
          <a
            href="https://youwho.io"
            target="_blank"
            className="button-animation"
          >
            <LogoLarge style={{ height: "30px", width: "160px" }} />
          </a>
        </Flex>
        <Flex display={["flex", , "none"]}>
          <a
            href="https://youwho.io"
            target="_blank"
            className="button-animation"
          >
            <LogoPill
              style={{
                height: "40px",
                width: "30px",
              }}
            />
          </a>
        </Flex>
      </Flex>
      <HStack>
        {!user && (
          // <AppButton onPress={() => login("Asdf", "1234")} title="login" />
          <AppButton onPress={loginModalObject?.onOpen} title="login" />
        )}
        {user && user?.attributes?.userPublic?.attributes?.avatar?._url && (
          <>
            <AppButton
              variant="outline"
              onPress={logout}
              title={`logout ${user.attributes.username}`}
            />
            <AppAvatar
              name={user?.attributes?.username}
              url={
                user?.attributes?.userPublic?.attributes?.avatar?._url ??
                user?.attributes?.userPublic?.attributes?.avatarUrl
              }
              variant="sm"
              enlarge
            />
          </>
        )}
      </HStack>
      {/* <LoginSignupModal isOpen={isOpen} onOpen={onOpen} onClose={onClose} /> */}
    </Flex>
  );
}

