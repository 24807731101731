import React from "react";
import { GoInfo } from "react-icons/go";

import AppModalCardBS from "./AppModalCardBS";
import { fontConfig as fonts } from "../config/fonts";
import colors from "../config/colors";

export default function AppModalMoreBS({
  MoreComponent,
  customButton,
  // modalHeight,
  iconColor = colors.black,
}) {
  return (
    <AppModalCardBS
      buttonComponent={
        customButton ? (
          customButton
        ) : (
          <GoInfo size={fonts.size.md} color={iconColor} />
        )
      }
      component={MoreComponent}
      // modalHeight={modalHeight ?? ["60%", "95%"]}
    />
  );
}

// const Component = ({ handlePressModal, MoreComponent }) => (
//   <View style={{ height: "100%" }}>
//     <View
//       style={{
//         alignItems: "flex-end",
//         // marginBottom: padding.sm,
//       }}
//     >
//       <AppPressable
//         onPress={() => {
//           handlePressModal(false);
//         }}
//         pressableStyle={{ width: getPx(padding.lg) }}
//         scale={0.95}
//       >
//         <AppIcon close />
//       </AppPressable>
//     </View>
//     {MoreComponent}
//   </View>
// );
