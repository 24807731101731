import React from "react";

import { VisibilityContext } from "react-horizontal-scrolling-menu";
import {
  FiArrowLeft,
  FiArrowRight,
  FiChevronLeft,
  FiChevronRight,
} from "react-icons/fi";
import AppButton from "../AppButton";
import AppPressable from "../AppPressable";

export function LeftArrow() {
  const visibility = React.useContext(VisibilityContext);
  const isFirstItemVisible = visibility.useIsVisible("first", true);

  return (
    <Arrow
      disabled={isFirstItemVisible}
      onClick={() => visibility.scrollPrev()}
    >
      <FiChevronLeft size={20} />
    </Arrow>
  );
}

export function RightArrow() {
  const visibility = React.useContext(VisibilityContext);
  const isLastItemVisible = visibility.useIsVisible("last", false);

  return (
    <Arrow disabled={isLastItemVisible} onClick={() => visibility.scrollNext()}>
      <FiChevronRight size={20} />
    </Arrow>
  );
}

function Arrow({ children, disabled, onClick }) {
  return (
    <AppPressable
      isDisabled={disabled}
      onPress={onClick}
      sx={{
        opacity: disabled ? "0.4" : "1",
        userSelect: "none",
      }}
    >
      {children}
    </AppPressable>
  );
}
