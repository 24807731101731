import React, { useState } from "react";
import {
  motion,
  useMotionValue,
  useMotionValueEvent,
  useScroll,
  useTransform,
} from "framer-motion";
import padding from "../../config/padding";
import { getPx, getSx } from "../../utils/helpers";
import shadows from "../../config/shadows";
import { Box, Flex } from "@chakra-ui/react";
import AppPressable from "../AppPressable";
import AppIcon from "../AppIcon";
import AppTextTranslate from "../AppTextTranslate";
import View from "../rnMimic/View";
import { useNavigate } from "react-router-dom";
import colors from "../../config/colors";

const AppFramerHeader = ({
  animateSubHeadingOnly,
  backgroundColor = colors.white,
  fadeHeading,
  opacityStyle,
  goBack,
  heading,
  noTranslate,
  RightComponent,
}) => {
  const [hidden, setHidden] = useState(false);
  const [shadow, setShadow] = useState(false);
  const navigation = useNavigate();
  const opacity = useMotionValue(1);
  const { scrollY } = useScroll();

  useMotionValueEvent(scrollY, "change", (latest) => {
    const previous = scrollY.getPrevious();
    if (latest > previous && latest > 150) {
      setHidden(true);
    } else {
      setHidden(false);
    }

    if (latest > 60) {
      setShadow(true);
    } else {
      setShadow(false);
    }
  });

  return (
    <>
      <motion.div
        variants={{
          visible: { y: 0 },
          hidden: { y: "-100%" },
        }}
        animate={
          animateSubHeadingOnly ? "visible" : hidden ? "hidden" : "visible"
        }
        transition={{ duration: 0.35, ease: "easeInOut" }}
        style={{
          display: "flex",
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          height: getPx(padding.topBar50),
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          paddingLeft: getPx(padding.sm),
          paddingRight: getPx(padding.sm),
          zIndex: 2,
          backgroundColor: backgroundColor,
          //   boxShadow: "0px 0px 7px 1px rgba(0,0,0,0.2)",
          boxShadow: shadow ? "0px 0px 7px 1px rgba(0,0,0,0.2)" : "none",
        }}
      >
        <AppPressable
          sx={{}}
          onPress={
            goBack
              ? goBack
              : () => {
                  navigation(-1);
                }
          }
        >
          <AppIcon back={!goBack} close={goBack} />
        </AppPressable>

        {/* <Box sx={{ display: ["none", "block"] }}> */}
        <motion.div
          style={{
            opacity: opacity,
            // typeof opacityOutter === "number" ? opacityOutter : opacity,
          }}
        >
          <AppTextTranslate
            noTranslate={noTranslate}
            noAuto
            variant="h2"
            numberOfLines={1}
          >
            {heading}
          </AppTextTranslate>
        </motion.div>
        {/* </Box> */}

        {RightComponent ? (
          <motion.div style={getSx([fadeHeading ? opacityStyle : undefined])}>
            {RightComponent}
          </motion.div>
        ) : (
          <View style={{ width: getPx(padding.xxl) }} />
        )}
      </motion.div>
    </>
  );
};

export default AppFramerHeader;
