import React, { useEffect, useState } from "react";
import AppPressable from "./AppPressable";
import AppButton from "./AppButton";
import AppModal from "./modals/AppModal";
import { useDisclosure } from "@chakra-ui/react";

const AppModalCardBS = ({
  component,
  buttonComponent,
  buttonTitle,
  buttonVariant = "default",
  buttonIconLeft,
  buttonIconRight,
  buttonIconSize,
  buttonLoading,
  buttonNoTranslate,
  buttonDisabled,
  buttonFontSize,
  buttonStyle,
  modalHeight = ["95%"],
  modalButtonTitle,
  modalButtonStyle,
  modalButtonIconLeft,
  modalButtonIconRight,
  modalButtonIsDisabled,
  modalIsSubmitting,
  modalButtonVariant,
  modalButtonOnPress,
  noButton,
  showModal,
  setShowModal,
  pill,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalVisible, setModalVisible] = useState(false);

  const handlePressModal = (show) => {
    if (show) {
      setModalVisible(true);
      if (setShowModal) setShowModal(true);
    } else {
      setModalVisible(false);
      if (setShowModal) setShowModal(false);
    }
  };

  useEffect(() => {
    if (modalVisible) {
      onOpen();
    } else {
      onClose();
    }
  }, [modalVisible]);

  return (
    <>
      {!noButton && (
        <>
          {buttonComponent ? (
            <AppPressable
              isDisabled={buttonDisabled}
              onPress={() => handlePressModal(!modalVisible)}
            >
              {buttonComponent}
            </AppPressable>
          ) : (
            <AppButton
              noTranslate={buttonNoTranslate}
              isLoading={buttonLoading}
              loaderSize="small"
              buttonStyle={buttonStyle}
              variant={buttonVariant}
              title={buttonTitle}
              IconLeft={buttonIconLeft}
              IconRight={buttonIconRight}
              iconSize={buttonIconSize}
              onPress={() => handlePressModal(!modalVisible)}
              isDisabled={buttonDisabled}
              pill={pill}
              fontSize={buttonFontSize}
            />
          )}
        </>
      )}
      <AppModal
        isOpen={isOpen}
        onClose={() => handlePressModal(false)}
        Component={component}
        // buttonTitle={modalButtonTitle}
        // buttonStyle={modalButtonStyle}
        // buttonIconLeft={modalButtonIconLeft}
        // buttonIconRight={modalButtonIconRight}
        // buttonIsDisabled={modalButtonIsDisabled}
        // isLoading={modalIsSubmitting}
        // buttonVariant={modalButtonVariant}
        // onPress={modalButtonOnPress}
      />
    </>
  );
};

export default AppModalCardBS;
