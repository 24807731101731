import React, { useCallback, useRef, useMemo, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useToast as useToastChakra,
  Button,
  useDisclosure,
  ButtonGroup,
  Flex,
  Box,
  HStack,
  Center,
  CloseButton,
  Stack,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Tabs,
  VStack,
} from "@chakra-ui/react";
import {
  FiCheck,
  FiCheckCircle,
  FiXCircle,
  FiUser,
  FiLock,
  FiKey,
  FiFeather,
  FiUnlock,
  FiUserCheck,
  FiMail,
} from "react-icons/fi";
import * as Yup from "yup";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";

import colors from "../../config/colors";
import { ReactComponent as YouwhoLogo } from "../../assets/svg/youwho-logos_logo-tab-icon.svg";
import padding from "../../config/padding";
import AppText from "../AppText";
import AppTextTranslate from "../AppTextTranslate";
import { getPx } from "../../utils/helpers";
import borders from "../../config/borders";
import AppButton from "../AppButton";
import { useAuth } from "../../hooks/useAuth";
import { useYouwho } from "../../package/react-youwho";
import AppForm from "../forms/AppForm";
import AppFormField from "../forms/AppFormField";
import AppFormCheckBox from "../forms/AppFormCheckBox";
import SubmitButton from "../forms/SubmitButton";
import ListItemSeparator from "../lists/ListItemSeparator";
import { fontConfig as fonts } from "../../config/fonts";
import { useToast } from "../../hooks/useToast";
import View from "../rnMimic/View";
import configLocal from "../../config/configLocal";
import AppPressable from "../AppPressable";
import { useTranslateText } from "../../hooks/useTranslate";
import AppTextLang from "../AppTextLang";

const validationSchemaSignup = Yup.object().shape({
  username: Yup.string().required().min(4).label("Username"),
  email: Yup.string().required().email().label("Email"),
  password: Yup.string()
    .required()
    .min(4)
    // .matches(
    //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
    //   "Password must have at least 8 characters which contains at least 1 lower case, 1 upper case and 1 digit"
    // )
    .label("Password"),
  agreed: Yup.boolean()
    .required()
    .isTrue()
    .label("Terms of Service and Privacy Policy"),
  refer: Yup.string().min(4).label("Referral code"),
});

const validationSchemaLogin = Yup.object().shape({
  email: Yup.string().required().min(4).label("Email"),
  password: Yup.string()
    .required()
    .min(4)
    // .matches(
    //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
    //   "Password must have at least 8 characters which contains at least 1 lower case, 1 upper case and 1 digit"
    // )
    .label("Password"),
});

const validationSchemaReset = Yup.object().shape({
  email: Yup.string().required().min(4).label("Email"),
});

const axiosInstance2 = axios.create({
  baseURL: process.env.REACT_APP_YOUWHO_SERVER_URL,
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
  },
});

const LoginSignupModal = ({ isOpen, onClose, defaultIndex = 0 }) => {
  const { appStore, login, signup, handleError, Parse } = useYouwho();
  const { loginModalObject } = appStore;
  const { AuthButtonsComponent } = useAuth();
  const [checked, setChecked] = useState(false);

  const cancelRef = useRef();
  const recaptchaLogin = useRef();
  const recaptchaSignup = useRef();
  const recaptchaReset = useRef();

  const handleTabsChange = (index) => {
    loginModalObject?.setDefaultIndex(index);
  };

  const handleClose = () => {
    loginModalObject?.setDefaultIndex(0);
    onClose();
  };

  const Toast = useToast();

  const handleSignup = async ({ username, password, email, agreed, refer }) => {
    try {
      const captchaValue = recaptchaSignup.current.getValue();
      if (!captchaValue) {
        handleError("Please verify the reCAPTCHA", "Signup error");
      } else {
        const data = { captchaValue };
        const { data: responseData } = await axiosInstance2({
          method: "post",
          url: process.env.REACT_APP_YOUWHO_RECAPTCHA_ENDPOINT,
          data,
        });

        if (responseData.success) {
          // make form submission
          await signup(username, password, email, agreed, refer);
          handleClose();
        } else {
          handleError("reCAPTCHA validation failed", "Signup error");
        }
      }
    } catch (error) {
      handleError(error.message, "Signup error");
    }
  };

  const handleLogin = async ({ email, password }) => {
    try {
      const captchaValue = recaptchaLogin.current.getValue();
      if (!captchaValue) {
        handleError("Please verify the reCAPTCHA", "Login error");
      } else {
        const data = { captchaValue };
        const { data: responseData } = await axiosInstance2({
          method: "post",
          url: process.env.REACT_APP_YOUWHO_RECAPTCHA_ENDPOINT,
          data,
        });

        if (responseData.success) {
          // make form submission
          await login(email, password);
          handleClose();
        } else {
          handleError("reCAPTCHA validation failed", "Login error");
        }
      }
    } catch (error) {
      handleError(error.message, "Login error");
    }
  };

  const handleReset = async ({ email }) => {
    try {
      const captchaValue = recaptchaReset.current.getValue();
      if (!captchaValue) {
        handleError("Please verify the reCAPTCHA", "Reset password error");
      } else {
        const data = { captchaValue };
        const { data: responseData } = await axiosInstance2({
          method: "post",
          url: process.env.REACT_APP_YOUWHO_RECAPTCHA_ENDPOINT,
          data,
        });

        if (responseData.success) {
          // make form submission
          await Parse.Cloud.run("sendPasswordResetEmail", { email });
          Toast.show({
            type: "appAlert",
            visibilityTime: 10000,
            position: "top",
            props: {
              title: "Success!",
              subtitle: `Password reset link sent to ${email}`,
              variant: "success",
            },
          });
          handleClose();
        } else {
          handleError("reCAPTCHA validation failed", "Reset password error");
        }
      }
    } catch (error) {
      handleError(error.message, "Reset password error");
    }
  };

  return (
    <>
      <Modal
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={handleClose}
        isOpen={isOpen}
        isCentered
      >
        <ModalOverlay />

        <ModalContent
          sx={{
            borderRadius: getPx(borders.borderRadius20),
            overflow: "hidden",
          }}
        >
          <Tabs
            variant="enclosed"
            defaultIndex={defaultIndex}
            index={defaultIndex}
            onChange={handleTabsChange}
          >
            <TabList>
              <Tab
                sx={{
                  borderTopLeftRadius: getPx(borders.borderRadius20),
                  borderTopRightRadius: getPx(borders.borderRadius20),
                  _selected: {
                    bg: colors.primary,
                    color: colors.white,
                    fontWeight: 600,

                    // borderWidth: 0,
                  },
                }}
              >
                {useTranslateText({ toTranslate: "Login", fromLanguage: "en" })}
              </Tab>
              <Tab
                sx={{
                  borderTopLeftRadius: getPx(borders.borderRadius20),
                  borderTopRightRadius: getPx(borders.borderRadius20),
                  _selected: {
                    bg: colors.info,
                    color: colors.white,
                    fontWeight: 600,
                  },
                }}
              >
                {useTranslateText({
                  toTranslate: "Signup",
                  fromLanguage: "en",
                })}
              </Tab>
              <Tab
                sx={{
                  borderTopLeftRadius: getPx(borders.borderRadius20),
                  borderTopRightRadius: getPx(borders.borderRadius20),
                  _selected: {
                    bg: colors.black,
                    color: colors.white,
                    fontWeight: 600,
                  },
                }}
              >
                {useTranslateText({ toTranslate: "Reset", fromLanguage: "en" })}
              </Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                {/* <Box
                    sx={{
                      width: "20%",
                      justifyContent: "center",
                      alignItems: "center",
                      // bg: "pink",
                      paddingLeft: getPx(padding.ms),
                    }}
                  >
                    <Center>
                      <YouwhoLogo />
                    </Center>
                  </Box> */}
                <Box
                  sx={{
                    width: "100%",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                  }}
                >
                  <ModalHeader>
                    <Center>
                      <YouwhoLogo width={"30%"} />
                    </Center>
                  </ModalHeader>
                  <ModalCloseButton
                    onClick={handleClose}
                    sx={{
                      borderRadius: getPx(borders.borderRadius12),
                      right: getPx(padding.sm),
                      top: getPx(padding.sm),
                      _hover: { background: "none" },
                    }}
                  />
                  <AppForm
                    initialValues={{
                      email: "",
                      password: "",
                    }}
                    onSubmit={handleLogin}
                    validationSchema={validationSchemaLogin}
                  >
                    <ModalBody>
                      <AuthButtonsComponent />
                      <Center>
                        <ListItemSeparator
                          style={{
                            marginY: getPx(padding.primary),
                            width: "20%",
                            height: getPx(2),
                          }}
                        />
                      </Center>

                      <View flexStart>
                        <View spacing={2} flexStart>
                          <AppFormField
                            Icon={FiUser}
                            name="email"
                            label={"Email / Username"}
                            noPaste
                            noErrorMessage
                          />
                          <AppFormField
                            Icon={FiLock}
                            name="password"
                            label={"Password"}
                            secureTextEntry
                            textContentType="password"
                            noPaste
                            noErrorMessage
                          />
                        </View>
                        <AppPressable
                          noAnimate
                          onPress={() => {
                            loginModalObject?.setDefaultIndex(2);
                          }}
                          style={{
                            flexDirection: "row",
                            paddingTop: getPx(padding.sm),
                            paddingBottom: getPx(padding.md),
                          }}
                        >
                          <AppTextLang
                            langKey="forgotPassword"
                            variant="md"
                            sx={{ marginRight: getPx(padding.sm) }}
                          />
                          <AppTextLang
                            langKey="resetPassword"
                            variant="mdb"
                            underline
                          />
                        </AppPressable>
                        <ReCAPTCHA
                          ref={recaptchaLogin}
                          sitekey={
                            process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY
                          }
                        />
                      </View>
                    </ModalBody>
                    <ModalFooter>
                      <View>
                        <SubmitButton
                          title="Login"
                          IconLeft={FiKey}
                          variant="primary"
                          fontSize={fonts.size.primary}
                          pressableStyle={{ width: "100%" }}
                          buttonStyle={{
                            // width: "100%",
                            // flex: 1,
                            paddingY: getPx(padding.md),
                            paddingX: getPx(padding.primary),
                          }}
                        />
                        <AppPressable
                          noAnimate
                          onPress={() => {
                            loginModalObject?.setDefaultIndex(1);
                          }}
                          style={{
                            flexDirection: "row",
                            paddingTop: getPx(padding.sm),
                            paddingBottom: getPx(padding.md),
                          }}
                        >
                          <AppTextTranslate
                            fromLanguage="en"
                            variant="md"
                            sx={{ marginRight: getPx(padding.sm) }}
                          >
                            Don't have an account?
                          </AppTextTranslate>
                          <AppTextTranslate
                            fromLanguage="en"
                            variant="mdb"
                            underline
                          >
                            Signup
                          </AppTextTranslate>
                        </AppPressable>
                      </View>
                    </ModalFooter>
                  </AppForm>
                </Box>
              </TabPanel>
              <TabPanel>
                {/* <Box
                    sx={{
                      width: "20%",
                      justifyContent: "center",
                      alignItems: "center",
                      // bg: "pink",
                      paddingLeft: getPx(padding.ms),
                    }}
                  >
                    <Center>
                      <YouwhoLogo style={{ color: colors.primary }} />
                    </Center>
                  </Box> */}
                <Box sx={{ width: "100%" }}>
                  <ModalHeader>
                    <Center>
                      <YouwhoLogo
                        width={"20%"}
                        style={{ color: colors.primary }}
                      />
                    </Center>
                  </ModalHeader>
                  <ModalCloseButton
                    onClick={handleClose}
                    sx={{
                      borderRadius: getPx(borders.borderRadius12),
                      right: getPx(padding.sm),
                      top: getPx(padding.sm),
                      _hover: { background: "none" },
                    }}
                  />
                  <AppForm
                    initialValues={{
                      username: "",
                      email: "",
                      password: "",
                      refer: "",
                      agreed: false,
                    }}
                    onSubmit={handleSignup}
                    validationSchema={validationSchemaSignup}
                  >
                    <ModalBody>
                      <AuthButtonsComponent />
                      <Center>
                        <ListItemSeparator
                          style={{
                            marginY: getPx(padding.primary),
                            width: "20%",
                            height: getPx(2),
                          }}
                        />
                      </Center>
                      <VStack
                        spacing={2}
                        sx={{
                          alignItems: "flex-start",
                        }}
                      >
                        <AppFormField
                          Icon={FiUserCheck}
                          name="username"
                          label={"Username"}
                          noPaste
                          noErrorMessage
                        />

                        <AppFormField
                          Icon={FiMail}
                          name="email"
                          label={"Email"}
                          textContentType="email"
                          noPaste
                          noErrorMessage
                        />

                        <AppFormField
                          Icon={FiLock}
                          name="password"
                          label={"Password"}
                          secureTextEntry
                          textContentType="password"
                          noPaste
                          noErrorMessage
                        />

                        <View
                          direction="row"
                          style={{
                            marginBottom: getPx(padding.sm),
                            paddingX: getPx(padding.sm),
                            // flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <AppFormCheckBox
                            name="agreed"
                            checked={checked}
                            size="md"
                            handleCheck={() => {
                              setChecked(true);
                            }}
                            handleUncheck={() => {
                              setChecked(false);
                            }}
                          />

                          <View
                            direction="row"
                            style={{
                              paddingLeft: getPx(padding.ms),
                              // flexDirection: "row",
                              flexWrap: "wrap",
                              flex: 1,
                            }}
                          >
                            <AppTextTranslate
                              fromLanguage="en"
                              variant="md"
                              sx={{ marginRight: getPx(padding.sm) }}
                            >
                              I agree to the YOUWHO
                            </AppTextTranslate>
                            <a
                              href={configLocal.url.tos}
                              target="_blank"
                              className="button-animation"
                            >
                              <AppTextTranslate
                                fromLanguage="en"
                                variant="mdb"
                                style={{
                                  color: colors.primary,
                                }}
                              >
                                Terms of Service
                              </AppTextTranslate>
                            </a>
                            <AppTextTranslate
                              fromLanguage="en"
                              variant="md"
                              sx={{ marginX: getPx(padding.sm) }}
                            >
                              and
                            </AppTextTranslate>
                            <a
                              href={configLocal.url.privacy}
                              target="_blank"
                              className="button-animation"
                            >
                              <AppTextTranslate
                                fromLanguage="en"
                                variant="mdb"
                                style={{
                                  color: colors.primary,
                                }}
                              >
                                Privacy Policy
                              </AppTextTranslate>
                            </a>
                          </View>
                        </View>
                        <ReCAPTCHA
                          ref={recaptchaSignup}
                          sitekey={
                            process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY
                          }
                        />
                      </VStack>
                    </ModalBody>
                    <ModalFooter>
                      <View>
                        <SubmitButton
                          title="Signup"
                          fontSize={fonts.size.primary}
                          pressableStyle={{ width: "100%" }}
                          buttonStyle={{
                            // width: "100%",
                            // flex: 1,
                            paddingY: getPx(padding.md),
                            paddingX: getPx(padding.primary),
                          }}
                          // variant="info"
                          IconLeft={FiFeather}
                        />
                        <AppPressable
                          noAnimate
                          onPress={() => {
                            loginModalObject?.setDefaultIndex(0);
                          }}
                          style={{
                            flexDirection: "row",
                            paddingTop: getPx(padding.sm),
                            paddingBottom: getPx(padding.md),
                          }}
                        >
                          <AppTextTranslate
                            fromLanguage="en"
                            variant="md"
                            sx={{ marginRight: getPx(padding.sm) }}
                          >
                            Already have an account?
                          </AppTextTranslate>
                          <AppText> </AppText>
                          <AppTextTranslate
                            fromLanguage="en"
                            variant="mdb"
                            underline
                          >
                            Login
                          </AppTextTranslate>
                        </AppPressable>
                      </View>
                    </ModalFooter>
                  </AppForm>
                </Box>
              </TabPanel>
              <TabPanel>
                {/* <Box
                    sx={{
                      width: "20%",
                      justifyContent: "center",
                      alignItems: "center",
                      // bg: "pink",
                      paddingLeft: getPx(padding.ms),
                    }}
                  >
                    <Center>
                      <YouwhoLogo />
                    </Center>
                  </Box> */}
                <Box
                  sx={{
                    width: "100%",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                  }}
                >
                  <ModalHeader>
                    <Center>
                      <YouwhoLogo width={"30%"} />
                    </Center>
                  </ModalHeader>
                  <ModalCloseButton
                    onClick={handleClose}
                    sx={{
                      borderRadius: getPx(borders.borderRadius12),
                      right: getPx(padding.sm),
                      top: getPx(padding.sm),
                      _hover: { background: "none" },
                    }}
                  />
                  <AppForm
                    initialValues={{
                      email: "",
                    }}
                    onSubmit={handleReset}
                    validationSchema={validationSchemaReset}
                  >
                    <ModalBody>
                      <Center>
                        <ListItemSeparator
                          style={{
                            marginY: getPx(padding.primary),
                            width: "20%",
                            height: getPx(2),
                          }}
                        />
                      </Center>

                      <VStack
                        spacing={5}
                        sx={{
                          alignItems: "flex-start",
                        }}
                      >
                        <AppTextTranslate fromLanguage="en" variant="h3">
                          Reset password
                        </AppTextTranslate>
                        <AppFormField
                          Icon={FiMail}
                          // maxLength={19}
                          // showMaxLength
                          name="email"
                          label={"Email"}
                          textContentType="text"
                          noPaste
                          noErrorMessage
                        />
                        <ReCAPTCHA
                          ref={recaptchaReset}
                          sitekey={
                            process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY
                          }
                        />
                      </VStack>
                    </ModalBody>
                    <ModalFooter>
                      <SubmitButton
                        title="Reset"
                        IconLeft={FiUnlock}
                        // variant="primary"
                        fontSize={fonts.size.primary}
                        pressableStyle={{ width: "100%" }}
                        buttonStyle={{
                          // width: "100%",
                          // flex: 1,
                          paddingY: getPx(padding.md),
                          paddingX: getPx(padding.primary),
                        }}
                      />
                      {/* <AppButton onPress={onOpenMfa} title="mfa" /> */}
                    </ModalFooter>
                  </AppForm>
                </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalContent>
      </Modal>
    </>
  );
};

export default LoginSignupModal;
