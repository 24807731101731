import React from "react";
import { useFormikContext } from "formik";

import AppButton from "../AppButton";

export default function SubmitButton({ isLoading, isDisabled, ...otherProps }) {
  const { handleSubmit, isSubmitting, isValid } = useFormikContext();
  return (
    <AppButton
      isLoading={isLoading ? isLoading : isSubmitting}
      isDisabled={
        isDisabled
          ? isDisabled
          : isLoading
          ? isLoading
          : isSubmitting
          ? isSubmitting
          : !isValid
      }
      onPress={handleSubmit}
      {...otherProps}
    />
  );
}

