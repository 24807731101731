import React from "react";

import colors from "../../config/colors";
import View from "../rnMimic/View";
import { getPx, getSx } from "../../utils/helpers";

function ListItemSeparator({ style }) {
  return <View style={getSx([styles.separator, style])} />;
}

const styles = Object.create({
  separator: {
    width: "100%",
    height: getPx(1),
    backgroundColor: colors.grayD,
  },
});

export default ListItemSeparator;

