export default class TranslationResult {
  text = "";
  pronunciation = undefined;
  from = {
    language: {
      didYouMean: undefined,
      iso: "",
    },
    text: {
      autoCorrected: undefined,
      value: "",
      didYouMean: undefined,
    },
  };
  raw = undefined;
  constructor(raw) {
    this.raw = raw;
  }
}
