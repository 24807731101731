import React, { useState, useMemo, useCallback, useEffect } from "react";
import dayjs from "dayjs";
import { MdOutlineSupervisedUserCircle } from "react-icons/md";
import { FiKey, FiSave, FiUserX } from "react-icons/fi";
import { FaDollarSign } from "react-icons/fa6";

import colors from "../../../config/colors";
import { fontConfig as fonts } from "../../../config/fonts";
import AppButton from "../../../components/AppButton";
// import AppListItem from "../../components/lists/AppListItem";
import AppText from "../../../components/AppText";
import ListItemSeparator from "../../../components/lists/ListItemSeparator";
import padding from "../../../config/padding";
import AppTextTranslate from "../../../components/AppTextTranslate";
// import AppModalCardBS from "../../components/AppModalCardBS";
import {
  setAutoTranslateRedux,
  setDisplayLanguageRedux,
} from "../../../store/slices/app";
// import AppModalMoreBS from "../../components/AppModalMoreBS";
import { arrayExists, getLangKey, getPx } from "../../../utils/helpers";
import Screen from "../../../components/Screen";
import View from "../../../components/rnMimic/View";
import AppListItem from "../../../components/lists/AppListItem";
import AppModalMoreBS from "../../../components/AppModalMoreBS";
import AppSwitch from "../../../components/AppSwitch";
import { IoGitBranch, IoLanguage } from "react-icons/io5";
import configLocal from "../../../config/configLocal";
import { HiMiniFingerPrint } from "react-icons/hi2";
import { SiGoogletranslate } from "react-icons/si";
import AppModalCardBS from "../../../components/AppModalCardBS";
import AppModalMultiCheckboxSearchSave from "../../../components/AppModalMultiCheckboxSearchSave";
import { useYouwho } from "../../../package/react-youwho";
import SplashScreen from "../../SplashScreen";
import AppViewLayout from "../../../components/AppViewLayout";
import AppHeaderRefreshIcon from "../../../components/headers/AppHeaderRefreshIcon";
import AppAvatar from "../../../components/AppAvatar";
import AppStar from "../../../components/AppStar";
import { FaUserCheck } from "react-icons/fa";
import AppPressable from "../../../components/AppPressable";
import { useNavigate } from "react-router-dom";
import { setNaviPublicProfileRedux } from "../../../store/slices/navi";
import AppTextLang from "../../../components/AppTextLang";

function AccountScreen({}) {
  // const { isOpen, onOpen, onClose } = useDisclosure();
  // const [defaultIndex, setDefaultIndex] = useState(0);
  const navigation = useNavigate();
  const {
    appStore,
    isLoading,
    dispatch,
    logout,
    refetchUserData,
    setIsLoading,
    user,
    urlQuery,
  } = useYouwho();
  const [switchIsLoading, setSwitchIsLoading] = useState(false);
  const { displayLanguage, autoTranslate, loginModalObject, config } = appStore;
  const handlePressProfile = async () => {
    dispatch(
      setNaviPublicProfileRedux(user.attributes?.userPublic?.attributes)
    );
    setTimeout(() => navigation("/profile/" + user.get("userPublic").id), 100);

    // info: user.attributes?.userPublic?.attributes
  };

  const displayLanguageObject = useMemo(
    () =>
      arrayExists(config?.languageList) &&
      config?.languageList?.find(
        (x) => x.languageCode.toLowerCase() === displayLanguage.toLowerCase()
      ),
    [config?.languageList, displayLanguage]
  );

  const initialData = useMemo(
    () =>
      arrayExists(config?.languageList) &&
      config?.languageList?.filter((x) => x["languageCodeDesktop"]),
    [config?.languageList]
  );

  const handleAutoTranslate = async () => {
    try {
      setSwitchIsLoading(true);
      dispatch(setAutoTranslateRedux(!autoTranslate));
    } catch (error) {
      console.log("handleTranslate error: " + error);
    } finally {
      setSwitchIsLoading(false);
    }
  };

  const handleDisplayLanguage = async (classTitle, data) => {
    // console.log("data: " + JSON.stringify(data));
    try {
      setSwitchIsLoading(true);
      dispatch(setDisplayLanguageRedux(data[0].languageCode));
    } catch (error) {
      console.log("handleDisplayLanguage error: " + error);
    } finally {
      setSwitchIsLoading(false);
    }
  };

  const userLogout = async () => {
    try {
      setIsLoading(true);
      dispatch(setDisplayLanguageRedux("en"));
      // await dispatch(setUserFavoritesRedux([]));
      await logout();
      // await logoutM();
      console.log(
        "######################## LOGGED OUT USER ########################"
      );
    } catch (error) {
      console.log("Error logging out :", error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <SplashScreen />;
  }

  return (
    <Screen title={`${user.get("username")}'s Account`}>
      <View flexStart style={styles.container} spacing={3}>
        <AppViewLayout fullWidth>
          <View
            direction="row"
            style={{
              justifyContent: "space-between",
              alignItems: "baseline",
            }}
          >
            <AppText
              variant="h1"
              numberOfLines={3}
              style={{
                marginBottom: getPx(padding.md),
                fontSize: getPx(fonts.size.xh1),
                paddingRight: getPx(padding.xl),
              }}
            >
              {user.attributes?.username}
            </AppText>

            <AppHeaderRefreshIcon
              onPress={refetchUserData}
              // onPress={handleAddNotificationSettings}
              //   style={{ right: getPx(-padding.ms), top: 0 }}
            />
          </View>

          <View direction="row">
            <AppAvatar
              name={user.attributes?.username}
              url={
                user.attributes?.userPublic?.attributes?.avatar?._url ??
                user.attributes?.userPublic?.attributes?.avatarUrl
              }
              style={{ marginBottom: getPx(padding.ms) }}
              variant="lg"
              pointer={user.attributes?.userPublic?.attributes}
            />

            <View style={{ marginLeft: getPx(padding.md) }} spacing={1}>
              <View direction="row" style={{ alignItems: "center" }}>
                <AppStar
                  sx={{
                    // marginBottom: getPx(padding.one),
                    // marginLeft: getPx(padding.sm),
                    bottom: getPx(2),
                    color: colors.black,
                    marginRight: user.attributes?.userPublic?.attributes
                      ?.identityVerified
                      ? getPx(padding.sm)
                      : 0,
                  }}
                  value={
                    user?.attributes?.isCustomer
                      ? user.attributes?.userPublic?.attributes
                          ?.reviewsAsCustomer
                        ? user.attributes?.userPublic?.attributes
                            ?.ratingAsCustomer / 5
                        : 0
                      : user.attributes?.userPublic?.attributes
                          ?.reviewsAsProvider
                      ? user.attributes?.userPublic?.attributes
                          ?.ratingAsProvider / 5
                      : 0
                  }
                  starSize={fonts.size.primary}
                />
                <AppText variant="lg">
                  {user?.attributes?.isCustomer
                    ? user.attributes?.userPublic?.attributes?.reviewsAsCustomer
                      ? " " +
                        user.attributes?.userPublic?.attributes
                          ?.ratingAsCustomer
                      : null
                    : user.attributes?.userPublic?.attributes?.reviewsAsProvider
                    ? " " +
                      user.attributes?.userPublic?.attributes?.ratingAsProvider
                    : null}
                  &nbsp;•&nbsp;
                </AppText>
                <AppText variant="lg">
                  {`${
                    user?.attributes?.isCustomer
                      ? user.attributes?.userPublic?.attributes
                          ?.reviewsAsCustomer
                        ? user.attributes?.userPublic?.attributes
                            ?.reviewsAsCustomer
                        : 0
                      : user.attributes?.userPublic?.attributes
                          ?.reviewsAsProvider
                      ? user.attributes?.userPublic?.attributes
                          ?.reviewsAsProvider
                      : 0
                  } reviews`}
                </AppText>
              </View>

              {user.attributes?.userPublic?.attributes?.identityVerified && (
                <View direction="row" style={{ alignItems: "center" }}>
                  <FaUserCheck
                    color={colors.black}
                    size={fonts.size.lg}
                    style={{
                      bottom: getPx(2),
                      marginRight: getPx(padding.ms),
                    }}
                  />

                  <AppTextTranslate variant="lg">
                    Identity Verified
                  </AppTextTranslate>
                </View>
              )}

              <View direction="row">
                <AppTextTranslate
                  variant="md"
                  style={{
                    color: colors.gray6,
                    marginRight: getPx(padding.xs),
                  }}
                >
                  Joined in
                </AppTextTranslate>

                <AppTextTranslate variant="md" style={{ color: colors.gray6 }}>
                  {dayjs(
                    user.attributes?.userPublic?.attributes?.createdAt
                  ).format("MMMM YYYY")}
                </AppTextTranslate>
              </View>

              <AppTextTranslate variant="md" capitalize>
                {user?.attributes?.isCustomer
                  ? "Customer"
                  : user.attributes?.userPublic?.attributes?.userType +
                    " Provider"}
              </AppTextTranslate>
            </View>
          </View>

          <AppPressable
            style={{ marginTop: getPx(padding.sm) }}
            flexStart
            noAnimate
            onPress={handlePressProfile}
          >
            <AppTextTranslate
              variant="lgb"
              underline
              // fromLanguage={fromLanguage}
              capitalize
            >
              view profile
            </AppTextTranslate>
          </AppPressable>
        </AppViewLayout>

        <View>
          <AppTextLang
            variant="h3"
            style={styles.heading}
            langKey="accountSettings"
          />

          {arrayExists(initialData) && (
            <>
              <AppListItem
                IconComponent={
                  <IoLanguage size={fonts.size.h2} style={styles.icon} />
                }
                title={"Language"}
                RightComponent={
                  <AppModalCardBS
                    buttonNoTranslate
                    buttonTitle={
                      <AppText variant="h3">
                        {displayLanguage.toUpperCase()}
                      </AppText>
                    }
                    buttonDisabled
                    buttonVariant="underline"
                    buttonFontSize={fonts.size.primary}
                    // modalHeight="95%"
                    component={
                      <AppModalMultiCheckboxSearchSave
                        classTitle="displayLanguage"
                        onPressButton={handleDisplayLanguage}
                        heading="Language"
                        buttonIconRight={FiSave}
                        buttonTitle="Save"
                        initialData={initialData}
                        initialValue={
                          displayLanguageObject
                            ? [displayLanguageObject]
                            : undefined
                        }
                        firstPropertyVariable="languageNameEnglish"
                        secondPropertyVariable="languageNameNative"
                        thirdPropertyVariable="languageCode"
                        single
                      />
                    }
                  />
                }
              />
              <ListItemSeparator />
            </>
          )}

          <AppListItem
            IconComponent={
              <SiGoogletranslate size={fonts.size.h2} style={styles.icon} />
            }
            TitleComponent={
              <View style={{ flexDirection: "row" }}>
                <AppTextTranslate fromLanguage="en" variant="lg">
                  Auto translate
                </AppTextTranslate>
                <AppModalMoreBS
                  MoreComponent={
                    <>
                      <AppTextTranslate
                        fromLanguage="en"
                        variant="h2"
                        style={{ marginBottom: getPx(padding.primary) }}
                      >
                        Auto translate
                      </AppTextTranslate>

                      <AppTextTranslate fromLanguage="en" variant="md">
                        Enabling Auto translate will automatically translate
                        information relating to services.
                      </AppTextTranslate>
                    </>
                  }
                />
              </View>
            }
            RightComponent={
              <>
                <AppSwitch
                  switchInitialState={autoTranslate}
                  isLoading={switchIsLoading}
                  onPress={handleAutoTranslate}
                />
              </>
            }
          />
          <ListItemSeparator />

          <AppListItem
            IconComponent={
              <FaDollarSign size={fonts.size.h2} style={styles.icon} />
            }
            TitleComponent={
              <View direction="row">
                <AppTextTranslate fromLanguage="en" variant="lg">
                  Currency to display
                </AppTextTranslate>
                <AppModalMoreBS
                  MoreComponent={
                    <>
                      <AppTextTranslate
                        fromLanguage="en"
                        variant="h2"
                        style={{ marginBottom: getPx(padding.primary) }}
                      >
                        Currency to display
                      </AppTextTranslate>

                      <AppTextTranslate
                        format={"html"}
                        fromLanguage="en"
                        variant="md"
                      >
                        This is the currency that will be used when calculating
                        prices for services in the App. The display currency is
                        not the same as the currency that will be used to pay
                        for services.
                        <br />
                        <br />
                      </AppTextTranslate>

                      <AppTextTranslate
                        format={"html"}
                        fromLanguage="en"
                        variant="md"
                      >
                        the `YOUWHO` app accepts two main forms of payment, that
                        being `cryptocurrencies` and the other being traditional
                        methods such as bank transfers.
                        <br />
                        <br />
                      </AppTextTranslate>

                      <AppTextTranslate fromLanguage="en" variant="md">
                        Cryptocurrencies (`USDT`, `USDC`, `YOU`) are the primary
                        payment method accepted by the app. You may also choose
                        to pay via traditional methods such as bank transfers,
                        credit cards, etc. if the service provider accepts this
                        alternative form of payment.
                      </AppTextTranslate>
                    </>
                  }
                />
              </View>
            }
            RightComponent={<AppText style={{ fontWeight: 500 }}>USD</AppText>}
          />
          <ListItemSeparator />

          <AppTextLang variant="h3" style={styles.heading} langKey="support" />
          <a
            href={configLocal.url.website}
            target="_blank"
            style={{ width: "100%" }}
            className="button-animation"
          >
            <AppListItem
              IconComponent={
                <HiMiniFingerPrint size={fonts.size.h2} style={styles.icon} />
              }
              more
              TitleComponent={
                <View direction="row">
                  <AppTextTranslate
                    fromLanguage="en"
                    variant="lg"
                    sx={{ marginRight: getPx(padding.sm) }}
                  >
                    About
                  </AppTextTranslate>
                  <AppText variant="lgb">
                    <b>YOUWHO</b>
                  </AppText>
                </View>
              }
            />
          </a>
          <ListItemSeparator />

          <a
            href={configLocal.url.discord}
            target="_blank"
            style={{ width: "100%" }}
            className="button-animation"
          >
            <AppListItem
              IconComponent={
                <MdOutlineSupervisedUserCircle
                  size={fonts.size.h2}
                  style={styles.icon}
                />
              }
              more
              TitleComponent={
                <AppTextLang
                  fromLanguage="en"
                  variant="lg"
                  sx={{ marginRight: getPx(padding.sm) }}
                  langKey={"communityForum"}
                />
              }
            />
          </a>
          <ListItemSeparator />

          <a
            href={configLocal.url.accountDeletion}
            target="_blank"
            style={{ width: "100%" }}
            className="button-animation"
          >
            <AppListItem
              IconComponent={
                <FiUserX size={fonts.size.h2} style={styles.icon} />
              }
              more
              TitleComponent={
                <AppTextLang
                  fromLanguage="en"
                  variant="lg"
                  sx={{ marginRight: getPx(padding.sm) }}
                  langKey={"accountDeletion"}
                />
              }
            />
          </a>
          <ListItemSeparator />

          <a
            href={configLocal.url.youwhoApp}
            target="_blank"
            style={{ width: "100%" }}
            className="button-animation"
          >
            <AppListItem
              IconComponent={
                <IoGitBranch size={fonts.size.h2} style={styles.icon} />
              }
              title={"Mobile app version"}
              RightComponent={
                <AppText variant="lg" style={{ fontWeight: 500 }}>
                  {configLocal.version}
                </AppText>
              }
            />
          </a>
        </View>

        <View style={{ alignItems: "flex-end", marginTop: getPx(padding.lg) }}>
          <AppButton
            IconLeft={FiKey}
            isLoading={isLoading}
            onPress={userLogout}
            title={"Logout"}
            variant="outline"
            thick
            pressableStyle={{ width: ["100%", "40%"] }}
          />
        </View>
      </View>
    </Screen>
  );
}

const styles = Object.create({
  button: { marginY: getPx(padding.xl) },
  container: {
    padding: getPx(padding.primary),
    paddingTop: getPx(padding.xxl),
    alignItems: "flex-start",
    // width: "100%",
    // flex: 1,
    // background: "red",
  },
  heading: {
    marginTop: getPx(padding.xxl),
  },
  icon: { color: colors.black },
  subHeading: { color: colors.gray7, fontSize: getPx(fonts.size.lg) },
});

export default AccountScreen;
