import React from "react";
import View from "./rnMimic/View";
import Lottie from "lottie-react";
import Animation from "../assets/animations/loadingDotsLottie.json";
import AnimationColor from "../assets/animations/loadingDotsLottieColor.json";
import AnimationGray from "../assets/animations/loadingDotsLottieGray9.json";
import Animation2 from "../assets/animations/typingDotsLottieDark.json";
import { ReactComponent as YouwhoTextLogo } from "../assets/svg/youwho-logos_text-logo-new-trans.svg";
import { getPx, getSx } from "../utils/helpers";
import { Center } from "@chakra-ui/react";

export default function AppLoadingAnimation({
  altAnimation,
  bgColor,
  color = true,
  height = 50,
  speed = 0.85,
  width = 100,
  noBg,
  solid,
  style,
  gray,
  withLogo,
}) {
  return (
    <Center
      flexDirection="column"
      sx={getSx([
        styles.loadingContainer,
        {
          // backgroundColor: bgColor
          //   ? bgColor
          //   : noBg
          //   ? "transparent"
          //   : solid
          //   ? colors.white
          //   : colors.white80,
        },
        style,
      ])}
    >
      {withLogo && <YouwhoTextLogo />}
      <Center
        sx={getSx([
          styles.lottieContainer,
          { height: getPx(height), width: getPx(width) },
        ])}
      >
        <Lottie
          speed={speed}
          loop
          animationData={
            altAnimation
              ? Animation2
              : gray
              ? AnimationGray
              : color
              ? AnimationColor
              : Animation
          }
          style={{ height: getPx(width), width: getPx(width) }}
        />
      </Center>
    </Center>
  );
}

const styles = Object.create({
  loadingContainer: {
    // alignItems: "center",
    // paddingTop: padding.md,
    // justifyContent: "center",
    zIndex: 100,
  },
  logoText: {
    // height: getPx(padding.topBar100),
    width: "100%",
  },
  lottieContainer: {
    // alignItems: "center",
    // justifyContent: "center",
    overflow: "hidden",
  },
});
