import { createSlice } from "@reduxjs/toolkit";
// dayjs
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
// dispatch
import { dispatch } from "../store";
import configLocal from "../../config/configLocal";
import { getFiatCurrencyObject } from "../../utils/helpers";

dayjs.extend(relativeTime);

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  biometricsEnabled: false,
  biometricsLocked: true,
  broadcastsProvider: [],
  displayCurrency: configLocal.displayLanguage, // not used, use fiatCurrencyObject instead
  fiatCurrencyObject: getFiatCurrencyObject("usd"),
  error: false,
  bookingsMessagesCustomer: [],
  bookingsMessagesProvider: [],
  gotLocationOnce: false,
  userMessagesCustomer: [],
  userMessagesProvider: [],
  listingsProvider: [],
  lockedReviews: [],
  wallet: {},
  userSessions: [],
  mfaEnabled: false,
  mfaPassed: false,
  newMessagesCustomer: 0,
  newMessagesProvider: 0,
  newUserMessagesCustomer: 0,
  newUserMessagesProvider: 0,
  notificationSettings: configLocal.notificationSettings,
  userFavorites: [],
  user: null,
  userEvmAddress: null,

  // user set selected
  selectedLocation: configLocal.selectedLocation,

  // user location now
  userLocation: configLocal.selectedLocation,
  newCustomerNotification: "",
  newProviderNotification: "",
};

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // SET USER
    setUserSuccess(state, action) {
      state.isLoading = false;
      state.user = action.payload;
    },

    // SET FIAT CURRENCY OBJECT
    setFiatCurrencyObjectSuccess(state, action) {
      state.isLoading = false;
      state.fiatCurrencyObject = action.payload;
    },

    // SET ENABLE BIOMETRICS
    setEnableBiometricsSuccess(state, action) {
      state.isLoading = false;
      state.biometricsEnabled = action.payload;
    },

    // SET BIOMETRICS LOCKED?
    setBiometricsLockedSuccess(state, action) {
      state.isLoading = false;
      state.biometricsLocked = action.payload;
    },

    // SET ENABLE MFA
    setEnableMfaSuccess(state, action) {
      state.isLoading = false;
      state.mfaEnabled = action.payload;
    },

    // SET PASSED MFA
    setMfaPassedSuccess(state, action) {
      state.isLoading = false;
      state.mfaPassed = action.payload;
    },

    // SET USER FAVORITES
    setUserFavoritesSuccess(state, action) {
      state.isLoading = false;
      state.userFavorites = action.payload;
    },

    // SET NEW MESSAGES CUSTOMER
    setNewMessagesCustomerSuccess(state, action) {
      state.isLoading = false;
      state.newMessagesCustomer = action.payload;
    },

    // SET NEW MESSAGES PROVIDER
    setNewMessagesProviderSuccess(state, action) {
      state.isLoading = false;
      state.newMessagesProvider = action.payload;
    },

    // SET MESSAGE ROOMS CUSTOMER
    setBookingsMessagesCustomerSuccess(state, action) {
      state.isLoading = false;
      state.bookingsMessagesCustomer = action.payload;
    },

    // SET MESSAGE ROOMS PROVIDER
    setBookingsMessagesProviderSuccess(state, action) {
      state.isLoading = false;
      state.bookingsMessagesProvider = action.payload;
    },

    // SET NEW USER MESSAGES CUSTOMER
    setNewUserMessagesCustomerSuccess(state, action) {
      state.isLoading = false;
      state.newUserMessagesCustomer = action.payload;
    },

    // SET NEW USER MESSAGES PROVIDER
    setNewUserMessagesProviderSuccess(state, action) {
      state.isLoading = false;
      state.newUserMessagesProvider = action.payload;
    },

    // SET USER MESSAGE ROOMS CUSTOMER
    setUserMessagesCustomerSuccess(state, action) {
      state.isLoading = false;
      state.userMessagesCustomer = action.payload;
    },

    // SET USER MESSAGE ROOMS PROVIDER
    setUserMessagesProviderSuccess(state, action) {
      state.isLoading = false;
      state.userMessagesProvider = action.payload;
    },

    // SET BROADCASTS TARGETED AT PROVIDER
    setBroadcastsProviderSuccess(state, action) {
      state.isLoading = false;
      state.broadcastsProvider = action.payload;
    },

    // SET LISTINGS PROVIDER
    setListingsProviderSuccess(state, action) {
      state.isLoading = false;
      state.listingsProvider = action.payload;
    },

    // GOT LOCATION ONCE
    setGotLocationOnceSuccess(state, action) {
      state.isLoading = false;
      state.gotLocationOnce = action.payload;
    },

    // SET NOTIFICATIONS
    setNotificationSettingsSuccess(state, action) {
      state.isLoading = false;
      state.notificationSettings = action.payload;
    },

    // SET WALLET
    setWalletSuccess(state, action) {
      state.isLoading = false;
      state.wallet = action.payload;
    },

    // SET USER SESSION
    setUserSessionsSuccess(state, action) {
      state.isLoading = false;
      state.userSessions = action.payload;
    },

    // CHANGE USER CURRENT LOCATION
    setSelectedLocationSuccess(state, action) {
      state.isLoading = false;
      state.selectedLocation = action.payload;
    },

    // CHANGE USER LOCATION
    setUserLocationSuccess(state, action) {
      state.isLoading = false;
      state.userLocation = action.payload;
    },

    ////// CUSTOMER NOTIFICATION
    // CHANGE NOTIFICATION
    setNewCustomerNotificationSuccess(state, action) {
      state.isLoading = false;
      state.newCustomerNotification = action.payload;
    },

    ////// PROVIDER NOTIFICATION
    // CHANGE NOTIFICATION
    setNewProviderNotificationSuccess(state, action) {
      state.isLoading = false;
      state.newProviderNotification = action.payload;
    },

    // SET LOCKED REVIEWS
    setLockedReviewsSuccess(state, action) {
      state.isLoading = false;
      state.lockedReviews = action.payload;
    },

    // SET LOGOUT
    setLogoutSuccess(state, action) {
      state.isLoading = false;
      state = undefined;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
// export const actions = slice.actions;

// ----------------------------------------------------------------------

export function setUserRedux(user, fetch = true) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      for (const item in user.attributes) {
        try {
          if (user.attributes[item].isValid() && fetch) {
            if (!user.attributes[item].isDataAvailable()) {
              await user.attributes[item].fetch();
              console.log("fetched: " + item);
            }
          }
          // console.log(
          //   "setUserRedux user.attributes?.userPublic?.attributes: " +
          //     JSON.stringify(
          //       user.attributes?.userPublic?.attributes ?? undefined
          //     )
          // );
          if (!user?.attributes?.userPublic?.isDataAvailable()) {
            console.log("$$$$$$$$$ FETCH #$$$$$$$$");
            await user.fetch();
          }
        } catch (error) {}
      }

      const fiatCurrencyObject = getFiatCurrencyObject(
        user.get("fiatCurrency") ?? "usd"
      );

      dispatch(slice.actions.setUserSuccess(user));
      dispatch(slice.actions.setFiatCurrencyObjectSuccess(fiatCurrencyObject));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setLogoutRedux() {
  return () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setLogoutSuccess());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setGotLocationOnceRedux(value) {
  return () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setGotLocationOnceSuccess(value));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setEnableBiometricsRedux(value) {
  return () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setEnableBiometricsSuccess(value));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setBiometricsLockedRedux(value) {
  return () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setBiometricsLockedSuccess(value));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setEnableMfaRedux(value) {
  return () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setEnableMfaSuccess(value));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setMfaPassedRedux(value) {
  return () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setMfaPassedSuccess(value));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setUserFavoritesRedux(value) {
  return () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setUserFavoritesSuccess(value));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setNewMessagesCustomerRedux(value) {
  return () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setNewMessagesCustomerSuccess(value));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setNewMessagesProviderRedux(value) {
  return () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setNewMessagesProviderSuccess(value));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setBookingsMessagesCustomerRedux(data) {
  return () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setBookingsMessagesCustomerSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setBookingsMessagesProviderRedux(data) {
  return () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setBookingsMessagesProviderSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setNewUserMessagesCustomerRedux(value) {
  return () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setNewUserMessagesCustomerSuccess(value));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setNewUserMessagesProviderRedux(value) {
  return () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setNewUserMessagesProviderSuccess(value));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setUserMessagesCustomerRedux(data) {
  return () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setUserMessagesCustomerSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setUserMessagesProviderRedux(data) {
  return () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setUserMessagesProviderSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setBroadcastsProviderRedux(data) {
  return () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setBroadcastsProviderSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setListingsProviderRedux(data) {
  return () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setListingsProviderSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setNotificationSettingsRedux(data) {
  return () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setNotificationSettingsSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setWalletRedux(data) {
  return () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setWalletSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setUserSessionsRedux(data) {
  return () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setUserSessionsSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setUserLocationRedux(value) {
  return () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setUserLocationSuccess(value));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setSelectedLocationRedux(value) {
  return () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setSelectedLocationSuccess(value));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// --------------------------- CUSTOMER NOTIFICATION -------------------------------------------

export function setNewCustomerNotificationRedux(value) {
  return () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setNewCustomerNotificationSuccess(value));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// --------------------------- PROVIDER NOTIFICATION -------------------------------------------

export function setNewProviderNotificationRedux(value) {
  return () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setNewProviderNotificationSuccess(value));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// -------------------------- LOCKED REVIEWS --------------------------------------------

export function setLockedReviewsRedux(value) {
  return () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setLockedReviewsSuccess(value));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
