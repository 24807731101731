import React, { useCallback } from "react";
import borders from "../config/borders";
import colors from "../config/colors";
import padding from "../config/padding";
import AppButton from "./AppButton";
import AppPressable from "./AppPressable";
import AppTextTranslate from "./AppTextTranslate";
import AppImage from "./AppImage";
import View from "./rnMimic/View";
import { arrayExists, getPx, getSx } from "../utils/helpers";
import { useNavigate } from "react-router-dom";
import AppScrollMenu from "./scrollMenu/AppScrollMenu";
import AppText from "./AppText";
import { setNaviListingRedux } from "../store/slices/navi";
import { dispatch } from "../store/store";

export default function AppUserAllListings({
  listingsUserProfileResults,
  info,
  handleInfo,
}) {
  const navigation = useNavigate();

  const handlePressListing = (index = 0) => {
    console.log("handlePressListing index", listingsUserProfileResults[index]);
    dispatch(setNaviListingRedux(listingsUserProfileResults[index]));
    setTimeout(
      () => navigation(`listing/${listingsUserProfileResults[index].id}`),
      100
    );
  };

  const renderItem = ({ item, index }) => (
    <AppPressable
      style={getSx([
        {
          width: getPx(260),
          padding: getPx(padding.sm),
          marginRight: getPx(padding.ms),
        },
      ])}
      onPress={() => handlePressListing(index)}
      flexStart
    >
      <View style={{ width: getPx(250) }}>
        {/* <AppAvatar
                name={item.attributes?.customerPublicPointer?.attributes?.username}
                url={
                  item.attributes?.customerPublicPointer?.attributes?.avatar?._url
                }
                variant="sm"
                pointer={item.attributes?.customerPublicPointer?.attributes}
              /> */}

        {/* <ImageBlurLoading
              fastImage
              source={{ uri: item.imgArray[0]._url }}
              style={styles.image}
              withIndicator={false}
            /> */}

        <AppImage
          source={item.imgArray[0]?._url}
          style={{
            height: getPx(180),
            width: getPx(250),
            borderRadius: getPx(borders.borderRadius12),
            background: colors.grayF4,
          }}
          loaderHeight={padding.xxxxl}
          loaderWidth={padding.xxxxl}
          // width={getPx(250)}
        />

        <View style={{ marginY: getPx(padding.sm) }}>
          <View direction="row">
            {/* <View style={{ bottom: 1.5 }}>
                    <Stars
                      display={item.attributes?.rating / 5}
                      count={1}
                      fullStar={
                        <FontAwesome
                          name={"star"}
                          style={[
                            styles.myStarStyle,
                            { bottom: 0, marginRight: padding.sm },
                          ]}
                          size={fonts.size.md}
                        />
                      }
                      emptyStar={
                        <FontAwesome
                          name={"star-o"}
                          style={[
                            styles.myStarStyle,
                            { bottom: 0, marginRight: padding.sm },
                          ]}
                          size={fonts.size.md}
                        />
                      }
                    />
                  </View> */}
            <AppTextTranslate
              variant="smb"
              capitalize
              style={{
                color: colors.gray6,
              }}
            >
              {item.category}
            </AppTextTranslate>
            <AppText style={{ marginX: getPx(padding.xs) }}>•</AppText>
            <AppTextTranslate noAuto variant="sm" numberOfLines={1}>
              {item.subCategory}
            </AppTextTranslate>
          </View>
          <AppTextTranslate numberOfLines={1} noAuto variant="smb">
            {item.title}
          </AppTextTranslate>
        </View>
      </View>
    </AppPressable>
  );

  const handlePressAllListing = (reviewIndex = 0, tabIndex = 0) => {
    console.log("handlePressAllListing info", info);
    console.log(
      "handlePressAllListing listingsUserProfileResults",
      listingsUserProfileResults
    );
    handleInfo("Coming soon...", "Unavailable");

    // navigation.navigate(routes.USER_ALL_LISTING_SCREEN.n, {
    //   listingsUserProfileResults: listingsUserProfileResults,
    //   info: info,
    // });
  };

  return (
    <>
      {arrayExists(listingsUserProfileResults) && (
        <>
          <AppScrollMenu
            style={{
              marginTop: getPx(padding.primary),
              marginBottom: getPx(padding.ms),
            }}

            // ListFooterComponent={<View style={{ width: padding.primary }} />}
          >
            {listingsUserProfileResults
              // .slice(0, 4)
              .map((item, index) => renderItem({ item, index }))}
          </AppScrollMenu>
          {/* {listingsUserProfileResults.length && (
                <AppButton
                  variant="outline"
                  title={`Show all ${reviews} reviews`}
                  onPress={() => handlePressReviews()}
                  buttonStyle={{
                    marginTop: padding.ms,
                  }}
                />
              )} */}
          {/* <View
            flexStart
            style={{
              paddingX: getPx(padding.md),
              marginTop: getPx(padding.ms),
            }}
          >
            <AppButton
              title={`View all ${listingsUserProfileResults.length} listings`}
              variant="underline"
              onPress={() => handlePressAllListing()}
            />
          </View> */}
        </>
      )}
    </>
  );
}
