import { useEffect, useState } from "react";
// import { Platform } from "react-native";
// import Toast from "react-native-toast-message";
// import { request, PERMISSIONS } from "react-native-permissions";
// import Geolocation from "react-native-geolocation-service";
import useGeoLocation from "react-ipgeolocation";
// import AsyncStorage from "@react-native-async-storage/async-storage";

import {
  setUserLocationRedux,
  setSelectedLocationRedux,
  setGotLocationOnceRedux,
} from "../store/slices/user";
import { getCountryNameFromCountryCode } from "../utils/helpers";
import { setDisplayLanguageRedux } from "../store/slices/app";
import { useYouwho } from "../package/react-youwho";

// export const useLocation = (appIsReady = true, delay = 0) => {
//   const { appStore, dispatch, userStore, user } = useYouwho();

//   const { displayLanguage, config } = appStore;
//   const { gotLocationOnce, userLocation } = userStore;
//   const [initialPosition, setInitialPosition] = useState(
//     user?.attributes?.location?.find((x) => x.active === true) ?? userLocation
//   );
//   const location = useGeoLocation();

//   useEffect(() => {
//     if (
//       appIsReady &&
//       !initialPosition &&
//       Boolean(!user || (user && !user.attributes?.location))
//     ) {
//       // console.log(
//       //   '$$$$$$$$$$$$$$$$$$$$$$$ ran useeffect appIsReady: ' + appIsReady,
//       // );
//       const locateCurrentPosition = () => {
//         Geolocation.getCurrentPosition(
//           (position) => {
//             setInitialPosition(position);
//           },
//           (error) => {
//             // console.log(error.code);
//             // if (error.code === 5) {
//             //   Geolocation.getCurrentPosition(
//             //     (position) => {
//             //       setInitialPosition(position);
//             //     },
//             //     (error) => {
//             //       console.log(error.code === 5);
//             //       if (error.code === 5) {
//             //       }
//             //       Toast.show({
//             //         type: "appAlert",
//             //         // And I can pass any custom props I want
//             //         props: {
//             //           subtitle: "GPS is off. Your location will be inaccurate.",
//             //           variant: "error",
//             //         },
//             //       });
//             //     },
//             //     {
//             //       accuracy: { android: "low", ios: "threeKilometers" },
//             //       timeout: 20000,
//             //       maximumAge: 1000,
//             //     }
//             //   );
//             // } else {
//             //   Toast.show({
//             //     type: "appAlert",
//             //     props: {
//             //       subtitle: JSON.stringify(error),
//             //       variant: "error",
//             //     },
//             //   });
//             // }
//             Toast.show({
//               type: "appAlert",
//               autoHide: false,
//               props: {
//                 subtitle:
//                   "We could not get your location. \nSearch results will be inaccurate.",
//                 variant: "error",
//               },
//             });
//           },
//           { enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 }
//         );
//         // setWatchId(
//         //   Geolocation.watchPosition((position) => {
//         //     setLastPosition(position);
//         //   })
//         // );
//       };

//       const requestLocationPermission = async () => {
//         if (Platform.OS === "ios") {
//           request(PERMISSIONS.IOS.LOCATION_WHEN_IN_USE).then((res) => {
//             console.log("Iphone permission location", res);
//             if (res === "granted") {
//               locateCurrentPosition();
//             }
//           });
//         } else {
//           request(PERMISSIONS.ANDROID.ACCESS_FINE_LOCATION).then((res) => {
//             console.log("Android permission location", res);
//             if (res === "granted") {
//               locateCurrentPosition();
//             }
//           });
//         }
//       };

//       setTimeout(() => requestLocationPermission(), delay);

//       //   return () => {
//       //     console.log("clear watchId", watchId);
//       //     watchId != null && Geolocation.clearWatch(watchId);
//       //   };
//     } else if (
//       user &&
//       user.attributes?.location &&
//       user.attributes?.location.length > 0
//     ) {
//       setInitialPosition(
//         user?.attributes?.location?.find((x) => x.active === true)
//       );
//     }
//   }, [appIsReady, user]);

//   useEffect(() => {
//     if (initialPosition) {
//       const position = {
//         latitude: initialPosition.coords?.latitude ?? initialPosition.latitude,
//         longitude:
//           initialPosition.coords?.longitude ?? initialPosition.longitude,
//         name:
//           initialPosition.classTitle ??
//           initialPosition.locationName ??
//           undefined,
//         address: initialPosition.address ?? undefined,
//         country: {
//           long_name:
//             initialPosition.countryName ??
//             getCountryNameFromCountryCode(location?.country) ??
//             undefined,
//           short_name:
//             initialPosition.countryCode ?? location?.country ?? undefined,
//         },
//       };
//       // console.log('%%%%%%%%%%%%%%%% dispatch position: ' + position);
//       dispatch(setUserLocationRedux(position));
//       dispatch(setSelectedLocationRedux(position));
//       setTimeout(() => dispatch(setGotLocationOnceRedux(true)), 700);
//     }
//   }, [initialPosition]);

// const initDisplayLanguage = async () => {
//   try {
//     const displayLanguageAsync = await AsyncStorage.getItem(
//       "displayLanguageAsync"
//     );
//     if (!displayLanguageAsync) {
//       const ipDisplayLanguage = config?.languageList.find(
//         (x) => x.languageCode.toLowerCase() === location.country.toLowerCase()
//       );
//       if (ipDisplayLanguage && ipDisplayLanguage.languageCode) {
//         dispatch(
//           setDisplayLanguageRedux(
//             ipDisplayLanguage.languageCode.toLowerCase()
//           )
//         );
//         await AsyncStorage.setItem(
//           "displayLanguageAsync",
//           ipDisplayLanguage.languageCode.toLowerCase()
//         );
//       }
//     }
//   } catch (error) {
//     console.log(
//       "initDisplayLanguage error: " + JSON.stringify(error.message)
//     );
//   }
// };

//   useEffect(() => {
//     if (
//       location &&
//       location.country &&
//       displayLanguage === "en" &&
//       !gotLocationOnce
//     ) {
//       // initDisplayLanguage(); // uncomment this to auto set display language based on country on first load
//     }
//   }, [location, config, displayLanguage, gotLocationOnce]);
// };

export const useGetLocation = () => {
  // console.log(
  //   '$$$$$$$$$$$$$$$$$$$$$$$ ran useeffect appIsReady: ' + appIsReady,
  // );

  const { dispatch, handleError } = useYouwho();
  const location = useGeoLocation();

  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition, showError);
    } else {
      handleError(
        "Geolocation is not supported by this browser.",
        "Location error"
      );
    }
  }

  function showPosition(position) {
    console.log("position", position);

    const positionFormatted = {
      latitude: position.coords?.latitude ?? position.latitude,
      longitude: position.coords?.longitude ?? position.longitude,
      country: {
        long_name:
          getCountryNameFromCountryCode(location?.country) ?? undefined,
        short_name: location?.country ?? undefined,
      },
    };

    dispatch(setSelectedLocationRedux(positionFormatted));
    dispatch(setUserLocationRedux(positionFormatted));
  }

  function showError(error) {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        handleError(
          "User denied the request for Geolocation.",
          "Location error"
        );
        break;
      case error.POSITION_UNAVAILABLE:
        handleError("Location information is unavailable.", "Location error");
        break;
      case error.TIMEOUT:
        handleError(
          "The request to get user location timed out.",
          "Location error"
        );
        break;
      case error.UNKNOWN_ERROR:
        handleError("An unknown error occurred.", "Location error");
        break;
    }
  }

  return getLocation;

  // const locateCurrentPosition = () => {
  //   console.log("getting location");

  //   const positionFormatted = {
  //     latitude: position.coords?.latitude ?? position.latitude,
  //     longitude: position.coords?.longitude ?? position.longitude,
  //     name: position.classTitle ?? position.locationName ?? undefined,
  //     address: position.address ?? undefined,
  //     country: {
  //       long_name:
  //         position.countryName ??
  //         getCountryNameFromCountryCode(location?.country) ??
  //         undefined,
  //       short_name: position.countryCode ?? location?.country ?? undefined,
  //     },
  //   };

  //   dispatch(setSelectedLocationRedux(positionFormatted));
  // };
};
