import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Center,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { useState, useRef } from "react";
import { ShareSocial } from "react-share-social";
import borders from "../config/borders";
import AppText from "../components/AppText";
import AppButton from "../components/AppButton";
import { FiCheck, FiCopy } from "react-icons/fi";
import { fontConfig as fonts } from "../config/fonts";
import padding from "../config/padding";
import { getPx } from "../utils/helpers";
import AppTextTranslate from "../components/AppTextTranslate";
import ListItemSeparator from "../components/lists/ListItemSeparator";
import AppListingsCardXs from "../components/AppListingsCardXs";

const ShareController = ({
  children,
  shareData,
  onInteraction,
  onSuccess,
  onError,
  onNonNativeShare,
  disabled,
}) => {
  const [openPopup, setOpenPopup] = useState(false);

  const handleNonNativeShare = () => {
    setOpenPopup(true);
  };

  const handleOnClick = async () => {
    onInteraction?.();
    if (navigator?.share) {
      try {
        await navigator.share(shareData);
        onSuccess?.();
      } catch (err) {
        onError?.(err);
      }
    } else {
      onNonNativeShare?.();
    }
  };

  return (
    <button onClick={handleOnClick} type="button" disabled={disabled}>
      {children}
    </button>
  );
};

// interface Props {
//   children: React.ReactNode;
//   shareData: ShareData;
//   onSuccess?: () => void;
//   onError?: (error?: unknown) => void;
//   onInteraction?: () => void;
//   disabled?: boolean;
// }

const SharePopup = ({ shareData, isOpen, onOpen, onClose, onError }) => {
  const [state, setState] = useState("pending");

  const cancelRef = useRef();

  const copyClicked = async () => {
    try {
      await navigator.clipboard.writeText(shareData?.url || "");
      setState("success");
    } catch (err) {
      onError && onError(err);
      setState("error");
      //   alert("error: ", err.message);
    }
  };

  const getButtonText = (state) => {
    switch (state) {
      case "success":
        return "Link copied";
      case "pending":
      default:
        return "Copy link";
    }
  };

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent
          sx={{ borderRadius: getPx(borders.borderRadius20) }}
        >
          <Box
            sx={{
              width: "100%",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            <AlertDialogHeader>
              <AppText variant="h3">{shareData.title}</AppText>
            </AlertDialogHeader>
            <AlertDialogCloseButton
              sx={{
                borderRadius: getPx(borders.borderRadius12),
                right: getPx(padding.md),
                top: getPx(padding.md),
              }}
            />

            <AlertDialogBody>
              <Box sx={{ width: "100%", paddingY: getPx(padding.ms) }}>
                <AppText variant="lgb" style={{ color: "primary" }}>
                  {shareData.url}
                </AppText>
              </Box>
            </AlertDialogBody>

            <AlertDialogFooter>
              <AppButton
                title={getButtonText(state)}
                IconRight={state === "success" ? FiCheck : FiCopy}
                variant="outline"
                fontSize={fonts.size.primary}
                pressableStyle={{ width: "100%" }}
                onPress={copyClicked}
                buttonStyle={{
                  // width: "100%",
                  //   flex: 1,
                  paddingY: getPx(padding.md),
                  //   paddingX: getPx(padding.primary),
                }}
              />
            </AlertDialogFooter>
          </Box>
        </AlertDialogContent>
      </AlertDialog>
      {/* <div>
        <div>
          <div>
            <div>
              <div>
                <div>
                  <h3></h3>
                  <button onClick={onClose}>
                    <span>Close Share</span>
                    <div aria-hidden="true">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <g id="close">
                          <path
                            id="x"
                            d="M18.717 6.697l-1.414-1.414-5.303 5.303-5.303-5.303-1.414 1.414 5.303 5.303-5.303 5.303 1.414 1.414 5.303-5.303 5.303 5.303 1.414-1.414-5.303-5.303z"
                          />
                        </g>
                      </svg>
                    </div>
                  </button>
                </div>
                <div>
                  {state === "error" ? (
                    <div>
                      <p>
                        Unable to copy to clipboard, please manually copy the
                        url to share.
                      </p>
                    </div>
                  ) : null}
                  <input value={shareData.url} readOnly />
                  <button onClick={copyClicked}>{getButtonText(state)}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

const style = {
  root: {
    // background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    // borderRadius: 3,
    padding: 0,
    // border: "1px solid black",
    // color: "white",
  },
  iconContainer: { paddingTop: 0 },
  copyContainer: {
    border: 0,
    display: "block",
    background: "white",
    padding: 0,
    marginBottom: "80px",
  },
  copyUrl: {
    overflowWrap: "break-word",
    whiteSpace: "unset",
    maxWidth: "unset",
    color: "#222222",
    fontFamily: "Quicksand, sans-serif",
    fontWeight: 700,
    color: "#222222",
    marginBottom: "20px",
    padding: "15px",
    borderRadius: 8,
    border: "1px solid #dddddd",
  },
  copyIcon: {
    // paddingTop: "20px",
    // width: "100%",
    // position: "unset",
    textAlign: "right",
    fontFamily: "Quicksand, sans-serif",
    fontSize: 17,
    fontWeight: 700,
    borderRadius: 8,
    // bottom: "-5px",
    padding: "10px 10px",
    color: "white",
    backgroundColor: "#222222",
  },
};

export const SharePopupCustom = ({
  shareData,
  isOpen,
  onOpen,
  onClose,
  onError,
  listing,
}) => {
  const [state, setState] = useState("pending");

  const cancelRef = useRef();

  const copyClicked = async () => {
    try {
      await navigator.clipboard.writeText(shareData?.url || "");
      setState("success");
    } catch (err) {
      onError && onError(err);
      setState("error");
      //   alert("error: ", err.message);
    }
  };

  const getButtonText = (state) => {
    switch (state) {
      case "success":
        return "Link copied";
      case "pending":
      default:
        return "Copy link";
    }
  };

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent
          sx={{ borderRadius: getPx(borders.borderRadius20) }}
        >
          <Box
            sx={{
              width: "100%",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            <AlertDialogHeader sx={{ marginTop: getPx(padding.ms) }}>
              <AppTextTranslate variant="h2">
                Share this listing
              </AppTextTranslate>
            </AlertDialogHeader>
            <AlertDialogCloseButton
              sx={{
                borderRadius: getPx(borders.borderRadius12),
                right: getPx(padding.md),
                top: getPx(padding.md),
              }}
            />

            <AlertDialogBody>
              <Box sx={{ width: "100%", height: "100%" }}>
                {/* <AppText>{shareData.title}</AppText> */}
                <AppListingsCardXs
                  //   shadow
                  //   border
                  cardWidth="100%"
                  category={listing?.category}
                  imgUrl={listing?.imgArray[0]?._url}
                  // onPress={() => handlePressXsCard(undefined, true, false)}
                  rating={listing?.rating}
                  reviews={listing?.reviews}
                  title={listing?.title}
                  hoverScale={1}
                  pressScale={1}
                  cursor={"auto"}
                />
                <ListItemSeparator
                  style={{ marginY: getPx(padding.primary) }}
                />
                <ShareSocial
                  url={shareData?.text}
                  socialTypes={[
                    "facebook",
                    "twitter",
                    "telegram",
                    "linkedin",
                    "reddit",
                    "whatsapp",
                    "line",
                  ]}
                  style={style}
                />
              </Box>
            </AlertDialogBody>
          </Box>
        </AlertDialogContent>
      </AlertDialog>
      {/* <div>
          <div>
            <div>
              <div>
                <div>
                  <div>
                    <h3></h3>
                    <button onClick={onClose}>
                      <span>Close Share</span>
                      <div aria-hidden="true">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <g id="close">
                            <path
                              id="x"
                              d="M18.717 6.697l-1.414-1.414-5.303 5.303-5.303-5.303-1.414 1.414 5.303 5.303-5.303 5.303 1.414 1.414 5.303-5.303 5.303 5.303 1.414-1.414-5.303-5.303z"
                            />
                          </g>
                        </svg>
                      </div>
                    </button>
                  </div>
                  <div>
                    {state === "error" ? (
                      <div>
                        <p>
                          Unable to copy to clipboard, please manually copy the
                          url to share.
                        </p>
                      </div>
                    ) : null}
                    <input value={shareData.url} readOnly />
                    <button onClick={copyClicked}>{getButtonText(state)}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
    </>
  );
};

const Share = ({
  children,
  shareData,
  onInteraction,
  onSuccess,
  onError,
  disabled,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <ShareController
        shareData={shareData}
        onInteraction={onInteraction}
        onSuccess={onSuccess}
        onError={onError}
        onNonNativeShare={onOpen}
        disabled={disabled}
      >
        {children}
      </ShareController>
      {isOpen ? (
        <SharePopup
          shareData={shareData}
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
        />
      ) : null}
    </>
  );
};

export default Share;
