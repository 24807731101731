import React from "react";
import { useFormikContext } from "formik";

import AppCheckBox from "../AppCheckBox";

export default function AppFormCheckBox({
  handleCheck,
  handleUncheck,
  name,
  ...otherProps
}) {
  const { setFieldValue, setFieldTouched } = useFormikContext();

  const handleFormUncheck = (e) => {
    handleUncheck(e);
    if (name) {
      setFieldValue(name, false);
      setTimeout(() => setFieldTouched(name), 0);
    }
  };
  const handleFormCheck = (e) => {
    handleCheck(e);
    if (name) {
      setFieldValue(name, true);
      setTimeout(() => setFieldTouched(name), 0);
    }
  };

  return (
    <AppCheckBox
      handleCheck={handleFormCheck}
      handleUncheck={handleFormUncheck}
      name={name}
      {...otherProps}
    />
  );
}
