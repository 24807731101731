import { useEffect, useState } from "react";
import { useYouwho } from "../package/react-youwho.js";
import {
  setFetchBookingsRedux,
  setFetchListingsRedux,
  setFetchWalletRedux,
  // setFetchUserSessionsRedux,
  setFetchLockedReviewsRedux,
  setFetchUserMessagesRedux,
} from "../store/slices/app.js";
import {
  setBookingsMessagesCustomerRedux,
  setBookingsMessagesProviderRedux,
  setNewMessagesCustomerRedux,
  setNewMessagesProviderRedux,
  setListingsProviderRedux,
  setWalletRedux,
  // setUserSessionsRedux,
  setLockedReviewsRedux,
  setUserMessagesCustomerRedux,
  setUserMessagesProviderRedux,
  setNewUserMessagesCustomerRedux,
  setNewUserMessagesProviderRedux,
} from "../store/slices/user.js";
import { formatImageArray, httpToHttps } from "../utils/formatters.js";
import { useSelector } from "react-redux";
import { store } from "../store/store.js";

const BOOKINGS_CLASS = "Bookings";
const LISTINGS_CLASS = "Listings";
const REVIEWS_PROVIDER_CLASS = "ReviewsProvider";
const SESSION_CLASS = "_Session";
const WALLETS_CLASS = "Wallets";
const USER_MESSAGES_CLASS = "UserMessages";

///
/////////// BOOKINGS /////////////////
///

export const setDataBookings = (data, user, isCustomer) => {
  // const { config } = useSelector((state) => state.app);
  const reduxState = store.getState();
  const config = reduxState.app.config;

  const bookingsMessages = data.map((x) => {
    const receiptArray = formatImageArray(x, config?.maxPaymentReceipts ?? 4);

    return {
      bookingsObject: x,
      bookingsId: x.id,
      objId: x.id,
      completed: x.attributes?.completed,
      currentStatus:
        x.attributes?.statusLog[x.attributes?.statusLog.length - 1]
          .currentStatus,
      currentStatusLog:
        x.attributes?.statusLog[x.attributes?.statusLog.length - 1],
      // currentStatusObject: x.attributes?.statusLog[x.attributes?.statusLog.length - 1]
      //   .currentStatusObject, // add new 1/12/65
      currentUserId: user.id,
      customerId: isCustomer ? user.id : x.attributes?.customer.id,
      customerPublicPointer: isCustomer
        ? user.attributes?.userPublic
        : x.attributes?.customerPublic,
      feePaid: x.attributes?.feePaid,
      isCustomer,
      lastMessage: x.attributes?.lastMessage
        ? x.attributes?.lastMessage
        : { sender: "" },
      lastMessageDate: x.attributes?.lastMessage?.date,
      latestStartingDate:
        x.attributes?.statusLog &&
        x.attributes?.statusLog[x.attributes?.statusLog.length - 1].timeFrom
          ? Math.floor(
              new Date(
                x.attributes?.statusLog[
                  x.attributes?.statusLog.length - 1
                ].timeFrom
              ).getTime() / 1000
            )
          : 0,
      // latestStartingDateNew: x.attributes?.statusLog
      //   ? Math.floor(
      //     new Date(
      //       x.attributes?.statusLog[
      //         x.attributes?.statusLog.length - 1
      //       ].timeFrom
      //     ) / 1000
      //   )
      //   : undefined,
      listingPointer: x.attributes?.listings,
      messagesPointer: x.attributes?.messages,
      messagesUnread: x.attributes?.messagesUnread
        ? x.attributes?.messagesUnread
        : 0,
      paymentMethod: x.attributes?.paymentMethod,
      providerPublicPointer: isCustomer
        ? x.attributes?.providerPublic
        : user.attributes?.userPublic,
      providerId: isCustomer ? x.attributes?.provider.id : user.id,
      receiptArray,
      reviewsCustomerPointer: x.attributes.reviewsCustomerPointer,
      reviewsProviderPointer: x.attributes.reviewsProviderPointer,
      statusLog: x.attributes?.statusLog,
      userTypingPointer: x.attributes?.userTyping,
      transactionLog: x.attributes?.transactionLog,
    };
  });

  return bookingsMessages;
};

export const useSubBookings = (
  isCustomer = false,
  liveQuery = false,
  dispatchFetch = true
) => {
  const { dispatch, user, Parse } = useYouwho();
  const [results, setResults] = useState([]);

  const query = new Parse.Query(BOOKINGS_CLASS)
    .equalTo(isCustomer ? "customer" : "provider", user)
    .descending("updatedAt")
    .include([
      "providerPublic",
      "customerPublic",
      "listings",
      "reviewsCustomerPointer",
      "reviewsProviderPointer",
    ]);

  const fetch = async () => {
    const res = await query.find();
    setResults(res);
  };

  useEffect(() => {
    fetch();
    if (dispatchFetch) dispatch(setFetchBookingsRedux(fetch));
    //
    if (liveQuery) {
      const subscription = subscribeToBookings();
      console.log(
        "^^^subbed useSubBookings " + (isCustomer ? "customer" : "provider")
      );

      //
      return () => {
        try {
          if (subscription) {
            subscription.unsubscribe();
            console.log(
              "***UNsubbed useSubBookings " +
                (isCustomer ? "customer" : "provider")
            );
          }
        } catch (error) {
          console.log("useSubBookings", error);
        }
      };
    }
  }, [isCustomer, liveQuery]);

  const subscribeToBookings = async () => {
    const query = new Parse.Query(BOOKINGS_CLASS);
    query.equalTo(isCustomer ? "customer" : "provider", user);
    query.descending("updatedAt");
    // query.include("providerPublic", "customerPublic", "listings");
    const subscription = await query.subscribe();
    // subscription.on("update", setCustomerBookings);
    // subscription.on("create", setCustomerBookings);
    subscription.on("update", fetch);
    subscription.on("create", fetch);

    return subscription;
  };

  useEffect(() => {
    setData(results);
  }, [results]);

  const setData = (data) => {
    // console.log("ran setdata bookings");

    if (data && data.length > 0) {
      const bookingsMessages = setDataBookings(data, user, isCustomer);
      // console.log("bookingsMessages", bookingsMessages);
      //console.log("status", bookingsMessages[0].customerId)
      dispatch(
        isCustomer
          ? setBookingsMessagesCustomerRedux(bookingsMessages)
          : setBookingsMessagesProviderRedux(bookingsMessages)
      );
      setTotalNewMessages(bookingsMessages);
    } else {
      // console.log('no ran')
      dispatch(
        isCustomer
          ? setBookingsMessagesCustomerRedux([])
          : setBookingsMessagesProviderRedux([])
      );
      setTotalNewMessages([]);
    }
  };

  const setTotalNewMessages = (messagesArray) => {
    let total = 0;
    if (messagesArray && messagesArray.length > 0) {
      for (let i = 0; i < messagesArray.length; i += 1) {
        if (
          messagesArray[i].messagesUnread &&
          messagesArray[i].lastMessage.sender !== user.id
        )
          total += Number(messagesArray[i].messagesUnread);
      }
    }
    dispatch(
      isCustomer
        ? setNewMessagesCustomerRedux(total)
        : setNewMessagesProviderRedux(total)
    );
  };
};

///
/////////// USER MESSAGES /////////////////
///

export const setDataUserMessages = (data, user, isCustomer) => {
  const userMessages = data.map((x) => {
    return {
      objId: x.id,
      currentUserId: user.id,
      customerId: isCustomer ? user.id : x.attributes?.userCustomerPointer.id,
      customerPublicPointer: isCustomer
        ? user.attributes?.userPublic
        : x.attributes?.userCustomerPublic,
      isCustomer,
      lastMessage: x.attributes?.lastMessage
        ? x.attributes?.lastMessage
        : { sender: "" },
      lastMessageDate: x.attributes?.lastMessage?.date,
      messagesUnread: x.attributes?.messagesUnread
        ? x.attributes?.messagesUnread
        : 0,
      providerPublicPointer: isCustomer
        ? x.attributes?.userProviderPublic
        : user.attributes?.userPublic,
      providerId: isCustomer ? x.attributes?.userProviderPointer.id : user.id,
      userMessagesObject: x,
      userMessagesId: x.id,
      userTypingPointer: x.attributes?.userMessagesTypingPointer,
    };
  });

  return userMessages;
};

export const useSubUserMessages = (isCustomer, liveQuery = false) => {
  const { user, Parse, dispatch } = useYouwho();
  const [results, setResults] = useState([]);

  const query = new Parse.Query(USER_MESSAGES_CLASS)
    .equalTo(isCustomer ? "userCustomerPointer" : "userProviderPointer", user)
    .descending("updatedAt")
    .include([
      "userMessagesTypingPointer",
      isCustomer ? "userProviderPublic" : "userCustomerPublic",
    ]);

  const fetch = async () => {
    const res = await query.find();
    setResults(res);
  };

  useEffect(() => {
    fetch();
    dispatch(setFetchUserMessagesRedux(fetch));

    if (liveQuery) {
      const subscription = subscribeToClass();
      console.log("^^^subbed useSubUserMessages");

      return () => {
        try {
          if (subscription) {
            subscription.unsubscribe();
            console.log("***UNsubbed useSubUserMessages");
          }
        } catch (error) {
          console.log("useSubUserMessages", error);
        }
      };
    }
  }, [isCustomer, liveQuery]);

  const subscribeToClass = async () => {
    const query = new Parse.Query(USER_MESSAGES_CLASS);
    query.equalTo(
      isCustomer ? "userCustomerPointer" : "userProviderPointer",
      user
    );
    query.descending("updatedAt");
    const subscription = await query.subscribe();
    // subscription.on("update", setCustomerBookings);
    // subscription.on("create", setCustomerBookings);
    subscription.on("update", fetch);
    subscription.on("create", fetch);

    return subscription;
  };

  useEffect(() => {
    setData(results);
  }, [results]);

  const setData = (data) => {
    if (data && data.length > 0) {
      const userMessages = setDataUserMessages(data, user, isCustomer);
      // console.log("userMessages", userMessages);
      //console.log("status", bookingsMessages[0].customerId)
      dispatch(
        isCustomer
          ? setUserMessagesCustomerRedux(userMessages)
          : setUserMessagesProviderRedux(userMessages)
      );
      setTotalNewMessages(userMessages);
    } else {
      // console.log('no ran')
      dispatch(
        isCustomer
          ? setUserMessagesCustomerRedux([])
          : setUserMessagesProviderRedux([])
      );
      setTotalNewMessages([]);
    }
  };

  const setTotalNewMessages = (messagesArray) => {
    let total = 0;
    if (messagesArray && messagesArray.length > 0) {
      for (let i = 0; i < messagesArray.length; i += 1) {
        if (
          messagesArray[i].messagesUnread &&
          messagesArray[i].lastMessage.sender !== user.id
        )
          total += Number(messagesArray[i].messagesUnread);
      }
    }
    dispatch(
      isCustomer
        ? setNewUserMessagesCustomerRedux(total)
        : setNewUserMessagesProviderRedux(total)
    );
  };
};

///
/////////// LOCKED REVIEWS /////////////////
///

export const useSubLockedReviews = (liveQuery = false) => {
  const { user, Parse, dispatch } = useYouwho();
  const [results, setResults] = useState([]);
  const userPublic = user.attributes?.userPublic;

  // const query = new Parse.Query(REVIEWS_PROVIDER_CLASS)
  //   .equalTo("providerPublicPointer", userPublic)
  //   .equalTo("visible", false);
  const query = new Parse.Query(BOOKINGS_CLASS)
    .containedIn("feePaid", [false, undefined])
    .equalTo("completed", true);

  const fetch = async () => {
    console.log("useSubLockedReviews");
    const res = await query.find();
    setResults(res);
  };

  useEffect(() => {
    fetch();
    dispatch(setFetchLockedReviewsRedux(fetch));

    if (liveQuery) {
      const subscription = subscribeToLockedReviews();
      console.log("^^^subbed useSubLockedReviews");
      return () => {
        try {
          if (subscription) {
            subscription.unsubscribe();
            console.log("***UNsubbed useSubLockedReviews");
          }
        } catch (error) {
          console.log("useSubLockedReviews", error);
        }
      };
    }
  }, [liveQuery]);

  const subscribeToLockedReviews = async () => {
    const query = new Parse.Query(REVIEWS_PROVIDER_CLASS);
    query.equalTo("providerPublicPointer", userPublic);
    query.containedIn("visible", [false, undefined]);
    const subscription = await query.subscribe();
    // subscription.on("update", setCustomerBookings);
    // subscription.on("create", setCustomerBookings);
    subscription.on("update", fetch);
    subscription.on("create", fetch);

    return subscription;
  };

  useEffect(() => {
    setData(results);
  }, [results]);

  const setData = (data, isCustomer = false) => {
    if (data && data.length > 0) {
      const reviewsIsNotVisibleArray = data.map((z) => {
        const x = z;
        const currentStatusLog =
          x.attributes?.statusLog[x.attributes?.statusLog.length - 1];
        const currentStatus =
          x.attributes?.statusLog[x.attributes?.statusLog.length - 1]
            .currentStatus;
        const feePaid = x.attributes?.feePaid;
        const providerPublicPointer = isCustomer
          ? x.attributes?.providerPublic
          : user.attributes?.userPublic;
        const customerPublicPointer = isCustomer
          ? user.attributes?.userPublic
          : x.attributes?.customerPublic;
        const completed = x.attributes?.completed;

        const bookingItems = {
          currentStatusLog,
          currentStatus,
          feePaid,
          providerPublicPointer,
          customerPublicPointer,
          completed,
        };

        return {
          // reviewObject: z,
          // reviewIsNotVisibleID: z.id,
          // createdAt: z.attributes?.createdAt,
          // listingPointer: z.attributes?.listingsPointer,
          listingPointer: x.attributes?.listings,
          bookingsPointer: x,
          // currentStatusLog,
          // customerPublicPointer: z.attributes?.customerPublicPointer,
          customerPublicPointer: x.attributes?.customerPublic,
          bookingItems,
          bookingsId: x.id,
        };
      });
      // const reviewsIsNotVisibleArray = data.map((z) => {
      //   const x = z.attributes?.bookingsPointer;
      //   const currentStatusLog =
      //     x.attributes?.statusLog[x.attributes?.statusLog.length - 1];
      //   const currentStatus =
      //     x.attributes?.statusLog[x.attributes?.statusLog.length - 1]
      //       .currentStatus;
      //   const feePaid = x.attributes?.feePaid;
      //   const providerPublicPointer = isCustomer
      //     ? x.attributes?.providerPublic
      //     : user.attributes?.userPublic;
      //   const customerPublicPointer = isCustomer
      //     ? user.attributes?.userPublic
      //     : x.attributes?.customerPublic;

      //   const bookingItems = {
      //     currentStatusLog,
      //     currentStatus,
      //     feePaid,
      //     providerPublicPointer,
      //     customerPublicPointer,
      //   };

      //   return {
      //     reviewObject: z,
      //     reviewIsNotVisibleID: z.id,
      //     createdAt: z.attributes?.createdAt,
      //     listingPointer: z.attributes?.listingsPointer,
      //     bookingsPointer: x,
      //     customerPublicPointer: z.attributes?.customerPublicPointer,
      //     bookingItems,
      //     bookingsId: x.id,
      //   };
      // });

      // console.log("reviewsIsNotVisibleArray", reviewsIsNotVisibleArray);
      //console.log("status", bookingsMessages[0].customerId)
      dispatch(setLockedReviewsRedux(reviewsIsNotVisibleArray));
    } else {
      // console.log('no ran')
      dispatch(setLockedReviewsRedux([]));
    }
  };
};

///
/////////// WALLETS /////////////////
///

export const useSubWallets = (liveQuery = false) => {
  const { dispatch, user, Parse, appStore } = useYouwho();
  const { config } = appStore;
  const [results, setResults] = useState([]);
  // console.log("config from usesubs wallet", config);

  const query = new Parse.Query(WALLETS_CLASS).equalTo("user", user);

  const fetch = async () => {
    const res = await query.find();
    setResults(res);
  };

  useEffect(() => {
    fetch();
    dispatch(setFetchWalletRedux(fetch));
    if (liveQuery) {
      const subscription = subscribeToWallets();
      console.log("^^^subbed useSubWallets");
      return () => {
        try {
          if (subscription) {
            subscription.unsubscribe();
            console.log("***UNsubbed useSubWallets");
          }
        } catch (error) {
          console.log("useSubWallets", error);
        }
      };
    }
  }, [liveQuery]);

  const subscribeToWallets = async () => {
    const query = new Parse.Query(WALLETS_CLASS);
    query.equalTo("user", user);
    const subscription = await query.subscribe();
    subscription.on("create", fetch);
    subscription.on("update", fetch);

    return subscription;
  };

  useEffect(() => {
    setData(results);
  }, [results]);

  const setData = async (data) => {
    // dispatch(setFetchWalletRedux(fetch));
    if (data && data.length > 0) {
      const wallets = {
        obj: data[0],
        walletAddress: data[0].attributes?.evmAddress,
        tokens: config.supportedTokens?.map((x) => ({
          name: x.name,
          amount: data[0].attributes[x.name],
          transactionLog: data[0].attributes[x.name + "TransactionLog"],
          address: x.address,
          logoUrl: x.logoUrl,
          decimals: x.decimals,
          withdrawFee: x.withdrawFee,
          withdrawMin: x.withdrawMin,
          price: x.price,
          yield: config.stakingYield[x.name] ?? undefined,
          rewards: data[0].attributes[x.name + "Rewards"] ?? undefined,
          isPayable: x.payable,
          youStakingPending:
            x.name == "you" ? data[0].attributes.youStakingPending : undefined,
          youNoWithdraw:
            x.name == "you" ? data[0].attributes.youNoWithdraw : undefined,
        })),
      };
      // const wallets = data.map((x) => {
      //   return {
      //     obj: x,
      //     tokens: {
      //       busd: x.attributes?.busd,
      //       usdt: x.attributes?.usdt,
      //       usdc: x.attributes?.usdc,
      //       you: x.attributes?.you
      //     },
      //   };
      // })
      // console.log("useSubWallets: " + wallets);
      dispatch(setWalletRedux(wallets));
    } else {
      console.log("No wallets found");
      dispatch(setWalletRedux(undefined));
    }
  };
};

///
/////////// LISTINGS /////////////////
///

export const useSubListings = () => {
  const { dispatch, user, Parse } = useYouwho();
  const { config } = useSelector((state) => state.app);
  const [results, setResults] = useState([]);

  const query = new Parse.Query(LISTINGS_CLASS)
    .equalTo("provider", user)
    // .notEqualTo("hidden", true)
    // .notEqualTo("disabled", true)
    // .containedIn("hidden", [false, undefined])
    .containedIn("disabled", [false, undefined])
    .descending("updatedAt");

  const fetch = async () => {
    const res = await query.find();
    setResults(res);
    // setData(res);
  };

  useEffect(() => {
    fetch();
    dispatch(setFetchListingsRedux(fetch));
    //
    // const subscription = subscribeToListings();
    // console.log("^^^subbed useSubListings");
    // return () => {
    //   try {
    //     if (subscription) {
    //       subscription.unsubscribe();
    //       console.log("***UNsubbed useSubListings");
    //     }
    //   } catch (error) {
    //     console.log("useSubListings", error);
    //   }
    // };
  }, []);

  const subscribeToListings = async () => {
    const query = new Parse.Query(LISTINGS_CLASS);
    query.equalTo("provider", user);
    // query.notEqualTo("hidden", true);
    query.notEqualTo("disabled", true);
    query.descending("updatedAt");
    const subscription = await query.subscribe();
    // subscription.on("update", setCustomerBookings);
    // subscription.on("create", setCustomerBookings);
    subscription.on("update", fetch);
    subscription.on("create", fetch);

    return subscription;
  };

  useEffect(() => {
    setData(results);
  }, [results]);

  const setData = async (data) => {
    // console.log("rannnnnn");
    if (data && data.length > 0) {
      // console.log("rannnnnn data", data);
      // console.log("data", data);
      const listings = data.map((x) => {
        const imgArray = formatImageArray(x, config?.maxListingImages ?? 12);

        const certArray = formatImageArray(
          x,
          config?.maxCertImages ?? 3,
          "cert"
        );

        return {
          category: x.attributes?.category,
          certArray,
          dayTime: x.attributes?.dayTime,
          geoPoint: x.attributes?.geoPoint,
          hidden: x.attributes?.hidden,
          id: x.id,
          imgArray,
          lat: x.attributes?.location.latitude,
          listDetail: x.attributes?.listingDetails,
          listingLocation: x.attributes?.location,
          lng: x.attributes?.location.longitude,
          obj: x,
          json: x.toJSON(),
          providerId: x.attributes?.provider.id,
          providerPublic: x.attributes?.providerPublic,
          rate: x.attributes?.rate,
          fiatCurrency: x.attributes?.fiatCurrency ?? "usd",
          serviceLocation: x.attributes?.serviceLocation,
          subCategory: x.attributes?.subCategory,
          subtitle: x.attributes?.job,
          title: x.attributes?.title,
          paymentMethod: x.attributes?.paymentMethod,
          reviewsProviderRelation: x.attributes?.reviewsProviderRelation,
          rating: x.attributes?.rating,
          reviews: x.attributes?.reviews,
          toReview: x.attributes?.toReview,
        };
      });
      // console.log("##################### listings", listings);
      dispatch(setListingsProviderRedux(listings));
    } else {
      console.log("No listings found");
      dispatch(setListingsProviderRedux([]));
    }
  };
};

///
/////////// UserSessions /////////////////
///

// export const useSubUserSession = () => {
//   const { dispatch, user, Parse } = useYouwho();

//   useEffect(() => {
//     const subscription = subscribeToUserSessions();
//      console.log("^^^subbed useSubUserSessions");
//     return () => {
//       try {
//         // if (subscription) { subscription.unsubscribe();
//                console.log("***UNsubbed useSubUserSession");
//            }
//       } catch (error) {
//         console.log("useSubUserSession", error);
//       }
//     };
//   }, []);

//   const subscribeToUserSessions = async () => {
//     const query = new Parse.Query(SESSION_CLASS);
//     query.equalTo("user", user);
//     query.descending("updatedAt");
//     const subscription = await query.subscribe();
//     subscription.on("create", fetchUserSessions);

//     return subscription;
//   };

//   useEffect(() => {
//     setUserSessions(dataUserSessions);
//     dispatch(setFetchUserSessionsRedux(fetchUserSessions));
//   }, [dataUserSessions]);

//   const setUserSessions = async (data) => {
//     if (data && data.length > 0) {
//       // console.log("data", data);
//       const userSessionArray = data.map((x) => x.id);

//       console.log("userSessionArray", userSessionArray);
//       dispatch(setUserSessionsRedux(userSessionArray));
//     } else {
//       console.log("No userSessionArray found");
//       dispatch(setUserSessionsRedux([]));
//     }
//   };
// };
