import React from "react";
import { useSpring, motion, useTransform, useMotionValue } from "framer-motion";

import AppPressable from "../AppPressable";
import padding from "../../config/padding";
import AppIcon from "../AppIcon";
import { FiRotateCcw } from "react-icons/fi";
import { getPx, getSx } from "../../utils/helpers";

export default function AppHeaderRefreshIcon({
  iconStyle,
  onPress,
  style,
  viewStyle,
  pressableStyle,
  size = 35,
  scale = 1,
  ...iconProps
}) {
  return (
    <AppPressable
      onPress={onPress}
      noAnimate
      hoverScale={1.01}
      pressScale={scale}
      style={getSx([
        {
          marginRight: getPx(-padding.sm),
          right: getPx(padding.md),
          top: getPx(padding.sm),
        },
        style,
      ])}
      pressableStyle={pressableStyle}
    >
      <motion.div
        animate={{ rotate: 0 }}
        transition={{ type: "spring", stiffness: 300, damping: 15 }}
        whileTap={{ rotate: -90 }}
        style={viewStyle}
      >
        <AppIcon
          Icon={FiRotateCcw}
          // name="rotate-ccw"
          size={size}
          backgroundColor="transparent"
          style={iconStyle}
          iconX={0.8}
          {...iconProps}
        />
      </motion.div>
    </AppPressable>
  );
}
