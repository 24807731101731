import colors from "./colors";
import { fontConfig as fonts } from "./fonts";

export const StyleSheet = {
  colors,
  text: {
    color: colors.gray0,
    fontSize: fonts.size.primary,
    // fontFamily: Platform.OS === 'android' ? 'Roboto' : 'Avenir',
  },
  absoluteFill: { position: "absolute", top: 0, left: 0, right: 0, bottom: 0 },
};

