import React from "react";
import AppText from "./AppText";
import { useTranslateText } from "../hooks/useTranslate";

export default function AppTextTranslate({
  children,
  clg,
  toLanguage,
  noAuto,
  noTranslate,
  fromLanguage,
  langKey,
  format,
  ...props
}) {
  const translated = useTranslateText({
    toTranslate: children,
    toLanguage,
    noAuto,
    fromLanguage,
    clg,
    langKey,
    format,
    noTranslate,
  });
  // if (clg) console.log("AppTextTranslate typeof children: ", typeof children);
  // if (clg)
  //   console.log("AppTextTranslate", children, String(noTranslate), translated);
  // const translated = children;
  return (
    <AppText clg={clg} {...props}>
      {noTranslate ? children : translated}
    </AppText>
  );
}
