export default Object.freeze({
  aboutUs: "เกี่ยวกับ YOUWHO",
  accountSettings: "การตั้งค่าบัญชี",
  useBiometrics: "ล็อคไบโอเมตริกซ์",
  currency: "สกุลเงินที่แสดง",
  currentLanguage: "ไทย",
  discuss: "กลุ่มสนทนา",
  email: "อีเมล์",
  favoriteListings: "รายการโปรดของฉัน",
  guestAccountHeading: "บัญชีของคุณ",
  guestAccountSubHeading: "ล็อคอินเพื่อเริ่มจองบริการของคุณ",
  guestBookingsHeading: "บุ๊คกิ้ง",
  guestBookingsSubTitle:
    "ดูบริการที่คุณจองไว้ทั้งหมดที่นี่หลังจากที่คุณล็อคอินแล้ว",
  guestBookingsTitle: "ยังไม่มีบุ๊คกิ้ง",
  guestLikesHeading: "ไลค์",
  guestLikesSubTitle: "คุณสามารถดูหรือแก้ไข ไลค์ ของคุณได้เมื่อคุณล็อคอินแล้ว",
  guestLikesTitle: "ล็อคอินเพื่อดู ไลค์ ของคุณ",
  guestMessagesHeading: "เมสเสจ",
  guestMessagesSubTitle:
    "เมื่อคุณล็อคอิน คุณจะแชทกับผู้ให้บริการอิสระได้ที่นี่",
  guestMessagesTitle: "ล็อคอินเพื่ออ่านเมสเสจ",
  guestWalletHeading: "วอเลท",
  guestWalletSubTitle: "คุณสามารถดูและจัดการ วอเลท ของคุณเมื่อคุณล็อคอินแล้ว",
  guestWalletTitle: "ล็อคอินเพื่อดู วอเลท ของคุณ",
  language: "ภาษา",
  login: "ล็อคอิน",
  loginQuestion: "มีบัญชีอยู่แล้ว?",
  logout: "ล็อคเอ้า",
  password: "รหัสผ่าน",
  personalInfo: "ข้อมูลส่วนตัว",
  signup: "สมัครสมาชิก",
  signupQuestion: "ยังไม่มีบัญชี?",
  switchToCustomer: "เปลี่ยนเป็นผู้ใช้บริการ",
  switchToProvider: "เปลี่ยนเป็นผู้ให้บริการ",
  unlockReviews: "ปลดล็อครีวิวและรางวัล",
  username: "ชื่อผู้ใช้",
  version: "เวอร์ชั่น",
});
