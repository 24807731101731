import React, { useState, useEffect, useRef } from "react";
import dayjs from "dayjs";

import AppAvatar from "../components/AppAvatar";
import AppButton from "../components/AppButton";
import AppText from "../components/AppText";
import Screen from "../components/Screen";
import colors from "../config/colors";
import { fontConfig as fonts } from "../config/fonts";
import padding from "../config/padding";

import { useQueryListingsUserProfile } from "../hooks/useQuery";
// import AppIconButton from "../components/AppIconButton";
import AppUserReviews from "../components/AppUserReviews";
import AppUserAllListings from "../components/AppUserAllListings";
// import { useScrollingHeaderReAnimation } from "../hooks/useAnimations";
import AppViewLayout from "../components/AppViewLayout";
import { useYouwho } from "../package/react-youwho";
// import AppReAnimatedHeader from "../components/headers/AppReAnimatedHeader";
import AppTextTranslate from "../components/AppTextTranslate";
import AppPressable from "../components/AppPressable";
import AppStar from "../components/AppStar";
import { getPx, getSx } from "../utils/helpers";
// import { FiEdit } from "react-icons/fi";
import View from "../components/rnMimic/View";
import { FaComment, FaHome, FaUserCheck } from "react-icons/fa";
import { useMotionValue } from "framer-motion";
import ListItemSeparator from "../components/lists/ListItemSeparator";
import { useParams } from "react-router-dom";
import { Center, background } from "@chakra-ui/react";
import SplashScreen from "./SplashScreen";
import { BottomSheet } from "react-spring-bottom-sheet";
import ListingDetailsScreen from "./ListingDetailsScreen";
import { setNaviListingRedux } from "../store/slices/navi";
import AppFramerHeader from "../components/headers/AppFramerHeader";
import AppIconButton from "../components/AppIconButton";
import { FiEdit } from "react-icons/fi";
import AppTextLang from "../components/AppTextLang";

const headerHeight = { value: 49 };

export default function PublicProfileScreen({ route }) {
  const { userPublicId, listingId } = useParams();
  const {
    user,
    dispatch,
    handleError,
    handleInfo,
    urlQuery,
    naviStore,
    navigation,
    Parse,
    isLoading,
    setIsLoading,
  } = useYouwho();
  const { listingsUserProfileResults, queryListsingsUserProfile } =
    useQueryListingsUserProfile();
  // const { handleScroll, headerHeight, opacityStyle, translateYStyle } =
  //   useScrollingHeaderReAnimation();
  const { naviPublicProfile } = naviStore;

  // console.log("naviPublicProfile: ", naviPublicProfile);
  const [profileAvailable, setProfileAvailable] = useState(true);
  const [info, setInfo] = useState(naviPublicProfile);
  const [readMoreProfile, setReadMoreProfile] = useState(0);
  const [pressReadMore, setPressReadMore] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  // const fromLanguage = getFromLanguage();
  const [showListing, setShowListing] = useState(false);
  const listingRef = useRef();
  const scrollYListing = useMotionValue(0);

  const handleScrollListing = (e) => {
    const { scrollTop } = e.target;
    scrollYListing.set(scrollTop);
  };

  const scrollY = useMotionValue(0);

  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    // console.log("scrollTop: ", scrollTop);
    // console.log("clientHeight: ", clientHeight);
    // console.log("scrollHeight: ", scrollHeight);
    // console.log("scrollHeight-scrollTop: ", scrollHeight - scrollTop);
    // console.log("start load: ", clientHeight * 2 > scrollHeight - scrollTop);
    scrollY.set(scrollTop);
    if (clientHeight * 2 > scrollHeight - scrollTop) {
      // console.log("start load: ", clientHeight * 2 > scrollHeight - scrollTop);
    }
  };

  const handleGetUserPublic = async () => {
    setIsLoading(true);
    try {
      const query = new Parse.Query("UserPublic");
      query.equalTo("objectId", userPublicId);
      const userPublic = await query.first();
      if (userPublic) setInfo(userPublic?.attributes);
      else setProfileAvailable(false);
    } catch (error) {
      console.log("handleGetUserPublic error: ", error);
    } finally {
      setTimeout(() => setIsLoading(false), 100);
    }
  };

  const handlePressReviews = (reviewIndex = 0, tabIndex = 0) => {
    // navigation("/reviews", {
    //   reviewIndex,
    //   info,
    //   tabIndex,
    // });
    handleInfo("Coming soon...", "Unavailable");
  };

  const handleLongDescription = (e) => {
    if (e.nativeEvent?.layout?.height > 125 && !pressReadMore) {
      setReadMoreProfile(5);
    }
  };

  const handleGoBack = () => {
    navigation(-1);
    setShowListing(false);
    dispatch(setNaviListingRedux(undefined));
  };

  const handlePressEdit = () => {
    handleInfo("Coming soon...", "Unavailable");
    // navigation("/listing/" + listingId + "/edit");
  };

  useEffect(() => {
    if (info?.user) {
      queryListsingsUserProfile(info?.user);
    } else {
      handleGetUserPublic();
    }
  }, [info]);

  useEffect(() => {
    if (listingId) {
      setShowListing(true);
    }
  }, [listingId]);

  if (isLoading) {
    return <SplashScreen />;
  }

  if (!profileAvailable) {
    return (
      <Center sx={{ h: "50vh", flexDir: "column" }}>
        <AppTextLang
          variant="h1"
          center
          style={{
            marginBottom: getPx(padding.md),
            //   fontSize: getPx(fonts.size.xxh1),
          }}
          langKey={"userProfile"}
        />
        <AppText
          noAuto
          variant="h1"
          center
          style={{
            marginBottom: getPx(padding.md),
            fontSize: getPx(fonts.size.xh1),
          }}
          // lineHeightNumber={fonts.size.xh1 + 1}
          sx={{ marginX: getPx(padding.sm) }}
        >
          {userPublicId}
        </AppText>
        <AppTextLang
          variant="h1"
          center
          style={{
            marginBottom: getPx(padding.md),
            //   fontSize: getPx(fonts.size.xxh1),
          }}
          // lineHeightNumber={fonts.size.xxh1 + 1}
          langKey={"notFound"}
        />
      </Center>
    );
  }

  return (
    <Screen
      title={`${
        user && info?.user?.id === user.id
          ? "My profile"
          : info?.username + "'s profile"
      }`}
    >
      <AppFramerHeader
        heading={`${
          user && info?.user?.id === user.id
            ? "My profile"
            : info?.username + "'s profile"
        }`}
        RightComponent={
          user &&
          info?.user.id === user.id && (
            <AppIconButton
              Icon={FiEdit}
              size={40}
              iconX={0.55}
              onPress={handlePressEdit}
              // pressStyle={{ marginRight: getPx(padding.ms) }}
            />
          )
        }
      />
      {/* <AppReAnimatedHeader
        translateYStyle={translateYStyle}
        opacityStyle={opacityStyle}
        headerHeight={headerHeight}
        heading={`${user && info?.user?.id === user.id ? "My profile" : ""}`}
        RightComponent={
          user &&
          info?.user.id === user.id && (
            <AppIconButton
              Icon={FiEdit}
              size={40}
              iconX={0.55}
              onPress={() => navigation("/account")} // placeholder
              pressStyle={{ marginRight: getPx(padding.ms) }}
            />
          )
        }
      /> */}

      <View
        // bounces={false}
        style={{
          padding: getPx(padding.primary),
          paddingTop: getPx(headerHeight.value + padding.primary),
          // paddingBottom: getPx(headerHeight.value),
        }}
        onScroll={handleScroll}
      >
        <AppViewLayout>
          <AppAvatar
            name={info?.username}
            url={info?.avatar?._url ?? info?.avatarUrl}
            variant="lg"
            enlarge
            metadata={info?.avatarMetadata}
          />
          <AppText style={{ marginTop: getPx(padding.md) }} variant="h1">
            {`Hi, I'm ${info?.username}`}
          </AppText>
          <AppTextTranslate variant="lg" style={{ color: colors.gray6 }}>
            {`Joined in ${dayjs(info?.createdAt).format("MMMM YYYY")}`}
          </AppTextTranslate>

          <View
            style={{
              marginTop: getPx(padding.md),
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <AppStar
              value={
                info?.reviewsAsProvider ? Number(info?.ratingAsProvider) / 5 : 0
              }
              style={getSx([
                {
                  color: colors.black,
                  // bottom: 2,
                  marginRight: getPx(padding.ms),
                },
              ])}
            />

            <AppTextTranslate variant="lg">
              {`${
                info?.reviewsAsProvider
                  ? " " +
                    info?.ratingAsProvider +
                    " " +
                    "•" +
                    " " +
                    info?.reviewsAsProvider +
                    " "
                  : " " + "•" + " " + 0 + " "
              } Reviews`}
            </AppTextTranslate>
          </View>

          {info?.identityVerified && (
            <View
              style={{
                marginTop: getPx(padding.sm),
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <FaUserCheck
                style={getSx([
                  {
                    color: colors.black,
                    // bottom: 2,
                    marginRight: getPx(padding.ms),
                  },
                ])}
                size={fonts.size.lg}
              />
              <AppTextTranslate variant="lg">
                Identity Verified
              </AppTextTranslate>
            </View>
          )}

          <ListItemSeparator style={{ marginY: getPx(padding.lg) }} />
        </AppViewLayout>

        <AppViewLayout>
          <AppTextTranslate
            style={{ marginBottom: getPx(padding.md) }}
            variant="h2"
          >
            About me
          </AppTextTranslate>
          <AppTextTranslate
            variant="md"
            numberOfLines={readMoreProfile}
            onLoad={handleLongDescription}
            noAuto
          >
            {info?.description}
          </AppTextTranslate>
          {readMoreProfile > 0 && (
            <AppPressable
              noAnimate
              onPress={() => {
                setPressReadMore(true);
                setReadMoreProfile(0);
              }}
            >
              <AppTextTranslate variant="mdb" underline right>
                read more
              </AppTextTranslate>
            </AppPressable>
          )}
          <View style={{ marginTop: getPx(padding.md) }}>
            {info?.livesIn && (
              <View
                style={{
                  marginTop: getPx(padding.sm),
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <FaHome
                  name={"home"}
                  style={getSx([
                    {
                      color: colors.black,
                      // bottom: 2,
                      marginRight: getPx(padding.ms),
                    },
                  ])}
                  size={fonts.size.lg}
                />
                <AppTextTranslate variant="lg">{`Lives in ${info?.livesIn}`}</AppTextTranslate>
              </View>
            )}
            {info?.spokenLanguages && info?.spokenLanguages.length > 0 && (
              <View
                style={{
                  marginTop: getPx(padding.sm),
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <FaComment
                  style={getSx([
                    {
                      color: colors.black,
                      // bottom: 2,
                      marginRight: getPx(padding.ms),
                    },
                  ])}
                  size={fonts.size.lg}
                />
                <AppTextTranslate variant="lg">
                  {`Speaks ${info?.spokenLanguages.map(
                    (x, i) => ` ${x.languageNameNative}`
                  )}`}
                </AppTextTranslate>
              </View>
            )}
          </View>
          <ListItemSeparator style={{ marginY: getPx(padding.lg) }} />
        </AppViewLayout>

        {listingsUserProfileResults &&
          listingsUserProfileResults.length > 0 && (
            <AppViewLayout fullWidth>
              <AppTextTranslate
                variant="h2"
                style={{ marginBottom: getPx(padding.md) }}
              >
                {`${listingsUserProfileResults?.length} Services`}
              </AppTextTranslate>

              <AppUserAllListings
                info={info}
                handleError={handleError}
                listingsUserProfileResults={listingsUserProfileResults}
              />

              <ListItemSeparator style={{ marginY: getPx(padding.lg) }} />
            </AppViewLayout>
          )}
        {Boolean(
          info?.reviewsAsProvider > 0 || info?.reviewsAsCustomer > 0
        ) && (
          <View>
            <AppUserReviews
              info={info}
              reviewIndex={0}
              tabIndex={tabIndex}
              setTabIndex={setTabIndex}
            />
            <AppButton
              title="Show All Reviews"
              onPress={() => handlePressReviews()}
              variant="outline"
            />
          </View>
        )}
      </View>

      <BottomSheet
        className="rsbs-listing"
        open={showListing}
        // skipInitialTransition={listingsFoundTotal > 0 ? true : false}
        // skipInitialTransition

        ref={listingRef}
        blocking={false}
        snapPoints={({ minHeight, maxHeight }) => [
          maxHeight,
          // maxHeight * 0.5,
          0,
        ]}
        defaultSnap={({ lastSnap, snapPoints }) => snapPoints[0]}
        style={{ zIndex: 40 }}
        // onDismiss={() => {
        //   // Full typing for the arguments available in snapTo, yay!!
        //   // listingsRef.current.snapTo(({ maxHeight }) => maxHeight);
        //   setOpenBottomSheet(false);
        // }}
        onScrollCapture={handleScrollListing}
        // scrollLocking
      >
        <ListingDetailsScreen
          goBack={handleGoBack}
          scrollYListing={scrollYListing}
        />
      </BottomSheet>
    </Screen>
  );
}
