import React, { useState } from "react";
import { FaHandHoldingHeart } from "react-icons/fa";

import borders from "../config/borders";
import { fontConfig as fonts } from "../config/fonts";
import colors from "../config/colors";
import AppText from "./AppText";
// import AppImageCarousel from "./AppImageCarousel";
import padding from "../config/padding";
import AppPressable from "./AppPressable";
// import shadows from "../config/shadows";
import AppTextTranslate from "./AppTextTranslate";
// import AppImage from "./AppImage";
import { getPx, getSx, toFixed } from "../utils/helpers";
import View from "./rnMimic/View";

import {
  Box,
  Center,
  Flex,
  HStack,
  Image,
  Spacer,
  Stack,
} from "@chakra-ui/react";
import { httpToHttps } from "../utils/formatters";
import AppStar from "./AppStar";
import AppImage from "./AppImage";
import AppImageCarousel from "./AppImageCarousel";

export default function AppListingsCard({
  cardHeight = "280px",
  cardWidth = "100%",
  category,
  id,
  imgArray,
  imageHeight = cardHeight,
  imageMaxHeight = "580px",
  onPress,
  proBono,
  rate,
  fiatCurrency = "usd",
  rating,
  reviews,
  subCategory,
  title,
}) {
  return (
    <AppPressable onPress={onPress} hoverScale={1.01}>
      {/* <View
          style={[
            {
              // width: getPx(Math.floor(width - padding.md * 2)),
              width: cardWidth,
              height: cardHeight,
            },
            // shadows[7],
          ]}
          boxShadow="lg"
        > */}
      <Stack sx={styles.card}>
        <AppImageCarousel
          containerStyle={styles.imageContainer}
          id={id}
          showDots
          showIndex={false}
          imgArray={imgArray}
          onPress={onPress}
          showFavorite
          height={cardHeight}
          width={cardWidth}
          minHeight={cardHeight}
          maxHeight={imageMaxHeight}
          boxShadow="lg"
          // fit="cover"
          sx={getSx([styles.imageContainer, { height: imageHeight }])}
        />
        {/* <Image
          src={httpToHttps(imgArray[0]?._url) ?? "https://bit.ly/dan-abramov"}
          // src={"https://bit.ly/dan-abramov"}
          // htmlHeight={cardHeight}
          // htmlWidth={cardWidth}
          // boxSize="100px"
          height={cardHeight}
          width={cardWidth}
          boxShadow="lg"
          fit="cover"
          sx={styles.imageContainer}
        /> */}
        {/* {imgArray && (
            <AppImageCarousel
              containerStyle={[styles.imageContainer, shadows[7]]}
              id={id}
              showDots
              showIndex={false}
              height={cardHeight}
              imgArray={imgArray}
              width={getPx(Math.floor(width - padding.md * 2))}
              onPress={onPress}
              showFavorite
              withIndicator
            />
          )} */}
        {/* </View> */}

        <Box sx={styles.detailsContainer}>
          <Flex
            flexDirection="row"
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Flex flexDirection="row" sx={{ flexGrow: 1 }}>
              <AppTextTranslate
                variant="mdb"
                sx={{ width: "100%" }}
                capitalize
                numberOfLines={1}
              >
                {category}
              </AppTextTranslate>
            </Flex>

            {/* <Spacer /> */}
            <Flex
              flexDirection="row"
              sx={{
                alignItems: "center",
                justifyContent: "flex-end",
                flexShrink: 1,
                // bg: "purple",
              }}
            >
              {proBono && (
                <>
                  <Center>
                    <FaHandHoldingHeart
                      style={styles.myStarStyle}
                      size={fonts.size.md}
                    />
                  </Center>
                  <AppText variant="mdb" sx={{ paddingX: getPx(padding.xs) }}>
                    {" "}
                    •{" "}
                  </AppText>
                </>
              )}

              <AppTextTranslate variant="md" numberOfLines={1}>
                {reviews > 0 ? toFixed(rating, 2) : "New"}
              </AppTextTranslate>

              <AppStar
                sx={{
                  marginBottom: getPx(padding.one),
                  marginLeft: getPx(padding.sm),
                }}
                value={reviews > 0 ? Number(rating ?? 0) / 5 : 0}
              />
            </Flex>
          </Flex>

          <AppTextTranslate noAuto variant="lgb" numberOfLines={1}>
            {title}
          </AppTextTranslate>
          <AppTextTranslate noAuto variant="md" numberOfLines={2}>
            {subCategory === "" ? "-" : subCategory}
          </AppTextTranslate>
          <AppTextTranslate variant="mdb" numberOfLines={1}>
            {rate}
          </AppTextTranslate>
        </Box>
      </Stack>
    </AppPressable>
  );
}

const styles = Object.create({
  card: {
    // backgroundColor: colors.info,
    width: "100%",
    paddingBottom: getPx(padding.xl),
    paddingX: getPx(padding.md),
    // overflow: "hidden",
  },
  detailsContainer: {
    marginTop: getPx(padding.sm),
    // flexDirection: "column",
    width: "100%",
    // alignItems: "flex-start",
  },
  image: {
    height: "100%",
    width: "100%",
  },
  imageContainer: {
    backgroundColor: colors.grayF4,
    borderRadius: getPx(borders.borderRadius12),
    // borderWidth: 1,
    // height: "100%",
    width: "100%",
    borderColor: colors.grayE,
    overflow: "hidden",
  },
  myStarStyle: {
    color: colors.black,
  },
});
