import React, { useCallback, useRef, useEffect, useState } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useToast as useToastChakra,
  Button,
  useDisclosure,
  ButtonGroup,
  Flex,
  Box,
  HStack,
  Center,
  CloseButton,
  Stack,
  useDimensions,
} from "@chakra-ui/react";
import { FiCheck, FiCheckCircle, FiXCircle, FiInfo } from "react-icons/fi";

import colors from "../config/colors";
import padding from "../config/padding";
import AppText from "../components/AppText";
import AppTextTranslate from "../components/AppTextTranslate";
import { getPx } from "../utils/helpers";
import borders from "../config/borders";
import AppButton from "../components/AppButton";

const variants = {
  success: {
    title: "Success",
    iconName: "check-circle",
    iconAlert: <FiCheckCircle color={colors.primary} size={40} />,
    iconComp: <FiCheckCircle color={colors.primary} size={50} />,
    iconColor: colors.primary,
    colorScheme: "customerBrand",
  },
  error: {
    title: "Error",
    iconName: "no-entry",
    iconAlert: <FiXCircle color={colors.danger} size={40} />,
    iconComp: <FiXCircle color={colors.danger} size={50} />,
    iconColor: colors.danger,
    colorScheme: "errorBrand",
  },
  info: {
    title: "Alert",
    iconName: "info",
    iconAlert: <FiInfo color={colors.info} size={40} />,
    iconComp: <FiInfo color={colors.info} size={50} />,
    iconColor: colors.info,
    colorScheme: "infoBrand",
  },
};

export const useToast = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToastChakra();
  const cancelRef = useRef();
  const [appToastParams, setAppToastParams] = useState();

  // const textContainerRef = useRef();
  // const dimensions = useSize(textContainerRef);

  // console.log("run appAlert: ", dimensions);

  const appAlert = useCallback((params) => {
    const { position, autoHide, onHide, props, visibilityTime = 6000 } = params;
    const {
      title,
      subtitle,
      hideClose = false,
      darkToast = false,
      noTranslateSubtitle,
      noTranslateTitle,
      contentColor = !darkToast ? colors.black : colors.white,
      iconName,
      iconSize = 40,
      onClose: onCloseToast = () => {},
      variant = "info",
      fontSize = 25,
      bottom = 180,
      hideLeft,
      iconLeft,
      iconMiddle,
      iconRight,
      rightButtonVariant = "primary",
      titleRight,
      pressLeft,
      pressMiddle,
      pressRight = () => {},
    } = props;

    toast({
      // isClosable: !hideClose,
      position,
      duration: autoHide === false ? null : visibilityTime,
      render: () => (
        <Flex
          boxShadow="lg"
          sx={{
            bg: colors.white,
            padding: getPx(padding.primary),
            borderRadius: borders.borderRadius12,
            border: "1px solid #f4f4f4",
            position: "relative",
            width: getPx(350),
            flexDir: "row",
            flex: 1,
          }}
        >
          {!hideClose && (
            <CloseButton
              onClick={() => {
                onCloseToast();
                hide();
              }}
              sx={{
                position: "absolute",
                right: getPx(padding.ms),
                top: getPx(padding.ms),
                textAlign: "right",
              }}
            />
          )}

          <Flex
            sx={{
              paddingRight: getPx(padding.ms),
              alignItems: "center",
              // paddingLeft: getPx(padding.sm),
              flex: 1,
            }}
          >
            {variants[variant].iconAlert}
          </Flex>
          <Flex
            // ref={textContainerRef}
            sx={{
              flex: 5,
              flexDir: "column",
              // flexWrap: "wrap",
              // background: "red",
            }}
          >
            <AppTextTranslate noTranslate={noTranslateTitle} variant="lgb">
              {title}
            </AppTextTranslate>
            <AppTextTranslate
              noTranslate={noTranslateSubtitle}
              variant="md"
              sx={{
                marginTop: getPx(padding.md),
                width: getPx(240),
                // flex: 1,
                // width: "100%",
              }}
            >
              {subtitle}
            </AppTextTranslate>
          </Flex>
        </Flex>
      ),
      // title: title,
      // description: subtitle,
      // status: variant,
      // onClose: () => {
      //   onCloseToast();
      //   hide();
      // },
      // colorScheme: variants[variant].colorScheme,
      // size: "lg",
      containerStyle: {
        pt: position === "top" ? getPx(padding.topBar80) : undefined,
        pb: position === "bottom" ? getPx(padding.bottomBar80) : undefined,
      },
      // containerStyle: { backgroundColor: "purple" },
    });
  }, []);

  const show = (params) => {
    const { type } = params;
    if (type === "appAlert") {
      appAlert(params);
    } else if (type === "appToast") {
      setAppToastParams(params);
      onOpen();
    }
  };

  const hide = () => {
    onClose();
    setAppToastParams();
    toast.closeAll();
  };

  const AppToast = ({ Component }) => {
    // const { isOpen, onOpen, onClose } = useDisclosure();
    // const cancelRef = React.useRef();
    if (appToastParams) {
      const { position, type, autoHide, onHide, props, visibilityTime } =
        appToastParams;
      const {
        title,
        subtitle,
        hideClose = false,
        darkToast = false,
        noTranslateSubtitle,
        noTranslateTitle,
        contentColor = !darkToast ? colors.black : colors.white,
        iconName,
        iconSize = 40,
        onClose: onCloseToast = () => {},
        variant = "info",
        fontSize = 25,
        bottom = 180,
        hideLeft,
        iconLeft,
        iconMiddle,
        iconRight,
        rightButtonVariant,
        // rightButtonColor = "black",
        titleRight,
        pressLeft,
        pressMiddle,
        pressRight = () => {},
      } = props;
      console.log("run AppToast");

      return (
        <>
          <AlertDialog
            motionPreset="slideInBottom"
            leastDestructiveRef={cancelRef}
            onClose={() => {
              onCloseToast();
              hide();
            }}
            isOpen={isOpen}
            isCentered
          >
            <AlertDialogOverlay />

            <AlertDialogContent
              sx={{ borderRadius: getPx(borders.borderRadius20) }}
            >
              <HStack spacing={0}>
                <Box
                  sx={{
                    width: "15%",

                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Flex sx={{ justifyContent: "flex-end" }}>
                    {variants[variant].iconComp}
                  </Flex>
                </Box>
                <Box sx={{ width: "85%" }}>
                  <AlertDialogHeader sx={{ marginTop: getPx(padding.ms) }}>
                    <AppTextTranslate
                      noTranslate={noTranslateTitle}
                      variant="h3"
                    >
                      {title}
                    </AppTextTranslate>
                  </AlertDialogHeader>
                  <AlertDialogCloseButton
                    sx={{
                      borderRadius: getPx(borders.borderRadius12),
                      right: getPx(padding.md),
                      top: getPx(padding.md),
                    }}
                  />
                  <AlertDialogBody>
                    <AppTextTranslate
                      noTranslate={noTranslateSubtitle}
                      variant="lg"
                    >
                      {subtitle}
                    </AppTextTranslate>
                  </AlertDialogBody>
                  <AlertDialogFooter sx={{ marginBottom: getPx(padding.sm) }}>
                    <ButtonGroup spacing="6">
                      {!hideLeft && (
                        <AppButton
                          buttonStyle={{ height: getPx(50) }}
                          ref={cancelRef}
                          onPress={pressLeft ? () => pressLeft() : () => hide()}
                          variant={"outline"}
                          IconRight={iconLeft ? iconLeft : FiCheck}
                        />
                      )}
                      {iconMiddle && pressMiddle && (
                        <AppButton
                          buttonStyle={{ height: getPx(50) }}
                          onPress={pressMiddle}
                          variant={"outline"}
                          IconRight={iconMiddle ?? FiCheck}
                        />
                      )}
                      <AppButton
                        buttonStyle={{ height: getPx(50) }}
                        onPress={() => {
                          pressRight();
                          onCloseToast();
                          hide();
                        }}
                        variant={rightButtonVariant}
                        // colorScheme={rightButtonColor}
                        IconRight={FiCheck}
                      />
                    </ButtonGroup>
                  </AlertDialogFooter>
                </Box>
              </HStack>
            </AlertDialogContent>
          </AlertDialog>
        </>
      );
    }
  };

  return { show, hide, AppToast };
};
