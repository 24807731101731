import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import { useToast } from "../../hooks/useToast";

export default function AppFormErrorToast({ name, noTouched, position }) {
  const { errors, touched } = useFormikContext();

  const Toast = useToast();

  useEffect(() => {
    if (Boolean(noTouched ? errors[name] : errors[name] && touched[name])) {
      Toast.show({
        type: "appAlert",
        visibilityTime: 10000,
        position: position,
        props: {
          title: "Uh oh!",
          subtitle: errors[name],
          variant: "error",
        },
      });
    }
  }, [noTouched, errors[name], touched[name]]);

  return <></>;
}
