import dayjs from "dayjs";
import web3 from "web3";
import { store } from "../store/store";
import configLocal from "../config/configLocal";
import fiatCurrency from "../config/fiatCurrency";
import lang from "../language";

const BN = web3.utils.BN;

export const isOdd = (num) => {
  return num % 2 !== 0;
};

export function randomString(length = 4) {
  return Math.round(
    Math.pow(36, length + 1) - Math.random() * Math.pow(36, length)
  )
    .toString(36)
    .slice(1);
}

export const removeFromString = (str, strToRemove) => {
  return str.replace(strToRemove, "");
};

const chains = {
  "0x1": "ETH",
  "0x89": "MATIC",
  "0x38": "BNB",
  "0xa86a": "AVAX",
};

export const getNativeByChain = (chain) => chains[chain];

export const getExpireDate = (expireInMinutes = 10080) => {
  const now = new Date();
  const expireTime = new Date(now);
  expireTime.setMinutes(now.getMinutes() + expireInMinutes);
  return expireTime;
};

export const listingHasLocation = (serviceLocation) =>
  serviceLocation !== "global";

export const getCountryFromGeocodeApi = (arr) => {
  let countryIndex;
  for (let i = 0; i < arr.length; i++) {
    let found = arr[i].types.find((x) => x === "country");
    if (found) {
      countryIndex = i;
      break;
    }
  }
  if (countryIndex) return arr[countryIndex];
  else return undefined;
};

export const fetchGeocodeApiUrl = (lat, lng) => {
  const GOOGLE_PLACES_API_KEY =
    process.env.REACT_APP_GOOGLE_MAPS_PLACES_API_KEY;
  return (
    "https://maps.googleapis.com/maps/api/geocode/json?address=" +
    lat +
    "," +
    lng +
    "&key=" +
    GOOGLE_PLACES_API_KEY
  );
};

export const getCountryCodeFromCountryName = (countryName) => {
  const countryObject = configLocal.countryCodes.find(
    (x) => x.countryName?.toUpperCase() === countryName?.toUpperCase()
  );
  return countryObject?.countryCode ?? undefined;
};

export const getCountryNameFromCountryCode = (countryCode) => {
  const countryObject = configLocal.countryCodes.find(
    (x) => x.countryCode?.toUpperCase() === countryCode?.toUpperCase()
  );
  return countryObject?.countryName ?? undefined;
};

export const getInterestFromApy = (apy = 0.051, interval = 365.25) =>
  ((apy + 1) ** (1 / interval) - 1) * interval;

export const awaitDuration = async (time = 1000) => {
  await new Promise((resolve) => setTimeout(() => resolve(), time));
};

export const getFromLanguage = () => {
  const state = store.getState();
  const { displayLanguage } = state.app;

  const fromLanguage = displayLanguage === "en" ? "en" : "th";

  return fromLanguage;
};

export const dualLanguage = (eng, thai) => {
  const fromLanguage = getFromLanguage();

  return fromLanguage === "en" ? eng : thai;
};

export const dualLanguage2 = (eng, thai, fromLanguage) => {
  return fromLanguage && fromLanguage === "en" ? eng : thai;
};

// export const getFromLanguage = () => {
//   const state = store.getState();
//   const { displayLanguage } = state.app;

//   const fromLanguage = displayLanguage === "en" ? "en" : "th";

//   return fromLanguage;
// };

// export const dualLanguage = (eng, thai) => {
//   const fromLanguage = getFromLanguage();
//   return fromLanguage === "en" ? eng : thai;
// };

export const getFiatCurrencyObject = (fiat = "usd") => {
  return fiatCurrency[fiat.toUpperCase()];
};

export function createAgendaDaysArray(dateFrom, dateTo) {
  const agendaDaysObject = {};
  const agendaDaysArray = [];

  let thisDate = dateFrom;

  while (thisDate <= dateTo) {
    agendaDaysObject[thisDate] = [];

    // agendaDaysArray.push({
    //   title: thisDate,
    //   data: [],
    // });

    thisDate = dayjs(thisDate).add(1, "day").format("YYYY-MM-DD");
  }

  return { agendaDaysArray, agendaDaysObject };
}

export function getDaysInMonth(month, year) {
  var date = new Date(year, month, 1);
  var days = [];
  while (date.getMonth() === month) {
    days.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  return days;
}

export const toFixed = (num, fixed = 18) => {
  var re = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?");
  return num.toString().match(re)[0];
};
//
export const fromWei = (x, y = "ether") =>
  web3.utils.fromWei(x ? (web3.utils.isBN(x) ? x : String(x)) : String("0"), y);
//
export const toWei = (x, y = "ether") =>
  web3.utils.toWei(String(toFixed(x) ?? "0"), y);
//
export const toWeiBN = (x, y) => new BN(toWei(String(x ?? "0"), y));

// async await version
export const processBatchAsync = async (
  query,
  process,
  batchSize = 100,
  startingAt = 0
) => {
  try {
    let skipAmount = startingAt,
      length;

    do {
      query.limit(batchSize);
      query.skip(skipAmount);

      const queryResults = await query.find({ useMasterKey: true });
      // console.log("beforeSave: " + skipAmount);
      const processResults = await process(queryResults);

      skipAmount += batchSize;
      length = processResults.length;
    } while (length === batchSize);

    return;
  } catch (error) {
    console.error("processBatchAsync: " + error.name + " - " + error.message);

    // console.log("processBatchAsync: " + error.message);
  }
};

// used in the web app for converting padding to px. RN uses numbers, web uses pixels
export const getPx = (num) => {
  return String(num + "px");
};

// used to enable array inputs for styles and sx props like in RN
export const getSx = (style) => {
  return Array.isArray(style)
    ? Object.assign({}, ...style)
    : Object.assign({}, style);
};

export const absoluteFill = () => ({
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
});

export const arrayExists = (array) =>
  array && Array.isArray(array) && array.length > 0;

export const getLangKey = (langKey, fromLanguage = "en") => {
  return lang[fromLanguage][langKey];
};
