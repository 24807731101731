import React from "react";
import { Flex, VStack } from "@chakra-ui/react";

import borders from "../config/borders";
import colors from "../config/colors";
import padding from "../config/padding";
import AppPressable from "./AppPressable";
import AppViewLayout from "./AppViewLayout";
import AppTextTranslate from "./AppTextTranslate";
import AppImage from "./AppImage";
import { getPx, getSx, toFixed } from "../utils/helpers";
import AppStar from "./AppStar";

export default function AppListingsCardXs({
  border,
  cardHeight = getPx(100),
  cardWidth = getPx(350),
  category,
  containerStyle,
  cursor,
  hidden,
  hoverScale = 1.01,
  pressScale,
  imgUrl,
  index,
  isDisabled,
  onPress = () => {},
  rating,
  reviews,
  shadow,
  title,
}) {
  // console.log("imgArray AppListingsCardXs", imgUrl);
  return (
    <AppViewLayout index={index} sx={{ userSelect: "none" }}>
      <AppPressable
        onPress={onPress}
        sx={getSx([
          {
            height: cardHeight,
            width: cardWidth,
            borderRadius: getPx(borders.borderRadius12),
            boxShadow: shadow ? "0px 0px 7px 1px rgba(0,0,0,0.2)" : null,
          },
        ])}
        cursor={cursor}
        hoverScale={hoverScale}
        pressScale={pressScale}
        isDisabled={isDisabled}
      >
        <Flex
          sx={getSx([
            styles.card,
            containerStyle,
            {
              borderWidth: border ? 1 : 0,
              opacity: hidden ? 0.4 : 1,
              // boxShadow: shadow ? "0px 0px 7px 1px rgba(0,0,0,0.2)" : null,
            },
          ])}
        >
          <Flex sx={styles.imageContainer}>
            <AppImage
              source={imgUrl}
              sx={styles.image}
              loaderHeight={padding.xxxxl}
              loaderWidth={padding.xxxxl}
            />
          </Flex>

          <VStack spacing={1} sx={styles.detailsContainer}>
            <Flex
              sx={{
                justifyContent: "space-between",
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
              }}
            >
              {category && (
                <AppTextTranslate
                  capitalize
                  variant="md"
                  sx={styles.category}
                  numberOfLines={1}
                >
                  {category}
                </AppTextTranslate>
              )}

              <Flex
                sx={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <AppTextTranslate variant="md" numberOfLines={1}>
                  {reviews > 0 ? toFixed(rating, 2) : "New"}
                </AppTextTranslate>

                <AppStar
                  sx={{
                    marginBottom: getPx(padding.one),
                    marginLeft: getPx(padding.sm),
                  }}
                  value={reviews > 0 ? Number(rating ?? 0) / 5 : 0}
                />
              </Flex>
            </Flex>

            <AppTextTranslate
              noAuto
              variant="lgb"
              // sx={styles.title}
              // lineHeightNumber={14}
              numberOfLines={2}
              // sx={{ width: "100%" }}
            >
              {title}
            </AppTextTranslate>
          </VStack>
        </Flex>
      </AppPressable>
    </AppViewLayout>
  );
}

const styles = Object.create({
  card: {
    backgroundColor: colors.white,
    flexDirection: "row",
    overflow: "hidden",
    borderRadius: getPx(borders.borderRadius12),
    // borderWidth: 1,
    // width: "100%",
    flex: 1,
    borderColor: colors.grayE,
  },
  category: { color: colors.gray7 },
  detailsContainer: {
    flex: 1,
    // width: "100%",
    paddingX: getPx(padding.ms),
    justifyContent: "center",
    alignItems: "flex-start",
    height: getPx(100),
  },
  image: {
    width: getPx(90),
    height: getPx(100),
    // minHeight: getPx(100),
  },
  imageContainer: {
    alignItems: "center",
    backgroundColor: colors.grayF7,
    justifyContent: "center",
    overflow: "hidden",
    width: getPx(90),
    height: getPx(100),
    // minHeight: getPx(100),
  },
  myStarStyle: {
    color: colors.black,
  },
});
