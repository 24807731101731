import React from "react";

import useCarouselDots from "../hooks/useCarouselDots";

const AppCarouselDots = (props) => {
  const {
    totalImages,
    current,
    maxDots = 5,
    size,
    margin,
    padding,
    inactiveColor,
    activeColor,
  } = props;

  const visible = Math.min(maxDots, totalImages);
  const total = totalImages;

  const {
    defaultHolderStyle,
    getHolderStyle,
    dots,
    defaultDotsStyle,
    getDotsStyle,
    defaultDotStyle,
    getDotStyle,
    defaultVisibleDotStyle,
    defaultActiveDotStyle,
    defaultSmallDotStyle,
    defaultMediumDotStyle,
    defaultBigDotStyle,
    isSmall,
    isMedium,
    isBig,
    isActive,
    isVisible,
  } = useCarouselDots({
    total,
    current,
    visible,
    size,
    margin,
    padding,
    inactiveColor,
    activeColor,
  });

  return (
    <div style={{ ...defaultHolderStyle, ...getHolderStyle }}>
      {dots.map((dot) => {
        return (
          <div key={dot} style={{ ...defaultDotsStyle, ...getDotsStyle }}>
            <div
              key={`${dot}-inner`}
              style={{
                ...defaultDotStyle,
                ...getDotStyle,
                ...(isActive(dot) ? defaultActiveDotStyle : {}),
                ...(isVisible(dot) ? defaultVisibleDotStyle : {}),
                ...(isSmall(dot) ? defaultSmallDotStyle : {}),
                ...(isMedium(dot) ? defaultMediumDotStyle : {}),
                ...(isBig(dot) ? defaultBigDotStyle : {}),
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

export default AppCarouselDots;
