import React from "react";

import AppPressable from "./AppPressable";
import AppIcon from "./AppIcon";

export default function AppIconButton({
  isDisabled,
  // hitSlop,
  onPress,
  pressStyle,
  scale = 0.97,
  size = 50,
  backgroundColor = "transparent",
  noAnimate,
  ...otherProps
}) {
  return (
    <AppPressable
      // hitSlop={hitSlop}
      noAnimate={noAnimate}
      isDisabled={isDisabled}
      onPress={onPress}
      pressScale={scale}
      style={pressStyle}
    >
      <AppIcon size={size} backgroundColor={backgroundColor} {...otherProps} />
    </AppPressable>
  );
}

