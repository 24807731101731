import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Screen from "../components/Screen";
import AppText from "../components/AppText";
import { useAuth } from "../hooks/useAuth";
import { Center } from "@chakra-ui/react";
import AppTextTranslate from "../components/AppTextTranslate";
import { getPx } from "../utils/helpers";
import padding from "../config/padding";
import { fontConfig as fonts } from "../config/fonts";

const AuthCallbackScreen = ({ platform }) => {
  const { authData } = useParams();

  const {
    authenticateWithApple,
    authenticateWithFacebook,
    authenticateWithGoogle,
    authenticateWithTwitter,
    handleSharedListing,
    setIsLoading,
  } = useAuth();

  //     if (url.url) {
  //       if (url.url.indexOf("appleAuthCallback") >= 0)
  //         authenticateWithApple(url.url);
  //       if (url.url.indexOf("facebookAuthCallback") >= 0)
  //         authenticateWithFacebook(url.url);
  //       if (url.url.indexOf("googleAuthCallback") >= 0)
  //         authenticateWithGoogle(url.url);
  //       if (url.url.indexOf("twitterAuthCallback") >= 0)
  //         authenticateWithTwitter(url.url);
  //       if (url.url.indexOf("listing") >= 0) handleSharedListing(url.url);
  //       // if (url.url.indexOf("refLink231027") >= 0)
  //       //   handleLinkClicked(url.url, "refLink231027");
  //     }

  const handleAuth = async () => {
    switch (platform) {
      case "apple":
        console.log("Signing in with Apple");
        setIsLoading(true);
        try {
          const str = window.location.href;
          const slug = str.split("appleAuthCallback/").pop();
          await authenticateWithApple("youwhoapp://appleAuthCallback/" + slug);
        } catch (error) {
          console.log(error);
          setIsLoading(false);
        }
        break;
      case "facebook":
        console.log("Signing in with Facebook");
        setIsLoading(true);
        try {
          const str = window.location.href;
          const slug = str.split("facebookAuthCallback/").pop();
          await authenticateWithFacebook(
            "youwhoapp://facebookAuthCallback/" + slug
          );
        } catch (error) {
          console.log(error);
          setIsLoading(false);
        }
        break;
      case "google":
        console.log("Signing in with Google");
        setIsLoading(true);
        try {
          const str = window.location.href;
          const slug = str.split("googleAuthCallback/").pop();
          console.log("window.location.href: ", window.location.href);
          console.log("slug: ", slug);
          await authenticateWithGoogle(
            "youwhoapp://googleAuthCallback/" + slug
          );
        } catch (error) {
          console.log(error);
          setIsLoading(false);
        }
        break;
      case "twitter":
        console.log("Signing in with Twitter");
        setIsLoading(true);
        try {
          const str = window.location.href;
          const slug = str.split("twitterAuthCallback/").pop();
          console.log("window.location.href: ", window.location.href);
          await authenticateWithTwitter(
            "youwhoapp://twitterAuthCallback/" + slug
          );
        } catch (error) {
          console.log(error);
          setIsLoading(false);
        }
        break;
    }
  };

  useEffect(() => {
    if (authData) {
      setTimeout(() => handleAuth(), 500);
    }
  }, [authData]);

  return (
    <Screen>
      <Center sx={{ h: "50vh", flexDir: "column" }}>
        <AppTextTranslate
          variant="lgb"
          center
          style={{
            marginBottom: getPx(padding.md),
            //   fontSize: getPx(fonts.size.xxh1),
          }}
        >
          Logging in with
        </AppTextTranslate>
        <AppText
          noAuto
          variant="h3"
          center
          style={{
            marginBottom: getPx(padding.md),
            fontSize: getPx(fonts.size.xh1),
          }}
          // lineHeightNumber={fonts.size.xh1 + 1}
          sx={{ marginX: getPx(padding.sm) }}
          capitalize
        >
          {platform}
        </AppText>
        <AppTextTranslate
          variant="lgb"
          center
          style={{
            marginBottom: getPx(padding.md),
            //   fontSize: getPx(fonts.size.xxh1),
          }}
          // lineHeightNumber={fonts.size.xxh1 + 1}
        >
          You will be redirected soon...
        </AppTextTranslate>
      </Center>
    </Screen>
  );
};

export default AuthCallbackScreen;
