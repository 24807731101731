import React from "react";
import { useFormikContext } from "formik";

// import AppTextInput from "../AppTextInput";
import ErrorMessage from "./ErrorMessage";
import colors from "../../config/colors";
import { useTranslateText } from "../../hooks/useTranslate";
import AppFormErrorToast from "./AppFormErrorToast";
import { fontConfig as fonts } from "../../config/fonts";
import padding from "../../config/padding";
import { getPx } from "../../utils/helpers";
import AppTextInput from "../AppTextInput";

export default function AppFormField({
  name,
  noErrorMessage,
  modal,
  onChangeTextField,
  contentStyle,
  placeholder = " ",
  toastError,
  toastPosition,
  noTouched,
  ...otherProps
}) {
  const { setFieldTouched, setFieldValue, errors, touched, values } =
    useFormikContext();

  const handleChangeTextField = (text) => {
    setFieldValue(name, text);
    if (onChangeTextField) onChangeTextField(text);
    setTimeout(() => setFieldTouched(name), 0);
  };

  return (
    <>
      {/* <FormControl variant={labelVariant} id={name} isRequired>
        <InputGroup size="lg">
        <InputLeftElement width={getPx(padding.xxxl)}>
            <AppButton
              fontSize={fonts.size.md}
              variant="none"
              onPress={() => setShow(!show)}
              IconRight={show ? FiEyeOff : FiEye}
            />
          </InputLeftElement>
          <Input
            maxLength={maxLength}
            placeholder={labelVariant === "floating" ? " " : placeholder}
            focusBorderColor="black"
            sx={{ _focus: { borderWidth: getPx(2) } }}
            errorBorderColor="primary"
            // size="lg"
            type={type === "password" ? (show ? type : "password") : type}
          />
          <FormLabel>{otherProps.label}</FormLabel>
          {type === "password" && 
          <InputRightElement width={getPx(padding.xxxl)}>
            <AppButton
              fontSize={fonts.size.md}
              variant="none"
              onPress={() => setShow(!show)}
              IconRight={show ? FiEyeOff : FiEye}
            />
          </InputRightElement>
          }
        </InputGroup> */}
      {/* <FormHelperText>Keep it very short and sweet!</FormHelperText> */}
      {/* <FormErrorMessage>Your First name is invalid</FormErrorMessage> */}

      <AppTextInput
        name={name}
        onBlurField={() => {
          setTimeout(() => setFieldTouched(name), 0);
        }}
        onChangeTextField={(text) => handleChangeTextField(text)}
        value={values[name] ?? undefined}
        contentStyle={
          errors[name] && touched[name]
            ? {
                ...contentStyle,
                borderColor: colors.primary,
                borderWidth: getPx(2),
              }
            : contentStyle
        }
        placeholder={placeholder}
        {...otherProps}
      />
      {toastError && (
        <AppFormErrorToast
          name={name}
          noTouched={noTouched}
          position={toastPosition}
        />
      )}
      {!noErrorMessage && (
        <ErrorMessage error={errors[name]} visible={touched[name]} />
      )}
      {/* </FormControl> */}
    </>
  );
}
