import React, { createContext, useContext, useMemo, useState } from "react";
import Parse from "parse";
// import translate from "google-translate-api-x";
import Web3 from "web3";

// import { useToast } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setEnableBiometricsRedux,
  setGotLocationOnceRedux,
  setLogoutRedux,
  setUserFavoritesRedux,
  setUserRedux,
  setNotificationSettingsRedux,
  setEnableMfaRedux,
  setMfaPassedRedux,
  setBiometricsLockedRedux,
} from "../store/slices/user";
import {
  setAppConfigRedux,
  setAppIsReadyRedux,
  startLoading,
  stopLoading,
} from "../store/slices/app";
import configLocal from "../config/configLocal";
import { useJsApiLoader } from "@react-google-maps/api";
import { useToast } from "../hooks/useToast";
import { useDisclosure } from "@chakra-ui/react";

const web3 = new Web3(Web3.givenProvider);

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const YouwhoContext = createContext();

export function useYouwho() {
  return useContext(YouwhoContext);
}

export function YouwhoProvider({ children }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const {
  //   isOpen: loginIsOpen,
  //   onOpen: loginOnOpen,
  //   onClose: loginOnClose,
  // } = useDisclosure();
  const appStore = useSelector((state) => state.app);
  const naviStore = useSelector((state) => state.navi);
  const userStore = useSelector((state) => state.user);
  const { appIsReady, isLoading } = appStore;
  const { user: userRedux } = userStore;
  const [user, setUser] = useState(userRedux);
  const Toast = useToast();
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_PLACES_API_KEY,
  });
  let urlQuery = useQuery();

  // useEffect(() => {
  //   setUserFromSession();
  // }, []);

  const setAppIsReady = (value) => {
    dispatch(setAppIsReadyRedux(value ? true : false));
  };

  const setIsLoading = (value) => {
    if (value === true) dispatch(startLoading());
    else dispatch(stopLoading());
  };

  const setUserYouwho = (newUser, fetch = true) => {
    dispatch(setUserRedux(newUser, fetch));
    setUser(newUser);
  };

  const handleError = (errorMessage, title = "Error") => {
    if (errorMessage)
      Toast.show({
        type: "appAlert",
        visibilityTime: 4000,
        props: {
          title,
          subtitle: errorMessage,
          variant: "error",
        },
      });
  };

  const handleInfo = (infoMessage, title = "Info") => {
    Toast.show({
      type: "appAlert",
      visibilityTime: 4000,
      props: {
        title,
        subtitle: infoMessage,
        variant: "info",
      },
    });
  };

  const getSessionToken = async () => {
    try {
      const currentSession = await Parse.Session.current();
      const sessionToken = currentSession.getSessionToken();
      return sessionToken;
    } catch (error) {
      console.log("getSessionToken error: " + error.message);
      logout();
    }
  };

  const setUserFromSession = async () => {
    try {
      const sessionToken = await getSessionToken();
      if (sessionToken) {
        console.log("sessionToken: " + (sessionToken ? "found" : "not found"));
        const loggedInUser = await Parse.User.become(sessionToken);
        if (loggedInUser) {
          // if (!loggedInUser.attributes?.userPublic) {
          //   await loggedInUser.fetch();
          // }
          const mfaPassed = await Parse.Cloud.run("getSessionMfaPassed");
          dispatch(setMfaPassedRedux(mfaPassed ?? false));
          dispatch(setEnableMfaRedux(loggedInUser.get("mfaEnabled") ?? false));
          dispatch(
            setEnableBiometricsRedux(loggedInUser.get("biometricsEnabled"))
          );
          // dispatch(setBiometricsLockedRedux(true));
          dispatch(
            setNotificationSettingsRedux(
              loggedInUser.get("notificationSettings") ??
                configLocal.notificationSettings
            )
          );
          setUserYouwho(loggedInUser);
        }
      }
    } catch (error) {
      console.log("setUserFromSession error: " + error.message);
      logout();
    }
  };

  const setUserFromCurrent = async () => {
    try {
      const loggedInUser = await Parse.User.currentAsync();
      if (loggedInUser) setUserYouwho(loggedInUser);
    } catch (error) {
      console.log("setUserFromCurrent error: " + error.message);
    }
  };

  const refetchUserData = async () => {
    setIsLoading(true);
    try {
      await setUserFromSession();
      console.log("refetching user data...");
      await user.fetch();
      // const userToFetch = await Parse.User.currentAsync();
      // if (userToFetch) {
      //   const loggedInUser = await userToFetch.fetch();
      //   setUserYouwho(loggedInUser);
      //   console.log("refetch user data success.");
      // }
      console.log("refetch user data success.");
    } catch (error) {
      handleError(error.message, "Refetch user error");
      console.log("refetchUserData error: " + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const login = async (email, password) => {
    setIsLoading(true);
    try {
      console.log("logging in user...");
      const loggedInUser = await Parse.User.logIn(email, password);

      if (loggedInUser) {
        // if (!loggedInUser.attributes?.userPublic) {
        //   await loggedInUser.fetch();
        // }
        const mfaPassed = await Parse.Cloud.run("getSessionMfaPassed");
        dispatch(setMfaPassedRedux(mfaPassed ?? false));
        dispatch(setEnableMfaRedux(loggedInUser.get("mfaEnabled") ?? false));
        dispatch(
          setEnableBiometricsRedux(loggedInUser.get("biometricsEnabled"))
        );
        dispatch(setBiometricsLockedRedux(true));
        dispatch(setGotLocationOnceRedux(false));
        dispatch(
          setNotificationSettingsRedux(
            loggedInUser.get("notificationSettings") ??
              configLocal.notificationSettings
          )
        );
        setUserYouwho(loggedInUser);
        console.log("login user success.");
      }
    } catch (error) {
      handleError(error.message, "Login error");
      console.log("login error: " + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const logout = async () => {
    setIsLoading(true);
    try {
      console.log("logging out user...");
      setUserYouwho(null);
      await Parse.User.logOut();
      dispatch(setGotLocationOnceRedux(false));
      dispatch(setUserFavoritesRedux([]));
      dispatch(setMfaPassedRedux(false));
      dispatch(setEnableMfaRedux(false));
      dispatch(setEnableBiometricsRedux(false));
      dispatch(setBiometricsLockedRedux(false));
      dispatch(setNotificationSettingsRedux(configLocal.notificationSettings));
      console.log("logout user success.");
    } catch (error) {
      // handleError(error.message, "Logout error");
      console.log("logout error: " + error.message);
    } finally {
      try {
        dispatch(setLogoutRedux());
      } catch (error) {
        console.log("setLogoutRedux error: " + error.message);
      }
      setIsLoading(false);
      // setAppIsReady(true);
    }
  };

  const signup = async (username, password, email, agreed, refer) => {
    setIsLoading(true);
    // console.log("signing up user...");
    // console.log("signing up username", username);
    // console.log("signing up email", email);
    // console.log("signing up password", password);
    const newUser = new Parse.User();
    newUser.set("username", username);
    newUser.set("email", email);
    newUser.set("password", password);
    newUser.set("agreedToTerms", agreed);
    if (refer) newUser.set("referredBy", refer);
    newUser.set("notificationSettings", configLocal.notificationSettings);

    // other fields can be set just like with Parse.Object
    // user.set("phone", "415-392-0202");
    try {
      await newUser.signUp();
      await login(email, password);
      console.log("signup user success.");
    } catch (error) {
      handleError(error.message, "Signup error");
      console.log("signup error: " + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const updateConfig = async () => {
    try {
      const query = new Parse.Query("Config");
      const response = await query.first();
      if (response) {
        console.log("updateConfig before");
        dispatch(setAppConfigRedux(response));
        console.log("updateConfig after");
      }
    } catch (error) {
      console.log("updateConfig error: " + error.message);
    }
  };

  const value = useMemo(
    () => ({
      appIsReady,
      appStore,
      dispatch,
      getSessionToken,
      handleError,
      handleInfo,
      isLoading,
      login,
      // loginIsOpen,
      // loginOnOpen,
      // loginOnClose,
      logout,
      mapIsLoaded: isLoaded,
      navigation: navigate,
      naviStore,
      Parse,
      refetchUserData,
      setAppIsReady,
      setIsLoading,
      setUserFromCurrent,
      setUserFromSession,
      setUserYouwho,
      signup,
      Toast,
      // translate,
      updateConfig,
      urlQuery,
      user,
      userStore,
      web3,
    }),
    [appIsReady, appStore, isLoading, user, userStore, naviStore, isLoaded]
  );

  return (
    <YouwhoContext.Provider value={value}>{children}</YouwhoContext.Provider>
  );
}
