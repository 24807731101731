import { Button, Flex, Text } from "@chakra-ui/react";
import colors from "../../config/colors";
import { FiSearch, FiLayers } from "react-icons/fi";
import { GoCommentDiscussion, GoPerson } from "react-icons/go";
import { HiMiniFingerPrint } from "react-icons/hi2";
import { NavLink } from "react-router-dom";
import { getPx } from "../../utils/helpers";
import padding from "../../config/padding";
import AppTextTranslate from "../AppTextTranslate";

export default function BottomTabs({ children }) {
  const tabs = [
    {
      title: "Services",
      icon: FiSearch,
      path: "/services",
    },
    {
      title: "Chats",
      icon: GoCommentDiscussion,
      path: "/chats",
    },
    {
      title: "Bookings",
      icon: HiMiniFingerPrint,
      path: "/bookings",
    },
    {
      title: "Wallet",
      icon: FiLayers,
      path: "/wallet",
    },
    {
      title: "Account",
      icon: GoPerson,
      path: "/account",
    },
  ];

  return (
    <Flex
      // className="p-sm-3"
      sx={{
        position: "fixed",
        // width: ["50vw", , , "100vw"],
        // h: ["50px", , "60px"],
        bottom: 0,
        left: 0,
        right: 0,
        flexDirection: "row",
        justifyContent: "space-between",
        backgroundColor: "white",
        boxShadow: "0px -1px 5px 1px rgba(0,0,0,0.1)",
        borderTopWidth: 1,
        borderColor: colors.grayD,
      }}
      // backgroundColor="white"
      // background="radial-gradient(circle at 20% 100%, rgba(255, 255, 255, 0.1) 0%, rgba(160, 160, 160, 0.3) 30%, rgba(255, 255, 255, 0.3) 100%)"
    >
      {tabs.map((x, i) => (
        <NavLink
          key={x.title}
          to={x.path}
          style={{
            flex: 1,
          }}
        >
          {({ isActive, isPending, isTransitioning }) => (
            <Button
              colorScheme="whiteAlpha"
              isActive={isActive}
              sx={{
                backgroundColor: "white",
                color: colors.gray6,
                fontWeight: 400,
                fontSize: ["xs", "small"],
                _hover: { color: colors.black, fontWeight: 600 },
                _active: { color: colors.black, fontWeight: 600 },
                paddingTop: getPx(i !== 2 ? padding.xs : padding.one),
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                flexDir: "column",
                height: [
                  getPx(padding.bottomBar50),
                  getPx(padding.bottomBar40),
                ],
                width: "100%",
              }}
            >
              <x.icon
                size={i !== 2 ? "40%" : "45%"}
                style={{
                  color: isActive ? colors.primary : undefined,
                }}
              />
              <AppTextTranslate
                fromLanguage="en"
                variant="sm"
                sx={
                  isActive
                    ? { color: colors.black, fontWeight: 600 }
                    : {
                        color: colors.gray6,
                        _hover: { color: colors.black },
                      }
                }
              >
                {x.title}
              </AppTextTranslate>
            </Button>
          )}
        </NavLink>
      ))}
    </Flex>
  );
}

