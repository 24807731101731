import axios from "axios";
const { langCodeExists } = require("./languages");

const API_KEY = process.env.REACT_APP_LIBRE_TRANSLATION_API_KEY;
const API_URL = "https://testserver4.youwho.io/translate/translate";
const conversionTable = { "zh-CN": "zh", "zh-TW": "zt", no: "nb" };

const convertLibreLanguageCodes = (languageCode) => {
  if (
    languageCode === "auto" ||
    !Object.keys(conversionTable).includes(languageCode)
  )
    return languageCode;

  return conversionTable[languageCode];
};

export const yTranslate = async (text, options) => {
  if (!langCodeExists(options.to)) throw new Error("Incorrect language code");

  const response = await axios.post(
    API_URL,
    {
      q: text,
      target: convertLibreLanguageCodes(options.to),
      source: convertLibreLanguageCodes(options.from),
      format: options.format ?? "text",
      api_key: API_KEY,
    },
    {
      headers: { "Content-Type": "application/json" },
    }
  );

  return response?.data?.translatedText;

  // axios
  //   .post(
  //     API_URL,
  //     {
  //       q: text,
  //       target: convertLibreLanguageCodes(options.to),
  //       source: convertLibreLanguageCodes(options.from),
  //       format: options.format ?? "text",
  //       api_key: API_KEY,
  //     },
  //     {
  //       headers: { "Content-Type": "application/json" },
  //     }
  //   )
  //   .then((response) => {
  //     return response?.data?.translatedText;
  //   })
  //   .catch((err) => {
  //     console.log("yTranslate error: ", err);
  //   });
};

export const yTranslateSync = (text, options) => {
  if (!langCodeExists(options.to)) throw new Error("Incorrect language code");

  axios
    .post(
      API_URL,
      {
        q: text,
        target: convertLibreLanguageCodes(options.to),
        source: convertLibreLanguageCodes(options.from),
        format: options.format ?? "text",
        api_key: API_KEY,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    )
    .then((response) => {
      return response?.data?.translatedText;
    })
    .catch((err) => {
      console.log("yTranslateSync error: ", err);
    });
};
