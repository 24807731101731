import { combineReducers } from "redux";
// slices
import userReducer from "./slices/user";
import naviReducer from "./slices/navi";
import appReducer from "./slices/app";

// ----------------------------------------------------------------------

const rootReducer = combineReducers({
  app: appReducer,
  navi: naviReducer,
  user: userReducer,
});

export { rootReducer };

