import { useEffect, useState } from "react";
import { useYouwho } from "../package/react-youwho";

import {
  setAppConfigRedux,
  setAppIsReadyRedux,
  setDisplayLanguageRedux,
} from "../store/slices/app";
import padding from "../config/padding";
import { awaitDuration } from "../utils/helpers";

const useInitApp = () => {
  const { logout, Parse, setUserFromSession, dispatch, user, appStore, Toast } =
    useYouwho();
  const { appIsReady } = appStore;
  // const [hasInternet, setHasInternet] = useState(true);

  const getData = async () => {
    console.log("started getData");

    try {
      await setUserFromSession();
    } catch (error) {
      logout();
      console.log("setUserFromSession error:" + error.message);
    }

    const queryData = async () => {
      const query = new Parse.Query("Config");
      const response = await query.first();
      if (response) {
        dispatch(setAppConfigRedux(response));
        console.log("dispatched config to store.");
        await awaitDuration(1500);
        dispatch(setAppIsReadyRedux(true));
        console.log("App initialization complete. Starting app...");
      }
    };

    try {
      await queryData();
    } catch (error) {
      console.log("useInitApp error", error.message);
      // if (!hasInternet) {
      //   Toast.show({
      //     type: "appToast",
      //     autoHide: false,
      //     visibilityTime: 10000,
      //     props: {
      //       title: "Can't connect to server",
      //       subtitle:
      //         "Please check your internet connection then restart the app.",
      //       variant: "error",
      //     },
      //   });
      // } else {
      // Toast.show({
      //   type: "appAlert",
      //   autoHide: false,
      //   visibilityTime: 10000,
      //   props: {
      //     title: "Server Unavailable",
      //     subtitle:
      //       "The YOUWHO server is currently unavailable, please try again soon.",
      //     variant: "error",
      //   },
      // });
      Toast.show({
        type: "appToast",
        autoHide: false,
        // onHide: () => {
        //   handleShowUnderlay(false);
        //   setIsSubmitting(false);
        // },
        // onShow: () => handleShowUnderlay(true),
        props: {
          // hideClose: false,
          hideLeft: true,
          title: "Server Unavailable",
          subtitle:
            "Unable to connect to the YOUWHO server, please try again soon.",
          // pressRight: () => {
          //   console.log("pressed query data");
          //   Toast.hide();
          //   getData();
          // },
          onClose: () => {
            console.log("pressed query data");
            // Toast.hide();
            getData();
          },
          iconRight: "rotate-ccw",
          variant: "error",
          bottom: -padding.primary,
          rightButtonVariant: "white",
        },
      });
      // }
    } finally {
    }
  };

  useEffect(() => {
    if (user && user?.get("displayLanguage"))
      dispatch(setDisplayLanguageRedux(user.get("displayLanguage")));
  }, [user]);

  // useEffect(() => {
  //   const unsubscribeNetInfo = NetInfo.addEventListener((state) => {
  //     if (!state.isConnected && appIsReady && hasInternet) {
  //       dispatch(setAppIsReadyRedux(false));
  //       setHasInternet(false);
  //     } else if (state.isConnected && !hasInternet) {
  //       getData();
  //       setHasInternet(true);
  //     }
  //     // console.log("has internet: " + state.isConnected);
  //   });

  //   return () => {
  //     // Clean up the event listeners
  //     unsubscribeNetInfo();
  //   };
  // }, []);

  useEffect(() => {
    let loopGetData;
    if (!appIsReady) {
      getData();
      // loopGetData = setTimeout(() => getData(), 10000);
    }
    return () => {
      // if (loopGetData) clearTimeout(loopGetData);
    };
  }, [appIsReady]);
};

export default useInitApp;
