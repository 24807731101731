import React, { useState, useCallback, useRef, useEffect } from "react";
import { Tabs, TabList, TabPanels, Tab, TabPanel, Box } from "@chakra-ui/react";
import dayjs from "dayjs";

import colors from "../config/colors";
import { fontConfig as fonts } from "../config/fonts";
import padding from "../config/padding";

import AppText from "./AppText";
import AppLoadingAnimation from "./AppLoadingAnimation";
import AppViewLayout from "./AppViewLayout";
import AppAvatar from "./AppAvatar";
import AppStar from "./AppStar";
import { arrayExists, getPx, getSx } from "../utils/helpers";
import { useNavigate } from "react-router-dom";
import AppPressable from "./AppPressable";
import ListItemSeparator from "./lists/ListItemSeparator";
import View from "./rnMimic/View";
import AppTextTranslate from "./AppTextTranslate";
import borders from "../config/borders";

export default function AppUserReviews({
  reviewIndex,
  info,
  tabIndex,
  setTabIndex,
}) {
  // console.log("route", reviewIndex, info, tabIndex);
  // const { reviewIndex, info, tabIndex } = route.params;
  const navigation = useNavigate();
  // const [info, setInfo] = useState(dataInfo);
  // console.log("AppUserReviews info", info);
  // console.log("AppUserReviews tabIndex", tabIndex);

  const handleTabsChange = (index) => {
    setTabIndex(index);
  };

  // const getReviews = async () => {
  //     const queryReviews = reviewsRelation.query();
  //     queryReviews.include(publicPointer);
  //     const reviewObjects = await queryReviews.find();
  //     setReviewsArray(reviewObjects);
  //     console.log("reviewObjects", reviewObjects);

  // };

  // useEffect(() => {
  //     setInfo(dataInfo);
  // }, [dataInfo])

  return (
    <View>
      <AppText variant="h2">
        {Number(info?.reviewsAsProvider || 0) +
          Number(info?.reviewsAsCustomer || 0) +
          " Reviews"}
      </AppText>

      <Tabs
        // defaultIndex={tabIndex}
        onChange={handleTabsChange}
        index={tabIndex}
        style={{ marginTop: getPx(padding.primary), width: "100%" }}
      >
        <TabList>
          <Tab
            color={colors.black}
            fontSize={fonts.size.md}
          >{`From Customers (${Number(info?.reviewsAsProvider || 0)})`}</Tab>
          <Tab
            color={colors.black}
            fontSize={fonts.size.md}
          >{`From Providers (${Number(info?.reviewsAsCustomer || 0)})`}</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <MapItem
              item={info}
              navigation={navigation}
              tabIndex={0}
              reviewsRelation={info?.reviewsProviderRelation}
              publicPointer={"customerPublicPointer"}
            />
          </TabPanel>
          <TabPanel>
            <MapItem
              item={info}
              navigation={navigation}
              tabIndex={1}
              reviewsRelation={info?.reviewsCustomerRelation}
              publicPointer={"providerPublicPointer"}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </View>
  );
}

// switch (route.key) {
//     case "fromCustomers":
//       return (
//         <ReviewsFromCustomers
//           reviewIndex={reviewIndex}
//           reviewsRelation={info?.reviewsProviderRelation}
//           tabIndex={index}
//           navigation={navigation}
//         />
//       );
//     case "fromProviders":
//       return (
//         <ReviewsFromProviders
//           reviewIndex={reviewIndex}
//           reviewsRelation={info?.reviewsCustomerRelation}
//           tabIndex={index}
//           navigation={navigation}
//         />
//       );
//     default:
//       return null;
//   }

const MapItem = ({
  item,
  navigation,
  tabIndex,
  reviewsRelation,
  publicPointer,
}) => {
  // console.log("MapItem item", item)
  // console.log("MapItem reviewsRelation ", reviewsRelation)
  // console.log("MapItem eviewsRelation.parent?.attributes?.username ", reviewsRelation.parent?.attributes?.username)
  // console.log("MapItem publicPointer", publicPointer)
  const [reviewsArray, setReviewsArray] = useState([]);
  const [reviewsArrayFull, setReviewsArrayFull] = useState([]);
  const [readMore, setReadMore] = useState(0);
  const [pressReadMore, setPressReadMore] = useState(false);

  const handlePressProfile = useCallback((pointer, tabIndex) => {
    // console.log("pointer", pointer, tabIndex);
    navigation(
      "/profile",
      { info: pointer?.attributes }
      // JSON.parse(JSON.stringify(userPublic.attributes))
    );
    // navigation.navigate(routes.PUBLIC_PROFILE_SCREEN.n, {
    //     screen: routes.PUBLIC_PROFILE_SCREEN.n,
    //     params: { info: pointer?.attributes },
    // })
  }, []);

  const getReviews = useCallback(async () => {
    console.log("reviewsRelation", reviewsRelation);
    try {
      const queryReviews = reviewsRelation.query();
      if (reviewsRelation.key === "reviewsProviderRelation") {
        queryReviews.equalTo("visible", true);
      }
      queryReviews.include(publicPointer);
      queryReviews.descending("updatedAt");
      const reviewObjects = await queryReviews.find();
      const reviewObjectsShort = [...reviewObjects];

      setReviewsArray(reviewObjects.slice(0, 7));
      setReviewsArrayFull(reviewObjects);
      //   console.log("reviewObjects", reviewObjects);
    } catch (error) {
      console.log("ReviewsFromCustomers getReviews error: ", error);
    }
  }, [item]);

  const handleLongReview = useCallback((e) => {
    // console.log("e.nativeEvent", e.nativeEvent?.layout?.height);
    if (e.nativeEvent?.layout?.height > 110 && !pressReadMore) {
      setReadMore(5);
    }
  }, []);

  useEffect(() => {
    getReviews();
  }, [item]);

  const renderItem = useCallback(
    ({ item, index }) => (
      <AppViewLayout fullWidth key={item.attributes?.createdAt} index={index}>
        <Box
          sx={getSx([
            {
              // marginRight: getPx(padding.primary),
              marginBottom: getPx(padding.primary),
            },
          ])}
        >
          <View flexStart direction="row">
            <AppAvatar
              name={
                tabIndex === 0
                  ? item.attributes?.customerPublicPointer?.attributes?.username
                  : item.attributes?.providerPublicPointer?.attributes?.username
              }
              url={
                tabIndex === 0
                  ? item.attributes?.customerPublicPointer?.attributes?.avatar
                      ?._url ??
                    item.attributes?.customerPublicPointer?.attributes
                      ?.avatarUrl
                  : item.attributes?.providerPublicPointer?.attributes?.avatar
                      ?._url ??
                    item.attributes?.providerPublicPointer?.attributes
                      ?.avatarUrl
              }
              variant="sm"
              style={{ top: getPx(4) }}
              pointer={
                tabIndex === 0
                  ? item.attributes?.customerPublicPointer?.attributes
                  : item.attributes?.providerPublicPointer?.attributes
              }
            />

            <View
              spacing={1}
              style={{ marginLeft: getPx(padding.ms), width: "100%" }}
            >
              <View direction="row">
                <AppStar
                  value={Number(item.attributes?.rating / 5)}
                  starSize={fonts.size.md}
                  style={{
                    color: colors.black,
                    bottom: getPx(2),
                    // marginRight: getPx(padding.sm),
                  }}
                />

                <AppText variant="mdb">{item.attributes?.rating}</AppText>
                <AppText variant="md" style={{ marginLeft: getPx(padding.sm) }}>
                  •&nbsp;
                  {tabIndex === 0
                    ? item.attributes?.customerPublicPointer?.attributes
                        ?.username
                    : item.attributes?.providerPublicPointer?.attributes
                        ?.username}
                </AppText>
              </View>

              <AppTextTranslate
                variant="sm"
                style={{
                  color: colors.gray6,
                  marginLeft: getPx(padding.xs),
                }}
              >
                {dayjs(item.attributes?.createdAt).format("MMMM YYYY")}
              </AppTextTranslate>

              <View spacing={1} style={{ marginTop: getPx(padding.ms) }}>
                <AppText
                  numberOfLines={readMore}
                  variant="md"
                  onLoad={handleLongReview}
                >
                  {item.attributes?.review}
                </AppText>
                {readMore > 0 && (
                  <AppPressable
                    noAnimate
                    onPress={() => {
                      setPressReadMore(true);
                      setReadMore(0);
                    }}
                    // style={{ marginRight: padding.ms }}
                  >
                    <AppText variant="mdb" underline right>
                      read more
                    </AppText>
                  </AppPressable>
                )}
              </View>
            </View>
          </View>

          <ListItemSeparator style={{ marginTop: getPx(padding.md) }} />
        </Box>
      </AppViewLayout>
    ),
    []
  );

  return (
    <View
      style={{
        marginTop: getPx(padding.ms),
        marginBottom: getPx(padding.primary),
      }}
    >
      {arrayExists(reviewsArray) &&
        reviewsArray.map((item, index) => renderItem({ item, index }))}
    </View>
  );
};
