import React, { useEffect, useMemo, useState } from "react";
import { Center, Image, useDisclosure } from "@chakra-ui/react";
import * as Yup from "yup";

import colors from "../config/colors";
import { ReactComponent as YouwhoLogo } from "../assets/svg/youwho-logos_logo-tab-icon.svg";
import padding from "../config/padding";
import ListItemSeparator from "../components/lists/ListItemSeparator";
import AppLoadingAnimation from "../components/AppLoadingAnimation";
import { useYouwho } from "../package/react-youwho";
import AppTextTranslate from "../components/AppTextTranslate";
import AppText from "../components/AppText";
import AppIconButton from "../components/AppIconButton";
import { fontConfig as fonts } from "../config/fonts";
import { dispatch } from "../store/store";
import { setDisplayLanguageRedux } from "../store/slices/app";
import AppForm from "../components/forms/AppForm";
import AppFormField from "../components/forms/AppFormField";
import SubmitButton from "../components/forms/SubmitButton";
import AppAlertModal from "../components/modals/AppAlertModal";
import { getPx } from "../utils/helpers";
import View from "../components/rnMimic/View";
import { FiLogIn } from "react-icons/fi";
import Screen from "../components/Screen";
import AppFramerHeader from "../components/headers/AppFramerHeader";

export default function EnterMfaScreen({}) {
  const { isLoading, setIsLoading, Parse, refetchUserData, logout, Toast } =
    useYouwho();

  const handleSubmit = async ({ code }) => {
    try {
      const result = await Parse.Cloud.run("setSessionMfaPassed", { code });
      console.log("EnterMfaScreen handleSubmit result: " + result);
      if (result == code) {
        await refetchUserData();
      } else {
        Toast.show({
          type: "appAlert",
          visibilityTime: 10000,
          // position: "top",
          props: {
            title: "Uh oh!",
            subtitle: "Incorrect code. Please try again.",
            variant: "error",
          },
        });
      }
    } catch (error) {
      console.log("EnterMfaScreen handleSubmit error: " + error.message);
    }
  };

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        code: Yup.string()
          .required()
          .min(6)
          .max(6)
          // .matches(
          //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
          //   "Password must have at least 8 characters which contains at least 1 lower case, 1 upper case and 1 digit"
          // )
          .label("MFA Code"),
      }),
    []
  );

  const userLogout = async () => {
    try {
      setIsLoading(true);
      dispatch(setDisplayLanguageRedux("en"));
      // await dispatch(setUserFavoritesRedux([]));
      await logout();
      // await logoutM();
      console.log(
        "######################## LOGGED OUT USER ########################"
      );
    } catch (error) {
      console.log("Error logging out :", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => userLogout(), 120000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <Screen maxW="350px" title={"Enter MFA Code"}>
      <AppFramerHeader goBack={userLogout} />
      <View style={styles.container}>
        <Center sx={{ width: "100%", marginBottom: getPx(padding.xxl) }}>
          <YouwhoLogo width={"60%"} />
        </Center>

        <AppForm
          initialValues={{
            code: "",
          }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          <AppFormField
            autoCapitalize="none"
            autoCorrect={false}
            icon="grid"
            keyboardType="number-pad"
            name="code"
            label={"MFA Code"}
            textContentType="oneTimeCode"
            // contentStyle={{ marginBottom: 0 }}
            // noPaste
            // toastError
            // toastPosition="top"
            noErrorMessage
          />

          <View style={{ height: getPx(padding.ms) }} />

          <SubmitButton
            buttonStyle={{
              width: "100%",
              marginTop: getPx(padding.ms),
            }}
            // textStyle={{ fontFamily: fonts.p500 }}
            thick
            IconRight={FiLogIn}
            fontSize={fonts.size.primary}
            // isLoading={Boolean(isAuthenticating || isLoading)}
            isLoading={isLoading}
            title="Submit"
            variant="underline"
          />
        </AppForm>
      </View>

      {isLoading && <AppLoadingAnimation style={{ zIndex: 201 }} />}
    </Screen>
  );
}

const styles = Object.create({
  container: {
    height: "80vh",
    // alignItems: "center",
    justifyContent: "center",
  },
  //   logo: {
  //     // width: 200,
  //     // height: 200,
  //     // alignSelf: "center",
  //     // marginTop: 30,
  //     marginBottom: getPx(padding.primary),
  //     width: getPx(250),
  //     height: getPx(250),
  //     alignSelf: "center",
  //     // marginTop: 30,
  //     tintColor: colors.gray4,
  //   },
});
