import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
// import ImageBlurLoading from "react-native-image-blur-loading";
import { Image, useDisclosure } from "@chakra-ui/react";
import Lightbox from "yet-another-react-lightbox";
import { Fullscreen, Download, Zoom } from "yet-another-react-lightbox/plugins";

import AppText from "./AppText";
import borders from "../config/borders";
import { fontConfig as fonts } from "../config/fonts";
// import routes from "../navigation/routes";
import colors from "../config/colors";
// import AppImage from "./AppImage";
import { httpToHttps } from "../utils/formatters";
import { getPx, getSx } from "../utils/helpers";
import AppPressable from "./AppPressable";
import View from "./rnMimic/View";

const variants = {
  sm: {
    container: {
      height: getPx(35),
      width: getPx(30),
    },
    font: { fontSize: getPx(fonts.size.lg) },
    img: { height: getPx(35), width: getPx(30) },
  },
  md: {
    container: {
      height: getPx(52.5),
      width: getPx(45),
    },
    font: { fontSize: getPx(fonts.size.h1) },
    img: { height: getPx(52.5), width: getPx(45) },
  },
  lg: {
    container: {
      height: getPx(80.5),
      width: getPx(69),
    },
    font: { fontSize: getPx(fonts.size.xxh1) },
    img: { height: getPx(80.5), width: getPx(69) },
  },
};

export default function AppAvatar({
  fastImage,
  imageBlurLoading = false,
  metadata,
  name,
  url,
  variant = "md",
  style,
  enlarge,
  resizeMode = "cover",
  withIndicator = true,
}) {
  // const navigation = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const fullscreenRef = useRef(null);
  const zoomRef = useRef(null);

  return (
    <>
      {url ? (
        <>
          {enlarge ? (
            <>
              <AppPressable
                onPress={onOpen}
                pressableStyle={{
                  ...styles.container,
                  ...variants[variant].container,
                  ...style,
                }}
              >
                {/* <ImageBlurLoading
                fastImage
                source={{ uri: url }}
                style={[variants[variant].img]}
                resizeMode={resizeMode}
                withIndicator={false}
              /> */}
                {/* <AppImage
                imageBlurLoading={imageBlurLoading}
                source={url}
                style={[variants[variant].img]}
                resizeMode={resizeMode}
                withIndicator={withIndicator}
                fastImage={fastImage}
              /> */}
                <Image
                  src={httpToHttps(url)}
                  {...variants[variant].img}
                  sx={variants[variant].img}
                  fit="cover"
                />
              </AppPressable>
              <Lightbox
                plugins={[Download, Fullscreen, Zoom]}
                fullscreen={{ ref: fullscreenRef }}
                zoom={{ ref: zoomRef }}
                on={{
                  click: () => fullscreenRef.current?.enter(),
                }}
                open={isOpen}
                close={() => onClose()}
                controller={{ closeOnBackdropClick: true }}
                slides={[
                  {
                    src: httpToHttps(url),
                    downloadUrl: httpToHttps(url),
                    downloadFilename: name,
                  },
                ]}
              />
            </>
          ) : (
            <View
              sx={getSx([styles.container, variants[variant].container, style])}
            >
              {/* <ImageBlurLoading
                fastImage
                source={{ uri: url }}
                style={[variants[variant].img]}
                resizeMode={resizeMode}
                withIndicator={false}
              /> */}
              {/* <AppImage
                imageBlurLoading={imageBlurLoading}
                source={url}
                style={[variants[variant].img]}
                resizeMode={resizeMode}
                withIndicator={withIndicator}
                fastImage={fastImage}
              /> */}
              <Image
                src={httpToHttps(url)}
                {...variants[variant].img}
                sx={variants[variant].img}
                fit="cover"
              />
            </View>
          )}
        </>
      ) : (
        <View
          sx={getSx([
            styles.container,
            {
              borderWidth: variant === "lg" ? getPx(3) : getPx(2),
              borderColor: colors.black,
            },
            variants[variant].container,
            style,
          ])}
        >
          <AppText bold uppercase variant="h1" style={variants[variant].font}>
            {name?.charAt(0) ?? "-"}
          </AppText>
        </View>
      )}
    </>
  );
}

const styles = Object.create({
  container: {
    borderRadius: getPx(borders.borderRadiusPill),
    backgroundColor: colors.white,
    overflow: "hidden",
    justifyContent: "center",
    alignItems: "center",
  },
});
