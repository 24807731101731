import React, { useCallback, useEffect } from "react";
import { Flex, Image } from "@chakra-ui/react";
import qs from "qs";
import padding from "../config/padding";
import AppPressable from "../components/AppPressable";
import colors from "../config/colors";
import borders from "../config/borders";
import { useYouwho } from "../package/react-youwho";
import { getPx, getSx, randomString } from "../utils/helpers";
import configLocal from "../config/configLocal";
import {
  setOauthRequestTokenRedux,
  setOauthRequestTokenSecretRedux,
} from "../store/slices/app";
import { httpToHttps } from "../utils/formatters";

const authButtons = [
  {
    key: "apple",
    logo: require("../assets/icons/social-login/logo_apple_icon-128.png"),
    height: padding.xxxl,
    isDisabled: false,
  },
  {
    key: "facebook",
    logo: require("../assets/icons/social-login/logo_facebook_f_icon-128.png"),
    height: padding.lg,
    isDisabled: false,
  },
  {
    key: "google",
    logo: require("../assets/icons/social-login/logo_google_g_icon-128.png"),
    height: padding.xxxl,
    isDisabled: false,
  },
  {
    key: "twitter",
    logo: require("../assets/icons/social-login/logo_twitter_t_icon-128.png"),
    height: padding.lg,
    isDisabled: false,
  },
];

export const useAuth = () => {
  const {
    appStore,
    Parse,
    setUserYouwho,
    isLoading,
    setIsLoading,
    dispatch,
    refetchUserData,
    Toast,
  } = useYouwho();

  // const { oauthRequestToken, oauthRequestTokenSecret } = appStore;

  let childWindow;
  const openWindow = (url) => {
    childWindow = window.open(url, "childWindow", "height=500,width=350");
  };

  // const handleSharedListing = async (url, noUrl) => {
  //   console.log("handle shared listing");
  //   try {
  //     let listingId;
  //     if (noUrl) listingId = url;
  //     else {
  //       if (url.indexOf("youwhoapp://listing/") >= 0)
  //         listingId = url.replace("youwhoapp://listing/", "");
  //       else
  //         listingId = url.replace("https://youwhoapp.youwho.io/listing/", "");
  //     }
  //     const query = new Parse.Query("Listings");
  //     query.equalTo("objectId", listingId);
  //     // query.notEqualTo("hidden", true);
  //     // query.notEqualTo("disabled", true);
  //     query.containedIn("toReview", [false, undefined]);
  //     query.containedIn("hidden", [false, undefined]);
  //     query.containedIn("disabled", [false, undefined]);
  //     const listings = await query.find();

  //     if (Array.isArray(listings) && listings.length > 0) {
  //       const newLists = formatListings(listings);
  //       if (user) {
  //         console.log("user");
  //         navigation.navigate(routes.MAIN_LISTINGS_NAV.n, {
  //           screen: routes.LISTING_DETAILS_SCREEN.n,
  //           params: { item: newLists[0] },
  //         });
  //       } else {
  //         console.log("NO user");
  //         navigation.navigate(routes.GUEST_LISTINGS_NAV.n, {
  //           screen: routes.LISTING_DETAILS_SCREEN.n,
  //           params: { item: newLists[0] },
  //         });
  //       }
  //     }

  //     console.log("Handle shared listing success");
  //     setTimeout(() => setIsLoading(false), 2000);
  //   } catch (error) {
  //     console.log("Handle shared listing error: " + error.message);
  //   } finally {
  //     // setTimeout(async () => await refetchUserData(), 1000)
  //   }
  // };

  const authenticateWithApple = async (url) => {
    console.log("Apple auth started");
    try {
      let urlParams;
      if (url.indexOf("youwhoapp://appleAuthCallback/") >= 0)
        urlParams = url.replace("youwhoapp://appleAuthCallback/", "");
      else
        urlParams = url.replace(
          "https://youwhoapp.youwho.io/appleAuthCallback/",
          ""
        );
      const appleAuthData = qs.parse(urlParams);

      console.log("appleAuthData: " + JSON.stringify(appleAuthData));

      const newUser = new Parse.User();
      // newUser.set("username", appleAuthData.email + appleAuthData.iat);
      newUser.set("username", appleAuthData.email + "-a" + randomString());
      newUser.set("emailAuth", appleAuthData.email);
      newUser.set("notificationSettings", configLocal.notificationSettings);

      const authData = {
        id: appleAuthData.id,
        token: appleAuthData.id_token,
        access_token: appleAuthData.access_token,
        refresh_token: appleAuthData.refresh_token,
      };

      // console.log("authData: " + JSON.stringify(authData));

      await newUser.linkWith("apple", {
        authData,
      });

      await newUser.save();
      await newUser.fetch();

      setUserYouwho(newUser);

      console.log("Apple auth success");
      setTimeout(() => setIsLoading(false), 2000);
    } catch (error) {
      console.log("Apple auth error: " + error.message);
      if (!error.message.toLowerCase().includes("invalid session token"))
        handleAuthError("Apple", error.message);
    } finally {
      // setTimeout(() => refetchUserData(), 100)
      window.opener.location.reload();
      // setTimeout(() => window.close(), 2500);
    }
  };

  const authenticateWithFacebook = async (url) => {
    console.log("Facebook auth started");
    // console.log("url", JSON.stringify(url));
    try {
      const urlParams = url.replace("youwhoapp://facebookAuthCallback/", "");
      const facebookAuthData = qs.parse(urlParams);

      // console.log("facebookAuthData: " + JSON.stringify(facebookAuthData));

      const newUser = new Parse.User();
      // newUser.set(
      //   "username",
      //   facebookAuthData.name.replace(" ", "") + facebookAuthData.id
      // );
      newUser.set("username", facebookAuthData.email + "-f" + randomString());
      newUser.set("emailAuth", facebookAuthData.email);
      newUser.set("firstName", facebookAuthData.first_name);
      newUser.set("lastName", facebookAuthData.last_name);
      newUser.set("name", facebookAuthData.name);
      if (facebookAuthData.picture) {
        newUser.set("avatarUrl", facebookAuthData.picture.data.url);
      }
      newUser.set("notificationSettings", configLocal.notificationSettings);

      const expires_in = facebookAuthData.expires_in.replace(/\D/g, "");

      const expiration_date = new Date(
        (Math.floor(Date.now() / 1000) + Number(expires_in)) * 1000
      ).toISOString();

      const authData = {
        id: facebookAuthData.id,
        access_token: facebookAuthData.access_token,
        expiration_date,
      };

      // console.log("authData: " + JSON.stringify(authData));

      await newUser.linkWith("facebook", {
        authData,
      });

      await newUser.save();
      await newUser.fetch();

      setUserYouwho(newUser);

      console.log("Facebook auth success");
      setTimeout(() => setIsLoading(false), 1500);
    } catch (error) {
      console.log("Facebook auth error: " + error.message);
      if (!error.message.toLowerCase().includes("invalid session token"))
        handleAuthError("Facebook", error.message);
    } finally {
      // setTimeout(() => refetchUserData(), 100);
      window.opener.location.reload();
      // setTimeout(() => window.close(), 2500);
    }
  };

  const authenticateWithGoogle = async (url) => {
    console.log("Google auth started");
    try {
      const code = url.replace("youwhoapp://googleAuthCallback/", "");
      const googleAuthData = await Parse.Cloud.run("GoogleAuthData", {
        code,
      });

      console.log("googleAuthData: " + JSON.stringify(googleAuthData));
      const newUser = new Parse.User();
      // newUser.set(
      //   "username",
      //   googleAuthData.name.replace(" ", "") + googleAuthData.id
      // );
      newUser.set("username", googleAuthData.email + "-g" + randomString());
      newUser.set("emailAuth", googleAuthData.email);
      if (googleAuthData.name) newUser.set("name", googleAuthData.name);
      newUser.set("notificationSettings", configLocal.notificationSettings);

      fetch(
        `https://www.googleapis.com/userinfo/v2/me?access_token=${googleAuthData.access_token}`
      )
        .then((data) => {
          return data.json();
        })
        .then((userInfo) => {
          //   console.log('userInfo: ' + JSON.stringify(userInfo));
          if (userInfo.family_name)
            newUser.set("lastName", userInfo.family_name);
          if (userInfo.given_name)
            newUser.set("firstName", userInfo.given_name);
          if (userInfo.picture) newUser.set("avatarUrl", userInfo.picture);
          if (userInfo.name) newUser.set("name", userInfo.name);
          if (userInfo.locale)
            newUser.set("displayLanguage", userInfo.locale.slice(0, 2));
          // if (userInfo.verified_email) newUser.set('emailVerified', true);
        });

      await newUser.linkWith("google", {
        authData: { id: googleAuthData.id, id_token: googleAuthData.id_token },
      });
      await newUser.save();
      await newUser.fetch();

      setUserYouwho(newUser);

      console.log("Google auth success");
      setTimeout(() => setIsLoading(false), 1500);
    } catch (error) {
      console.log("Google auth error: " + error.message);
      if (!error.message.toLowerCase().includes("invalid session token"))
        handleAuthError("Google", error.message);
    } finally {
      window.opener.location.reload();
      // setTimeout(() => window.close(), 2500);
    }
  };

  const authenticateWithTwitter = async (url) => {
    console.log("Twitter auth started");

    try {
      const oauth_verifier = url.replace(
        "youwhoapp://twitterAuthCallback/",
        ""
      );

      const oauthRequestToken = localStorage.getItem("oauthRequestToken");
      const oauthRequestTokenSecret = localStorage.getItem(
        "oauthRequestTokenSecret"
      );

      if (
        oauthRequestToken === undefined ||
        oauthRequestTokenSecret === undefined
      )
        throw new Error("Could not obtain Twitter login details.");

      // const oauthRequestToken = await AsyncStorage.getItem("oauthRequestToken");
      // const oauthRequestTokenSecret = await AsyncStorage.getItem(
      //   "oauthRequestTokenSecret"
      // );

      // console.log(oauthRequestToken, oauthRequestTokenSecret, oauth_verifier);

      const twitterAuthData = await Parse.Cloud.run("TwitterAuthData", {
        oauthRequestToken,
        oauthRequestTokenSecret,
        oauth_verifier,
      });

      // console.log("twitterAuthData: " + JSON.stringify(twitterAuthData));

      const newUser = new Parse.User();
      // newUser.set("username", twitterAuthData.username);
      newUser.set("username", twitterAuthData.username + "-t" + randomString());
      newUser.set("twitterId", twitterAuthData.id);
      newUser.set("notificationSettings", configLocal.notificationSettings);

      await newUser.linkWith("twitter", {
        authData: twitterAuthData,
      });

      // await AsyncStorage.removeItem("oauthRequestToken");
      // await AsyncStorage.removeItem("oauthRequestTokenSecret");

      dispatch(setOauthRequestTokenRedux(undefined));
      localStorage.removeItem("oauthRequestToken");
      dispatch(setOauthRequestTokenSecretRedux(undefined));
      localStorage.removeItem("oauthRequestTokenSecret");

      await newUser.save();
      await newUser.fetch();

      setUserYouwho(newUser);

      console.log("Twitter auth success");
      setTimeout(() => setIsLoading(false), 1500);
    } catch (error) {
      console.log("Twitter auth error: " + error.message);
      if (!error.message.toLowerCase().includes("invalid session token"))
        handleAuthError("Twitter", error.message);
    } finally {
      // setTimeout(() => refetchUserData(), 100);
      window.opener.location.reload();
      // setTimeout(() => window.close(), 2500);
    }
  };

  const handleAuthError = (provider, errorMessage) => {
    if (errorMessage)
      Toast.show({
        type: "appAlert",
        // onHide: () => setIsLoading(false),
        visibilityTime: 4000,
        props: {
          title: provider + " Login Error",
          subtitle: errorMessage,
          variant: "error",
          onClose: () => setIsLoading(false),
        },
      });
  };

  const handlePressAuth = async (provider) => {
    switch (provider) {
      case "apple":
        console.log("Signing in with Apple");
        setIsLoading(true);
        try {
          const res = await Parse.Cloud.run("AppleSignIn");
          console.log("AppleSignIn res", res);
          if (res) openWindow(res);
          // if (res) Linking.openURL(res);
          // if (res) await authenticateWithApple();
        } catch (error) {
          console.log(error);
          setIsLoading(false);
        }
        break;
      case "facebook":
        console.log("Signing in with Facebook");
        setIsLoading(true);
        try {
          const res = await Parse.Cloud.run("FacebookSignIn");
          // console.log("FacebookSignIn res", res);
          if (res) openWindow(res);
          // if (res) Linking.openURL(res);
        } catch (error) {
          console.log(error);
          setIsLoading(false);
        }
        break;
      case "google":
        console.log("Signing in with Google");
        setIsLoading(true);
        try {
          const res = await Parse.Cloud.run("GoogleSignIn");
          // console.log("GoogleSignIn res", res);
          if (res) openWindow(res);
          // if (res) Linking.openURL(res);
        } catch (error) {
          console.log(error);
          setIsLoading(false);
        }
        break;
      case "twitter":
        console.log("Signing in with Twitter");
        setIsLoading(true);
        try {
          const res = await Parse.Cloud.run("TwitterSignIn");
          // console.log("TwitterSignIn res", res);
          if (res && res.oauthCallbackConfirmed) {
            // await AsyncStorage.setItem(
            //   "oauthRequestToken",
            //   res.oauthRequestToken
            // );
            // dispatch(setOauthRequestTokenRedux(res.oauthRequestToken));
            localStorage.setItem("oauthRequestToken", res.oauthRequestToken);
            // await AsyncStorage.setItem(
            //   "oauthRequestTokenSecret",
            //   res.oauthRequestTokenSecret
            // );
            // dispatch(
            //   setOauthRequestTokenSecretRedux(res.oauthRequestTokenSecret)
            // );
            localStorage.setItem(
              "oauthRequestTokenSecret",
              res.oauthRequestTokenSecret
            );
            openWindow(res.loginLink);
            // Linking.openURL(res.loginLink);
          }
        } catch (error) {
          console.log(error);
          setIsLoading(false);
        }
        break;
    }
  };

  const AuthButtonsComponent = useCallback(() => {
    return (
      <Flex style={{ flexDirection: "row", justifyContent: "space-around" }}>
        {authButtons.map((x, i) => (
          <React.Fragment key={x.key}>
            {i !== 0 && <Flex style={{ width: getPx(padding.md) }}></Flex>}
            <AppPressable
              scale={0.97}
              // isLoading={isLoading}
              isDisabled={x.isDisabled}
              onPress={() => handlePressAuth(x.key)}
              style={getSx([
                styles.socialContainer,
                {
                  backgroundColor: x.isDisabled ? colors.grayD : colors.white,
                },
              ])}
            >
              <Image sx={{ width: getPx(x.height) }} src={x.logo} />
            </AppPressable>
          </React.Fragment>
        ))}
      </Flex>
    );
  }, []);

  return {
    AuthButtonsComponent,
    authenticateWithApple,
    authenticateWithFacebook,
    authenticateWithGoogle,
    authenticateWithTwitter,
    handlePressAuth,
    // handleSharedListing,
    isLoading,
    setIsLoading,
  };
};

const styles = Object.create({
  socialContainer: {
    flex: 1,
    borderWidth: getPx(1),
    borderRadius: getPx(borders.borderRadius12),
    borderColor: colors.grayD,
    paddingY: getPx(padding.md),
    paddingX: getPx(padding.md),
    justifyContent: "center",
    alignItems: "center",
    minHeight: getPx(padding.xxxxl),
  },
});
