import React from "react";
import { Container } from "@chakra-ui/react";
import { getPx, getSx } from "../utils/helpers";
import padding from "../config/padding";
import SEO from "./SEO";

const Screen = ({
  children,
  containerRef,
  sx,
  style,
  maxW = "container.md",
  ...otherProps
}) => {
  return (
    <>
      <SEO homepage="https://app.youwho.io" {...otherProps} />
      <Container
        //   onLoad={() => setContainerReady(true)}
        ref={containerRef}
        maxW={maxW}
        //   minH="100vh"
        sx={getSx([
          { padding: 0, paddingBottom: getPx(padding.bottomBar90) },
          sx,
          style,
        ])}
      >
        {children}
      </Container>
    </>
  );
};

export default Screen;
