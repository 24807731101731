import React from "react";
import { Outlet } from "react-router-dom";
import { Box } from "@chakra-ui/react";

import SEO from "../components/SEO";
import { getPx } from "../utils/helpers";
import padding from "../config/padding";
import BottomTabs from "../components/layouts/BottomTabs";
import TopBar from "../components/layouts/TopBar";

const BottomTabLayout = () => {
  return (
    <>
      <SEO homepage="https://app.youwho.io" />
      <Box
        sx={{
          // position: "relative",
          height: "100vh",
          paddingTop: [getPx(padding.topBar60), getPx(padding.topBar50)],
          paddingBottom: [
            getPx(padding.bottomBar60),
            getPx(padding.bottomBar50),
          ],
        }}
      >
        <Outlet />
      </Box>
      <BottomTabs />
    </>
  );
};

export default BottomTabLayout;
