import React from "react";
import { Box, Container } from "@chakra-ui/react";
import AppLoadingAnimation from "../components/AppLoadingAnimation";

const SplashScreen = () => {
  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
        backgroundColor: "white",
        zIndex: 2,
      }}
    >
      <Container maxW={500} sx={{ h: "70vh" }}>
        <AppLoadingAnimation
          style={{ h: "100%" }}
          withLogo
          width={150}
          height={150}
        />
      </Container>
    </Box>
  );
};

export default SplashScreen;
