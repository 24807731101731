import React, { useEffect } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import BottomTabLayout from "../screens/BottomTabLayout";
import ListingsSearchResultsScreenBS from "../screens/tabScreens/ListingsSearchResultsScreenBS";
import GuestScreen from "../screens/tabScreens/guestTabScreens/GuestScreen";
import GuestAccountScreen from "../screens/tabScreens/guestTabScreens/GuestAccountScreen";
import FullScreenLayout from "../screens/FullScreenLayout";
import ReportListingScreen from "../screens/ReportListingScreen";
import ListingDetailsScreen from "../screens/ListingDetailsScreen";
import { useYouwho } from "../package/react-youwho";
import { useQueryBroadcasts } from "../hooks/useQuery";
import AccountScreen from "../screens/tabScreens/userTabScreens/AccountScreen";
import PublicProfileScreen from "../screens/PublicProfileScreen";

const AppNavigator = () => {
  const {
    setAppIsReady,
    navigation,
    dispatch,
    user,
    refetchUserData,
    appStore,
    userStore,
    logout,
  } = useYouwho();
  const { queryBroadcasts } = useQueryBroadcasts();

  useEffect(() => {
    if (window?.opener) {
      window.close();
    }
  }, [window?.opener]);

  return (
    <>
      <Routes>
        <Route element={<MainLayout />}>
          <Route element={<BottomTabLayout />}>
            <Route index element={<Navigate to="/services" />} />
            {/* <Route
            exact
            element={<Navigate to="/services" replace />}
            path="/services/"
          /> */}
            <Route
              // exact
              element={<ListingsSearchResultsScreenBS />}
              path="/services/:listingId?"
            />
            {/* <Route
              element={
                <GuestScreen
                  heading={"Messages"}
                  subtitle={
                    "The web app currently has limited functionality. For full functionality please install the iOS or Android app."
                  }
                  title={"Get the mobile app now"}
                />
              }
              path="/chats"
            /> */}
            <Route
              element={
                <GuestScreen
                  heading={"Messages"}
                  subtitle={
                    "Once you login, you'll find messages from independent service providers here."
                  }
                  title={"Login to read messages"}
                />
              }
              path="/chats"
            />
            {/* <Route element={<GuestChatsScreen />} path="chats" /> */}
            <Route
              element={
                <GuestScreen
                  heading={"Bookings"}
                  subtitle={
                    "View all of your booked services here after you've logged in."
                  }
                  title={"No Bookings yet"}
                />
              }
              path="/bookings"
            />
            <Route
              element={
                <GuestScreen
                  heading={"Wallet"}
                  subtitle={
                    "You can view and manage your Wallet once you've logged in."
                  }
                  title={"Login to view your Wallet"}
                />
              }
              path="/wallet"
            />
            <Route
              element={<AccountScreen subHeading={"guestAccountSubHeading"} />}
              path="/account"
            />
          </Route>
          <Route element={<FullScreenLayout />}>
            <Route
              element={<ReportListingScreen heading={"Listing report"} />}
              path="/listing/:listingId/report"
            />
            <Route
              element={<GuestAccountScreen heading={"Listing reviews"} />}
              path="/listing/:listingId/reviews"
            />
            <Route
              element={<ListingDetailsScreen />}
              path="/listing/:listingId"
            />
            <Route
              element={<PublicProfileScreen />}
              path="/profile/:userPublicId/listing/:listingId"
            />
            <Route
              element={<PublicProfileScreen />}
              path="/profile/:userPublicId"
            />
          </Route>
          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      </Routes>
    </>
  );
};

const MainLayout = () => (
  <>
    <Outlet />
  </>
);

export default AppNavigator;
