import {
  extendTheme,
  defineStyle,
  defineStyleConfig,
  StyleFunctionProps,
} from "@chakra-ui/react";
import colors from "./colors";
import borders from "./borders";
import { getPx } from "../utils/helpers";

// const activeLabelStyles = {
//   transform: "scale(0.85) translateY(-28px)",
// };

// const buttonOutline = defineStyle({
//   borderColor: colors.gray4,
//   borderWidth: getPx(1),
//   color: colors.black,
// });

// const buttonSolid = defineStyle((props) => ({
//   // color: props.colorMode === "dark" ? "red.300" : "red.500",
//   color: colors.black,
// }));

const Heading = defineStyleConfig({
  baseStyle: { fontWeight: 600 },
  //   defaultProps: {
  //     fontWeight: 300,
  //     variant: brandHeading,
  //   },
  //   variants: { brandHeading },
});

const Container = defineStyleConfig({
  defaultProps: {
    maxW: "container.md",
  },
});

const Button = defineStyleConfig({
  baseStyle: {
    borderRadius: getPx(borders.borderRadius8),
  },
  defaultProps: {
    // colorScheme: "grayBrand",
  },
  variants: {
    // outline: buttonOutline,
    // solid: buttonSolid,
  },
});

const Form = defineStyleConfig({
  // variants: {
  //   floating: {
  //     container: {
  //       _focusWithin: {
  //         label: {
  //           ...activeLabelStyles,
  //         },
  //       },
  //       "input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label":
  //         {
  //           ...activeLabelStyles,
  //         },
  //       label: {
  //         top: 0,
  //         left: 0,
  //         zIndex: 2,
  //         position: "absolute",
  //         backgroundColor: "white",
  //         pointerEvents: "none",
  //         mx: 3,
  //         px: 1,
  //         my: 3,
  //         transformOrigin: "left top",
  //         color: "black50",
  //       },
  //     },
  //   },
  // },
});

const AlertDialogCloseButton = defineStyleConfig({
  baseStyle: { borderRadius: getPx(borders.borderRadius12) },
  // defaultProps: {
  //   borderRadius: getPx(borders.borderRadius12),
  //     fontWeight: 300,
  //     variant: brandHeading,
  // },
  //   variants: { brandHeading },
});

const AlertDialogContent = defineStyleConfig({
  baseStyle: { borderRadius: getPx(borders.borderRadius20) },
  // defaultProps: {
  //   borderRadius: getPx(borders.borderRadius20),
  //     fontWeight: 300,
  //     variant: brandHeading,
  // },
  //   variants: { brandHeading },
});
const ModalCloseButton = defineStyleConfig({
  baseStyle: { borderRadius: getPx(borders.borderRadius12) },
  // defaultProps: {
  //   borderRadius: getPx(borders.borderRadius12),
  //     fontWeight: 300,
  //     variant: brandHeading,
  // },
  //   variants: { brandHeading },
});

const ModalContent = defineStyleConfig({
  baseStyle: { borderRadius: getPx(borders.borderRadius20) },
  // defaultProps: {
  // borderRadius: getPx(borders.borderRadius20),
  //     fontWeight: 300,
  //     variant: brandHeading,
  // },
  //   variants: { brandHeading },
});

const Tab = defineStyleConfig({
  baseStyle: {
    borderTopLeftRadius: getPx(borders.borderRadius20),
    borderTopRightRadius: getPx(borders.borderRadius20),
  },
  // defaultProps: {
  // borderTopLeftRadius: getPx(borders.borderRadius20),
  // borderTopRightRadius: getPx(borders.borderRadius20),
  //     fontWeight: 300,
  //     variant: brandHeading,
  // },
  //   variants: { brandHeading },
});

// const Text = defineStyleConfig({
//   baseStyle: { fontWeight: 400 },
//   defaultProps: {
//     fontWeight: 300,
//     variant: brandHeading,
//   },
//   variants: { brandHeading },
// });

export const breakpoints = {
  base: "0em",
  sm: "30em",
  md: "48em",
  lg: "62em",
  xl: "80em",
  "2xl": "96em",
};

export const theme = extendTheme({
  breakpoints,
  colors,
  components: {
    AlertDialogCloseButton,
    AlertDialogContent,
    ModalCloseButton,
    ModalContent,
    Button,
    Container,
    Form,
    Heading,
    Tab,
  },
  fonts: {
    heading: `'Quicksand', sans-serif`,
    body: `'Quicksand', sans-serif`,
  },
});
