import React from "react";
import { Outlet } from "react-router-dom";

import SEO from "../components/SEO";

const FullScreenLayout = ({ ...otherProps }) => {
  return (
    <>
      <Outlet />
    </>
  );
};

export default FullScreenLayout;
