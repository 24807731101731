import React, { useState } from "react";
import { ReactComponent as Logo } from "../assets/svg/youwho-logos_logo-tab-icon.svg";
import colors from "../config/colors";
import { httpToHttps } from "../utils/formatters";
import AppLoadingAnimation from "./AppLoadingAnimation";
import { Center, Image } from "@chakra-ui/react";
import { getPx, getSx } from "../utils/helpers";

const AppImage = ({
  bgColor,
  // fastImage, // from RN
  iconColor = colors.gray9,
  iconSize = 80,
  // iconName, // from RN
  fit = "cover",
  loaderHeight,
  loaderWidth,
  // imageBlurLoading, // = true from RN
  // noUri, // from RN
  source,
  style,
  sx,
  // withIndicator, // from RN
  // progressiveRenderin gEnabled = true, // from RN
  ...otherProps
}) => {
  // console.log("srcc: " + httpToHttps(source));
  const [noError, setNoError] = useState(true);
  // return (
  //   <ImageBlurLoading.PreviewGroup>
  //     <ImageBlurLoading src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png" />
  //     <ImageBlurLoading src="https://gw.alipayobjects.com/mdn/rms_08e378/afts/img/A*P0S-QIRUbsUAAAAAAAAAAABkARQnAQ" />
  //   </ImageBlurLoading.PreviewGroup>
  // );

  // return (
  //   <Center direction="center" sx={getSx([style, sx])} {...otherProps}>
  //     <AppLoadingAnimation withLogo />
  //   </Center>
  // );

  return (
    <>
      {!source || !noError ? (
        <Center
          sx={getSx([
            {
              bg: colors.grayF4,
            },
            style,
            sx,
          ])}
          {...otherProps}
        >
          {/* <AppLoadingAnimation /> */}
          {/* <HiMiniFingerPrint color={iconColor} size={getPx(iconSize)} /> */}
          <Logo
            style={{
              height: getPx(iconSize),
              width: getPx(iconSize),
              color: iconColor,
            }}
          />
          {/* <ReactSVG
            // wrapper="svg"
            className="youwho-logo"
            style={{
              height: getPx(iconSize),
              width: getPx(iconSize),
              color: iconColor,
            }}
            src="../assets/svg/youwho-logos_logo-tab-icon.svg"
          /> */}
        </Center>
      ) : (
        <Image
          // loading="eager"
          onError={() => setNoError(false)}
          // fallback={<HiMiniFingerPrint color={iconColor} size={iconSize} />}
          fallback={
            <Center sx={getSx([style, sx])} {...otherProps}>
              <AppLoadingAnimation height={loaderHeight} width={loaderWidth} />
            </Center>
          }
          // src={noUri ? source : { uri: httpToHttps(source) }}
          src={httpToHttps(source)}
          sx={getSx([style, sx])}
          objectFit={fit}
          {...otherProps}
        />
      )}
    </>
  );
};

export default AppImage;
