import { useState } from "react";
import { useClipboard } from "@chakra-ui/react";
import { useToast } from "./useToast";

export const useCopy = () => {
  const [copiedText, setCopiedText] = useState("");
  const { onCopy, value, setValue, hasCopied } = useClipboard("");
  const Toast = useToast();

  const copyToClipboard = (
    str,
    noTranslateSubtitle = false,
    noTranslateTitle = false
  ) => {
    setValue(str);
    onCopy();
    if (hasCopied)
      Toast.show({
        type: "appAlert",
        // autoHide: false,
        // And I can pass any custom props I want
        props: {
          title: "copied to clipboard",
          noTranslateSubtitle,
          noTranslateTitle,
          subtitle: value,
          variant: "info",
        },
      });
  };

  const pasteFromClipboard = () => {
    // setCopiedText(""); // to force useEffect to run
    setCopiedText(value);
  };

  return { copyToClipboard, pasteFromClipboard, copiedText };
};
