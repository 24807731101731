export const TRANSLATE_PATH = "https://translate.google.";

export const DEFAULT_OPTIONS = {
  from: "auto",
  to: "en",
  autoCorrect: false,
  tld: "com",
  requestFunction(url, fetchinit) {
    return fetch(url, fetchinit);
  },
  requestOptions: {
    credentials: "omit",
    headers: {
      //   "Access-Control-Allow-Origin": "*",
    },
    mode: "no-cors",
  },
  fallbackBatch: true,
  forceBatch: true,
  forceFrom: false,
  forceTo: false,
  rejectOnPartialFail: true,
};
