import axios from "axios";
const { langCodeExists } = require("./languages");

const API_KEY = process.env.REACT_APP_GOOGLE_TRANSLATE_API_KEY;
const API_URL = "https://translation.googleapis.com/language/translate/v2";

export const gTranslate = async (text, options) => {
  if (!langCodeExists(options.to)) throw new Error("Incorrect language code");

  const response = await axios.post(`${API_URL}?key=${API_KEY}`, {
    q: text,
    target: options.to,
    source: options.from
      ? options.from === "auto"
        ? undefined
        : options.from
      : undefined,
    format: options.format ?? "html",
  });

  return response.data.data.translations[0].translatedText;
};

export const gTranslateSync = (text, options) => {
  if (!langCodeExists(options.to)) throw new Error("Incorrect language code");

  axios
    .post(`${API_URL}?key=${API_KEY}`, {
      q: text,
      target: options.to,
      source: options.from
        ? options.from === "auto"
          ? undefined
          : options.from
        : undefined,
      format: options.format ?? "html",
    })
    .then((response) => {
      return response.data.data.translations[0].translatedText;
    })
    .catch((err) => {
      console.log("gTranslateSync error: ", err);
    });
};
