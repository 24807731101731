import React from "react";
import AppPressable from "./AppPressable";
import { FiDownload } from "react-icons/fi";
import { getPx } from "../utils/helpers";
import padding from "../config/padding";
import colors from "../config/colors";

export default function AppSaveImage({
  imageUrl,
  imageName,
  imageType = "image/jpg",
  iconColor = colors.white,
  iconSize,
}) {
  const download = () => {
    const element = document.createElement("a");
    const file = new Blob([imageUrl], { type: imageType });
    element.href = URL.createObjectURL(file);
    element.download = imageName;
    element.click();
  };
  return (
    <AppPressable onClick={download}>
      <FiDownload color={iconColor} size={iconSize} />
    </AppPressable>
  );
}
