import { useEffect, useCallback, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import singleTranslate from "../language/translate/singleTranslate";
import lang from "../language";
import syncTranslate from "../language/translate/syncTranslate";
import { gTranslate, gTranslateSync } from "../language/translate/gTranslate";
import { yTranslate, yTranslateSync } from "../language/translate/yTranslate";

async function translateText(text, options) {
  // const res = await yTranslate(text, options);
  // const res = await gTranslate(text, options);
  const res = await singleTranslate(text, options);
  return res;
}

function translateTextSync(text, options) {
  // return yTranslateSync(text, options);
  // return gTranslateSync(text, options);
  return syncTranslate(text, options);
}

// export function useTranslate(toTranslateObject, toLanguage) {
//   const appStore = useSelector((state) => state.app);
//   const { displayLanguage } = appStore;
//   const [translated, setTranslated] = useState();

//   const handleTranslation = useCallback(async (inputObject) => {
//     const res = await translate(inputObject, {
//       to: toLanguage ?? displayLanguage,
//       autoCorrect: true,
//       client: "gtx",
//     });
//     let translatedObject = res;
//     Object.keys(translatedObject).forEach(function (key, index) {
//       translatedObject[key] = {
//         from: translatedObject[key].from.language.iso,
//         to: toLanguage ?? displayLanguage,
//         textFrom: inputObject[key],
//         textTo: translatedObject[key].text,
//       };
//     });
//     setTranslated(translatedObject);
//   }, []);

//   useEffect(() => {
//     if (toTranslateObject) handleTranslation(toTranslateObject);
//   }, []);

//   return translated;
// }

export function useTranslateText({
  toTranslate,
  toLanguage,
  noAuto,
  fromLanguage,
  clg,
  langKey,
  format,
  noTranslate,
}) {
  const { displayLanguage, autoTranslate: autoTranslateGuest } = useSelector(
    (state) => state.app
  );

  // if (clg) {
  //   console.log("useTranslateText displayLanguage: ", displayLanguage);
  //   console.log("useTranslateText toTranslate: ", toTranslate);
  //   console.log("useTranslateText toLanguage: ", toLanguage);
  //   console.log("useTranslateText noAuto: ", noAuto);
  //   console.log("useTranslateText fromLanguage: ", fromLanguage);
  // }

  const { user } = useSelector((state) => state.user);
  const [translated, setTranslated] = useState(toTranslate);

  const autoTranslate = useMemo(
    () =>
      noAuto
        ? user
          ? user.attributes?.autoTranslate
          : autoTranslateGuest
        : true,
    [noAuto, user, autoTranslateGuest]
  );

  // const languageList = Object.entries(languages)
  //   .map((entry) => ({
  //     languageNameEnglish: entry[1],
  //     languageCode: entry[0],
  //   }))
  //   .filter(
  //     (entry) =>
  //       typeof entry.languageNameEnglish === "string" &&
  //       entry.languageCode !== "auto"
  //   );

  // const findNative = (arr) => {
  //   const arrs = [];

  //   arr.forEach((element) => {
  //     const found = configLocal.languageList.find(
  //       (x) =>
  //         x.languageNameEnglish.toLocaleLowerCase() ===
  //         element.languageNameEnglish.toLowerCase()
  //     );

  //     const obj = {
  //       ...element,
  //       languageNameNative: found?.languageNameNative ?? "MISSING",
  //     };
  //     arrs.push(obj);
  //   });
  //   return arrs;
  // };

  // const extraSupportedLanguages = findNative(languageList);

  // console.log(
  //   "extraSupportedLanguages: " + JSON.stringify(extraSupportedLanguages)
  // );

  const handleTranslation = useCallback(async () => {
    // const res = await translate("Ik spreek Engels", { to: "en" });
    // console.log(res.text); //=> I speak English
    // console.log(res.from.language.iso); //=> nl
    if (
      Boolean(
        autoTranslate &&
          (displayLanguage !== "en" || displayLanguage !== fromLanguage) &&
          !noTranslate
      )
    ) {
      // try {
      //   // setTranslated("...");
      //   if (clg)
      //     console.log("useTranslateText before translateV: ", typeof translate);

      //   const resV = await translate(toTranslate, {
      //     // to: toLanguage ?? displayLanguage ?? "auto",
      //     // from: fromLanguage ?? "auto",
      //     to: "th",
      //     // from: "auto",
      //     // autoCorrect: true,
      //     client: "gtx",
      //     fetchOptions: {},
      //     // requestFunction: "axios",
      //   });
      //   if (clg) console.log("useTranslateText after trans resV: ", resV);

      //   setTranslated(resV.text);
      //   // setTranslated(toTranslate);
      // } catch (error) {
      //   if (clg)
      //     console.log(
      //       "handleTranslation translateV error: " +
      //         error.name +
      //         " " +
      //         error.message +
      //         " | toTranslate: " +
      //         toTranslate +
      //         " | toLanguage: " +
      //         toLanguage +
      //         " | displayLanguage: " +
      //         displayLanguage +
      //         " | fromLanguage: " +
      //         fromLanguage
      //     );
      //   setTranslated(toTranslate);
      // }

      try {
        // setTranslated("...");
        if (clg)
          console.log(
            "useTranslateText before translateText: ",
            typeof translateText
          );

        const res = await translateText(toTranslate, {
          from: fromLanguage ?? "auto",
          to: toLanguage ?? displayLanguage,
          format,
          // to: "th",
          // corsUrl: "http://cors-anywhere.herokuapp.com/",
          // from: "auto",
          // autoCorrect: true,
          // client: "gtx",
          // requestFunction: "axios",
        });
        setTranslated(res);
        // if (clg)
        //   console.log("useTranslateText after translateText res: ", {
        //     ...res,
        //     toTranslate,
        //   });

        // if (res?.status === "success") setTranslated(res.translatedText);
        // else throw new Error(res?.message);
      } catch (error) {
        if (clg)
          console.log(
            "handleTranslation translate error: " +
              error.name +
              " " +
              error.message +
              " | toTranslate: " +
              toTranslate +
              " | fromLanguage: " +
              fromLanguage +
              " | toLanguage: " +
              toLanguage +
              " | displayLanguage: " +
              displayLanguage
          );
        setTranslated(toTranslate);
      }

      // translate(toTranslate, {
      //   to: toLanguage ?? displayLanguage,
      //   autoCorrect: true,
      //   client: "gtx",
      // })
      //   .then((res) => {
      //     setTranslated(res.text);
      //   })
      //   .catch((err) => {
      //     setTranslated(toTranslate);
      //   });
    } else {
      if (clg) console.log("no translation required: ", toTranslate);
      setTranslated(toTranslate);
    }
  }, [toTranslate, toLanguage, autoTranslate, displayLanguage, fromLanguage]);

  useEffect(() => {
    if (toTranslate) handleTranslation();
  }, [toTranslate, toLanguage, autoTranslate, displayLanguage, fromLanguage]);

  return translated;
}

/////////////////////////////////////////////////
//

export function useTranslate() {
  const { displayLanguage, autoTranslate: autoTranslateGuest } = useSelector(
    (state) => state.app
  );

  // if (clg) {
  //   console.log("useTranslateText displayLanguage: ", displayLanguage);
  //   console.log("useTranslateText toTranslate: ", toTranslate);
  //   console.log("useTranslateText toLanguage: ", toLanguage);
  //   console.log("useTranslateText noAuto: ", noAuto);
  //   console.log("useTranslateText fromLanguage: ", fromLanguage);
  // }

  const { user } = useSelector((state) => state.user);

  const autoTranslate = useMemo(() =>
    user
      ? user.attributes?.autoTranslate
      : autoTranslateGuest[(user, autoTranslateGuest)]
  );

  // const languageList = Object.entries(languages)
  //   .map((entry) => ({
  //     languageNameEnglish: entry[1],
  //     languageCode: entry[0],
  //   }))
  //   .filter(
  //     (entry) =>
  //       typeof entry.languageNameEnglish === "string" &&
  //       entry.languageCode !== "auto"
  //   );

  // const findNative = (arr) => {
  //   const arrs = [];

  //   arr.forEach((element) => {
  //     const found = configLocal.languageList.find(
  //       (x) =>
  //         x.languageNameEnglish.toLocaleLowerCase() ===
  //         element.languageNameEnglish.toLowerCase()
  //     );

  //     const obj = {
  //       ...element,
  //       languageNameNative: found?.languageNameNative ?? "MISSING",
  //     };
  //     arrs.push(obj);
  //   });
  //   return arrs;
  // };

  // const extraSupportedLanguages = findNative(languageList);

  // console.log(
  //   "extraSupportedLanguages: " + JSON.stringify(extraSupportedLanguages)
  // );

  const handleTranslation = (toTranslate, fromLanguage = "en", format) => {
    if (Boolean(autoTranslate) && displayLanguage !== fromLanguage) {
      // syncTranslate(toTranslate, {
      translateTextSync(toTranslate, {
        // gTranslateSync(toTranslate, {
        from: fromLanguage ?? "auto",
        to: displayLanguage,
        format,
        // to: "th",
        // corsUrl: "http://cors-anywhere.herokuapp.com/",
        // from: "auto",
        // autoCorrect: true,
        // client: "gtx",
        // requestFunction: "axios",
      })
        .then((res) => {
          return res;
          // if (res?.status === "success") return res.translatedText;
          // else throw new Error(res?.message);
        })
        .catch((error) => {
          console.log(
            "handleTranslation translate error: " +
              error.name +
              " " +
              error.message +
              " | toTranslate: " +
              toTranslate +
              " | displayLanguage: " +
              displayLanguage
          );
        });
    } else {
      return toTranslate;
    }

    return toTranslate;
  };

  return handleTranslation;
}
