// import { map, filter } from 'lodash';
// import configLocal from "../../config/configLocal";
import { createSlice } from "@reduxjs/toolkit";
// Parse
// import Parse from "parse/react-native";
// dispatch
import { dispatch } from "../store";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  naviPublicProfile: undefined,
  naviListing: undefined,
  naviListings: undefined,
};

const slice = createSlice({
  name: "navi",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setNaviPublicProfileSuccess(state, action) {
      state.isLoading = false;
      state.naviPublicProfile = action.payload;
    },

    setNaviListingsSuccess(state, action) {
      state.isLoading = false;
      state.naviListings = action.payload;
    },

    setNaviListingSuccess(state, action) {
      state.isLoading = false;
      state.naviListing = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { startLoading, stopLoading } = slice.actions;

// ----------------------------------------------------------------------

export function setNaviPublicProfileRedux(value) {
  return () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setNaviPublicProfileSuccess(value));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setNaviListingsRedux(value) {
  return () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setNaviListingsSuccess(value));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setNaviListingRedux(value) {
  return () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setNaviListingSuccess(value));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
