import padding from "../config/padding";
import { createAgendaDaysArray } from "./helpers";
import { store } from "../store/store";
import configLocal from "../config/configLocal";

const dayjs = require("dayjs");

// export const n6 = new Intl.NumberFormat('en-us', {
//   style: 'decimal',
//   minimumFractionDigits: 0,
//   maximumFractionDigits: 6,
// });
// export const n4 = new Intl.NumberFormat('en-us', {
//   style: 'decimal',
//   minimumFractionDigits: 0,
//   maximumFractionDigits: 4,
// });

// export const c2 = new Intl.NumberFormat('en-us', {
//   style: 'currency',
//   currency: 'USD',
//   minimumFractionDigits: 2,
//   maximumFractionDigits: 2,
// });

/**
 * Returns a string of form "abc...xyz"
 * @param {string} str string to strink
 * @param {number} n number of chars to keep at front/end
 * @returns {string}
 */
export const getEllipsisTxt = (str, n = 6) => {
  if (str && str.length >= n + 3) {
    return `${str.slice(0, n)}...${str.slice(str.length - n)}`;
  } else {
    return str;
  }
};

export const tokenValue = (value, decimals) =>
  decimals ? value / Math.pow(10, decimals) : value;

export const dateConverter = (dateInput) => {
  let unix_timestamp = dateInput;
  // Create a new JavaScript Date object based on the timestamp
  // multiplied by 1000 so that the argument is in milliseconds, not seconds.
  var dates = new Date(unix_timestamp * 1000);
  //var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  var year = dates.getFullYear();
  var month = ("0" + (dates.getMonth() + 1)).slice(-2);
  // var month = dates.getMonth() + 1;
  var date = ("0" + dates.getDate()).slice(-2);
  // var date = dates.getDate();
  // Hours part from the timestamp
  var hours = dates.getHours();
  // Minutes part from the timestamp
  var minutes = "0" + dates.getMinutes();
  // Seconds part from the timestamp
  // var seconds = "0" + dates.getSeconds();

  // Will display time in 10:30:23 format
  var formattedTime =
    hours + ":" + minutes.substr(-2) + " | " + year + "-" + month + "-" + date;
  // date + ' ' + month + ' ' + year + ' ' + hours + ":" + minutes.substr(-2);
  return formattedTime;
  //return date?.toISOString("MM-DD-YYYY").split("T")[0];
};

export const dateConverterMessagesScreen = (dateInput) => {
  let unix_timestamp = dateInput;

  var dates = new Date(unix_timestamp * 1000);
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var month = months[dates.getMonth()];
  var date = dates.getDate();
  var formattedTime = date + " " + month;

  return formattedTime;
};

export const formatImageArray = (
  item,
  arraylength = 12,
  columnName = "image"
) => {
  // console.log(
  //   "!Boolean(item.get(`${columnName}Set`))",
  //   !Boolean(item.get(`${columnName}Set`))
  // );
  const imageSet = !Boolean(item.get(`${columnName}Set`))
    ? ""
    : String("set" + item.get(`${columnName}Set`));

  const imgArray = [];
  for (let j = 0; j < arraylength; j += 1) {
    if (item.get(`${columnName + j + imageSet}`)) {
      const imgInfo = item.get(`${columnName + j + imageSet}`);
      const imgObj = {
        _url: httpToHttps(imgInfo.url()),
        _name: imgInfo.name(),
        metadata: item.get(`${columnName + j + imageSet}metadata`),
        imgInfo,
      };
      // imgInfo["metadata"] = item.attributes[`cert${j}metadata`];
      // console.log("imgInfo", imgInfo);
      imgArray.push(imgObj);
    }
  }

  return imgArray;
};

export const formatImageArrayStatusLog = (inputImgArray, length = 10) => {
  // console.log('inputImgArray new', inputImgArray)
  const imgArray = [];
  for (let j = 0; j < length; j += 1) {
    if (inputImgArray[j]) {
      // const imgInfo = inputImgArray[j];
      //imgInfo["metadata"] = inputImgArray[j].metadata;
      imgArray.push(inputImgArray[j]);
    }
  }
  // console.log("imgArray BookingDetailsScreen new", imgArray);

  return [...imgArray];
};

export const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

export const filterInPlace = (a, condition, thisArg) => {
  let j = 0;

  a.forEach((e, i) => {
    if (condition.call(thisArg, e, i, a)) {
      if (i !== j) a[j] = e;
      j++;
    }
  });

  a.length = j;
  return a;
};

// /**
//  * Return a formatted string with the symbol at the end
//  * @param {number} value integer value
//  * @param {number} decimals number of decimals
//  * @param {string} symbol token symbol
//  * @returns {string}
//  */
// export const tokenValueTxt = (value, decimals, symbol) =>
//   `${n4.format(tokenValue(value, decimals))} ${symbol}`;

export const sortBookingStatusLog = (statusLog, ascending = true) => {
  // console.log("statusLogstatusLog", statusLog);
  const sortedArray = statusLog.sort(
    ({ latestStartingDate: dateA }, { latestStartingDate: dateB }) =>
      dateA - dateB
  );
  if (!ascending) sortedArray.reverse();
  return sortedArray;
};

export const sortCurrency = (currencyArray, ascending = true) => {
  const sortedArray = currencyArray.sort(
    ({ currencyCode: currA }, { currencyCode: currB }) => {
      if (currA < currB) {
        return -1;
      }
      if (currA > currB) {
        return 1;
      }
      return 0;
    }
  );
  if (!ascending) sortedArray.reverse();
  return sortedArray;
};

export const sortArray = (arrayToSort, selector, ascending = true) => {
  const sortedArray = arrayToSort.sort((a, b) => {
    if (a[selector] < b[selector]) {
      return -1;
    }
    if (a[selector] > b[selector]) {
      return 1;
    }
    return 0;
  });
  if (!ascending) sortedArray.reverse();
  return sortedArray;
};

// for production builds only
export const httpToHttps = (str) => {
  if (
    typeof str === "string" &&
    process.env.NODE_ENV === "production" &&
    str.indexOf("https") < 0
  ) {
    return str.replace("http", "https");
  } else return str;
};

// for production builds only
export const getBackupIpfsUrl = (imgObject) => {
  return (
    configLocal.url.backupIpfsGateway + imgObject?.hash + "/" + imgObject?._name
  );
};

//
export function getAgendaItems(bookings, isCustomer) {
  const reduxState = store.getState();
  const config = reduxState.app.config;
  // const agendaDaysArray = [];

  // const agendaDaysObject = {};

  if (
    (Array.isArray(bookings) && bookings.length <= 0) ||
    !Array.isArray(bookings)
  )
    return {
      agendaItemsArray: [],
      agendaItemsObject: {},
    };

  const firstDate = dayjs(bookings[0].currentStatusLog.timeFrom)
    .subtract(1, "month")
    .format("YYYY-MM-DD");
  //
  const lastDate = dayjs(
    bookings[bookings.length - 1].currentStatusLog.timeFrom
  )
    .add(1, "month")
    .format("YYYY-MM-DD");

  const { agendaDaysArray, agendaDaysObject } = createAgendaDaysArray(
    firstDate,
    lastDate
  );

  // console.log("agendaDaysArray: ", agendaDaysArray);
  // console.log("agendaDaysObject: ", agendaDaysObject);

  bookings.forEach((item, index) => {
    const currentStatusLog = item?.currentStatusLog;
    const currentStatus = item?.currentStatus;
    // const timeFromSeconds =
    //   new Date(currentStatusLog.timeFrom).getTime() / 1000;
    // const timeToSeconds = timeFromSeconds + currentStatusLog.totalHours * 3600;
    const otherPublicPointer = isCustomer
      ? item.providerPublicPointer
      : item.customerPublicPointer;

    const thisDate = dayjs(currentStatusLog.timeFrom).format("YYYY-MM-DD");
    const currDate = dayjs().format("YYYY-MM-DD");

    agendaDaysObject[thisDate].push({
      name: item.listingPointer.get("title"),
      height: padding.topBar100 + padding.primary * currentStatusLog.totalHours,
      day: thisDate,
      startTime: dayjs(currentStatusLog.timeFrom).format("h:mm a"),
      duration: currentStatusLog.totalHours,
      otherPublicPointer,
      completed: Boolean(
        thisDate < currDate &&
          config.statusObject[currentStatus].bookingStatusGroup !== "ongoing"
      ),
      ongoing: Boolean(
        thisDate === currDate ||
          (thisDate <= currDate &&
            config.statusObject[currentStatus].bookingStatusGroup === "ongoing")
      ),
      item,
    });

    // const existingDateIndex = agendaDaysArray.findIndex(
    //   (x) =>
    //     x.title == dayjs(item.currentStatusLog.timeFrom).format("YYYY-MM-DD")
    // );

    //   {
    //     title: dates[1],
    //     data: [
    //         { hour: '4pm', duration: '1h', title: 'Pilates ABC' },
    //         { hour: '5pm', duration: '1h', title: 'Vinyasa Yoga' }
    //     ]
    // },

    // if (existingDateIndex >= 0) {
    //   agendaDaysArray[existingDateIndex].data.push({
    //     hour: dayjs(item.currentStatusLog.timeFrom).format("h:mma"),
    //     duration: item.currentStatusLog.totalHours + "h",
    //     title: item.listingPointer.get("title"),
    //     // title: "title",
    //     item: item,
    //   });

    //   agendaDaysObject[agendaDaysArray[existingDateIndex].title].push({
    //     name: item.listingPointer.get("title"),
    //     height: padding.xxxxl,
    //     day: agendaDaysArray[existingDateIndex].title,
    //     item: item,
    //   });
    // }
    // else {
    //   agendaDaysArray.push({
    //     title: dayjs(item.currentStatusLog.timeFrom).format("YYYY-MM-DD"),
    //     data: [
    //       {
    //         hour: dayjs(item.currentStatusLog.timeFrom).format("h:mma"),
    //         duration: item.currentStatusLog.totalHours + "h",
    //         title: item.listingPointer.get("title"),
    //         // title: "title",
    //         item: item,
    //       },
    //     ],
    //   });

    //   agendaDaysObject[
    //     dayjs(item.currentStatusLog.timeFrom).format("YYYY-MM-DD")
    //   ] = [
    //     {
    //       name: item.listingPointer.get("title"),
    //       height: padding.xxxxl,
    //       day: dayjs(item.currentStatusLog.timeFrom).format("YYYY-MM-DD"),
    //       item: item,
    //     },
    //   ];
    // }

    // date.toISOString().split('T')[0]
    // NOTE: only mark dates with data
    // if (item.data && item.data.length > 0 && !isEmpty(item.data[0])) {
    //     marked[item.title] = { marked: true };
    // } else {
    //     marked[item.title] = { disabled: true };
    // }
  });

  // if (agendaDaysArray.length > 0) {
  //   const currentDateIndex = agendaDaysArray.findIndex(
  //     (x) => x.title == dayjs().format("YYYY-MM-DD")
  //   );

  //   if (currentDateIndex < 0) {
  //     const currentDateIndex2 = agendaDaysArray.findIndex(
  //       (x) => x.title > dayjs().format("YYYY-MM-DD")
  //     );

  //     agendaDaysArray.splice(currentDateIndex2, 0, {
  //       title: dayjs().format("YYYY-MM-DD"),
  //       data: [{}],
  //     });
  //   }
  // }

  return {
    agendaItemsArray: agendaDaysArray,
    agendaItemsObject: agendaDaysObject,
  };
}

export const maxInputLength = (str, maxLength = 500) => {
  return str.substring(0, maxLength);
};

export const formatLoc = (locToFormat) => ({
  lat: locToFormat.latitude,
  lng: locToFormat.longitude,
});
