import React, { useCallback, useEffect, useState } from "react";

import AppPressable from "./AppPressable";
import AppIcon from "./AppIcon";
import AppText from "./AppText";
import AppButton from "./AppButton";
import AppCheckBox from "./AppCheckBox";
import AppTextInput from "./AppTextInput";
import colors from "../config/colors";
import padding from "../config/padding";
import shadows from "../config/shadows";
import AppTextTranslate from "./AppTextTranslate";
import { useYouwho } from "../package/react-youwho";
import { absoluteFill, arrayExists, getPx, getSx } from "../utils/helpers";
import AppViewLayout from "./AppViewLayout";
import {
  Box,
  Button,
  Flex,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useDisclosure,
} from "@chakra-ui/react";
import View from "./rnMimic/View";
import ListItemSeparator from "./lists/ListItemSeparator";

export default function AppModalMultiCheckboxSearchSave({
  handlePressModal,
  capitalize,
  classTitle,
  buttonVariant = "primary",
  buttonTitle,
  buttonIconLeft,
  buttonIconRight,
  buttonStyle,
  firstPropertyVariable,
  heading,
  initialData,
  initialValue = [],
  noSearch,
  onPressButton,
  secondPropertyVariable,
  setClassTitle,
  setDataToSave,
  single,
  thirdPropertyVariable,
  translate,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { user } = useYouwho();

  const formatData = (oldData) => {
    const newArr = [];
    for (let i = 0; i < oldData.length; i++) {
      const newObj = {
        firstProperty: oldData[i][firstPropertyVariable],
        secondProperty: secondPropertyVariable
          ? oldData[i][secondPropertyVariable]
          : undefined,
        thirdProperty: thirdPropertyVariable
          ? oldData[i][thirdPropertyVariable]
          : undefined,
      };
      newArr.push(newObj);
    }
    return newArr;
  };

  const reformatData = (oldData) => {
    const newArr = [];
    for (let i = 0; i < oldData.length; i++) {
      const newObj = {};
      newObj[firstPropertyVariable] = oldData[i].firstProperty;
      if (secondPropertyVariable)
        newObj[secondPropertyVariable] = oldData[i].secondProperty;
      if (thirdPropertyVariable)
        newObj[thirdPropertyVariable] = oldData[i].thirdProperty;
      newArr.push(newObj);
    }
    return newArr;
  };

  const [selectedData, setSelectedData] = useState(() =>
    formatData(initialValue)
  );

  const [data, setData] = useState(() => formatData(initialData));
  const formattedInitialData = formatData(initialData);

  const handleSearch = (searchText) => {
    const lowerCased = searchText?.toLowerCase() ?? "";
    const searchFirstProperty = formattedInitialData.filter((t) =>
      t.firstProperty?.toLowerCase().includes(lowerCased)
    );
    const searchSecondProperty = formattedInitialData.filter((t) =>
      t.secondProperty?.toLowerCase().includes(lowerCased)
    );

    setData(
      searchFirstProperty && searchFirstProperty.length > 0
        ? searchFirstProperty
        : searchSecondProperty
    );
  };

  const handleUncheck = (x) => {
    const uncheck = selectedData.find(
      (y) => y.firstProperty === x.firstProperty
    );
    setSelectedData(
      selectedData.filter((t) => t.firstProperty !== uncheck.firstProperty)
    );
  };

  const handlePressButton = () => {
    // console.log("reformatData(selectedData)", reformatData(selectedData));
    onPressButton(classTitle, reformatData(selectedData));
    handlePressModal(false);
  };

  useEffect(() => {
    if (setDataToSave) setDataToSave(reformatData(selectedData));
  }, [selectedData]);

  useEffect(() => {
    if (setClassTitle) setClassTitle(classTitle);
  }, [setClassTitle]);

  const renderItem = useCallback(
    ({ item, index }) => {
      return (
        <>
          <Flex key={index} style={styles.checkboxContainer}>
            <AppTextTranslate
              fromLanguage="en"
              capitalize={capitalize}
              variant="lg"
              numberOfLines={1}
              style={{ flex: 1 }}
              noTranslate={!translate}
            >
              {`${item.firstProperty}${
                item.secondProperty ? " • " + item.secondProperty : ""
              }`}
            </AppTextTranslate>
            <AppCheckBox
              checked={selectedData?.find(
                (y) => y.firstProperty === item.firstProperty
              )}
              handleCheck={() => {
                setSelectedData((text) => (single ? [item] : [...text, item]));
              }}
              handleUncheck={() => {
                handleUncheck(item);
              }}
            />
          </Flex>
          {arrayExists(data) && index !== data.length - 1 && (
            <ListItemSeparator />
          )}
        </>
      );
    },
    [selectedData, data]
  );

  // return (
  //   <>
  //     <ModalHeader>Modal Title</ModalHeader>
  //     <ModalBody>
  //       {data.map((item, index) => renderItem({ item, index }))}
  //     </ModalBody>
  //     <ModalFooter>
  //       <Button onClick={() => handlePressModal(false)}>Close</Button>
  //     </ModalFooter>
  //   </>
  // );

  return (
    <Box>
      <AppTextTranslate
        fromLanguage="en"
        variant="h1"
        sx={{ marginBottom: getPx(padding.primary) }}
      >
        {heading}
      </AppTextTranslate>
      {!noSearch &&
        Boolean(!translate || user?.attributes?.displayLanguage === "en") && (
          <AppTextInput
            autoCapitalize="none"
            autoCorrect={false}
            icon="search"
            name="search"
            label="search"
            onChangeTextField={handleSearch}
          />
        )}

      <Box
        sx={{
          marginTop: getPx(padding.ms),
          // paddingBottom: getPx(padding.bottomBar80),
          // marginY: getPx(padding.sm),
          maxHeight: "50vh",
          overflow: "auto",
        }}
      >
        {data.map((item, index) => renderItem({ item, index }))}
      </Box>

      {onPressButton && (
        <>
          <ListItemSeparator style={{ marginBottom: getPx(padding.primary) }} />
          <AppButton
            thick
            title={buttonTitle}
            buttonStyle={getSx([buttonStyle])}
            IconLeft={buttonIconLeft}
            IconRight={buttonIconRight}
            isDisabled={initialValue === selectedData}
            // isLoading={isSubmitting}
            variant={buttonVariant}
            onPress={handlePressButton}
          />
        </>
      )}
    </Box>
  );
}

const styles = Object.create({
  checkboxContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    // paddingY: getPx(padding.primary),
    height: getPx(padding.xxxxl),
    // flex: 1,
    // width: "100%",
    // backgroundColor: "pink",
  },
});
