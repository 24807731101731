import React, { useMemo, useEffect, useRef, useState } from "react";
// import { Linking } from "react-native";
// import Toast from "react-native-toast-message";
// navigator.geolocation = require("react-native-geolocation-service");
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom";
import { Helmet } from "react-helmet-async";

// internal
// import AppNavigator from "./navigation/AppNavigator";
// import AuthNavigator from "./navigation/AuthNavigator";
import SplashScreen from "./screens/SplashScreen";
import useInitApp from "./hooks/useInitApp";
// import { useLocation } from "./hooks/useLocation";
// import AppModalUnderlay from "./components/AppModalUnderlay";
// import { useAuth } from "./hooks/useAuth";
import { useYouwho } from "./package/react-youwho";
// import { toastConfig } from "./config/toast";
// import padding from "./config/padding";
// import EnterMfaScreen from "./screens/EnterMfaScreen";
import { useLinks } from "./hooks/useLinks";
// import TestingScreen from "./screens/TestingScreen";
import FullScreenLayout from "./screens/FullScreenLayout";
import BottomTabLayout from "./screens/BottomTabLayout";
import GuestAccountScreen from "./screens/tabScreens/guestTabScreens/GuestAccountScreen";
import GuestScreen from "./screens/tabScreens/guestTabScreens/GuestScreen";
import ListingDetailsScreen from "./screens/ListingDetailsScreen";
import ListingsSearchResultsScreenBS from "./screens/tabScreens/ListingsSearchResultsScreenBS";
import { useDispatch } from "react-redux";
import { useDisclosure } from "@chakra-ui/react";
import { setLoginModalObjectRedux } from "./store/slices/app";
import LoginSignupModal from "./components/modals/LoginSignupModal";
import ReportListingScreen from "./screens/ReportListingScreen";
import AppNavigator from "./navigation/AppNavigator";
import AuthNavigator from "./navigation/AuthNavigator";
import useScript from "./hooks/useScript";
import EnterMfaScreen from "./screens/EnterMfaScreen";

export default function App() {
  // useScript(
  //   "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit2",
  //   {
  //     strategy: "afterInteractive",
  //   }
  // );
  useInitApp();
  const { appStore, user, dispatch, userStore } = useYouwho();
  const { mfaEnabled, mfaPassed } = userStore;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [defaultIndex, setDefaultIndex] = useState(0);

  // const { user, appStore, userStore } = useYouwho();
  const { appIsReady, config } = appStore;
  // useGoogleTranslateScript2();
  // const { mfaEnabled, mfaPassed } = userStore;
  // useLocation(appIsReady);
  // const {
  //   authenticateWithApple,
  //   authenticateWithFacebook,
  //   authenticateWithGoogle,
  //   authenticateWithTwitter,
  //   handleSharedListing,
  //   setIsLoading,
  // } = useAuth();
  // const { handleLinkClicked } = useLinks();

  // useEffect(() => {
  //   const linkingSubscription = Linking.addEventListener("url", async (url) => {
  //     console.log("linkingSubscription: " + JSON.stringify(url));
  //     if (url.url) {
  //       if (url.url.indexOf("appleAuthCallback") >= 0)
  //         authenticateWithApple(url.url);
  //       if (url.url.indexOf("facebookAuthCallback") >= 0)
  //         authenticateWithFacebook(url.url);
  //       if (url.url.indexOf("googleAuthCallback") >= 0)
  //         authenticateWithGoogle(url.url);
  //       if (url.url.indexOf("twitterAuthCallback") >= 0)
  //         authenticateWithTwitter(url.url);
  //       if (url.url.indexOf("listing") >= 0) handleSharedListing(url.url);
  //       // if (url.url.indexOf("refLink231027") >= 0)
  //       //   handleLinkClicked(url.url, "refLink231027");
  //     }
  //   });

  //   Linking.getInitialURL().then((url) => {
  //     if (url) console.log("getInitialUrl1 ", url);
  //   });

  //   return () => {
  //     // Clean up the event listeners
  //     linkingSubscription.remove();
  //   };
  // }, [user]);

  // if (user && mfaEnabled && !mfaPassed) {
  //   return <EnterMfaScreen />;
  // }

  // const location = useLocation();
  // useEffect(
  //   () =>
  //     (document.title =
  //       titles[location.pathname] ??
  //       "YOUWHO | Crypto Based On Demand Services Platform"),
  //   [location]
  // );

  useEffect(() => {
    // if (dispatch && isOpen && onOpen && onClose)
    dispatch(
      setLoginModalObjectRedux({
        isOpen,
        onOpen,
        onClose,
        setDefaultIndex,
        defaultIndex,
      })
    );
  }, []);

  if (!appIsReady) {
    return <SplashScreen />;
  }

  if (user && mfaEnabled && !mfaPassed) {
    return <EnterMfaScreen />;
  }

  // return <>{user ? <AppNavigator /> : <ListingsSearchResultsScreenBS />}</>;
  return (
    <>
      {user ? <AppNavigator /> : <AuthNavigator />}
      <LoginSignupModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        defaultIndex={defaultIndex}
      />
    </>
  );
}

