import React from "react";

import AppButton from "../../../components/AppButton";
import ListItemSeparator from "../../../components/lists/ListItemSeparator";
import padding from "../../../config/padding";
import { getPx } from "../../../utils/helpers";
import { fontConfig as fonts } from "../../../config/fonts";
import AppTextTranslate from "../../../components/AppTextTranslate";
import { useSelector } from "react-redux";
import { FiKey } from "react-icons/fi";
import Screen from "../../../components/Screen";
import View from "../../../components/rnMimic/View";

function GuestScreen({ heading, navigation, subtitle, title }) {
  const { loginModalObject } = useSelector((state) => state.app);

  return (
    <Screen title={heading}>
      <View flexStart style={styles.container} spacing={5}>
        <AppTextTranslate
          fromLanguage="en"
          center
          variant="h1"
          style={styles.heading}
        >
          {heading}
        </AppTextTranslate>
        {/* <ListItemSeparator style={{ marginY: padding.xl }} /> */}
        <AppTextTranslate fromLanguage="en" center variant="h3">
          {title}
        </AppTextTranslate>
        <AppTextTranslate fromLanguage="en" center variant="lg">
          {subtitle}
        </AppTextTranslate>
        <View direction="row" spacing={5}>
          <AppButton
            variant="primary"
            thick
            IconLeft={FiKey}
            title="Login"
            onPress={loginModalObject?.onOpen}
          />
          {/* <a href={configLocal.url.androidApp} target="_blank">
            <AppButton
              variant="primary"
              IconLeft={IoLogoGooglePlaystore}
              title="Android"
            />
          </a>
          <a href={configLocal.url.iosApp} target="_blank">
            <AppButton variant="info" IconLeft={SiAppstore} title="iOS" />
          </a> */}
          {/* <Button
            href={urls.desktop}
            target="_blank"
            size="large"
            sx={{ marginLeft: '15px', marginTop: '15px' }}
            color="info"
            variant="contained"
            startIcon={<LuGlobe2 />}
            disabled
          >
            Web
          </Button> */}
        </View>
      </View>
    </Screen>
  );
}

const styles = Object.create({
  button: { marginTop: getPx(padding.ms) },
  container: {
    padding: getPx(padding.primary),
    paddingTop: getPx(padding.xxl),
    // alignItems: "flex-start",
  },
  heading: {
    fontSize: getPx(fonts.size.xh1),
    marginTop: getPx(padding.sm),
    paddingBottom: getPx(padding.ms),
    borderBottom: "2px solid grey",
  },
});

export default GuestScreen;
