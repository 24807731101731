import React from "react";
import { FiCheck } from "react-icons/fi";
import { useFormikContext } from "formik";

import colors from "../config/colors";
import AppPressable from "./AppPressable";
import { getPx, getSx } from "../utils/helpers";

const checkBoxSize = {
  sm: { height: 20, width: 20, borderRadius: 4, size: 35, borderWidth: 2 },
  md: { height: 25, width: 25, borderRadius: 5, size: 40, borderWidth: 2 },
  lg: { height: 30, width: 30, borderRadius: 6, size: 50, borderWidth: 2 },
  xl: { height: 40, width: 40, borderRadius: 8, size: 60, borderWidth: 2 },
};

export default function AppCheckBox({
  checkBoxToggleColor = colors.black,
  checked,
  handleCheck = () => {},
  handleUncheck = () => {},
  size = "lg",
}) {
  return (
    <AppPressable
      scale={0.97}
      onPress={(e) => {
        if (checked) {
          handleUncheck(e);
        } else {
          handleCheck(e);
        }
      }}
      style={getSx([
        styles.checkBoxContainer,
        {
          borderRadius: getPx(checkBoxSize[size].borderRadius),
          height: getPx(checkBoxSize[size].height),
          width: getPx(checkBoxSize[size].width),
          backgroundColor: checked ? checkBoxToggleColor : "transparent",
          borderWidth: getPx(checkBoxSize[size].borderWidth),
          borderColor: checkBoxToggleColor,
        },
      ])}
    >
      {checked && (
        <FiCheck size={checkBoxSize[size].size} color={colors.white} />
      )}
    </AppPressable>
  );
}

const styles = Object.create({
  checkBoxContainer: {
    alignItems: "center",
    justifyContent: "center",
  },
});
