import React from "react";
import borders from "../config/borders";
import colors from "../config/colors";
import { fontConfig } from "../config/fonts";
import padding from "../config/padding";
import AppPressable from "./AppPressable";
import ActivityIndicator from "./rnMimic/ActivityIndicator";
import AppText from "./AppText";
import AppTextTranslate from "./AppTextTranslate";
import { getPx, getSx } from "../utils/helpers";
import { Box, Flex } from "@chakra-ui/react";
import ActivityIndicator2 from "./rnMimic/ActivityIndicator2";
import shadows from "../config/shadows";

const variants = {
  default: {
    button: {
      backgroundColor: colors.black,
      borderWidth: 1,
      borderColor: colors.black,
    },
    text: { color: colors.white },
  },
  outline: {
    button: {
      backgroundColor: colors.white,
      borderWidth: getPx(padding.one),
      borderColor: colors.black,
      // paddingX: getPx(padding.lg),
      // paddingY: getPx(padding.ms),
    },
    text: { color: colors.black },
    dark: true,
  },
  primary: {
    button: {
      backgroundColor: colors.primary,
      borderWidth: 1,
      borderColor: colors.primary,
    },
    text: { color: colors.white },
  },
  secondary: {
    button: {
      backgroundColor: colors.secondary,
      borderWidth: 1,
      borderColor: colors.secondary,
    },
    text: { color: colors.white },
  },
  tertiary: {
    button: {
      backgroundColor: colors.tertiary,
      borderWidth: 1,
      borderColor: colors.tertiary,
    },
    text: { color: colors.white },
  },
  info: {
    button: {
      backgroundColor: colors.info,
      borderWidth: 1,
      borderColor: colors.info,
    },
    text: { color: colors.white },
  },
  danger: {
    button: {
      backgroundColor: colors.danger,
      borderWidth: 1,
      borderColor: colors.danger,
    },
    text: { color: colors.white },
  },
  small: {
    button: {
      backgroundColor: colors.black,
      // height: 45,
      // minWidth: 100,
      // marginY: 0,
      paddingY: getPx(padding.ms),
      borderWidth: 1,
      borderColor: colors.black,
    },
    text: { color: colors.white },
  },
  underline: {
    button: {
      backgroundColor: "transparent",
      paddingX: 0,
      paddingY: 0,
    },
    text: { color: colors.black, textDecorationLine: "underline" },
    dark: true,
  },
  white: {
    button: {
      backgroundColor: colors.white,
      borderWidth: 1,
      borderColor: colors.white,
      _hover: { backgroundColor: colors.grayE },
    },
    text: { color: colors.black },
    dark: true,
  },
  none: {
    text: { color: colors.black },
    dark: true,
  },
};

export default function AppButton({
  buttonStyle,
  fontColor,
  fontSize = fontConfig.size.lg,
  fromLanguage = "en",
  IconLeft,
  IconRight,
  iconSize,
  isDisabled,
  isLoading,
  // loaderSize = Platform.OS === "ios" ? "small" : "large",
  loaderSize = "large",
  noAnimate,
  noTranslate,
  numberOfLines,
  onPress = () => {},
  pill,
  pressableStyle,
  scale,
  shadow,
  textStyle,
  textVariant,
  title,
  thick,
  variant = "default",
}) {
  return (
    <AppPressable
      pressableStyle={pressableStyle}
      onPress={onPress}
      isDisabled={isDisabled}
      scale={scale}
      isLoading={isLoading}
      useLoader={false}
      noAnimate={noAnimate}
      style={shadow ? shadows.web2 : {}}
      // style={pressableStyle}
    >
      <Flex
        sx={{
          // styles.button(fontSize, pill, thick),
          ...{
            alignItems: "center",
            backgroundColor: colors.black,
            borderRadius: getPx(
              pill ? borders.borderRadiusPill : borders.borderRadius8
            ),
            flexDirection: "row",
            // height: getPx(fontSize + padding.lg * 2),
            justifyContent: "center",
            // marginY: 10,
            // minWidth: "40%",
            paddingX: getPx(thick ? padding.primary : padding.md),

            paddingY: getPx(thick ? padding.ms : padding.sm),
            width: "100%",
          },
          ...variants[variant].button,

          ...{
            backgroundColor:
              isDisabled && variant !== "underline"
                ? colors.grayD
                : variants[variant].button?.backgroundColor,
            borderColor:
              isDisabled && variant !== "underline"
                ? colors.grayD
                : variants[variant].button?.borderColor,
          },
          ...buttonStyle,
        }}
      >
        {isLoading ? (
          <ActivityIndicator2
            // dark={false}
            loaderSize={loaderSize}
            // isDisabled={isDisabled}
          />
        ) : (
          <>
            {IconLeft && (
              <IconLeft
                size={getPx(iconSize ? iconSize : fontSize * 1.2)}
                style={getSx([
                  // styles.text,
                  variants[variant].text,
                  {
                    color: fontColor ? fontColor : variants[variant].text.color,
                    marginRight: title ? getPx(padding.xs) : undefined,
                    // bottom: 1.5,
                  },
                  isDisabled ? { color: colors.gray9 } : {},
                ])}
              />
            )}
            {title && (
              <AppTextTranslate
                noTranslate={noTranslate}
                fromLanguage={fromLanguage}
                sx={{
                  // styles.text,
                  fontWeight: 500,
                  ...{ fontSize: getPx(fontSize) },
                  ...variants[variant].text,
                  ...{
                    color: fontColor ? fontColor : variants[variant].text.color,
                    marginX: getPx(fontSize / 2.5),
                  },
                  ...textStyle,
                  ...(isDisabled ? { color: colors.gray9 } : {}),
                }}
                variant={textVariant}
                numberOfLines={numberOfLines}
              >
                {title}
              </AppTextTranslate>
            )}
            {IconRight && (
              <IconRight
                size={getPx(iconSize ? iconSize : fontSize * 1.2)}
                style={getSx([
                  // styles.text,
                  variants[variant].text,
                  {
                    color: fontColor ? fontColor : variants[variant].text.color,
                    marginLeft: title ? getPx(padding.xs) : undefined,
                    // bottom: 1.5,
                  },
                  isDisabled ? { color: colors.gray9 } : {},
                ])}
              />
            )}
          </>
        )}
      </Flex>
    </AppPressable>
  );
}
