import React, { useState, useMemo, useEffect } from "react";

import colors from "../../../config/colors";
import { fontConfig as fonts } from "../../../config/fonts";
import AppButton from "../../../components/AppButton";
// import AppListItem from "../../components/lists/AppListItem";
import AppText from "../../../components/AppText";
import ListItemSeparator from "../../../components/lists/ListItemSeparator";
import { useDispatch, useSelector } from "react-redux";
import padding from "../../../config/padding";
import AppTextTranslate from "../../../components/AppTextTranslate";
// import AppModalCardBS from "../../components/AppModalCardBS";
import {
  setAutoTranslateRedux,
  setDisplayLanguageRedux,
} from "../../../store/slices/app";
// import AppModalMoreBS from "../../components/AppModalMoreBS";
import { arrayExists, getPx } from "../../../utils/helpers";
import AppPressable from "../../../components/AppPressable";
import Screen from "../../../components/Screen";
import View from "../../../components/rnMimic/View";
import { FiFeather, FiKey, FiSave } from "react-icons/fi";
import AppListItem from "../../../components/lists/AppListItem";
import { FaDollarSign } from "react-icons/fa6";
import AppModalMoreBS from "../../../components/AppModalMoreBS";
import AppSwitch from "../../../components/AppSwitch";
import { IoGitBranch, IoLanguage } from "react-icons/io5";
import configLocal from "../../../config/configLocal";
import { HiMiniFingerPrint } from "react-icons/hi2";
import { SiGoogletranslate } from "react-icons/si";
import AppModalCardBS from "../../../components/AppModalCardBS";
import AppModalMultiCheckboxSearchSave from "../../../components/AppModalMultiCheckboxSearchSave";
import AppTextLang from "../../../components/AppTextLang";
import { getConfigLangCode } from "../../../language/translate/languages";

function GuestAccountScreen({ heading, navigation, subHeading }) {
  // const { isOpen, onOpen, onClose } = useDisclosure();
  // const [defaultIndex, setDefaultIndex] = useState(0);
  const dispatch = useDispatch();
  const [switchIsLoading, setSwitchIsLoading] = useState(false);
  const { displayLanguage, autoTranslate, loginModalObject, config } =
    useSelector((state) => state.app);

  const displayLanguageObject = useMemo(
    () =>
      arrayExists(config?.languageList) &&
      config?.languageList?.find(
        (x) => x.languageCode.toLowerCase() === displayLanguage.toLowerCase()
      ),
    [config?.languageList, displayLanguage]
  );

  const initialData = useMemo(
    () =>
      arrayExists(config?.languageList) &&
      config?.languageList?.filter((x) => x["languageCodeDesktop"]),
    [config?.languageList]
  );

  const handleAutoTranslate = async () => {
    try {
      setSwitchIsLoading(true);
      dispatch(setAutoTranslateRedux(!autoTranslate));
    } catch (error) {
      console.log("handleTranslate error: " + error);
    } finally {
      setSwitchIsLoading(false);
    }
  };

  const handleDisplayLanguage = async (classTitle, data) => {
    // console.log("data: " + JSON.stringify(data));
    try {
      setSwitchIsLoading(true);
      dispatch(setDisplayLanguageRedux(data[0].languageCode));
    } catch (error) {
      console.log("handleDisplayLanguage error: " + error);
    } finally {
      setSwitchIsLoading(false);
    }
  };

  return (
    <Screen title={heading}>
      <View flexStart style={styles.container} spacing={3}>
        <div id="gt-mordadam-43217984"></div>
        <AppTextLang langKey={heading} variant="h1" style={styles.heading} />
        <AppTextLang langKey={subHeading} style={styles.subHeading} />
        <View spacing={3} style={styles.button}>
          <AppButton
            IconLeft={FiKey}
            onPress={loginModalObject?.onOpen}
            title={"Login"}
            variant="primary"
            thick
            // buttonStyle={{ width: ["100%"] }}
            pressableStyle={{ width: ["100%", "40%"] }}
          />
          <AppPressable
            onPress={() => {
              loginModalObject?.setDefaultIndex(1);
              loginModalObject?.onOpen();
            }}
            style={{ flexDirection: "row" }}
          >
            <AppTextTranslate
              fromLanguage="en"
              variant="md"
              sx={{ marginRight: getPx(padding.sm) }}
            >
              Don't have an account?
            </AppTextTranslate>
            <AppTextTranslate fromLanguage="en" variant="mdb" underline>
              Signup
            </AppTextTranslate>
          </AppPressable>
        </View>

        <View>
          {arrayExists(initialData) && (
            <>
              <AppListItem
                IconComponent={
                  <IoLanguage size={fonts.size.h2} style={styles.icon} />
                }
                title={"Language"}
                RightComponent={
                  <AppModalCardBS
                    buttonNoTranslate
                    buttonTitle={
                      <AppText variant="h3">
                        {displayLanguage.toUpperCase()}
                      </AppText>
                    }
                    buttonDisabled
                    buttonVariant="underline"
                    buttonFontSize={fonts.size.primary}
                    // modalHeight="95%"
                    component={
                      <AppModalMultiCheckboxSearchSave
                        classTitle="displayLanguage"
                        onPressButton={handleDisplayLanguage}
                        heading="Language"
                        buttonIconRight={FiSave}
                        buttonTitle="Save"
                        initialData={initialData}
                        initialValue={
                          displayLanguageObject
                            ? [displayLanguageObject]
                            : undefined
                        }
                        firstPropertyVariable="languageNameEnglish"
                        secondPropertyVariable="languageNameNative"
                        thirdPropertyVariable="languageCode"
                        single
                      />
                    }
                  />
                }
              />
              <ListItemSeparator />
            </>
          )}

          <AppListItem
            IconComponent={
              <SiGoogletranslate size={fonts.size.h2} style={styles.icon} />
            }
            TitleComponent={
              <View style={{ flexDirection: "row" }}>
                <AppTextTranslate fromLanguage="en" variant="lg">
                  Auto translate
                </AppTextTranslate>
                <AppModalMoreBS
                  MoreComponent={
                    <>
                      <AppTextTranslate
                        fromLanguage="en"
                        variant="h2"
                        style={{ marginBottom: getPx(padding.primary) }}
                      >
                        Auto translate
                      </AppTextTranslate>

                      <AppTextTranslate fromLanguage="en" variant="md">
                        Enabling Auto translate will automatically translate
                        information relating to services.
                      </AppTextTranslate>
                    </>
                  }
                />
              </View>
            }
            RightComponent={
              <>
                <AppSwitch
                  switchInitialState={autoTranslate}
                  isLoading={switchIsLoading}
                  onPress={handleAutoTranslate}
                />
              </>
            }
          />
          <ListItemSeparator />

          <AppListItem
            IconComponent={
              <FaDollarSign size={fonts.size.h2} style={styles.icon} />
            }
            TitleComponent={
              <View direction="row">
                <AppTextTranslate fromLanguage="en" variant="lg">
                  Currency to display
                </AppTextTranslate>
                <AppModalMoreBS
                  MoreComponent={
                    <>
                      <AppTextTranslate
                        fromLanguage="en"
                        variant="h2"
                        style={{ marginBottom: getPx(padding.primary) }}
                      >
                        Currency to display
                      </AppTextTranslate>

                      <AppTextTranslate
                        format={"html"}
                        fromLanguage="en"
                        variant="md"
                      >
                        This is the currency that will be used when calculating
                        prices for services in the App. The display currency is
                        not the same as the currency that will be used to pay
                        for services.
                        <br />
                        <br />
                      </AppTextTranslate>

                      <AppTextTranslate
                        format={"html"}
                        fromLanguage="en"
                        variant="md"
                      >
                        the `YOUWHO` app accepts two main forms of payment, that
                        being `cryptocurrencies` and the other being traditional
                        methods such as bank transfers.
                        <br />
                        <br />
                      </AppTextTranslate>

                      <AppTextTranslate fromLanguage="en" variant="md">
                        Cryptocurrencies (`USDT`, `USDC`, `YOU`) are the primary
                        payment method accepted by the app. You may also choose
                        to pay via traditional methods such as bank transfers,
                        credit cards, etc. if the service provider accepts this
                        alternative form of payment.
                      </AppTextTranslate>
                    </>
                  }
                />
              </View>
            }
            RightComponent={<AppText style={{ fontWeight: 500 }}>USD</AppText>}
          />
          <ListItemSeparator />

          <a
            href={configLocal.url.website}
            target="_blank"
            style={{ width: "100%" }}
            className="button-animation"
          >
            <AppListItem
              IconComponent={
                <HiMiniFingerPrint size={fonts.size.h2} style={styles.icon} />
              }
              more
              TitleComponent={
                <View direction="row">
                  <AppTextTranslate
                    fromLanguage="en"
                    variant="lg"
                    sx={{ marginRight: getPx(padding.sm) }}
                  >
                    About
                  </AppTextTranslate>
                  <AppText variant="lgb">
                    <b>YOUWHO</b>
                  </AppText>
                </View>
              }
            />
          </a>
          <ListItemSeparator />

          <a
            href={configLocal.url.youwhoApp}
            target="_blank"
            style={{ width: "100%" }}
            className="button-animation"
          >
            <AppListItem
              IconComponent={
                <IoGitBranch size={fonts.size.h2} style={styles.icon} />
              }
              title={"Mobile app version"}
              RightComponent={
                <AppText variant="lg" style={{ fontWeight: 500 }}>
                  {configLocal.version}
                </AppText>
              }
            />
          </a>
        </View>
      </View>
    </Screen>
  );
}

const styles = Object.create({
  button: { marginY: getPx(padding.xl) },
  container: {
    padding: getPx(padding.primary),
    paddingTop: getPx(padding.xxl),
    alignItems: "flex-start",
    // width: "100%",
    // flex: 1,
    // background: "red",
  },
  heading: {
    fontSize: getPx(fonts.size.xh1),
    marginTop: getPx(padding.sm),
  },
  icon: { color: colors.black },
  subHeading: { color: colors.gray7, fontSize: getPx(fonts.size.lg) },
});

export default GuestAccountScreen;
