import React, { useRef, useState, useMemo, useCallback } from "react";
import {
  GoogleMap,
  // InfoBox,
  // Marker,
  // MarkerClusterer,
  MarkerF as Marker,
  // MarkerClustererF as MarkerClusterer,
  InfoBoxF as InfoBox,
  // InfoWindowF as InfoWindow,
} from "@react-google-maps/api";

import borders from "../config/borders";
import configLocal from "../config/configLocal";
// import shadows from "../config/shadows";
// import AppTextTranslate from "./AppTextTranslate";
import { Box, Center, StackDivider, VStack } from "@chakra-ui/react";
import { useGetLocation } from "../hooks/useLocation";
import { getFiatCurrencyObject, getPx, getSx } from "../utils/helpers";
import padding from "../config/padding";
import { useYouwho } from "../package/react-youwho";
import { formatLoc } from "../utils/formatters";
import AppViewLayout from "./AppViewLayout";
import colors from "../config/colors";
import { IoNavigate } from "react-icons/io5";
import { FaMinus, FaPlus } from "react-icons/fa";
import AppButton from "./AppButton";
import View from "./rnMimic/View";

const AppCardMap = ({
  customer,
  height = 250,
  latitude,
  longitude,
  onPress,
  zoom = 12,
}) => {
  // const { height, width } = useWindowDimensions();
  const { dispatch, userStore, mapIsLoaded } = useYouwho();
  const { userLocation } = userStore;
  const [center, setCenter] = useState({
    lat: latitude,
    lng: longitude,
  });
  const getLocation = useGetLocation();
  const [map, setMap] = useState(null);
  const mapRef = useRef();

  const onLoad = useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    // const bounds = new window.google.maps.LatLngBounds(center);
    // console.log("onLoad map.fitBounds(bounds);");
    // map.fitBounds(bounds);

    setMap(map);
  }, []);

  // const onLoadInfoBox = (infoBox) => {
  //   console.log("infoBox: ", infoBox);
  // };

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  const zoomIn = () => {
    console.log("zoomIn map.setZoom(map.getZoom() + 1);");
    map.setZoom(map.getZoom() + 1);
    // mapRef.current?.setZoom(mapRef.current?.getZoom() + 1);
  };
  const zoomOut = () => {
    console.log("zoomOut map.setZoom(map.getZoom() - 1);");
    map.setZoom(map.getZoom() - 1);
    // mapRef.current?.setZoom(mapRef.current?.getZoom() - 1);
  };

  const googleMap = useMemo(
    () => (
      <GoogleMap
        ref={mapRef}
        mapContainerStyle={{
          width: "100%",
          height: getPx(height),
        }}
        center={center}
        onLoad={onLoad}
        onUnmount={onUnmount}
        onClick={onPress}
        // onMouseDown={() =>
        //   listingsRef.current?.snapTo(({ snapPoints }) =>
        //     Math.min(...snapPoints)
        //   )
        // }
        // onRightClick={handlePressMap}
        options={{
          styles: configLocal.customMapStyle,
          zoomControl: false,
          zoom: 12,
          maxZoom: 12,
          minZoom: 12,
          mapTypeControl: false,
          rotateControl: false,
          fullscreenControl: false,
          streetViewControl: false,
          clickableIcons: false,
          gestureHandling: "none",
          draggableCursor: "pointer",
          draggingCursor: "pointer",
        }}
      >
        {/* <TopBar /> */}
        {/* <AppViewLayout
          sx={{
            position: "absolute",
            top: getPx(padding.primary),
            right: getPx(padding.primary),
          }}
        >
          <VStack
            spacing={0}
            sx={{
              border: "1px solid ",
              borderColor: colors.grayD,
              borderRadius: getPx(borders.borderRadius8),
              bg: colors.grayD,
              overflow: "hidden",
              boxShadow: "0px 0px 7px 1px rgba(0,0,0,0.2)",
            }}
            divider={<StackDivider borderColor="gray.200" />}
          >
            {[
              { onPress: getLocation, title: <IoNavigate /> },
              { onPress: zoomIn, title: <FaPlus /> },
              { onPress: zoomOut, title: <FaMinus /> },
            ].map((x, i) => (
              <Center key={i}>
                <AppButton
                  variant="white"
                  onPress={x.onPress}
                  title={x.title}
                  buttonStyle={{
                    borderRadius: 0,
                    paddingY: 0,
                    paddingX: 0,
                    height: getPx(padding.xxl),
                    width: getPx(padding.xxl),
                  }}
                />
              </Center>
            ))}
          </VStack>
        </AppViewLayout> */}

        {/* Child components, such as markers, info windows, etc. */}
        {/* {false && */}

        <Marker
          position={center}
          identifier={"provider"}
          key={"provider"}
          // icon={"./assets/icons/m1.png"}
          icon={"https://youwhoapp.youwho.io/assets/icons/m1.png"}
          // title={`${
          //   getFiatCurrencyObject(x.marker.fiatCurrency).symbol_native +
          //   x.marker.rate
          // } ${x.marker.fiatCurrency.toUpperCase()}/hr.`}
          // title={`Provider`}
          // onClick={() => {
          //   console.log("onPress x.marker.obj: " + JSON.stringify(x.markerID));
          //   dispatch(setFocusMarkerRedux(x.marker.obj));
          //   // setFocusMarkerState(x.marker.obj);
          // }}
        >
          {/* <InfoWindow
                onLoad={onLoadInfoBox}
                position={x.marker.location}
              >
                <Box
                  sx={{
                    fontFamily: "Quicksand, sans-serif",
                    fontWeight: "700",
                    backgroundColor: "white",
                    borderRadius: getPx(borders.borderRadius8),
                    padding: getPx(padding.sm),
                  }}
                >
                  <AppText variant="smb">
                    {`${
                      getFiatCurrencyObject(x.marker.fiatCurrency)
                        .symbol_native + x.marker.rate
                    } ${x.marker.fiatCurrency.toUpperCase()}/hr.`}
                  </AppText>
                </Box>
              </InfoWindow> */}
          {/* <InfoBox
            // onLoad={onLoadInfoBox}
            options={{
              closeBoxURL: "",
              enableEventPropagation: true,
              // visible: focusMarker?.markerID === x.markerID,
              // // visible: true,
              boxStyle: {
                fontFamily: "Quicksand, sans-serif",
                fontWeight: "700",
                borderRadius: getPx(borders.borderRadius8),
                boxShadow: "0px 0px 7px 1px rgba(0,0,0,0.2)",
              },
              pixelOffset: { height: -100, width: -45 },
            }}
            position={x.marker.location}
          >
            <Box
              sx={{
                borderRadius: getPx(borders.borderRadius8),
                backgroundColor: "white",
                padding: getPx(padding.sm),
                height: "24.55px",
                overflowY: "hidden",
              }}
            >
              <AppText variant="smb" sx={{ paddingX: getPx(padding.sm) }}>
                {`${
                  getFiatCurrencyObject(x.marker.fiatCurrency).symbol_native +
                  x.marker.rate +
                  "\xa0" +
                  x.marker.fiatCurrency.toUpperCase()
                }/hr. `}
              </AppText>
            </Box>
          </InfoBox> */}
        </Marker>

        {/* Users own location */}
        {/* {userLocation && (
          <Marker
            // ref={customerMarkerRef}
            position={formatLoc(userLocation)}
            // description={x.marker.rate}
            identifier="customer"
            key="customer"
            // onClick={() => {
            //   dispatch(setFocusMarkerRedux(undefined));
            //   // setFocusMarkerState(undefined);
            // }}
            title={"You"}
            // // icon={"./assets/icons/you1.png"}
            // icon={"./assets/icons/you1.png"}
            icon={"https://youwhoapp.youwho.io/assets/icons/you1.png"}
          >
            <InfoBox
              // onLoad={onLoadInfoBox}
              options={{
                closeBoxURL: "",
                enableEventPropagation: true,
                boxStyle: {
                  fontFamily: "Quicksand, sans-serif",
                  fontWeight: "700",
                  borderRadius: getPx(borders.borderRadius8),
                  boxShadow: "0px 0px 7px 1px rgba(0,0,0,0.2)",
                },
                pixelOffset: { height: -130, width: -20 },
              }}
              position={formatLoc(userLocation)}
              // anchor={{}}
            >
              <Box
                sx={{
                  borderRadius: getPx(borders.borderRadius8),
                  backgroundColor: "white",
                  padding: getPx(padding.sm),
                  height: "24.55px",
                  overflowY: "hidden",
                }}
              >
                <AppTextTranslate variant="smb">You</AppTextTranslate>
              </Box>
            </InfoBox>
          </Marker>
        )} */}
      </GoogleMap>
    ),
    [center]
  );

  return (
    <>
      {/* <View
        onStartShouldSetResponder={() => true}
        onResponderRelease={onPress}
        style={{
          height,
          width: "100%",
          position: "absolute",
          zIndex: 100,
          // backgroundColor: "red",
          top: 55,
        }}
      /> */}
      <View
        style={getSx([
          { height: getPx(height), width: "100%" },
          styles.container,
        ])}
      >
        {center && mapIsLoaded && googleMap}
      </View>
    </>
  );
};

export default AppCardMap;

const styles = Object.create({
  container: {
    // ...StyleSheet.absoluteFillObject,
    boxShadow: "0px 0px 7px 1px rgba(0,0,0,0.2)",
    borderRadius: getPx(borders.borderRadius12),
    overflow: "hidden",
    flex: 1,
  },
});
