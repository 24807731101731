import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Textarea,
} from "@chakra-ui/react";
import { FiEye, FiEyeOff } from "react-icons/fi";

import borders from "../config/borders";
import colors from "../config/colors";
import { fontConfig as fonts } from "../config/fonts";
import padding from "../config/padding";
import AppText from "./AppText";
import { getPx, getSx } from "../utils/helpers";
import AppButton from "./AppButton";
import { useTranslateText } from "../hooks/useTranslate";

function AppTextInput({
  contentStyle,
  fontSize = fonts.size.h2,
  height = 60,
  Icon,
  iconColor = colors.gray9,
  iconSize = fonts.size.h2,
  inputRef,
  isRequired = true,
  label,
  labelSize,
  labelVariant = "floating",
  leftText,
  maxLength = 500,
  multiline,
  name,
  noPaste,
  onBlurField = () => {},
  onFocusField = () => {},
  pill,
  placeholderTextColor,
  placeholder,
  onChangeTextField = () => {},
  RightComponent,
  rightText,
  secureTextEntry,
  showMaxLength,
  textInputStyle,
  textContentType = "text",
  variant = "default",
  width = "100%",
  defaultValue,
  value,
  ...otherProps
}) {
  const [text, setText] = useState(null);
  const [show, setShow] = useState(false);
  const [focusStyles, setFocusStyles] = useState();

  const translatedLabel = useTranslateText({
    toTranslate: label,
    fromLanguage: "en",
  });
  const translatedPlaceholder = useTranslateText({
    toTranslate: placeholder,
    fromLanguage: "en",
  });

  const activeLabelStyles = {
    transform: "scale(0.85) translateY(-34px)",
  };

  const variants = useMemo(
    () => ({
      default: {
        container: {
          //backgroundColor: "red",
          borderColor: colors.grayD,
          borderWidth: getPx(1),
        },
        focus: { borderColor: colors.black, borderWidth: getPx(2) },
        textInput: {
          color: colors.black,
        },
        placeholderTextColor: { color: colors.gray9 },
      },
      filled: {
        container: {
          backgroundColor: colors.grayD,
          borderColor: colors.grayD,
          borderWidth: 0,
        },
        focus: { borderColor: colors.grayD, borderWidth: 0 },
        textInput: { color: colors.black },
        placeholderTextColor: { color: colors.gray9 },
      },
      clean: {
        container: {
          //backgroundColor: "red",
          borderColor: colors.grayD,
          borderWidth: 0,
        },
        focus: { borderColor: colors.black, borderWidth: 0 },
        textInput: { color: colors.black },
        placeholderTextColor: { color: colors.gray9 },
      },
      cleanFocus: {
        container: {
          //backgroundColor: "red",
          borderColor: colors.grayD,
          borderWidth: getPx(1),
        },
        focus: { borderColor: colors.black, borderWidth: 0 },
        textInput: { color: colors.black },
        placeholderTextColor: { color: colors.gray9 },
      },
    }),
    [label]
  );

  const onFocus = () => {
    onFocusField();
    setFocusStyles(activeLabelStyles);
  };

  const onBlur = () => {
    onBlurField();
    if (!text) {
      setFocusStyles();
    }
  };

  const onChangeText = (e) => {
    const inputText = e.target.value;
    // console.log("onChangeText", inputText);
    onChangeTextField(inputText);
    setText(inputText);
  };

  // useEffect(() => {
  //   if (defaultValue) {
  //     // console.log("defaultValue", defaultValue);
  //     onFocus();
  //     onChangeText(defaultValue);
  //   }
  // }, []);

  // useEffect(() => {
  //   if (value) {
  //     console.log("yesvalue");
  //     onFocus();
  //     onChangeText(value);
  //   } else {
  //     console.log("No value");
  //     onBlur();
  //     onChangeText("");
  //   }
  // }, [value]);

  // if (!noPaste)
  //   useEffect(() => {
  //     if (value) {
  //       // console.log("yesvalue");
  //       onChangeText(value);
  //       onFocus();
  //     } else {
  //       // console.log("No value");
  //       // onBlur();
  //       // onChangeText(null);
  //     }
  //   }, [value]);

  // console.log("value text: " + value + " " + text);

  return (
    // <Box
    //   style={[
    //     styles.container,
    //     {
    //       borderRadius: pill
    //         ? borders.borderRadiusPill
    //         : borders.borderRadius12,
    //       height,
    //       paddingX: icon ? 0 : pill ? padding.primary : padding.ms,
    //       marginY: pill ? 0 : padding.ms,
    //       width,
    //       minHeight: pill ? 50 : 60,
    //       overflow: "hidden",
    //     },
    //     variants[variant].container,
    //     contentStyle,
    //     { ...focusStyles },
    //   ]}
    // >
    <FormControl variant={labelVariant} id={name} isRequired={isRequired}>
      <InputGroup size="lg">
        {leftText && (
          <Box>
            <AppText style={{}}>{leftText}</AppText>
          </Box>
        )}
        {Icon && (
          <InputLeftElement sx={{ top: getPx(padding.sm) }}>
            <Icon
              size={getPx(iconSize ? iconSize : fontSize * 1.4)}
              style={getSx([
                {
                  color: focusStyles
                    ? variants[variant].textInput.color
                    : iconColor,
                },
              ])}
            />
          </InputLeftElement>
        )}

        {multiline ? (
          <Textarea
            defaultValue={defaultValue}
            maxLength={maxLength}
            placeholder={
              labelVariant === "floating" ? " " : translatedPlaceholder
            }
            focusBorderColor={variants[variant].focus.borderColor ?? "black"}
            errorBorderColor="primary"
            onBlur={onBlur}
            // onEndEditing={(e) => console.log("onEndEditing", e.target.value)}
            onChange={onChangeText}
            onFocus={onFocus}
            _placeholder={{
              color: placeholderTextColor
                ? placeholderTextColor
                : variants[variant].placeholderTextColor.color,
            }}
            ref={inputRef}
            // selectionColor={colors.primary}
            // cursorColor={colors.black}
            // underlineColorAndroid={colors.black}
            sx={getSx([
              {
                fontSize: getPx(fontSize),
                height: getPx(height),
                // fontFamily: "Quicksand, sans-serif",
                _focus: variants[variant].focus,
              },
              variants[variant].textInput,
              textInputStyle,
            ])}
            type={
              textContentType === "password"
                ? show
                  ? "text"
                  : textContentType
                : textContentType
            }
            value={value ?? text}
            {...otherProps}
          />
        ) : (
          <Input
            defaultValue={defaultValue}
            maxLength={maxLength}
            placeholder={
              labelVariant === "floating" ? " " : translatedPlaceholder
            }
            focusBorderColor={variants[variant].focus.borderColor ?? "black"}
            errorBorderColor="primary"
            onBlur={onBlur}
            // onEndEditing={(e) => console.log("onEndEditing", e.target.value)}
            onChange={onChangeText}
            onFocus={onFocus}
            _placeholder={{
              color: placeholderTextColor
                ? placeholderTextColor
                : variants[variant].placeholderTextColor.color,
            }}
            ref={inputRef}
            // selectionColor={colors.primary}
            // cursorColor={colors.black}
            // underlineColorAndroid={colors.black}
            sx={getSx([
              {
                fontSize: getPx(fontSize),
                height: getPx(height),
                _focus: variants[variant].focus,
                borderRadius: getPx(borders.borderRadius12),
              },
              variants[variant].textInput,
              textInputStyle,
            ])}
            type={
              textContentType === "password"
                ? show
                  ? "text"
                  : textContentType
                : textContentType
            }
            value={value ?? text}
            {...otherProps}
          />
        )}
        {label && (
          <FormLabel
            sx={getSx([
              styles.label,
              { left: Icon ? getPx(padding.xl) : 0 },
              focusStyles,
            ])}
          >
            {translatedLabel}
          </FormLabel>
        )}

        {(textContentType === "password" || secureTextEntry) && (
          <InputRightElement
            width={getPx(padding.xxxl)}
            sx={{ top: getPx(padding.sm) }}
          >
            <AppButton
              fontSize={fonts.size.md}
              fontColor={
                focusStyles ? variants[variant].textInput.color : iconColor
              }
              variant="none"
              onPress={() => setShow(!show)}
              IconRight={show ? FiEyeOff : FiEye}
            />
          </InputRightElement>
        )}
        {rightText && <AppText>{rightText}</AppText>}
        {RightComponent && (
          <InputRightElement sx={{ top: getPx(padding.sm) }}>
            <RightComponent />
          </InputRightElement>
        )}

        {maxLength && showMaxLength && (
          <Box
            sx={{
              position: "absolute",
              zIndex: 3,
              bottom: getPx(padding.xs),
              right: getPx(padding.sm),
              backgroundColor: colors.white,
              paddingX: getPx(padding.xs),
              borderRadius: getPx(borders.borderRadius4),
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <AppText variant="md" style={{ color: colors.gray7 }}>
              {text?.length ?? 0}/{maxLength}
            </AppText>
          </Box>
        )}
      </InputGroup>
    </FormControl>
  );
}

const styles = Object.create({
  label: {
    top: getPx(padding.sm),
    zIndex: 2,
    position: "absolute",
    backgroundColor: "white",
    pointerEvents: "none",
    mx: 3,
    px: 1,
    my: 3,
    transformOrigin: "left top",
    color: "black50",
  },
});

export default AppTextInput;
