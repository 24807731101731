import React from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import BottomTabLayout from "../screens/BottomTabLayout";
import ListingsSearchResultsScreenBS from "../screens/tabScreens/ListingsSearchResultsScreenBS";
import GuestScreen from "../screens/tabScreens/guestTabScreens/GuestScreen";
import GuestAccountScreen from "../screens/tabScreens/guestTabScreens/GuestAccountScreen";
import FullScreenLayout from "../screens/FullScreenLayout";
import ReportListingScreen from "../screens/ReportListingScreen";
import ListingDetailsScreen from "../screens/ListingDetailsScreen";
// import IframeParent from "../components/modals/testing/IframeParent";
// import IframeChild from "../components/modals/testing/IframeChild";
// import WindowParent from "../components/modals/testing/WindowParent";
// import WindowChild from "../components/modals/testing/WindowChild";
// import WindowParentIframe from "../components/modals/testing/WindowParentIframe";
import AuthCallbackScreen from "../screens/AuthCallbackScreen";
import PublicProfileScreen from "../screens/PublicProfileScreen";

const AuthNavigator = () => {
  return (
    <>
      <Routes>
        <Route element={<MainLayout />}>
          <Route element={<BottomTabLayout />}>
            <Route index element={<Navigate to="/services" />} />
            {/* <Route
            exact
            element={<Navigate to="/services" replace />}
            path="/services/"
          /> */}
            <Route
              // exact
              element={<ListingsSearchResultsScreenBS />}
              path="/services/:listingId?"
            />
            {/* <Route
              element={
                <GuestScreen
                  heading={"Messages"}
                  subtitle={
                    "The web app currently has limited functionality. For full functionality please install the iOS or Android app."
                  }
                  title={"Get the mobile app now"}
                />
              }
              path="/chats"
            /> */}
            <Route
              element={
                <GuestScreen
                  heading={"Messages"}
                  subtitle={
                    "Once you login, you'll find messages from independent service providers here."
                  }
                  title={"Login to read messages"}
                />
              }
              path="/chats"
            />
            {/* <Route element={<GuestChatsScreen />} path="chats" /> */}
            <Route
              element={
                <GuestScreen
                  heading={"Bookings"}
                  subtitle={
                    "View all of your booked services here after you've logged in."
                  }
                  title={"No Bookings yet"}
                />
              }
              path="/bookings"
            />
            <Route
              element={
                <GuestScreen
                  heading={"Wallet"}
                  subtitle={
                    "You can view and manage your Wallet once you've logged in."
                  }
                  title={"Login to view your Wallet"}
                />
              }
              path="/wallet"
            />
            <Route
              element={
                <GuestAccountScreen
                  heading={"guestAccountHeading"}
                  subHeading={"guestAccountSubHeading"}
                />
              }
              path="/account"
            />
          </Route>
          <Route element={<FullScreenLayout />}>
            <Route
              element={<ReportListingScreen heading={"Listing report"} />}
              path="/listing/:listingId/report"
            />
            <Route
              element={<GuestAccountScreen heading={"Listing reviews"} />}
              path="/listing/:listingId/reviews"
            />
            <Route
              element={<ListingDetailsScreen />}
              path="/listing/:listingId"
            />
            <Route
              element={<AuthCallbackScreen platform="apple" />}
              path="/appleAuthCallback/:authData?/*"
            />
            <Route
              element={<AuthCallbackScreen platform="facebook" />}
              path="/facebookAuthCallback/:authData?/*"
            />
            <Route
              element={<AuthCallbackScreen platform="google" />}
              path="/googleAuthCallback/:authData?/*"
            />
            <Route
              element={<AuthCallbackScreen platform="twitter" />}
              path="/twitterAuthCallback/:authData?/*"
            />
            <Route
              element={<PublicProfileScreen />}
              path="/profile/:userPublicId/listing/:listingId"
            />
            <Route
              element={<PublicProfileScreen />}
              path="/profile/:userPublicId"
            />
          </Route>
          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      </Routes>
    </>
  );
};

const MainLayout = () => (
  <>
    <Outlet />
  </>
);

export default AuthNavigator;
