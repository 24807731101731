import React, { useEffect, useState } from "react";
import { motion, useAnimation, useMotionValue } from "framer-motion";
import { FaStar, FaStarHalfAlt } from "react-icons/fa";
import { FaRegStar } from "react-icons/fa6";
import { getPx, getSx } from "../utils/helpers";
import View from "./rnMimic/View";
import { Box, Flex } from "@chakra-ui/react";
import { fontConfig as fonts } from "../config/fonts";

export default function AppStar({
  value = 0,
  sx,
  style,
  starSize = fonts.size.lg,
}) {
  const width = useMotionValue(value * starSize * 0.9);

  return (
    <Flex
      sx={getSx([
        {
          position: "relative",
          width: getPx(starSize),
          height: getPx(starSize),
        },
        sx,
        style,
      ])}
    >
      <motion.div
        // initial={true}
        // animate={{
        //   width: width,
        // }}
        style={{
          position: "absolute",
          width: width,
          overflow: "hidden",
        }}
      >
        <FaStar size={getPx(starSize)} />
      </motion.div>
      <div style={{ position: "absolute" }}>
        <FaRegStar size={getPx(starSize)} />
      </div>
    </Flex>
  );
}
