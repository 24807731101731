import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useYouwho } from "../package/react-youwho";

import {
  setFetchBroadcastsRedux,
  setListingsFoundTotalRedux,
  setListingsResultsRedux,
  setRateDataObjectRedux,
} from "../store/slices/app";
import { setBroadcastsProviderRedux } from "../store/slices/user";
import {
  filterInPlace,
  formatImageArray,
  httpToHttps,
  shuffleArray,
} from "../utils/formatters";
import { setDataBookings, setDataUserMessages } from "./useSubs";
import { store } from "../store/store";
import { processBatchAsync } from "../utils/helpers";
import { setNaviListingsRedux } from "../store/slices/navi";

const TIMEOUT_DELAY = 4000;

export function formatListings(listings) {
  // const { config } = useSelector((state) => state.app);
  const reduxState = store.getState();
  const config = reduxState.app.config;
  const newLists = listings.map((x, i) => {
    const imgArray = formatImageArray(x, config?.maxListingImages ?? 12);

    const certArray = formatImageArray(x, config?.maxCertImages ?? 3, "cert");

    return {
      category: x.attributes?.category,
      certArray,
      certified: x.attributes?.certified,
      dayTime: x.attributes?.dayTime,
      geoPoint: x.attributes?.geoPoint,
      id: x.id,
      identityVerified: x.attributes?.identityVerified,
      imgArray,
      lat: x.attributes?.location.latitude,
      listDetail: x.attributes?.listingDetails,
      listingLocation: x.attributes?.location,
      lng: x.attributes?.location.longitude,
      obj: x,
      json: x.toJSON(),
      paymentMethod: x.attributes?.paymentMethod,
      proBono: x.attributes?.proBono,
      providerId: x.attributes?.provider.id,
      providerPublic: x.attributes?.providerPublic,
      rate: x.attributes?.rate,
      fiatCurrency: x.attributes?.fiatCurrency ?? "usd",
      rating: x.attributes?.rating,
      reviews: x.attributes?.reviews,
      reviewsProviderRelation: x.attributes?.reviewsProviderRelation,
      serviceLocation: x.attributes?.serviceLocation,
      subCategory: x.attributes?.subCategory,
      // subtitle: x.attributes?.job,
      title: x.attributes?.title,
      updatedAt: x.attributes?.updatedAt,
    };
  });

  return newLists;
}

//

const getRheostatData = (
  unfilteredRateArray = [],
  filteredRateArray = [],
  listingsSearchObject
) => {
  /// START data for the price slider rheostat used in filters screen ///

  const calculateAverage = (array) => {
    let total = 0;
    let count = 0;

    array.forEach((item, index) => {
      total += item;
      count++;
    });

    return total / count;
  };

  const minRate = Math.min(...unfilteredRateArray);
  const maxRate = Math.max(...unfilteredRateArray);
  const minValue = Math.min(...filteredRateArray);
  const maxValue = Math.max(...filteredRateArray);
  const avgRate = calculateAverage(unfilteredRateArray);
  const totalListings = unfilteredRateArray.length;
  const totalFilteredListings = filteredRateArray.length;
  const rateInterval = 1;

  const rateAreaSvgDataArray = [];

  for (let i = 0; i < unfilteredRateArray.length; i++) {
    const arrayIndex = Math.floor(unfilteredRateArray[i] / rateInterval);
    if (rateAreaSvgDataArray[arrayIndex] >= 1) {
      rateAreaSvgDataArray[arrayIndex] = rateAreaSvgDataArray[arrayIndex] + 1;
    } else {
      rateAreaSvgDataArray[arrayIndex] = 1;
    }
  }

  const rateAreaSvgDataArraySliced = rateAreaSvgDataArray.slice(
    Math.floor(minRate / rateInterval)
  );
  const rateAreaSvgDataArraySlicedDefined = Array.from(
    rateAreaSvgDataArraySliced,
    (v) => (v === undefined ? 0 : v)
  );

  const snapPoints = Array(rateAreaSvgDataArraySlicedDefined.length)
    .fill()
    .map((_, idx) => minRate + idx * rateInterval);

  if (unfilteredRateArray && unfilteredRateArray.length > 0)
    store.dispatch(
      setRateDataObjectRedux({
        rateInterval,
        areaSvgData: rateAreaSvgDataArraySlicedDefined,
        avgRate,
        minRate,
        maxRate,
        minValue,
        maxValue,
        snapPoints,
        totalListings,
        totalFilteredListings,
        rateArray: unfilteredRateArray,
        filteredRateArray,
      })
    );
  else
    store.dispatch(
      setRateDataObjectRedux({
        rateInterval,
        areaSvgData: [0, 0],
        avgRate: 0,
        minRate: Number(listingsSearchObject.filters.rateLower),
        maxRate: Number(listingsSearchObject.filters.rateUpper),
        minValue: Number(listingsSearchObject.filters.rateLower),
        maxValue: Number(listingsSearchObject.filters.rateUpper),
        snapPoints: [
          Number(listingsSearchObject.filters.rateLower),
          Number(listingsSearchObject.filters.rateUpper),
        ],
        totalListings: 0,
        totalFilteredListings: 0,
        rateArray: unfilteredRateArray,
        filteredRateArray,
      })
    );

  /// END data for the price slider rheostat ///
};

// separate out filter function to only filter the listings array not re query every time

//

export function useQueryListings() {
  const { appStore, userStore, user, dispatch, Parse } = useYouwho();
  // const dispatch = useDispatch();
  const [queryIsLoading, setQueryIsLoading] = useState(false);
  const {
    listingsSearchObject: listingsSearchObjectRedux,
    unfilteredListingsFoundTotal: unfilteredListingsFoundTotalRedux,
    listingsFoundTotal: listingsFoundTotalRedux,
    listingsResults: listingsResultsRedux,
    unfilteredListingsResults: unfilteredListingsResultsRedux,
    rateDataObject: rateDataObjectRedux,
  } = appStore;
  const { selectedLocation } = userStore;

  const buildQuery = (query, listingsSearchObject) => {
    query = new Parse.Query("Listings");
    if (user) query.notEqualTo("provider", user);
    // query.notEqualTo("hidden", true);
    // query.notEqualTo("disabled", true);
    // query.equalTo("provider", !user);
    query.containedIn("toReview", [false, undefined]);
    query.containedIn("hidden", [false, undefined]);
    query.containedIn("disabled", [false, undefined]);
    query.include("listingDetails");

    // search terms

    if (listingsSearchObject?.id && listingsSearchObject?.id !== "")
      query.equalTo("objectId", listingsSearchObject?.id);

    if (listingsSearchObject?.string !== "")
      query.fullText("title", listingsSearchObject?.string);

    if (listingsSearchObject?.category.length === 1)
      query.equalTo("category", listingsSearchObject?.category[0].categoryName);

    if (listingsSearchObject?.category.length > 1)
      query.containedIn(
        "category",
        listingsSearchObject?.category.map((x) => x.categoryName)
      );

    if (
      listingsSearchObject?.serviceLocation === "global" ||
      listingsSearchObject?.serviceLocation === "both"
    )
      query.equalTo("serviceLocation", listingsSearchObject?.serviceLocation);

    if (
      listingsSearchObject?.serviceLocation === "customer" ||
      listingsSearchObject?.serviceLocation === "provider"
    )
      query.containedIn("serviceLocation", [
        listingsSearchObject?.serviceLocation,
        "both",
      ]);

    if (listingsSearchObject?.searchLocation === "near") {
      const location = new Parse.GeoPoint(
        selectedLocation?.latitude,
        selectedLocation?.longitude
      );
      // console.log("location", location);
      const distance = Number(listingsSearchObject?.searchWithinKm);
      const sorted = false;
      // console.log("distance", distance);

      query.withinKilometers("geoPoint", location, distance, sorted);
    }

    if (listingsSearchObject?.searchLocation === "country") {
      if (listingsSearchObject?.country.length === 1)
        query.equalTo("country", listingsSearchObject?.country[0].countryCode);

      if (listingsSearchObject?.country?.length > 1)
        query.containedIn(
          "country",
          listingsSearchObject?.country.map((x) => x.countryCode)
        );
    }

    // filtered out with filter function
    // if (listingsSearchObject?.filters.rateLower !== "") {
    //   query.greaterThanOrEqualTo(
    //     "rate",
    //     Number(listingsSearchObject?.filters.rateLower)
    //   );
    // }

    // if (listingsSearchObject?.filters.rateUpper !== "") {
    //   query.lessThanOrEqualTo(
    //     "rate",
    //     Number(listingsSearchObject?.filters.rateUpper)
    //   );
    // }

    if (listingsSearchObject?.filters.certification === true) {
      query.equalTo("certified", true);
    }

    if (listingsSearchObject?.filters.verifiedUsers === true) {
      query.equalTo("identityVerified", true);
    }

    if (listingsSearchObject?.filters.reviews === "sort least popular first") {
      query.addAscending("reviews");
    }
    if (listingsSearchObject?.filters.reviews === "sort most popular first") {
      query.addDescending("reviews");
    }

    if (listingsSearchObject?.filters.ratingLower !== 0) {
      query.greaterThanOrEqualTo(
        "rating",
        Number(listingsSearchObject?.filters.ratingLower)
      );
    }

    if (listingsSearchObject?.filters.ratingUpper !== 5) {
      query.lessThanOrEqualTo(
        "rating",
        Number(listingsSearchObject?.filters.ratingUpper)
      );
    }

    if (listingsSearchObject?.filters.age === "sort least active first") {
      query.addAscending("updatedAt");
    }
    if (listingsSearchObject?.filters.age === "sort most active first") {
      query.addDescending("updatedAt");
    }

    return query;
  };

  const queryListings = async (
    listingsSearchObject = listingsSearchObjectRedux,
    firstQuery
  ) => {
    console.log("queryListings listingsSearchObject", listingsSearchObject);
    setQueryIsLoading(true);
    try {
      let query;
      query = buildQuery(query, listingsSearchObject);

      let listingsFoundTotal = await query.count();
      if (listingsFoundTotal >= listingsSearchObject?.maxResults)
        listingsFoundTotal = listingsSearchObject?.maxResults;

      dispatch(setListingsFoundTotalRedux(listingsFoundTotal));
      console.log("total query count: " + listingsFoundTotal);

      query.limit(listingsSearchObject?.limit);
      const result = await query.find();

      // let resultNoUserListings = [];
      // if (user)
      //   resultNoUserListings = result?.filter(
      //     (x) => x.get("provider")?.id != user?.id
      //   );
      // const newLists = formatListings(user ? resultNoUserListings : result);
      const newLists = formatListings(result);

      filterListings(
        dispatch,
        listingsSearchObject,
        newLists,
        listingsFoundTotal,
        true,
        firstQuery
      );

      console.log("success queryListings", newLists.length);
    } catch (error) {
      console.log("error queryListings:", error);
    } finally {
      setTimeout(() => setQueryIsLoading(false), TIMEOUT_DELAY);
    }
  };

  const queryNextListings = async () => {
    console.log(
      "queryNextListings listingsSearchObject",
      listingsSearchObjectRedux
    );

    setQueryIsLoading(true);

    try {
      let query;
      query = buildQuery(query, listingsSearchObjectRedux);
      // const query = buildQuery(listingsSearchObjectRedux);
      query.skip(unfilteredListingsFoundTotalRedux);
      query.limit(listingsSearchObjectRedux?.limit);
      const result = await query.find();
      const newLists = formatListings(result);
      const filteredListingsObject = filterListings(
        dispatch,
        listingsSearchObjectRedux,
        newLists,
        listingsFoundTotalRedux,
        false
      );
      const {
        filtered: currentFiltered,
        unfiltered: currentUnfiltered,
        filteredRateArray: currentFilteredRateArray,
        unfilteredRateArray: currentUnfilteredRateArray,
      } = filteredListingsObject;

      const newFiltered = [...listingsResultsRedux, ...currentFiltered];
      const newUnfiltered = [
        ...unfilteredListingsResultsRedux,
        ...currentUnfiltered,
      ];
      const newFilteredRateArray = [
        ...rateDataObjectRedux.filteredRateArray,
        ...currentFilteredRateArray,
      ];
      const newUnfilteredRateArray = [
        ...rateDataObjectRedux.rateArray,
        ...currentUnfilteredRateArray,
      ];

      const newListingsObject = {
        filtered: newFiltered,
        unfiltered: newUnfiltered,
        filteredRateArray: newFilteredRateArray,
        unfilteredRateArray: newUnfilteredRateArray,
        listingsFoundTotal: listingsFoundTotalRedux,
      };

      dispatch(setListingsResultsRedux(newListingsObject));
      // rheostat data
      getRheostatData(
        newListingsObject.unfilteredRateArray,
        newListingsObject.filteredRateArray,
        listingsSearchObjectRedux
      );
      console.log("success queryNextListings", newUnfiltered.length);
    } catch (error) {
      console.log("error queryNextListings:", error);
    } finally {
      setTimeout(() => setQueryIsLoading(false), TIMEOUT_DELAY);
    }
  };
  // await processBatchAsync(queryUserWallets, calculateTotalYou);

  return {
    queryListings,
    queryNextListings,
    queryIsLoading,
  };
}

// filter listings

export function filterListings(
  dispatch,
  listingsSearchObject,
  listingsInput = [],
  listingsFoundTotalInput,
  handleDispatch = true,
  firstQuery
) {
  // console.log("filterListings listingsSearchObject", listingsSearchObject);
  const reduxState = store.getState();
  const listingsFoundTotal =
    listingsFoundTotalInput ?? reduxState.app.listingsFoundTotal;

  try {
    let listings = [];
    if (firstQuery) listings = shuffleArray(listingsInput);
    else listings = listingsInput;
    let filtered = [...listings];
    let unfiltered = [...listings];
    let filteredRateArray = [];
    let unfilteredRateArray = [];

    // filters

    if (listingsSearchObject?.filters?.rateLower !== "") {
      // query.greaterThanOrEqualTo(
      //   "rate",
      //   Number(listingsSearchObject?.filters?.rateLower)
      // );
      console.log("sorted Rate lower");
      let condition = (x) =>
        Number(x.rate) >= Number(listingsSearchObject?.filters?.rateLower);

      // filtered.splice(
      //   0,
      //   filtered.length,
      //   ...filtered.filter(condition)
      // );

      filterInPlace(filtered, condition);
    }

    if (listingsSearchObject?.filters?.rateUpper !== "") {
      // query.lessThanOrEqualTo(
      //   "rate",
      //   Number(listingsSearchObject?.filters?.rateUpper)
      // );
      console.log("sorted Rate upper");
      let condition = (x) =>
        Number(x.rate) <= Number(listingsSearchObject?.filters?.rateUpper);

      // filtered.splice(
      //   0,
      //   filtered.length,
      //   ...filtered.filter(
      //     (x) =>
      //       Number(x.rate) <= Number(listingsSearchObject?.filters?.rateUpper)
      //   )
      // );

      filterInPlace(filtered, condition);
    }

    // if (listingsSearchObject?.filters?.certification) {
    //   // query.equalTo("certified", true);
    //   filtered.splice(
    //     0,
    //     filtered.length,
    //     ...filtered.filter((x) => x.certified === true)
    //   );
    //   console.log("sorted cert");
    // }

    // if (listingsSearchObject?.filters?.verifiedUsers) {
    //   // query.equalTo("identityVerified", true);
    //   filtered.splice(
    //     0,
    //     filtered.length,
    //     ...filtered.filter((x) => x.identityVerified === true)
    //   );
    //   console.log("sorted verified");
    // }

    // if (listingsSearchObject?.filters?.reviews === "sort least popular first") {
    //   // query.ascending("reviews");
    //   filtered.sort(function (a, b) {
    //     return Number(a.reviews ?? 0) - Number(b.reviews ?? 0);
    //   });
    //   console.log("sort least popular first");
    // } else if (
    //   listingsSearchObject?.filters?.reviews === "sort most popular first"
    // ) {
    //   // query.descending("reviews");
    //   filtered.sort(function (a, b) {
    //     return Number(b.reviews ?? 0) - Number(a.reviews ?? 0);
    //   });
    //   console.log("sort most popular first");
    // }

    // if (
    //   listingsSearchObject?.filters?.ratingLower >= 0 &&
    //   listingsSearchObject?.filters?.ratingLower < 5
    // ) {
    //   // query.greaterThanOrEqualTo(
    //   //   "rating",
    //   //   Number(listingsSearchObject?.filters?.ratingLower)
    //   // );
    //   filtered.splice(
    //     0,
    //     filtered.length,
    //     ...filtered.filter(
    //       (x) =>
    //         Number(x.rating ?? 0) >=
    //         Number(listingsSearchObject?.filters?.ratingLower)
    //     )
    //   );
    //   console.log("sort rating lower");
    // }

    // if (
    //   listingsSearchObject?.filters?.ratingUpper > 0 &&
    //   listingsSearchObject?.filters?.ratingUpper <= 5
    // ) {
    //   // query.lessThanOrEqualTo(
    //   //   "rating",
    //   //   Number(listingsSearchObject?.filters?.ratingUpper)
    //   // );
    //   filtered.splice(
    //     0,
    //     filtered.length,
    //     ...filtered.filter(
    //       (x) =>
    //         Number(x.rating ?? 0) <=
    //         Number(listingsSearchObject?.filters?.ratingUpper)
    //     )
    //   );
    //   console.log("sort rating upper");
    // }

    // if (listingsSearchObject?.filters?.age === "sort least active first") {
    //   // query.ascending("updatedAt");
    //   filtered.splice(
    //     0,
    //     filtered.length,
    //     ...filtered.sort(function (a, b) {
    //       return a.updatedAt.getTime() - b.updatedAt.getTime();
    //     })
    //   );
    //   console.log("sort least active first");
    // } else if (
    //   listingsSearchObject?.filters?.age === "sort most active first"
    // ) {
    //   // query.descending("updatedAt");
    //   filtered.splice(
    //     0,
    //     filtered.length,
    //     ...filtered.sort(function (a, b) {
    //       return b.updatedAt.getTime() - a.updatedAt.getTime();
    //     })
    //   );
    //   console.log("sort most active first");
    // }

    filtered.forEach((x) => filteredRateArray.push(Number(x.rate)));
    unfiltered.forEach((x) => unfilteredRateArray.push(Number(x.rate)));

    const listingsObject = {
      filtered,
      unfiltered,
      filteredRateArray,
      unfilteredRateArray,
      listingsFoundTotal,
    };

    if (handleDispatch) {
      dispatch(setListingsResultsRedux(listingsObject));
      // rheostat data
      getRheostatData(
        unfilteredRateArray,
        filteredRateArray,
        listingsSearchObject
      );
    }

    // console.log("success filterListings", filteredRateArray.length);

    // console.log(
    //   "listingId: " +
    //     Array.from(filtered, (x) => x.updatedAt.getTime() + " " + x.id)
    // );

    return listingsObject;
  } catch (error) {
    console.error("error filterListings:", error);
  }
}

//

export function useQueryListingsUserProfile() {
  const { Parse, dispatch } = useYouwho();
  const { config } = useSelector((state) => state.app);
  const [listingsUserProfileResults, setListingsUserProfileResults] = useState(
    []
  );
  const [listingsUserProfileLoading, setListingsUserProfileLoading] =
    useState(false);

  const queryListsingsUserProfile = async (userProfile) => {
    // console.log("userProfile", userProfile)
    // console.log("start queryListsingsUserProfile");
    try {
      setListingsUserProfileLoading(true);
      const query = new Parse.Query("Listings");
      query.equalTo("provider", userProfile);
      query.include("providerPublic", "listingDetails");
      query.notEqualTo("toReview", true);
      query.notEqualTo("hidden", true);
      query.notEqualTo("disabled", true);
      query.descending("updatedAt");
      const result = await query.find();

      const newListingsUserProfile = result.map((x, i) => {
        const imgArray = formatImageArray(x, config?.maxListingImages ?? 12);

        const certArray = formatImageArray(
          x,
          config?.maxCertImages ?? 3,
          "cert"
        );

        // console.log("useGetData geoPoint", x.attributes?.geoPoint);

        return {
          category: x.attributes?.category,
          certArray,
          dayTime: x.attributes?.dayTime,
          geoPoint: x.attributes?.geoPoint,
          id: x.id,
          imgArray,
          lat: x.attributes?.location.latitude,
          listDetail: x.attributes?.listingDetails,
          listingLocation: x.attributes?.location,
          lng: x.attributes?.location.longitude,
          obj: x,
          json: x.toJSON(),
          providerId: x.attributes?.provider.id,
          providerPublic: x.attributes?.providerPublic,
          rate: x.attributes?.rate,
          fiatCurrency: x.attributes?.fiatCurrency ?? "usd",
          rating: x.attributes?.rating,
          reviews: x.attributes?.reviews,
          reviewsProviderRelation: x.attributes?.reviewsProviderRelation,
          serviceLocation: x.attributes?.serviceLocation,
          subCategory: x.attributes?.subCategory,
          // subtitle: x.attributes?.job,
          title: x.attributes?.title,
        };
      });

      // setListingsUserProfileResults(result);
      dispatch(setNaviListingsRedux(newListingsUserProfile));
      setListingsUserProfileResults(newListingsUserProfile);
    } catch (error) {
      console.log("error queryListsingsUserProfile:", error);
    } finally {
      setListingsUserProfileLoading(false);
    }
  };
  return {
    listingsUserProfileResults,
    queryListsingsUserProfile,
    listingsUserProfileLoading,
  };
}

export function useQueryBroadcasts() {
  const { Parse, user, dispatch } = useYouwho();

  const [broadcastIsLoading, setBroadcastIsLoading] = useState(false);
  // const [broadcastResults, setBroadcastResults] = useState([]);

  const queryBroadcasts = async () => {
    // console.log("userProfile", userProfile)
    // console.log("start queryListsingsUserProfile");
    try {
      setBroadcastIsLoading(true);
      const query = new Parse.Query("Broadcasts");
      query.equalTo("userIds", user.id);
      query.descending("updatedAt");
      const results = await query.find();

      const broadcastsArray = results?.map((x) => ({
        ...x.attributes,
        id: x.id,
      }));

      // console.log("broadcastsArray: " + JSON.stringify(broadcastsArray));

      // setListingsUserProfileResults(result);
      // setBroadcastResults(broadcastsArray);
      dispatch(setBroadcastsProviderRedux(broadcastsArray));
    } catch (error) {
      console.log("error queryBroadcasts:", error);
    } finally {
      setBroadcastIsLoading(false);
    }
  };

  useEffect(() => {
    queryBroadcasts();
    dispatch(setFetchBroadcastsRedux(queryBroadcasts));
    //
    // const subscription = subscribeToListings();
    // console.log("^^^subbed useSubListings");
    // return () => {
    //   try {
    //     if (subscription) {
    //       subscription.unsubscribe();
    //       console.log("***UNsubbed useSubListings");
    //     }
    //   } catch (error) {
    //     console.log("useSubListings", error);
    //   }
    // };
  }, []);

  return {
    // broadcastResults,
    queryBroadcasts,
    broadcastIsLoading,
  };
}

export function useQueryBookings(isCustomer = false, bookingId) {
  const { Parse, user } = useYouwho();

  const [bookingsIsLoading, setBookingsIsLoading] = useState(false);
  const [bookingsResults, setBookingsResults] = useState([]);

  const queryBookings = async () => {
    // console.log("userProfile", userProfile)
    // console.log("start queryListsingsUserProfile");
    try {
      setBookingsIsLoading(true);
      const query = new Parse.Query("Bookings");
      query.equalTo(isCustomer ? "customer" : "provider", user);
      if (bookingId) query.equalTo("objectId", bookingId);
      const results = await query.find();

      const bookingsMessages = setDataBookings(results, user, isCustomer);
      setBookingsResults(bookingsMessages);
      return bookingsMessages;
    } catch (error) {
      console.log("error useQueryBookings:", error);
    } finally {
      setBookingsIsLoading(false);
    }
  };

  return {
    bookingsResults,
    queryBookings,
    bookingsIsLoading,
  };
}

export function useQueryBooking(isCustomer = false, bookingId) {
  const { Parse, user } = useYouwho();

  const queryBooking = async () => {
    // console.log("userProfile", userProfile)
    // console.log("start queryListsingsUserProfile");
    try {
      const query = new Parse.Query("Bookings");
      if (bookingId) query.equalTo("objectId", bookingId);
      const results = await query.find();

      return setDataBookings(results, user, isCustomer);
    } catch (error) {
      console.log("error useQueryBooking:", error);
    }
  };

  return {
    queryBooking,
  };
}

export function useQueryObject() {
  const { Parse } = useYouwho();

  const getPointerFromId = async (id, className, include) => {
    try {
      const query = new Parse.Query(className);
      query.equalTo("objectId", id);
      if (include) query.include(include);
      return query.first();
    } catch (error) {
      console.log("getPointerFromId error: " + error.message);
    }
  };

  const getPointerFromValue = async (className, colName, value, include) => {
    try {
      const query = new Parse.Query(className);
      query.equalTo(colName, value);
      if (include) query.include(include);
      query.descending("createdAt");
      return query.first();
    } catch (error) {
      console.log("getPointerFromValue error: " + error.message);
    }
  };

  return {
    getPointerFromId,
    getPointerFromValue,
  };
}

export function useQueryUserMessages(isCustomer = false, userMessagesId) {
  const { Parse, user } = useYouwho();

  const [userMessagesIsLoading, setUserMessagesIsLoading] = useState(false);
  const [userMessagesResults, setUserMessagesResults] = useState([]);

  const queryUserMessages = async () => {
    // console.log("userProfile", userProfile)
    // console.log("start queryListsingsUserProfile");
    try {
      setUserMessagesIsLoading(true);
      const query = new Parse.Query("UserMessages");
      query.equalTo("objectId", userMessagesId);
      const results = await query.find();

      const userMessages = setDataUserMessages(results, user, isCustomer);
      setUserMessagesResults(userMessages);
      return userMessages;
    } catch (error) {
      console.log("error useQueryUserMessages:", error);
    } finally {
      setUserMessagesIsLoading(false);
    }
  };

  return {
    userMessagesResults,
    queryUserMessages,
    userMessagesIsLoading,
  };
}
