import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, RouterProvider } from "react-router-dom";
import { ChakraProvider, CSSReset } from "@chakra-ui/react";
import Parse from "parse";
// import { Drawer } from "vaul";
// redux
import { store, persistor } from "./store/store";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
// youwho
import { YouwhoProvider } from "./package/react-youwho";
//Helmet
import { Helmet, HelmetProvider } from "react-helmet-async";
//
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Fonts from "./config/fonts";
import { theme } from "./config/theme";
import "yet-another-react-lightbox/styles.css";
import "react-spring-bottom-sheet/dist/style.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "react-horizontal-scrolling-menu/dist/styles.css";
import "./index.css";
// import router from "./navigation/Router";

// const appId = "youwho-test";
// const appId = "youwho-main";
const appId = process.env.REACT_APP_YOUWHO_APPLICATION_ID;
// const serverUrl = "https://d812-171-103-62-202.ngrok-free.app/server";
// const serverUrl = "https://youwho-test.herokuapp.com/server";
// const serverUrl = "https://testserver5.youwho.io/server";
// const serverUrl = "https://server3.youwho.io/server";
// const serverUrl = "http://192.168.200.32:1339/server";
// const serverUrl = "http://192.168.200.200:1337/server";
// const serverUrl = "http://192.168.1.45:1337/server";
// const serverUrl = "http://192.168.56.1:1337/server";
const serverUrl =
  process.env.REACT_APP_YOUWHO_SERVER_URL +
  process.env.REACT_APP_YOUWHO_SERVER_ENDPOINT;

console.log("Provider appId", appId);
console.log("Provider serverUrl", serverUrl);
// console.log("Provider jsKey", jsKey);

if (process.env.NODE_ENV === "production") {
  console.log("#!#!#!#!#!# LIVE MODE #!#!#!#!#!#");
} else {
  console.log("%%%%%%%%%% DEBUG MODE %%%%%%%%%%");
}

Parse.initialize(appId); // jsKey
Parse.serverURL = serverUrl;
Parse.enableLocalDatastore();
Parse.User.enableUnsafeCurrentUser();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <PersistGate persistor={persistor}>
        <ChakraProvider theme={theme}>
          <CSSReset />
          <Fonts />
          <BrowserRouter>
            <YouwhoProvider>
              <HelmetProvider>
                {/* <RouterProvider router={router} /> */}
                <App />
              </HelmetProvider>
            </YouwhoProvider>
          </BrowserRouter>
        </ChakraProvider>
      </PersistGate>
    </ReduxProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

