import {
  useCallback,
  useEffect,
  useState,
  useRef,
  createRef,
  useMemo,
} from "react";
import {
  Box,
  Center,
  Container,
  Flex,
  HStack,
  Stack,
  StackDivider,
  Text,
  VStack,
} from "@chakra-ui/react";
import axios from "axios";
import scrollIntoView from "smooth-scroll-into-view-if-needed";
import { useTransform, motion, useMotionValue } from "framer-motion";
import { BottomSheet } from "react-spring-bottom-sheet";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import {
  GoogleMap,
  // InfoBox,
  // Marker,
  // MarkerClusterer,
  MarkerF as Marker,
  MarkerClustererF as MarkerClusterer,
  InfoBoxF as InfoBox,
  // InfoWindowF as InfoWindow,
} from "@react-google-maps/api";
import { FaPlus, FaMinus } from "react-icons/fa6";
import { FiList, FiMap } from "react-icons/fi";
import { IoNavigate } from "react-icons/io5";
import { useYouwho } from "../../package/react-youwho";
import BottomTabLayout from "../../components/layouts/BottomTabs";
import configLocal from "../../config/configLocal";
import TopBar from "../../components/layouts/TopBar";
import { useToast } from "../../hooks/useToast";
import AppViewLayout from "../../components/AppViewLayout";
import AppButton from "../../components/AppButton";
import AppListingsCard from "../../components/AppListingsCard";
import {
  awaitDuration,
  fetchGeocodeApiUrl,
  getCountryFromGeocodeApi,
  getFiatCurrencyObject,
  getPx,
  getSx,
} from "../../utils/helpers";
import { useQueryListings } from "../../hooks/useQuery";
import colors from "../../config/colors";
import padding from "../../config/padding";
import { fontConfig as fonts } from "../../config/fonts";
import AppTextTranslate from "../../components/AppTextTranslate";
import SEO from "../../components/SEO";
import borders from "../../config/borders";
import ListItemSeparator from "../../components/lists/ListItemSeparator";
import {
  setFitToMapMarkersRedux,
  setFocusMarkerRedux,
} from "../../store/slices/app";
import AppText from "../../components/AppText";
import { useTranslateText } from "../../hooks/useTranslate";
import { useGetLocation } from "../../hooks/useLocation";
import {
  setSelectedLocationRedux,
  setUserLocationRedux,
} from "../../store/slices/user";
import AppListingsCardXs from "../../components/AppListingsCardXs";
import { useParams } from "react-router-dom";
import AppLoadingAnimation from "../../components/AppLoadingAnimation";
import { formatLoc } from "../../utils/formatters";
import ListingDetailsScreen from "../ListingDetailsScreen";
import SplashScreen from "../SplashScreen";
import { useContainerDimensions } from "../../hooks/useDimensions";

const TIMEOUT_DELAY = 0;
const CLOSED_TAB_HEIGHT = padding.bottomBar80;

export default function ListingsSearchResultsScreenBS() {
  const { listingId } = useParams();
  const {
    login,
    logout,
    navigation,
    handleError,
    dispatch,
    user,
    setUserFromSession,
    mapIsLoaded,
    appStore,
    urlQuery,
    userStore,
  } = useYouwho();
  const [initialLoading, setInitialLoading] = useState(true);
  const [expandOnContentDrag, setExpandOnContentDrag] = useState(false);
  const [bsLoaded, setBsLoaded] = useState(false);
  const { queryListings, queryNextListings, queryIsLoading } =
    useQueryListings();
  const getLocation = useGetLocation();
  const [map, setMap] = useState(null);

  const {
    fitToMapMarkers,
    listingsResults,
    focusMarker,
    listingsSearchObject,
    listingsFoundTotal,
    unfilteredListingsFoundTotal,
  } = appStore;
  const { userLocation, gotLocationOnce, fiatCurrencyObject } = userStore;

  // console.log("listingsFoundTotal: ", listingsFoundTotal);
  // console.log("focusTarget: ", urlQuery.get("focusTarget"));
  // console.log("listingId: ", listingId);
  // console.log("queryIsLoading: ", queryIsLoading);

  // const item = listingsResults[0] ?? {};

  const [markers, setMarkers] = useState();
  const [markersIDArray, setMarkersIDArray] = useState();
  const [focusMap, setFocusMap] = useState(true);
  const [focusTarget, setFocusTarget] = useState(urlQuery.get("focusTarget"));
  const [focusMarkerState, setFocusMarkerState] = useState(undefined);
  const [endReached, setEndReached] = useState(false);
  const [loadingNextListings, setLoadingNextListings] = useState(true);
  const [locationIsLoading, setLocationIsLoading] = useState(false);
  const [totalServicesFound, setTotalServicesFound] = useState(
    listingsResults?.length ?? 0
  );
  const [showMarkers, setShowMarkers] = useState(true);
  const [showListing, setShowListing] = useState(false);

  const [center, setCenter] = useState(formatLoc(configLocal.selectedLocation));

  const focusRef = useRef();
  const listingsRef = useRef();
  const listingRef = useRef();
  const listingImageRef = useRef();
  // const flatlistRef = useRef();
  const mapRef = useRef();
  const customerMarkerRef = useRef();
  const markerRefs = useRef([]);

  const { height: containerHeight, width: containerWidth } =
    useContainerDimensions(mapRef);

  const Toast = useToast();

  const scrollY = useMotionValue(0);
  const opacity = useTransform(scrollY, [0, 10], [0, 1]);

  const scrollYListing = useMotionValue(0);

  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    // console.log("scrollTop: ", scrollTop);
    // console.log("clientHeight: ", clientHeight);
    // console.log("scrollHeight: ", scrollHeight);
    // console.log("scrollHeight-scrollTop: ", scrollHeight - scrollTop);
    // console.log("start load: ", clientHeight * 2 > scrollHeight - scrollTop);
    scrollY.set(scrollTop);
    if (clientHeight * 2 > scrollHeight - scrollTop) {
      // console.log("start load: ", clientHeight * 2 > scrollHeight - scrollTop);
    }
  };
  const handleScrollListing = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    // console.log("handleScrollListing scrollTop: ", scrollTop);
    // console.log(
    //   "handleScrollListing typeof scrollYListing: ",
    //   typeof scrollYListing
    // );
    // console.log("clientHeight: ", clientHeight);
    // console.log("scrollHeight: ", scrollHeight);
    // console.log("scrollHeight-scrollTop: ", scrollHeight - scrollTop);
    // console.log("start load: ", clientHeight * 2 > scrollHeight - scrollTop);
    scrollYListing.set(scrollTop);
    // if (clientHeight * 2 > scrollHeight - scrollTop) {
    // console.log("start load: ", clientHeight * 2 > scrollHeight - scrollTop);
    // }
  };

  useEffect(() => {
    if (user && user.isDataAvailable()) {
      // console.log(user.attributes.userPublic.attributes.avatar.attributes);

      // console.log(user.get("location")[0]?.latitude);
      // console.log(user.get("location")[0]?.longitude);

      if (user.get("location"))
        setCenter(
          formatLoc(user.get("location")[0])
          //   {
          //   lat: user.get("location")[0]?.latitude,
          //   lng: user.get("location")[0]?.longitude,
          // }
        );
    } else {
      // setUserFromSession();
    }

    if (listingsFoundTotal === 0) queryListings(listingsSearchObject, true);
  }, [user]);

  const onLoad = useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(formatLoc(userLocation));
    console.log("onLoad map?.fitBounds(bounds);");
    map?.fitBounds(bounds);

    setMap(map);
  }, []);

  // const onLoadInfoBox = (infoBox) => {
  //   console.log("infoBox: ", infoBox);
  // };

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  const zoomIn = () => {
    console.log("zoomIn map?.setZoom(map?.getZoom() + 1);");
    map?.setZoom(map?.getZoom() + 1);
    // mapRef.current?.setZoom(mapRef.current?.getZoom() + 1);
  };
  const zoomOut = () => {
    console.log("zoomOut map?.setZoom(map?.getZoom() - 1);");
    map?.setZoom(map?.getZoom() - 1);
    // mapRef.current?.setZoom(mapRef.current?.getZoom() - 1);
  };

  const getNextListings = async () => {
    try {
      console.log(
        ">>> start getNextListings",
        unfilteredListingsFoundTotal,
        listingsFoundTotal,
        unfilteredListingsFoundTotal === listingsFoundTotal
      );
      setLoadingNextListings(true);
      if (unfilteredListingsFoundTotal === listingsFoundTotal)
        setEndReached(true);
      else await queryNextListings();
    } catch (error) {
      console.log("getNextListings error: " + error.message);
    } finally {
      setTimeout(() => setLoadingNextListings(false), 0);
    }
  };

  const handlePressShowMap = (value) => {
    if (value) {
      listingsRef.current?.snapTo(({ snapPoints }) => Math.min(...snapPoints));
      listingRef.current?.snapTo(({ snapPoints }) => Math.min(...snapPoints));
      setShowListing(false);
    } else {
      listingsRef.current?.snapTo(({ maxHeight }) => maxHeight);
    }
    // setOffsetY.value = 0;
    setFocusMap(value);
  };

  useEffect(() => {
    setLocationIsLoading(true);
    if (listingsResults && listingsResults.length > 0) {
      const markersArray = [];
      const markerIDs = [];
      for (let i = 0; i < listingsResults.length; i += 1) {
        // if (listingsResults[i].lat !== undefined) {
        markerRefs.current[i] = createRef();
        const markerObj = {};
        markerObj.markerID = listingsResults[i].id;
        markerObj.marker = {
          location: {
            lat: listingsResults[i].lat,
            lng: listingsResults[i].lng,
          },
          rate: String(listingsResults[i].rate),
          fiatCurrency: listingsResults[i].fiatCurrency ?? "usd",
          obj: listingsResults[i],
        };
        markerObj.markerRef = markerRefs.current[i];
        markerIDs.push(markerObj.markerID);
        markersArray.push(markerObj);
        // }
      }
      setMarkers(markersArray);
      setMarkersIDArray(markerIDs);
      // setTimeout(() => listingsRef.current?.snapToIndex(1), TIMEOUT_DELAY);
      if (fitToMapMarkers) {
        // setTimeout(
        //   () =>
        //     mapRef.current?.fitToSuppliedMarkers(markerIDs, {
        //       edgePadding,
        //     }),
        //   TIMEOUT_DELAY
        // );
      }
      dispatch(setFocusMarkerRedux(undefined));
      setTotalServicesFound(listingsResults.length);
    } else {
      setTimeout(() => handlePressShowMap(true), TIMEOUT_DELAY);
      // if (fitToMapMarkers) {
      //   mapRef.current?.fitToSuppliedMarkers(["customerMarker"], {
      //     edgePadding,
      //   });
      // }
      setMarkers([]);
      setMarkersIDArray([]);
      dispatch(setFocusMarkerRedux(undefined));
      setTotalServicesFound(0);
    }
    // setOffsetY.value = 0;

    dispatch(setFitToMapMarkersRedux(false));
    // setTimeout(() => setLocationIsLoading(false), 4000);
    setLocationIsLoading(false);
  }, [listingsResults, fitToMapMarkers]);

  useEffect(() => {
    if (userLocation && map) {
      if (initialLoading) {
        setInitialLoading(false);
      } else {
        console.log(
          "if (userLocation && map) { map?.panTo(formatLoc(userLocation));"
        );
        setTimeout(() => {
          try {
            map?.panTo(formatLoc(userLocation)); // Pan map to that position
          } catch (err) {}
        }, 1000); // Pan map to that position
        // setTimeout(() => map?.setZoom(4), 1200);
        // mapRef.current?.panTo(formatLoc(userLocation));
      }
    }
  }, [userLocation, map]);

  const handlePressMap = async (e) => {
    const event = {
      coordinate: { latitude: e.latLng.lat(), longitude: e.latLng.lng() },
    };
    console.log(
      "handlePressMap event",
      fetchGeocodeApiUrl(event.coordinate.latitude, event.coordinate.longitude)
    );
    try {
      const { data } = await axios({
        method: "get",
        url: fetchGeocodeApiUrl(
          event.coordinate.latitude,
          event.coordinate.longitude
        ),
      });

      console.log("data: " + JSON.stringify(data.results[0]));

      // setLocation({
      //   ...event.coordinate,
      //   name: event.name,
      //   address: data.results[0].formatted_address,
      //   country: getCountryFromGeocodeApi(data.results[0].address_components),
      // });
      const location = {
        ...event.coordinate,
        name: undefined,
        address: data.results[0].formatted_address,
        country: getCountryFromGeocodeApi(data.results[0].address_components),
      };
      dispatch(setSelectedLocationRedux(location));
      dispatch(setUserLocationRedux(location));
    } catch (error) {
      console.log("handlePressMap error: " + error.message);
      // setLocation({
      //   ...event.coordinate,
      //   name: event.name,
      //   country: undefined,
      // });
      const location = {
        ...event.coordinate,
        name: undefined,
        country: undefined,
      };
      dispatch(setSelectedLocationRedux(location));
      dispatch(setUserLocationRedux(location));
    } finally {
    }
  };

  useEffect(() => {
    if (map && markers && Array.isArray(markers) && markers.length > 0) {
      if (focusMarker?.id) {
        let marker = markers.find((x) => focusMarker.id === x.markerID);
        if (marker) {
          setFocusMarkerState(marker);
          handlePressXsCard(marker, false, false);
        }
      } else if (focusTarget) {
        console.log("focusTarget: ", focusTarget);
        let marker = markers.find((x) => focusTarget === x.markerID);
        if (marker) {
          setFocusMarkerState(marker);
          handlePressXsCard(marker, false);
        }
      } else {
        setFocusMarkerState(undefined);
        // const bounds = new window.google.maps.LatLngBounds(
        //   formatLoc(userLocation)
        // );
        // map?.fitBounds(bounds);
        // console.log("useEffect(() => { map?.panTo(formatLoc(userLocation));");
        // setTimeout(() => map?.panTo(formatLoc(userLocation)), 1200); // Pan map to that position
        // setTimeout(() => map?.setZoom(14), 1400);
      }
    }
  }, [focusMarker?.id, focusTarget, markers, map]);

  // useEffect(() => {
  //   if (!focusMarker) {
  //     navigation("/services/", { replace: true });
  //   }
  // }, [focusMarker]);

  const handlePressXsCard = (markerInput, navigate = true, zoom = true) => {
    const marker = markerInput ?? focusMarkerState;
    // console.log("marker", marker.markerID);
    if (marker) {
      // mapRef.current?.fitToSuppliedMarkers([focusMarker.id], { edgePadding });
      // navigation.navigate(
      //   user ? routes.MAIN_LISTINGS_NAV.n : routes.GUEST_LISTINGS_NAV.n,
      //   {
      //     screen: routes.LISTING_DETAILS_SCREEN.n,
      //     params: { item: focusMarker },
      //   }
      // );
      // const bounds = new window.google.maps.LatLngBounds(
      //   marker.marker.location
      // );
      // map?.fitBounds(bounds);
      console.log("handlePressXsCard map?.panTo(marker.marker.location);");
      try {
        map?.panTo(marker.marker.location); // Pan map to that position
      } catch (err) {
        console.log("error: ", err);
      }
      if (zoom) setTimeout(() => map?.setZoom(14), 300);

      if (navigate) {
        navigation("/services/" + marker.markerID);
        setShowListing(true);
      }

      setTimeout(
        () =>
          listingsRef.current?.snapTo(({ snapPoints }) =>
            Math.min(...snapPoints)
          ),
        600
      );

      // map?.panTo(marker.marker.location);
      // handleError(marker.marker.obj.title);
      // dispatch(setFocusMarkerRedux(undefined));
    }
  };

  useEffect(() => {
    setTimeout(() => {
      // console.log("started listingId: ", listingId);
      if (listingId) {
        // console.log("exists listingId 1: ", listingId);
        let marker;
        if (Array.isArray(markers) && markers.length > 0) {
          // console.log("exists listingId 2: ", markers[0]);
          marker = markers.find((x) => listingId === x.markerID);
        }
        console.log("exists listingId 3: ", marker);
        if (marker) {
          dispatch(setFocusMarkerRedux(marker.marker.obj));
          handlePressXsCard(marker);
        }
      }
    }, 4000);
  }, [markers]);

  const googleMap = useMemo(
    () => (
      <GoogleMap
        ref={mapRef}
        mapContainerStyle={{
          width: "100%",
          height: "100%",
        }}
        center={center}
        onLoad={onLoad}
        onUnmount={onUnmount}
        onClick={() => dispatch(setFocusMarkerRedux(undefined))}
        // onMouseDown={() =>
        //   listingsRef.current?.snapTo(({ snapPoints }) =>
        //     Math.min(...snapPoints)
        //   )
        // }
        onRightClick={handlePressMap}
        options={{
          styles: configLocal.customMapStyle,
          zoomControl: false,
          zoom: 4,
          maxZoom: 19,
          minZoom: 2,
          mapTypeControl: false,
          rotateControl: false,
          fullscreenControl: false,
          streetViewControl: false,
          clickableIcons: false,
        }}
      >
        {/* <TopBar /> */}
        <AppViewLayout
          sx={{
            position: "absolute",
            top: getPx(padding.primary),
            right: getPx(padding.primary),
          }}
        >
          <VStack
            spacing={0}
            sx={{
              border: "1px solid ",
              borderColor: colors.grayD,
              borderRadius: getPx(borders.borderRadius8),
              bg: colors.grayD,
              overflow: "hidden",
              boxShadow: "0px 0px 7px 1px rgba(0,0,0,0.2)",
            }}
            divider={<StackDivider borderColor="gray.200" />}
          >
            {[
              { onPress: getLocation, title: <IoNavigate /> },
              { onPress: zoomIn, title: <FaPlus /> },
              { onPress: zoomOut, title: <FaMinus /> },
            ].map((x, i) => (
              <Center key={i}>
                <AppButton
                  variant="white"
                  onPress={x.onPress}
                  noTranslate
                  title={x.title}
                  buttonStyle={{
                    borderRadius: 0,
                    paddingY: 0,
                    paddingX: 0,
                    height: getPx(padding.xxl),
                    width: getPx(padding.xxl),
                  }}
                />
              </Center>
            ))}
          </VStack>
        </AppViewLayout>

        {/* Child components, such as markers, info windows, etc. */}
        {/* {false && */}
        {showMarkers &&
          markers &&
          Array.isArray(markers) &&
          markers.length > 0 && (
            <MarkerClusterer
              options={{
                averageCenter: true,
                // imagePath: "https://youwhoapp.youwho.io/assets/icons/map-markers/m",
                // "../../assets/icons/m",
                // imageExtension: "png",
                // imageSizes: [70, 70, 70, 70, 70, 70],
                maxZoom: 18,
                styles: Array.from({ length: 6 }, (x, i) => ({
                  textColor: "white",
                  // url: "./assets/icons/mc1.png",
                  // url: "https://youwhoapp.youwho.io/assets/icons/mc1.png",
                  url: "/assets/icons/mc1.png",
                  height: 70,
                  width: 70,
                  anchorText: [-7, -1],
                  textSize: fonts.size.lg,
                  fontFamily: "Quicksand,sans-serif",
                  fontWeight: "700",
                })),
              }}
              // averageCenter
              // enableRetinaIcons
              // gridSize={60}
            >
              {(clusterer) =>
                markers.map((x) => (
                  <Marker
                    ref={x.markerRef}
                    position={x.marker.location}
                    identifier={x.markerID}
                    key={x.markerID}
                    clusterer={clusterer}
                    // icon={"./assets/icons/m1.png"}
                    // icon={"https://youwhoapp.youwho.io/assets/icons/m1.png"}
                    icon={"/assets/icons/m1.png"}
                    title={`${
                      getFiatCurrencyObject(x.marker.fiatCurrency)
                        .symbol_native + x.marker.rate
                    } ${x.marker.fiatCurrency.toUpperCase()}/hr.`}
                    onClick={() => {
                      console.log(
                        "onPress x.marker.obj: " + JSON.stringify(x.markerID)
                      );
                      dispatch(setFocusMarkerRedux(x.marker.obj));
                      // setFocusMarkerState(x.marker.obj);
                    }}
                  >
                    {/* <InfoWindow
                onLoad={onLoadInfoBox}
                position={x.marker.location}
              >
                <Box
                  sx={{
                    fontFamily: "Quicksand, sans-serif",
                    fontWeight: "700",
                    backgroundColor: "white",
                    borderRadius: getPx(borders.borderRadius8),
                    padding: getPx(padding.sm),
                  }}
                >
                  <AppText variant="smb">
                    {`${
                      getFiatCurrencyObject(x.marker.fiatCurrency)
                        .symbol_native + x.marker.rate
                    } ${x.marker.fiatCurrency.toUpperCase()}/hr.`}
                  </AppText>
                </Box>
              </InfoWindow> */}
                    <InfoBox
                      // onLoad={onLoadInfoBox}
                      options={{
                        closeBoxURL: "",
                        enableEventPropagation: true,
                        // visible: focusMarker?.markerID === x.markerID,
                        // // visible: true,
                        boxStyle: {
                          fontFamily: "Quicksand, sans-serif",
                          fontWeight: "700",
                          borderRadius: getPx(borders.borderRadius8),
                          boxShadow: "0px 0px 7px 1px rgba(0,0,0,0.2)",
                        },
                        pixelOffset: { height: -100, width: -45 },
                      }}
                      position={x.marker.location}
                    >
                      <Box
                        sx={{
                          borderRadius: getPx(borders.borderRadius8),
                          backgroundColor: "white",
                          padding: getPx(padding.sm),
                          height: "24.55px",
                          overflowY: "hidden",
                        }}
                      >
                        <AppText
                          variant="smb"
                          sx={{ paddingX: getPx(padding.sm) }}
                        >
                          {`${
                            getFiatCurrencyObject(x.marker.fiatCurrency)
                              .symbol_native +
                            x.marker.rate +
                            "\xa0" +
                            x.marker.fiatCurrency.toUpperCase()
                          }/hr. `}
                        </AppText>
                      </Box>
                    </InfoBox>
                  </Marker>
                ))
              }
            </MarkerClusterer>
          )}

        {/* Users own location */}
        {userLocation && (
          <Marker
            ref={customerMarkerRef}
            position={formatLoc(userLocation)}
            // description={x.marker.rate}
            identifier="customerMarker"
            key="customerMarker"
            onClick={() => {
              dispatch(setFocusMarkerRedux(undefined));
              // setFocusMarkerState(undefined);
            }}
            title={"You"}
            // // icon={"./assets/icons/you1.png"}
            // icon={"./assets/icons/you1.png"}
            // icon={"https://youwhoapp.youwho.io/assets/icons/you1.png"}
            icon={"/assets/icons/you1.png"}
          >
            <InfoBox
              // onLoad={onLoadInfoBox}
              options={{
                closeBoxURL: "",
                enableEventPropagation: true,
                boxStyle: {
                  fontFamily: "Quicksand, sans-serif",
                  fontWeight: "700",
                  borderRadius: getPx(borders.borderRadius8),
                  boxShadow: "0px 0px 7px 1px rgba(0,0,0,0.2)",
                },
                pixelOffset: { height: -130, width: -20 },
              }}
              position={formatLoc(userLocation)}
              // anchor={{}}
            >
              <Box
                sx={{
                  borderRadius: getPx(borders.borderRadius8),
                  backgroundColor: "white",
                  padding: getPx(padding.sm),
                  height: "24.55px",
                  overflowY: "hidden",
                }}
              >
                <AppTextTranslate variant="smb">You</AppTextTranslate>
              </Box>
            </InfoBox>
          </Marker>
        )}
      </GoogleMap>
    ),
    [markers, userLocation, center]
  );

  return (
    <>
      <TopBar />

      {mapIsLoaded && googleMap}

      {focusMarker && (
        <AppViewLayout
          sx={getSx([
            {
              position: "fixed",
              top: [undefined, "-80vh"],
              bottom: [getPx(padding.bottomBar70), undefined],

              // top: "10vh",
              left: "50%",
              transform: "translate(-50%)",
              zIndex: 1,
              height: getPx(100),
              width: getPx(350),
              // paddingX: getPx(padding.md),
            },
          ])}
          offset={100}
          unmountOnExit
        >
          <AppListingsCardXs
            shadow
            // border
            category={focusMarker?.category}
            imgUrl={focusMarker?.imgArray[0]?._url}
            onPress={() => handlePressXsCard(undefined, true, false)}
            rating={focusMarker?.rating}
            reviews={focusMarker?.reviews}
            title={focusMarker?.title}
          />
        </AppViewLayout>
      )}

      <BottomSheet
        className="rsbs-listings"
        open
        // skipInitialTransition={listingsFoundTotal > 0 ? true : false}
        skipInitialTransition
        ref={listingsRef}
        blocking={false}
        snapPoints={({ minHeight, maxHeight }) => [
          maxHeight,
          // maxHeight * 0.5,
          CLOSED_TAB_HEIGHT,
        ]}
        expandOnContentDrag={expandOnContentDrag}
        // onSpringStart={async (event) => {
        //   if (event.type === "OPEN") {
        //     // the bottom sheet gently waits
        //     // await queryListings(listingsSearchObject, true);
        //     await awaitDuration(listingsFoundTotal > 0 ? 0 : 2000);
        //     // and now we can proceed
        //   }
        // }}
        onSpringEnd={(event) => {
          // console.log(
          //   "Finished transition to:",
          //   listingsRef.current?.height,
          //   JSON.parse(JSON.stringify(event))
          // );
          if (listingsRef.current?.height > CLOSED_TAB_HEIGHT) {
            setTimeout(() => setFocusMap(false), 0);
          } else {
            setTimeout(() => setFocusMap(true), 0);
          }
        }}
        header={
          <Stack
            style={{
              width: "100%",
            }}
          >
            <AppTextTranslate variant="lgb" sx={{ marginTop: getPx(-5) }}>
              services available
            </AppTextTranslate>
            <motion.div
              style={{
                // marginTop: getPx(0),
                // boxShadow: "0px 1px 5px 1px rgba(0,0,0,0.1)",
                borderBottomWidth: 1,
                borderColor: colors.grayD,
                width: "100%",
                opacity,
              }}
            />
            {/* <AppImage /> */}
          </Stack>
        }
        footer={<BottomTabLayout />}
        defaultSnap={({ lastSnap, snapPoints }) => snapPoints[1]}
        style={{ zIndex: -4 }}
        // onDismiss={() => {
        //   // Full typing for the arguments available in snapTo, yay!!
        //   // listingsRef.current.snapTo(({ maxHeight }) => maxHeight);
        //   setOpenBottomSheet(false);
        // }}
        onScrollCapture={handleScroll}
        // scrollLocking
      >
        <motion.div ref={focusRef} />
        <ResponsiveMasonry
          columnsCountBreakPoints={{
            0: 1,
            510: 2,
            850: 3,
            1350: 4,
            1800: 5,
          }}
          style={{ paddingTop: getPx(padding.md) }}
        >
          <Masonry>
            {Array.isArray(listingsResults) &&
              listingsResults.length > 0 &&
              listingsResults.slice().map((item, index) => (
                // <AppViewLayout
                //   offset={listingsFoundTotal > 0 ? padding.ms : 0}
                //   index={index}
                //   key={item.id}
                // >
                <AppListingsCard
                  key={item.id}
                  category={item.category}
                  id={item.id}
                  // imageHeight="100%"
                  imgArray={item.imgArray}
                  onPress={() => {
                    dispatch(setFocusMarkerRedux(item));
                    let marker;
                    if (Array.isArray(markers) && markers.length > 0) {
                      marker = markers.find((x) => item.id === x.markerID);
                    }

                    if (marker) handlePressXsCard(marker);
                    // // setFocusMarkerState(item);
                    // handlePressCard(item, index);
                    // navigation.navigate(
                    //   user ? routes.MAIN_LISTINGS_NAV.n : routes.GUEST_LISTINGS_NAV.n,
                    //   {
                    //     screen: routes.LISTING_DETAILS_SCREEN.n,
                    //     params: { item },
                    //   }
                    // );
                  }}
                  rate={
                    getFiatCurrencyObject(item.fiatCurrency ?? "usd")
                      .symbol_native +
                    item.rate +
                    " " +
                    getFiatCurrencyObject(item.fiatCurrency ?? "usd").code +
                    "/hr."
                  }
                  subCategory={item.subCategory}
                  title={item.title}
                  reviews={item.reviews}
                  rating={item.rating}
                />
                // </AppViewLayout>
              ))}
          </Masonry>
        </ResponsiveMasonry>
        <Stack
          // w="200px"
          // alignSelf="center"
          alignItems="center"
          justifyContent="center"
          paddingBottom={getPx(padding.bottomBar60)}
        >
          <AppButton
            onPress={() => {
              // Full typing for the arguments available in snapTo, yay!!
              handlePressShowMap(false);
              // setOpenBottomSheet(false);
            }}
            title="Expand to full height"
          />
          {user && user.isDataAvailable() && (
            <Flex>
              <Text>Welcome {user.get("username")}</Text>
              {/* {user.attributes.userPublic.attributes?.avatar?._url && (
                  <Image
                    src={user.attributes.userPublic.attributes?.avatar?._url}
                    h="200px"
                  />
                )} */}
            </Flex>
          )}

          <AppButton
            onPress={async () => {
              await scrollIntoView(focusRef.current, { block: "end" });
              focusRef.current.focus();
            }}
            title="up"
          />
        </Stack>
      </BottomSheet>

      {!(queryIsLoading || locationIsLoading) && !showListing && (
        <AppViewLayout
          sx={{
            position: "fixed",
            bottom: ["180px"],
            left: "50%",
            transform: "translate(-50%, 0)",
            zIndex: 4,
          }}
        >
          <AppButton
            buttonStyle={{ boxShadow: "0px 0px 7px 1px rgba(0,0,0,0.2)" }}
            onPress={() => handlePressShowMap(!focusMap)}
            title={focusMap ? "show list" : "show map"}
            pill
            IconRight={focusMap ? FiList : FiMap}
            iconSize={focusMap ? 18 : 15}
          />
        </AppViewLayout>
      )}

      {showListing && (
        <AppViewLayout
          sx={{
            position: "fixed",
            bottom: ["180px"],
            left: "50%",
            transform: "translate(-50%, 0)",
            zIndex: 4,
          }}
        >
          <AppButton
            buttonStyle={{ boxShadow: "0px 0px 7px 1px rgba(0,0,0,0.2)" }}
            onPress={() => handlePressShowMap(true)}
            title={"show map"}
            pill
            IconRight={FiMap}
            iconSize={15}
          />
        </AppViewLayout>
      )}

      <BottomSheet
        className="rsbs-listing"
        open={showListing}
        // skipInitialTransition={listingsFoundTotal > 0 ? true : false}
        // skipInitialTransition

        ref={listingRef}
        blocking={false}
        snapPoints={({ minHeight, maxHeight }) => [
          maxHeight,
          // maxHeight * 0.5,
          0,
        ]}
        // expandOnContentDrag={expandOnContentDrag}
        // onSpringStart={async (event) => {
        //   if (event.type === "OPEN") {
        //     // the bottom sheet gently waits
        //     // await queryListings(listingsSearchObject, true);
        //     await awaitDuration(listingsFoundTotal > 0 ? 0 : 2000);
        //     // and now we can proceed
        //   }
        // }}
        onSpringEnd={(event) => {
          // console.log(
          //   "Finished transition to:",
          //   listingsRef.current?.height,
          //   JSON.parse(JSON.stringify(event))
          // );
          if (listingRef.current?.height === 0) {
            handlePressShowMap(true);
          }
        }}
        // header={}
        // footer={<BottomTabLayout />}
        defaultSnap={({ lastSnap, snapPoints }) => snapPoints[0]}
        style={{ zIndex: 40 }}
        // onDismiss={() => {
        //   // Full typing for the arguments available in snapTo, yay!!
        //   // listingsRef.current.snapTo(({ maxHeight }) => maxHeight);
        //   setOpenBottomSheet(false);
        // }}
        onScrollCapture={handleScrollListing}
        // scrollLocking
      >
        {/* <Stack
          sx={{
            w: "200px",
            // alignSelf="center"
            alignItems: "center",
            justifyContent: "center",
            paddingBottom: getPx(padding.bottomBar60),
          }}
        >
          {focusMarker && (
            <Flex>
              <AppText>{focusMarker.id}</AppText>
              <AppButton
                onPress={() =>
                  navigation("/listing/" + focusMarkerState.markerID, {
                    replace: false,
                  })
                }
                title="go to Listing"
              />
            </Flex>
          )}
        </Stack> */}
        <ListingDetailsScreen
          goBack={() => handlePressShowMap(true)}
          scrollYListing={scrollYListing}
        />
      </BottomSheet>

      {(queryIsLoading || locationIsLoading || !mapIsLoaded) && (
        <SplashScreen />
      )}

      <Toast.AppToast />
    </>
  );
}
