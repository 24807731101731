import React from "react";
import { useDisclosure } from "@chakra-ui/react";

import colors from "../../config/colors";
import AppViewLayout from "../AppViewLayout";
import padding from "../../config/padding";
import borders from "../../config/borders";
import AppTextTranslate from "../AppTextTranslate";
import AppImage from "../AppImage";
import { getPx, getSx } from "../../utils/helpers";
import View from "../rnMimic/View";
import AppModal from "../modals/AppModal";
import AppPressable from "../AppPressable";
import { FiChevronRight } from "react-icons/fi";

const AppListItem = ({
  bold,
  containerStyle,
  edit,
  fromLanguage = "en",
  height = 70,
  IconComponent,
  image,
  ModalComponent,
  more,
  noAuto = true,
  onPress,
  noTranslate,
  RightComponent,
  subtitle,
  SubtitleComponent,
  title,
  TitleComponent,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handlePressable = () => {
    if (ModalComponent) {
      onOpen();
    } else {
      if (onPress) onPress();
      // handlePressModal(true);
    }
  };

  return (
    <>
      <AppViewLayout fullWidth noBox>
        <AppPressable
          noAnimate
          // underlayColor={colors.danger}
          onPress={handlePressable}
          sx={{ width: "100%" }}
          cursor={onPress ? "pointer" : "normal"}
        >
          <View
            direction="row"
            style={getSx([
              styles.container,
              containerStyle,
              { height: getPx(height), width: "100%" },
              // { backgroundColor: "green" },
            ])}
          >
            {IconComponent && IconComponent}
            {image && (
              // <ImageBlurLoading
              //   fastImage
              //   style={[styles.image, { height, width: height }]}
              //   source={image}
              //   withIndicator={false}
              // />
              <AppImage
                style={getSx([
                  styles.image,
                  { height: getPx(height), width: getPx(height) },
                ])}
                source={image}
                noUri
              />
            )}

            <View
              style={getSx([
                styles.detailsContainer,
                {
                  paddingLeft: Boolean(IconComponent || image)
                    ? getPx(padding.ms)
                    : 0,
                },
              ])}
            >
              {TitleComponent ? (
                TitleComponent
              ) : (
                <AppTextTranslate
                  fromLanguage={fromLanguage}
                  variant={bold ? "lgb" : "lg"}
                  numberOfLines={1}
                  // noAuto={noAuto}
                >
                  {title}
                </AppTextTranslate>
              )}
              {SubtitleComponent ? (
                SubtitleComponent
              ) : (
                <>
                  {subtitle !== undefined ? (
                    <AppTextTranslate
                      fromLanguage={fromLanguage}
                      variant="md"
                      style={{ color: colors.gray4 }}
                      numberOfLines={2}
                      noAuto={noAuto}
                    >
                      {subtitle}
                    </AppTextTranslate>
                  ) : undefined}
                </>
              )}
            </View>
            {RightComponent}
            {more && <FiChevronRight color={colors.black} size={24} />}
            {edit && (
              <AppTextTranslate
                fromLanguage="en"
                variant="lgb"
                style={{ fontWeight: 500 }}
                underline
              >
                Edit
              </AppTextTranslate>
            )}
          </View>
        </AppPressable>
      </AppViewLayout>
      {ModalComponent && (
        <AppModal
          isOpen={isOpen}
          onClose={onClose}
          Component={ModalComponent}
        />
      )}
    </>
  );
};

const styles = Object.create({
  container: {
    // backgroundColor: colors.danger,
    top: getPx(padding.sm),
    // paddingBottom: padding.ms,
    // paddingTop: padding.primary,
    // height: 70,
  },
  detailsContainer: {
    flex: 1,
    justifyContent: "center",
  },
  image: {
    borderRadius: getPx(borders.borderRadius30),
  },
});

export default AppListItem;
