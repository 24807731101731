import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Center,
  Container,
  Flex,
  HStack,
  useDisclosure,
} from "@chakra-ui/react";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import {
  motion,
  useMotionValueEvent,
  useScroll,
  useTransform,
} from "framer-motion";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localizedFormat from "dayjs/plugin/localizedFormat";
import {
  FiAlertCircle,
  FiCopy,
  FiEdit,
  FiEye,
  FiEyeOff,
  FiKey,
  FiShare,
  FiTrash2,
  FiUploadCloud,
  FiX,
} from "react-icons/fi";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { formatListings, useQueryListings } from "../hooks/useQuery";
import {
  useContainerDimensions,
  useWindowDimensions,
} from "../hooks/useDimensions";
import Screen from "../components/Screen";
import AppLoadingAnimation from "../components/AppLoadingAnimation";
import { fontConfig as fonts } from "../config/fonts";
import padding from "../config/padding";
import { useYouwho } from "../package/react-youwho";
import { getFiatCurrencyObject, getPx, getSx } from "../utils/helpers";
import AppPressable from "../components/AppPressable";
import AppIcon from "../components/AppIcon";
import { useUser } from "../hooks/useUser";
import colors from "../config/colors";
import borders from "../config/borders";
import AppImageCarousel from "../components/AppImageCarousel";
import AppText from "../components/AppText";
import { useToast } from "../hooks/useToast";
import Share, { SharePopupCustom } from "../hooks/useShare";
import AppViewLayout from "../components/AppViewLayout";
import AppTextTranslate from "../components/AppTextTranslate";
import { FaHandHoldingHeart } from "react-icons/fa";
import AppStar from "../components/AppStar";
import ListItemSeparator from "../components/lists/ListItemSeparator";
import { useCopy } from "../hooks/useCopy";
import View from "../components/rnMimic/View";
import AppButton from "../components/AppButton";
import AppProviderProfileCard from "../components/AppProviderProfileCard";
import AppCardMap from "../components/AppCardMap";
import AppAvatar from "../components/AppAvatar";
import AppScrollMenu from "../components/scrollMenu/AppScrollMenu";

dayjs.extend(customParseFormat);
// dayjs.extend(utc);
// dayjs.extend(timezone);
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

const ListingDetailsScreen = ({ goBack, scrollYListing }) => {
  const { listingId } = useParams();
  //   const { height, width } = useWindowDimensions();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { copyToClipboard } = useCopy();

  const {
    appStore,
    naviStore,
    Parse,
    navigation,
    user,
    handleError,
    handleInfo,
  } = useYouwho();
  const { naviListing } = naviStore;

  const {
    favorite,
    handlePressFavorite,
    showFavToast,
    setShowFavToast,
    // handleFavToast,
  } = useUser(listingId ?? undefined);

  const Toast = useToast();

  const { listingsResults, loginModalObject, config } = appStore;

  const [loadingListing, setLoadingListing] = useState(true);
  const [listing, setListing] = useState(
    naviListing?.id === listingId
      ? naviListing
      : listingsResults.find((x) => x.id === listingId)
  );
  const [detail, setDetail] = useState();
  const [dataProvider, setDataProvider] = useState();
  const [reviewsArray, setReviewsArray] = useState([]);
  const [hiding, setHiding] = useState(false);
  const [containerReady, setContainerReady] = useState(false);

  const imagesRef = useRef();
  const imagesContainerRef = useRef();
  const containerRef = useRef();
  const topButtonContainerRef = useRef();
  const { height: containerHeight, width: containerWidth } =
    useContainerDimensions(containerRef);

  const bookingFiatCurrencyObject = getFiatCurrencyObject(
    listing?.fiatCurrency ?? "usd"
  );

  const { scrollYProgress, scrollY } = useScroll({
    container: containerRef,
    target: imagesContainerRef,
    // container: imagesContainerRef,
    // target: topButtonContainerRef,
    offset: ["start end"],
  });
  const opacity = useTransform(
    scrollYListing ?? scrollY,
    [0, 300, 450],
    [0, 0, 1]
  );

  // useMotionValueEvent(scrollY, "change", (latest) => {
  //   console.log("Page scroll: ", latest);
  // });
  const [selected, setSelected] = useState([]);

  const isItemSelected = (id) => !!selected.find((el) => el === id);

  const handleClick =
    (id) =>
    ({ getItemById, scrollToItem }) => {
      const itemSelected = isItemSelected(id);

      setSelected((currentSelected) =>
        itemSelected
          ? currentSelected.filter((el) => el !== id)
          : currentSelected.concat(id)
      );
    };

  //   console.log("scrollY: ", scrollY);

  const getListing = async (noLoading) => {
    console.log("------ started getListing on ListingDetailsScreen ------");
    try {
      if (!noLoading) setLoadingListing(true);
      const query = new Parse.Query("Listings");
      query.equalTo("objectId", listingId);
      query.include([
        "providerPublic",
        "listingDetails",
        "reviewsProviderRelation",
      ]);
      const result = await query.find();

      const newLists = formatListings(result);

      // console.log("result[0].toJSON(): ", result[0].toJSON());
      // const fromJSON = new Parse.Object(result[0].toJSON());
      // console.log("fromJSON: ", fromJSON);

      setDetail(newLists[0]?.listDetail.attributes?.description);
      setDataProvider(
        Object.assign(
          {
            id: newLists[0]?.providerPublic?.id,
          },
          newLists[0]?.providerPublic?.attributes
        )
      );

      setListing(newLists[0]);
    } catch (error) {
      console.log("getListing error: " + error.message);
    } finally {
      setLoadingListing(false);
    }
  };

  const getReviews = async (item) => {
    try {
      if (item?.reviewsProviderRelation) {
        console.log("item?.obj: ", item?.obj);
        const queryListings = new Parse.Query("Listings");
        queryListings.equalTo("objectId", item?.id);
        queryListings.select(["reviewsProviderRelation"]);
        const resultListing = await queryListings.first();
        const queryReviews =
          resultListing?.attributes?.reviewsProviderRelation?.query();
        queryReviews.limit(7);
        queryReviews.include("customerPublicPointer");
        const reviewObjects = await queryReviews.find();
        // console.log("reviewObjects", reviewObjects);

        const reviewObjectsVisible = [];
        reviewObjects.map((x) => {
          if (x.attributes?.visible === true) {
            reviewObjectsVisible.push(x);
          }
        });
        // console.log("reviewObjectsVisible", reviewObjectsVisible);
        // setReviewsArray(reviewObjects);
        setReviewsArray(reviewObjectsVisible);
        // console.log("reviewObjects", reviewObjects);
      }
    } catch (error) {
      console.log("ListingDetailsScreen getReviews error: ", error);
    } finally {
      setLoadingListing(false);
    }
  };

  const fetchProviderPublic = async (item) => {
    // if (!listing.providerPublic.isDataAvailable()) {

    if (item.providerPublic?.attributes) {
      setDataProvider(
        Object.assign(
          {
            id: item.providerPublic?.id,
          },
          item.providerPublic?.attributes
        )
      );
    } else {
      // await item.providerPublic.fetch();
      // const provPub = new Parse.Object(item.json);
      // const provPub2 = provPub.get("providerPublic");
      // await provPub2.fetch();
      // console.log("provPub2: ", provPub2);
      const query = new Parse.Query("UserPublic");
      query.equalTo("objectId", item.json.providerPublic?.objectId);
      const result = await query.first();
      console.log("UserPublic result: ", result);
      setDataProvider(
        Object.assign(
          {
            id: result?.id,
          },
          result?.attributes
        )
      );
    }
    // }
    setLoadingListing(false);
  };

  useEffect(() => {
    if (!listing) {
      getListing();
    } else {
      setDetail(listing.listDetail.attributes?.description);
      fetchProviderPublic(listing);
      getReviews(listing);
    }
  }, [listing]);

  //   console.log("listing: ", listing);

  const handleFavToast = () => {
    Toast.show({
      type: "appToast",
      autoHide: false,
      // And I can pass any custom props I want
      //   onHide: () => setShowFavToast(false),
      // onShow: () => handleShowUnderlay(true),
      props: {
        hideClose: false,
        title: "Login",
        titleRight: "Login",
        subtitle: "Please login to add favorite services to watchlist",
        hideLeft: true,
        // iconRight: "key",
        // fontSize: fonts.size.lg,
        rightButtonVariant: "outline",
        pressRight: () => {
          console.log("onOpenLogin");
          setTimeout(() => {
            loginModalObject?.onOpen();
          }, 400);

          // navigation.navigate(routes.LOGIN_SCREEN.n);
          // Toast.hide();
        },
      },
    });
  };

  const handlePressProfile = () => {
    navigation("/profile/" + listing.providerPublic.id);
  };
  const handlePressReviews = (reviewId) => {
    handleInfo("Coming soon...", "Unavailable");
    // navigation("/listing/" + listingId + "/reviews/" + (reviewId ?? ""));
  };
  const handlePressEdit = () => {
    handleInfo("Coming soon...", "Unavailable");
    // navigation("/listing/" + listingId + "/edit");
  };
  const handlePressBooking = () => {
    handleInfo("Coming soon...", "Unavailable");
    // navigation("/listing/" + listingId + "/book");
  };
  const handlePressMap = () => {
    handleInfo("Coming soon...", "Unavailable");
    // navigation("/listing/" + listingId + "/map");
  };
  const handlePressReport = () => {
    navigation("/listing/" + listingId + "/report");
  };

  const handlePressDelete = useCallback(() => {
    Toast.show({
      type: "appToast",
      //   autoHide: false,
      // And I can pass any custom props I want
      //   onHide: () => handleShowUnderlay(false),
      // onShow: () => handleShowUnderlay(true),
      props: {
        variant: "error",
        title: "Remove Listing",
        subtitle:
          "Are you sure you want to remove your listing?\nThis action can not be undone.",
        pressRight: async () => {
          Toast.hide();
          await Parse.Cloud.run("userDeleteListing", {
            listingId: listingId,
          });
          await getListing(true);
          navigation(-1);
        },
      },
    });
  }, []);

  const handlePressHide = useCallback(async () => {
    Toast.show({
      type: "appToast",
      //   autoHide: false,
      // And I can pass any custom props I want
      //   onHide: () => handleShowUnderlay(false),
      // onShow: () => handleShowUnderlay(true),
      props: {
        variant: "info",
        title: `${listing?.obj.get("hidden") ? "Unhide" : "Hide"} Listing`,
        subtitle: `Are you sure you want to ${
          listing?.obj.get("hidden") ? "unhide" : "hide"
        } your listing?`,
        iconLeft: FiX,
        pressRight: async () => {
          try {
            setHiding(true);
            Toast.hide();
            listing?.obj.set(
              "hidden",
              listing?.obj.get("hidden") ? false : true
            );
            await listing?.obj.save();
            await getListing(true);
            // navigation.goBack();
          } catch (error) {
            console.log("handlePressHide error: " + error.message);
            Toast.show({
              type: "appAlert",
              visibilityTime: 6000,
              props: {
                title: "Save error",
                subtitle: error?.message,
                variant: "error",
              },
            });
          } finally {
            setHiding(false);
          }
        },
      },
    });
  }, [listing]);

  const renderItem = useCallback(
    ({ item: itemr, index }) => (
      <AppPressable
        style={getSx([
          styles.review,
          { width: getPx(250), ":hover": { background: colors.grayF7 } },
        ])}
        onPress={() => handlePressReviews(itemr.id)}
        flexStart
        noAnimate
      >
        <View>
          <View direction="row">
            <AppAvatar
              name={
                itemr.attributes?.customerPublicPointer?.attributes?.username
              }
              url={
                itemr.attributes?.customerPublicPointer?.attributes?.avatar
                  ?._url ??
                itemr.attributes?.customerPublicPointer?.attributes?.avatarUrl
              }
              variant="sm"
              pointer={itemr.attributes?.customerPublicPointer?.attributes}
            />
            <View
              // direction="column"
              spacing={1}
              style={{ marginLeft: getPx(padding.ms), width: "100%" }}
            >
              <View direction="row">
                <AppStar
                  sx={{
                    // marginBottom: getPx(padding.one),
                    // marginLeft: getPx(padding.sm),
                    bottom: getPx(2),
                    color: colors.black,
                  }}
                  value={
                    listing?.reviews > 0 ? Number(listing?.rating ?? 0) / 5 : 0
                  }
                />

                <AppText variant="mdb">{itemr.attributes?.rating}</AppText>
                <AppText variant="md" sx={{ marginLeft: getPx(padding.sm) }}>
                  •&nbsp;
                  {
                    itemr.attributes?.customerPublicPointer?.attributes
                      ?.username
                  }
                </AppText>
              </View>

              <AppTextTranslate
                variant="sm"
                style={{
                  color: colors.gray6,
                  marginLeft: getPx(padding.xs),
                }}
              >
                {`${dayjs().to(dayjs(itemr.attributes?.createdAt))}`}
              </AppTextTranslate>
            </View>
          </View>

          <AppTextTranslate
            noAuto
            numberOfLines={5}
            variant="md"
            sx={{ marginTop: getPx(padding.ms) }}
          >
            {`${itemr.attributes?.review}`}
          </AppTextTranslate>
        </View>
      </AppPressable>
    ),
    []
  );

  if (loadingListing)
    return (
      <Screen title={`Loading ${listingId}`} maxW={500} sx={{ h: "70vh" }}>
        <AppLoadingAnimation
          style={{ h: "100%" }}
          withLogo
          width={150}
          height={150}
        />
      </Screen>
    );

  return (
    <>
      <Screen title={listing?.title} containerRef={containerRef}>
        <motion.div
          ref={topButtonContainerRef}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            opacity: opacity,
            // typeof opacityOutter === "number" ? opacityOutter : opacity,
            zIndex: 4,
            boxShadow: "0px 0px 7px 1px rgba(0,0,0,0.2)",
            height: getPx(padding.topBar50),
            backgroundColor: "white",
          }}
        />

        {/* **********Top Icon********** */}
        <Flex
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            height: getPx(padding.topBar50),
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            paddingX: getPx(padding.sm),
            zIndex: 5,
          }}
        >
          <AppPressable
            sx={{}}
            onPress={
              goBack
                ? goBack
                : () => {
                    navigation("/services/" + (listing ? listing.id : ""));
                    //   try {
                    //     if (navigation.canGoBack()) {
                    //       navigation.goBack();
                    //     } else if (user?.attributes?.isCustomer) {
                    //       navigation.navigate(routes.LISTINGS_SEARCH_RESULTS_SCREEN.n);
                    //     } else if (!user?.attributes?.isCustomer) {
                    //       navigation.navigate(routes.PROVIDER_LISTINGS_SCREEN.n);
                    //     } else navigation.navigate(routes.ACCOUNT_SCREEN.n);
                    //   } catch (error) {
                    //     navigation.navigate(routes.ACCOUNT_SCREEN.n);
                    //   }
                  }
              // () => navigation.navigate(routes.LISTINGS_SEARCH_RESULTS_SCREEN.n)
            }
          >
            <AppIcon back={!goBack} close={goBack} />
          </AppPressable>

          <Box sx={{ display: ["none", "block"] }}>
            <motion.div
              style={{
                opacity: opacity,
                // typeof opacityOutter === "number" ? opacityOutter : opacity,
              }}
            >
              <AppTextTranslate noAuto variant="h2" numberOfLines={1}>
                {listing?.title}
              </AppTextTranslate>
            </motion.div>
          </Box>

          {listing && (
            <HStack>
              {user && listing?.providerId === user.id && (
                <>
                  <AppPressable
                    style={styles.iconRight}
                    // onPress={handlePressDelete}
                    onPress={() => alert("handlePressDelete")}
                  >
                    <AppIcon
                      Icon={FiTrash2}
                      // backgroundColor={colors.danger}
                      iconColor={colors.danger}
                    />
                  </AppPressable>
                  <AppPressable
                    style={styles.iconRight}
                    onPress={handlePressHide}
                    // onPress={() => alert("handlePressHide")}
                  >
                    <AppIcon
                      Icon={
                        hiding
                          ? FiUploadCloud
                          : listing?.obj.get("hidden")
                          ? FiEye
                          : FiEyeOff
                      }
                      // backgroundColor={colors.danger}
                      iconColor={
                        hiding
                          ? colors.grayD
                          : listing?.obj.get("hidden")
                          ? colors.primary
                          : colors.tertiary
                      }
                    />
                  </AppPressable>
                  <AppPressable
                    style={styles.iconRight}
                    onPress={handlePressEdit}
                    // onPress={() => alert("handlePressEdit")}
                  >
                    <AppIcon
                      Icon={FiEdit}
                      // backgroundColor={colors.secondary}
                      iconColor={colors.info}
                    />
                  </AppPressable>
                </>
              )}
              {/* <Share
                shareData={{
                  title: listing?.title,
                  text: `${listing?.title} | YOUWHO | On-Demand Services Platform | https://youwhoapp.youwho.io/listing/${listingId}`,
                  url: `https://youwhoapp.youwho.io/listing/${listingId}`,
                }}
              >
                <AppIcon
                  style={{ paddingLeft: getPx(padding.ms) }}
                  Icon={FiShare}
                  backgroundColor={colors.white}
                />
              </Share> */}
              <AppPressable style={styles.iconRight} onPress={onOpen}>
                <AppIcon Icon={FiShare} backgroundColor={colors.white} />
              </AppPressable>

              <AppPressable
                style={styles.iconRight}
                // onPress={handlePressFavorite}
                onPress={user ? handlePressFavorite : handleFavToast}
              >
                <AppIcon
                  Icon={favorite ? AiFillHeart : AiOutlineHeart}
                  color={favorite ? colors.info : colors.black}
                  //   size={20}
                  backgroundColor={colors.white}
                />
              </AppPressable>
            </HStack>
          )}
        </Flex>
        {/* **********End Top Icon********** */}
        {!listing ? (
          <Center sx={{ h: "50vh", flexDir: "column" }}>
            <AppTextTranslate
              variant="h1"
              center
              style={{
                marginBottom: getPx(padding.md),
                //   fontSize: getPx(fonts.size.xxh1),
              }}
              // lineHeightNumber={fonts.size.xxh1 + 1}
            >
              Listing ID
            </AppTextTranslate>
            <AppText
              noAuto
              variant="h1"
              center
              style={{
                marginBottom: getPx(padding.md),
                fontSize: getPx(fonts.size.xh1),
              }}
              // lineHeightNumber={fonts.size.xh1 + 1}
              sx={{ marginX: getPx(padding.sm) }}
            >
              {listingId}
            </AppText>
            <AppTextTranslate
              variant="h1"
              center
              style={{
                marginBottom: getPx(padding.md),
                //   fontSize: getPx(fonts.size.xxh1),
              }}
              // lineHeightNumber={fonts.size.xxh1 + 1}
            >
              not found
            </AppTextTranslate>
          </Center>
        ) : (
          <>
            <Container
              maxW="container.md"
              padding={getPx(padding.md)}
              paddingTop={getPx(padding.xl)}
              paddingLeft={[getPx(padding.xxxl), , , getPx(padding.md)]}
              display={["none", "none", "block"]}
            >
              <AppTextTranslate
                noAuto
                variant="h1"
                style={{ marginBottom: getPx(padding.sm) }}
              >
                {listing?.title}
              </AppTextTranslate>
            </Container>

            <AppViewLayout
              ref={imagesContainerRef}
              sx={{
                borderRadius: [0, 0, getPx(borders.borderRadius20)],
                overflow: "hidden",
                cursor: "pointer",
                userSelect: "none",
                // boxShadow: "0px 0px 7px 1px rgba(0,0,0,0.2)",
              }}
            >
              <AppImageCarousel
                containerStyle={{ zIndex: 3 }}
                imgArray={listing?.imgArray}
                width={["100vw", "100vw", "100%"]}
                height={["100vw", "100vw", "100%"]}
                minHeight={"200px"}
                maxHeight={"50vh"}
                handleRef={imagesRef}
                showArrows
                showLightbox
                // imageStyle={imageStyle}
                // containerStyle={imageStyle}
                // onPress={() => handlePressImage(listing?.imgArray)}
                //   onPress={() => alert("handlePressImage")}
              />
            </AppViewLayout>

            <Container
              maxW="container.md"
              sx={{ padding: getPx(padding.md), paddingBottom: 0 }}
            >
              <AppViewLayout>
                {user &&
                  listing?.providerId === user.id &&
                  !listing?.obj.get("toReview") &&
                  listing?.obj.get("hidden") && (
                    <>
                      <AppTextTranslate
                        noAuto
                        variant="h2"
                        style={{
                          color: colors.tertiary,
                          // backgroundColor: "red",
                          textAlign: "center",
                        }}
                      >
                        YOUR LISTING IS CURRENTLY HIDDEN
                      </AppTextTranslate>
                      <ListItemSeparator
                        style={{ marginY: getPx(padding.md) }}
                      />
                    </>
                  )}
                {user &&
                  listing?.providerId === user.id &&
                  listing?.obj.get("toReview") && (
                    <>
                      <AppTextTranslate
                        noAuto
                        variant="h2"
                        style={{
                          color: colors.danger,
                          // backgroundColor: "red",
                          textAlign: "center",
                        }}
                      >
                        YOUR LISTING IS CURRENTLY UNDER REVIEW
                      </AppTextTranslate>
                      <ListItemSeparator
                        style={{ marginY: getPx(padding.md) }}
                      />
                    </>
                  )}
                <AppTextTranslate
                  noAuto
                  variant="h1"
                  style={{
                    marginBottom: getPx(padding.ms),
                    display: ["block", "block", "none"],
                  }}
                >
                  {listing?.title}
                </AppTextTranslate>

                <AppPressable
                  style={{
                    flexDirection: "row",
                    marginBottom: getPx(padding.ms),
                  }}
                  flexStart
                  noAnimate
                  onPress={
                    listingId
                      ? () => copyToClipboard(listingId, true)
                      : undefined
                  }
                >
                  <AppText variant="sm">( </AppText>
                  <AppTextTranslate
                    variant="sm"
                    sx={{ marginRight: getPx(padding.sm) }}
                  >
                    listing id :
                  </AppTextTranslate>
                  <AppText variant="smb">{listingId}</AppText>
                  <AppIcon
                    iconX={0.75}
                    size={fonts.size.sm}
                    Icon={FiCopy}
                    style={{ marginLeft: getPx(padding.xs) }}
                  />
                  <AppText variant="sm"> )</AppText>
                </AppPressable>

                {listing?.proBono ? (
                  <AppPressable
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      marginBottom: getPx(padding.ms),
                    }}
                    noAnimate
                    flexStart
                    onPress={handlePressProfile}
                  >
                    <>
                      <FaHandHoldingHeart
                        color={colors.black}
                        size={fonts.size.md}
                      />
                      <AppTextTranslate
                        variant="mdb"
                        sx={{ marginLeft: getPx(padding.sm) }}
                      >
                        • Pro bono
                      </AppTextTranslate>
                    </>
                  </AppPressable>
                ) : (
                  <AppPressable
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      marginBottom: getPx(padding.ms),
                    }}
                    flexStart
                    noAnimate
                    onPress={() => handlePressReviews()}
                  >
                    <AppStar
                      sx={{
                        // marginBottom: getPx(padding.one),
                        // marginLeft: getPx(padding.sm),
                        color: colors.black,
                      }}
                      value={
                        listing?.reviews > 0
                          ? Number(listing?.rating ?? 0) / 5
                          : 0
                      }
                    />

                    {/* <View style={{ marginRight: 5, bottom: 1 }}>
                <Stars
                  display={listing?.reviews > 0 ? listing?.rating / 5 : 0}
                  count={1}
                  fullStar={
                    <FontAwesome
                      name={"star"}
                      style={[styles.myStarStyle]}
                      size={fonts.size.lg}
                    />
                  }
                  emptyStar={
                    <FontAwesome
                      name={"star-o"}
                      style={[styles.myStarStyle]}
                      size={fonts.size.lg}
                    />
                  }
                />
              </View> */}
                    <AppTextTranslate
                      variant="md"
                      sx={{ marginX: getPx(padding.sm) }}
                    >
                      {`${listing?.reviews > 0 ? listing?.rating : "New"} •`}
                    </AppTextTranslate>
                    <AppTextTranslate
                      variant="mdb"
                      underline={listing?.reviews && listing?.reviews > 0}
                    >
                      {`${
                        listing?.reviews > 0 ? listing?.reviews : "0"
                      } reviews`}
                    </AppTextTranslate>
                  </AppPressable>
                )}
                {Boolean(
                  listing?.listingLocation.name ||
                    listing?.listingLocation.address
                ) &&
                  listing?.serviceLocation !== "global" && (
                    <AppTextTranslate variant="md">
                      {`${
                        listing?.listingLocation.name
                          ? listing?.listingLocation.name
                          : listing?.listingLocation.address
                      }`}
                    </AppTextTranslate>
                  )}
                {config && listing?.serviceLocation === "global" && (
                  <AppTextTranslate variant="md">
                    {`${config.serviceLocation[3].text}`}
                  </AppTextTranslate>
                )}
              </AppViewLayout>

              <ListItemSeparator style={styles.separator} />

              {dataProvider && (
                <AppViewLayout
                  style={{
                    marginBottom: getPx(padding.sm),
                  }}
                >
                  <AppProviderProfileCard info={dataProvider} />
                </AppViewLayout>
              )}

              <ListItemSeparator style={styles.separator} />

              <AppViewLayout>
                <View
                  direction="row"
                  style={{
                    // flexDirection: "row",
                    marginBottom: getPx(padding.md),
                    flex: 1,
                    flexWrap: "wrap",
                  }}
                >
                  <AppTextTranslate
                    // noAuto
                    variant="h3"
                    capitalize
                  >
                    {listing?.category}
                  </AppTextTranslate>
                  <AppText
                    noAuto
                    variant="p"
                    sx={{ marginX: getPx(padding.sm) }}
                  >
                    {"•"}
                  </AppText>
                  <AppTextTranslate noAuto variant="p">
                    {listing?.subCategory}
                  </AppTextTranslate>
                </View>

                <AppTextTranslate noAuto variant="lg">
                  {detail}
                </AppTextTranslate>
              </AppViewLayout>

              <ListItemSeparator style={styles.separator} />

              <AppViewLayout>
                <AppTextTranslate
                  variant="h2"
                  style={{ marginBottom: getPx(padding.md) }}
                >
                  Service Location
                </AppTextTranslate>

                {config?.serviceLocation?.map((x, i) => (
                  <View direction="row" key={x.key}>
                    {listing?.serviceLocation === x.key && (
                      <AppTextTranslate variant="lg">{x.text}</AppTextTranslate>
                    )}
                  </View>
                ))}
              </AppViewLayout>

              <ListItemSeparator style={styles.separator} />

              <AppViewLayout>
                <AppTextTranslate
                  variant="h2"
                  style={{ marginBottom: getPx(padding.md) }}
                >
                  Providers Location
                </AppTextTranslate>
                {Boolean(
                  listing?.listingLocation.name ||
                    listing?.listingLocation.address
                ) && (
                  <AppPressable
                    flexStart
                    sx={{ marginBottom: getPx(padding.primary) }}
                    noAnimate
                    onPress={handlePressMap}
                  >
                    <AppTextTranslate variant="lg">
                      {`${
                        listing?.listingLocation.name
                          ? listing?.listingLocation.name
                          : listing?.listingLocation.address
                      }`}
                    </AppTextTranslate>
                  </AppPressable>
                )}

                <View style={{ marginY: getPx(padding.md) }}>
                  <AppCardMap
                    latitude={listing?.lat}
                    longitude={listing?.lng}
                    onPress={handlePressMap}
                  />
                </View>

                <AppPressable
                  sx={{
                    marginTop: getPx(padding.ms),
                    paddingLeft: getPx(padding.sm),
                  }}
                  noAnimate
                  onPress={handlePressMap}
                >
                  <View spacing={1}>
                    <View direction="row">
                      <AppText
                        variant="mdb"
                        sx={{ marginRight: getPx(padding.sm) }}
                      >
                        Lat:
                      </AppText>
                      <AppText variant="mdb" underline>
                        {listing?.lat}
                      </AppText>
                    </View>
                    <View direction="row">
                      <AppText
                        variant="mdb"
                        sx={{ marginRight: getPx(padding.sm) }}
                      >
                        Lng:
                      </AppText>
                      <AppText variant="mdb" underline>
                        {listing?.lng}
                      </AppText>
                    </View>
                  </View>
                </AppPressable>
              </AppViewLayout>

              <ListItemSeparator style={styles.separator} />

              <AppViewLayout>
                <AppTextTranslate
                  variant="h2"
                  style={{ marginBottom: getPx(padding.sm) }}
                >
                  Availability
                </AppTextTranslate>
                <View spacing={1}>
                  {listing?.dayTime &&
                    JSON.parse(listing?.dayTime).length > 0 && (
                      <>
                        {JSON.parse(listing?.dayTime).map((x, ix) => (
                          <View key={"dayTime" + ix}>
                            {x && (
                              <View flexStart spacing={1}>
                                <AppTextTranslate variant="lgb">
                                  {`${dayjs(
                                    x.timeRangeArray[0].timeFrom
                                  ).format("dddd")}`}
                                </AppTextTranslate>
                                <View
                                  key={dayjs(
                                    x.timeRangeArray[0].timeFrom
                                  ).format()}
                                  style={{ marginBottom: getPx(padding.sm) }}
                                >
                                  {x.timeRangeArray.map((y, iy) => (
                                    <View key={dayjs(y.timeFrom).format() + iy}>
                                      <AppTextTranslate
                                        variant="md"
                                        noTranslate
                                      >
                                        {`${
                                          dayjs(y.timeFrom).format("h:mm a") +
                                          (dayjs(y.timeFrom).format("dddd") !==
                                          dayjs(y.timeTo).format("dddd")
                                            ? " " +
                                              dayjs(y.timeFrom).format("dddd")
                                            : "") +
                                          " - " +
                                          dayjs(y.timeTo).format("h:mm a") +
                                          (dayjs(y.timeFrom).format("dddd") !==
                                          dayjs(y.timeTo).format("dddd")
                                            ? " " +
                                              dayjs(y.timeTo).format("dddd")
                                            : "")
                                        }`}
                                      </AppTextTranslate>
                                    </View>
                                  ))}
                                </View>
                              </View>
                            )}
                          </View>
                        ))}
                      </>
                    )}
                </View>
              </AppViewLayout>

              <ListItemSeparator style={styles.separator} />

              {listing?.certArray && listing?.certArray.length > 0 && (
                <AppViewLayout>
                  <AppTextTranslate
                    variant="h2"
                    sx={{ marginBottom: getPx(padding.primary) }}
                  >
                    Certification
                  </AppTextTranslate>
                  <Box
                    sx={{
                      overflow: "hidden",
                      borderRadius: getPx(borders.borderRadius12),
                      borderWidth: getPx(1),
                      borderColor: colors.grayE,
                      marginBottom: getPx(padding.lg),
                      maxWidth: ["350px"],
                      boxShadow: "0px -1px 5px 1px rgba(0,0,0,0.1)",
                    }}
                  >
                    <AppImageCarousel
                      imgArray={listing?.certArray}
                      showArrows
                      showDots
                      showIndex={false}
                      showLightbox
                    />
                  </Box>

                  <ListItemSeparator style={styles.separator} />
                </AppViewLayout>
              )}

              {listing?.reviews > 0 && (
                <AppViewLayout>
                  <View
                    direction="row"
                    style={{
                      marginBottom: getPx(padding.primary),
                      marginTop: getPx(padding.lg),
                    }}
                  >
                    <AppStar
                      sx={{
                        // marginBottom: getPx(padding.one),
                        marginRight: getPx(padding.sm),
                        color: colors.black,
                        // bottom: getPx(3),
                      }}
                      value={
                        listing?.reviews > 0
                          ? Number(listing?.rating ?? 0) / 5
                          : 0
                      }
                    />
                    {/* <Stars
                        display={listing?.reviews > 0 ? listing?.rating / 5 : 0}
                        count={1}
                        fullStar={
                          <FontAwesome
                            name={"star"}
                            style={[
                              styles.myStarStyle,
                              { bottom: 0, marginRight: padding.sm },
                            ]}
                            size={fonts.size.primary}
                          />
                        }
                        emptyStar={
                          <FontAwesome
                            name={"star-o"}
                            style={[
                              styles.myStarStyle,
                              { bottom: 0, marginRight: padding.sm },
                            ]}
                            size={fonts.size.primary}
                          />
                        }
                      /> */}

                    <AppTextTranslate variant="h3">
                      {`${listing?.rating} • ${listing?.reviews} reviews`}
                    </AppTextTranslate>
                  </View>

                  <AppScrollMenu>
                    {reviewsArray.length < listing?.reviews
                      ? [
                          ...reviewsArray.map((item, index) => {
                            return renderItem({ item, index });
                          }),
                          <AppPressable
                            style={getSx([
                              styles.review,
                              {
                                width: getPx(250),
                                // alignItems: "center",
                                // justifyContent: "center",
                                // marginRight: padding.primary,
                                ":hover": { background: colors.grayF7 },
                              },
                            ])}
                            onPress={() => handlePressReviews()}
                            noAnimate
                          >
                            <AppTextTranslate variant="mdb" underline>
                              {`Show all ${listing?.reviews} reviews`}
                            </AppTextTranslate>
                          </AppPressable>,
                        ]
                      : reviewsArray.map((item, index) => {
                          return renderItem({ item, index });
                        })}
                  </AppScrollMenu>
                  {/* <FlatList
                    contentContainerStyle={{
                      marginY: getPx(padding.primary),
                    }}
                    // style={{ flex: 1 }}
                    data={reviewsArray}
                    ListFooterComponent={
                      reviewsArray.length < listing?.reviews ? (
                        <AppPressable
                          style={[
                            styles.review,
                            {
                              width: getPx(350),
                              alignItems: "center",
                              justifyContent: "center",
                              marginRight: getPx(padding.primary),
                            },
                          ]}
                          onPress={() => handlePressReviews()}
                        >
                          <AppTextTranslate variant="lgb" underline>
                            {`Show all ${listing?.reviews} reviews`}
                          </AppTextTranslate>
                        </AppPressable>
                      ) : (
                        <View style={{ width: getPx(padding.primary) }} />
                      )
                    }
                    keyExtractor={(itemr) => itemr.id}
                    horizontal
                    renderItem={renderItem}
                    showsHorizontalScrollIndicator={false}
                    snapToInterval={width - padding.primary * 3}
                    style={{ marginX: -padding.primary }}
                    viewabilityConfig={viewConfig}
                  /> */}
                  {Boolean(reviewsArray.length < listing?.reviews || true) && (
                    <AppButton
                      noAnimate
                      variant="underline"
                      title={`Show all ${listing?.reviews} reviews`}
                      onPress={() => handlePressReviews()}
                      buttonStyle={{
                        marginTop: getPx(padding.primary),
                        // marginBottom: getPx(padding.ms),
                      }}
                      pressableStyle={{
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                      }}
                    />
                  )}
                  <ListItemSeparator style={styles.separator} />
                </AppViewLayout>
              )}

              {dataProvider && (
                <AppViewLayout>
                  <View style={{ marginBottom: getPx(padding.sm) }}>
                    <AppProviderProfileCard info={dataProvider} long />
                    {user && user.id !== listing?.providerId && (
                      <View style={{ marginTop: getPx(padding.primary) }}>
                        <AppButton
                          title="Contact Provider"
                          variant="outline"
                          fontSize={fonts.size.lg}
                          // onPress={handlePressMessageProvider}
                          isDisabled
                          // isDisabled={submitting}
                          // isLoading={submitting}
                          thick
                          noAnimate
                        />
                      </View>
                    )}
                  </View>
                  <ListItemSeparator style={styles.separator} />
                </AppViewLayout>
              )}

              {Boolean(user) && listing?.providerId !== user?.id && (
                <AppViewLayout>
                  <AppPressable
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                    onPress={handlePressReport}
                    noAnimate
                  >
                    <AppIcon
                      iconX={0.9}
                      Icon={FiAlertCircle}
                      size={fonts.size.primary}
                      style={{
                        marginRight: getPx(padding.sm),
                        // bottom: 1
                      }}
                    />
                    <AppTextTranslate variant="md" underline>
                      Report this service
                    </AppTextTranslate>
                  </AppPressable>
                </AppViewLayout>
              )}
            </Container>
          </>
        )}

        {/* <Box h="100vh" /> */}
      </Screen>

      {/* **********Bottom  Bar ********** */}
      {listing && (
        <Flex
          // className="p-sm-3"
          sx={getSx([
            {
              // display: ["flex", , "none"],
              position: "fixed",
              // width: ["50vw", , , "100vw"],
              bottom: 0,
              left: 0,
              right: 0,
            },
            styles.bottomBar,
          ])}
          // backgroundColor="white"
          // background="radial-gradient(circle at 20% 100%, rgba(255, 255, 255, 0.1) 0%, rgba(160, 160, 160, 0.3) 30%, rgba(255, 255, 255, 0.3) 100%)"
        >
          <Box>
            <AppTextTranslate variant="md" style={{ height: getPx(16) }}>
              from
            </AppTextTranslate>
            <Flex flexDir="row" alignItems="flex-end">
              <AppText variant="h3" sx={{ marginRight: getPx(padding.sm) }}>
                {bookingFiatCurrencyObject?.symbol_native + listing?.rate}
              </AppText>
              <AppText variant="lg">
                {`${bookingFiatCurrencyObject?.code}/hr.`}
              </AppText>
            </Flex>
          </Box>
          {user && user.id !== listing?.providerId && (
            <AppButton
              // iconLeft="edit"
              fontSize={fonts.size.md}
              thick
              IconRight="arrow-right"
              onPress={handlePressBooking}
              title="Book now"
              variant="primary"
              // textStyle={{ height: 30 }}
              // fontColor={colors.black}
            />
          )}

          {user && user.id === listing?.providerId && (
            <AppButton
              // iconLeft="edit"
              // iconRight="edit"
              fontSize={fonts.size.md}
              thick
              IconRight={FiEdit}
              onPress={handlePressEdit}
              title="Edit"
              variant="info"
              // textStyle={{ height: 30 }}
              // fontColor={colors.black}
            />
          )}

          {!user && (
            <AppButton
              // iconLeft="edit"
              fontSize={fonts.size.md}
              thick
              IconRight={FiKey}
              onPress={loginModalObject?.onOpen}
              title="Login"
              variant="primary"
              // textStyle={{ height: 30 }}
              // fontColor={colors.black}
            />
          )}
        </Flex>
      )}
      {/* **********Bottom  Bar ********** */}

      <SharePopupCustom
        shareData={{
          title: listing?.title,
          text: `${listing?.title} | YOUWHO - Global On Demand Services Platform | https://youwhoapp.youwho.io/listing/${listingId}`,
          url: `https://youwhoapp.youwho.io/listing/${listingId}`,
        }}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        onError={handleError}
        listing={listing}
      />
      <Toast.AppToast />
    </>
  );
};

export default ListingDetailsScreen;

const styles = Object.create({
  // bannerContainer: {
  // marginTop: -1000,
  // paddingTop: 1000,
  // alignItems: "center",
  // overflow: "hidden",
  // zIndex: 3,
  // },
  // container: {
  //   flex: 1,
  // },
  // detailsContainer: {
  //   padding: getPx(padding.primary),
  //   paddingBottom: getPx(padding.bottomBar80 + padding.primary),
  //   zIndex: 5,
  //   backgroundColor: colors.white,
  // },
  bottomBar: {
    top: undefined,
    flexDirection: "row",
    zIndex: 18,
    paddingX: getPx(padding.primary),
    paddingTop: getPx(padding.ms),
    height: getPx(padding.bottomBar60),
    backgroundColor: colors.white,
    justifyContent: "space-between",
    alignItems: "flex-start",
    borderTopWidth: getPx(1),
    borderColor: colors.grayD,
    boxShadow: "0px -1px 5px 1px rgba(0,0,0,0.1)",
  },
  // iconContainerTop: {
  //   bottom: undefined,
  //   flexDirection: "row",
  //   paddingTop: getPx(padding.xl),
  //   paddingLeft: getPx(padding.md),
  //   paddingRight: getPx(padding.primary),
  //   justifyContent: "space-between",
  //   zIndex: 20,
  // },
  iconRight: {
    paddingLeft: [getPx(padding.sm), getPx(padding.ms)],
  },
  // myStarStyle: {
  //   color: colors.black,
  //   // bottom: 1,
  // },
  // providerContainer: {
  //   flexDirection: "row",
  // },
  // rate: {
  //   fontSize: getPx(30),
  //   marginY: getPx(10),
  //   // fontFamily: fonts.p600,
  // },
  review: {
    height: getPx(160),
    borderRadius: getPx(borders.borderRadius12),
    borderWidth: getPx(1),
    borderColor: colors.grayD,
    padding: getPx(padding.md),
    marginRight: getPx(padding.primary),
  },
  separator: {
    marginY: getPx(padding.primary),
  },
  // topActions: {
  //   bottom: undefined,
  //   height: getPx(padding.topBar80),
  //   backgroundColor: colors.white,
  //   borderBottomWidth: getPx(1),
  //   borderColor: colors.grayD,
  //   alignItems: "center",
  //   justifyContent: "space-between",
  //   flexDirection: "row",
  //   zIndex: 19,
  // },
});
