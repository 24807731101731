import React from "react";
import AppText from "./AppText";
import { useTranslateText } from "../hooks/useTranslate";
import AppTextTranslate from "./AppTextTranslate";
import lang from "../language";

export default function AppTextLang({
  langKey,
  fromLanguage = "en",
  ...props
}) {
  return (
    <AppTextTranslate fromLanguage={fromLanguage} langKey={langKey} {...props}>
      {lang[fromLanguage][langKey]}
    </AppTextTranslate>
  );
}
