import React, { useRef, useState } from "react";
import * as Yup from "yup";

import { fontConfig as fonts } from "../config/fonts";
import ListItemSeparator from "../components/lists/ListItemSeparator";
import Screen from "../components/Screen";
import padding from "../config/padding";
import AppCheckBox from "../components/AppCheckBox";
import AppForm from "../components/forms/AppForm";
import AppFormField from "../components/forms/AppFormField";
import SubmitButton from "../components/forms/SubmitButton";
// import { useScrollingHeaderReAnimation } from "../hooks/useAnimations";
// import AppAnimatedHeader from "../components/headers/AppAnimatedHeader";
// import AppReAnimatedHeader from "../components/headers/AppReAnimatedHeader";
import AppTextTranslate from "../components/AppTextTranslate";
import { useYouwho } from "../package/react-youwho";
import { useParams } from "react-router-dom";
import { useToast } from "../hooks/useToast";
import { getPx } from "../utils/helpers";
import View from "../components/rnMimic/View";
import { Flex } from "@chakra-ui/react";
import AppPressable from "../components/AppPressable";
import AppIcon from "../components/AppIcon";

const validationSchema = Yup.object().shape({
  reportCategory: Yup.string().required("A report category is required."),
  reportDescription: Yup.string()
    .required()
    .min(5)
    .max(500)
    .label("Description"),
});

const reportArray = [
  { title: "It's inaccurate or incorrect", key: "inaccurate" },
  { title: "It's not a real listing", key: "fake" },
  { title: "It's a scam", key: "scam" },
  { title: "It's offensive", key: "offensive" },
  { title: "It's something else", key: "other" },
];

export default function ReportListingScreen({}) {
  const { Parse, navigation } = useYouwho();
  const { listingId } = useParams();
  const [checked, setChecked] = useState("");
  const formikRef = useRef();
  // const { handleScroll, headerHeight, opacityStyle, translateYStyle } =
  //   useScrollingHeaderReAnimation();

  const Toast = useToast();

  const handleSubmit = async (
    { reportCategory, reportDescription },
    { resetForm }
  ) => {
    try {
      console.log("submit", reportCategory, reportDescription);
      await Parse.Cloud.run("reportListing", {
        listingId,
        reportCategory,
        reportDescription,
      });

      // await new Promise((resolve) => setTimeout(() => resolve(), 3000));

      resetForm();
      setChecked("");

      Toast.show({
        type: "appAlert",
        visibilityTime: 6000,
        props: {
          title: "Report Success!",
          subtitle: "Our team will action your report accordingly.",
          variant: "success",
        },
      });

      navigation(-1);
    } catch (error) {
      console.log(error);
      Toast.show({
        type: "appAlert",
        visibilityTime: 6000,
        props: {
          title: "Report Error",
          subtitle: error.message,
          variant: "error",
        },
      });
    } finally {
    }
  };

  // <AppReAnimatedHeader
  //   translateYStyle={translateYStyle}
  //   opacityStyle={opacityStyle}
  //   headerHeight={headerHeight}
  //   // heading={items.bookingsObject.id}
  // />

  return (
    <Screen
      sx={{
        paddingTop: getPx(padding.topBar70),
      }}
    >
      {/* **********Top Icon********** */}
      <Flex
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          height: getPx(padding.topBar60),
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          paddingX: getPx(padding.md),
          zIndex: 5,
        }}
      >
        <AppPressable
          onPress={() => {
            navigation(-1);
          }}
        >
          <AppIcon back />
        </AppPressable>
      </Flex>
      {/* **********End Top Icon********** */}

      <View
        // bounces={false}
        sx={{
          paddingX: getPx(padding.primary),
          // paddingTop: padding.primary,
          // paddingBottom: ,
        }}
        spacing={2}
      >
        <AppTextTranslate variant="h1">
          Why are you reporting this listing?
        </AppTextTranslate>
        <AppTextTranslate variant="md">
          This won't be shared with the Provider.
        </AppTextTranslate>
        <View style={{ height: getPx(40) }} />
        <AppForm
          initialValues={{
            reportCategory: "",
            reportDescription: "",
          }}
          innerRef={formikRef}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          <View spacing={1}>
            {reportArray.map((x, i) => (
              <>
                <Flex
                  sx={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                  key={x.key}
                >
                  <AppTextTranslate variant="lg">{x.title}</AppTextTranslate>
                  <AppCheckBox
                    checked={x.key === checked}
                    handleCheck={() => {
                      setChecked(x.key);
                      formikRef.current?.setFieldValue("reportCategory", x.key);
                    }}
                    handleUncheck={() => {
                      setChecked("");
                      formikRef.current?.setFieldValue("reportDescription", "");
                    }}
                  />
                </Flex>
                {reportArray.length - 1 !== i && (
                  <ListItemSeparator style={{ marginY: getPx(padding.lg) }} />
                )}
              </>
            ))}
          </View>

          <View style={{ height: getPx(padding.xl) }} />
          <AppFormField
            maxLength={500}
            showMaxLength
            multiline
            name="reportDescription"
            numberOfLines={6}
            placeholder="Please provide more details here"
            height="auto"
            expand
            noErrorMessage
            textInputStyle={{
              fontWeight: 500,
              fontSize: fonts.size.lg,
              minHeight: getPx(150),
            }}
          />
          <View style={{ height: getPx(30) }} />

          <SubmitButton iconLeft="send" title="Send" variant="outline" thick />
        </AppForm>
      </View>
    </Screen>
  );
}
