import React from "react";
import { Formik } from "formik";

export default function AppForm({
  initialValues,
  onSubmit,
  validationSchema,
  children,
  innerRef,
  enableReinitialize,
  validateOnChange = false,
  validateOnBlur,
  validateOnMount = false,
  ...otherProps
}) {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      innerRef={innerRef}
      enableReinitialize={enableReinitialize}
      validateOnChange={validateOnChange}
      validateOnBlur={validateOnBlur}
      validateOnMount={validateOnMount}
      {...otherProps}
    >
      {() => <>{children}</>}
    </Formik>
  );
}

