import React from "react";
import { Flex } from "@chakra-ui/react";
import { FiArrowLeft, FiX } from "react-icons/fi";
import colors from "../config/colors";
import { getPx, getSx } from "../utils/helpers";

export default function AppIcon({
  back,
  close,
  backgroundColor = colors.white,
  Icon,
  iconColor = colors.black,
  size = 40,
  style,
  iconX = back || close ? 0.6 : 0.5,
}) {
  return (
    <Flex
      sx={getSx([
        {
          width: getPx(size),
          height: getPx(size),
          borderRadius: getPx(size / 2),
          backgroundColor,
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
        },
        style,
      ])}
    >
      {back && <FiArrowLeft color={iconColor} size={size * iconX} />}
      {close && <FiX color={iconColor} size={size * iconX} />}
      {Icon && <Icon color={iconColor} size={size * iconX} />}
    </Flex>
  );
}

