export default Object.freeze({
  aboutUs: "About YOUWHO",
  accountDeletion: "Request account deletion",
  accountSettings: "Account Settings",
  useBiometrics: "Biometric lock",
  communityForum: "Community forum",
  currency: "Display currency",
  currentLanguage: "Eng",
  discuss: "Discussion group",
  email: "Email",
  favoriteListings: "My favorite listings",
  forgotPassword: "Forgot your password?",
  guestAccountHeading: "Your Account",
  guestAccountSubHeading: "Login to start booking your service.",
  guestBookingsHeading: "Bookings",
  guestBookingsSubTitle:
    "View all of your booked services here after you've logged in.",
  guestBookingsTitle: "No Bookings yet",
  guestLikesHeading: "Likes",
  guestLikesSubTitle: "You can view or edit your Likes once you've logged in.",
  guestLikesTitle: "Login to view your Likes",
  guestMessagesHeading: "Messages",
  guestMessagesSubTitle:
    "Once you login, you'll find messages from independent service providers here.",
  guestMessagesTitle: "Login to read messages",
  guestWalletHeading: "Wallet",
  guestWalletSubTitle:
    "You can view and manage your Wallet once you've logged in.",
  guestWalletTitle: "Login to view your Wallet",
  language: "Language",
  login: "Login",
  loginQuestion: "Already have an account?",
  logout: "Logout",
  notFound: "not found",
  password: "Password",
  personalInfo: "Personal information",
  resetPassword: "Reset password",
  signup: "Signup",
  signupQuestion: "Don't have an account?",
  support: "Support",
  switchToCustomer: "Switch to customer",
  switchToProvider: "Switch to provider",
  unlockReviews: "Unlock reviews & rewards",
  username: "Username",
  userProfile: "User Profile",
  version: "Version",
});
