export default Object.freeze({
  daysArray: [
    { day: "Sunday", date: "2021-10-03" },
    { day: "Monday", date: "2021-10-04" },
    { day: "Tuesday", date: "2021-10-05" },
    { day: "Wednesday", date: "2021-10-06" },
    { day: "Thursday", date: "2021-10-07" },
    { day: "Friday", date: "2021-10-08" },
    { day: "Saturday", date: "2021-10-09" },
  ],
  version: "v. 1.1.07",
  displayLanguage: "en",
  displayCurrency: "USD",
  supportedCurrencies: [
    {
      currencyCode: "AED",
      currencyLong: "United Arab Emirates Dirham",
      updatedAt: "2024-06-16T13-04-48Z",
      currencyVsUsd: 0.27233115468409586,
    },
    {
      currencyCode: "AFN",
      currencyLong: "Afghan Afghani",
      updatedAt: "2024-06-16T12-02-55Z",
      currencyVsUsd: 0.01414287128572843,
    },
    {
      currencyCode: "ALL",
      currencyLong: "Albanian Lek",
      updatedAt: "2024-06-16T12-02-58Z",
      currencyVsUsd: 0.01068604402650139,
    },
    {
      currencyCode: "AMD",
      currencyLong: "Armenian Dram",
      updatedAt: "2024-06-16T12-03-10Z",
      currencyVsUsd: 0.002577984016499098,
    },
    {
      currencyCode: "ANG",
      currencyLong: "Netherlands Antillean Guilder",
      updatedAt: "2024-06-16T12-03-18Z",
      currencyVsUsd: 0.5602240896358543,
    },
    {
      currencyCode: "AOA",
      currencyLong: "Angolan Kwanza",
      updatedAt: "2024-06-16T12-03-25Z",
      currencyVsUsd: 0.0011730425439069824,
    },
    {
      currencyCode: "ARS",
      currencyLong: "Argentine Peso",
      updatedAt: "2024-06-16T12-03-33Z",
      currencyVsUsd: 0.0011074197120708748,
    },
    {
      currencyCode: "AUD",
      currencyLong: "Australian Dollar",
      updatedAt: "2024-06-16T12-03-41Z",
      currencyVsUsd: 0.6614981610351124,
    },
    {
      currencyCode: "AWG",
      currencyLong: "Aruban Florin",
      updatedAt: "2024-06-16T12-03-57Z",
      currencyVsUsd: 0.5586592178770949,
    },
    {
      currencyCode: "AZN",
      currencyLong: "Azerbaijani Manat",
      updatedAt: "2024-06-16T12-04-02Z",
      currencyVsUsd: 0.5885815185403178,
    },
    {
      currencyCode: "BAM",
      currencyLong: "Bosnia-Herzegovina Convertible Marka",
      updatedAt: "2024-06-16T12-04-16Z",
      currencyVsUsd: 0.547645125958379,
    },
    {
      currencyCode: "BBD",
      currencyLong: "Bajan Dollar",
      updatedAt: "2024-06-16T12-04-16Z",
      currencyVsUsd: 0.5,
    },
    {
      currencyCode: "BDT",
      currencyLong: "Bangladeshi Taka",
      updatedAt: "2024-06-16T12-04-21Z",
      currencyVsUsd: 0.00851063829787234,
    },
    {
      currencyCode: "BGN",
      currencyLong: "Bulgarian Lev",
      updatedAt: "2024-06-16T12-04-25Z",
      currencyVsUsd: 0.5463883728554256,
    },
    {
      currencyCode: "BHD",
      currencyLong: "Bahraini Dinar",
      updatedAt: "2024-06-16T12-04-29Z",
      currencyVsUsd: 2.6532236667551072,
    },
    {
      currencyCode: "BIF",
      currencyLong: "Burundian Franc",
      updatedAt: "2024-06-16T12-04-41Z",
      currencyVsUsd: 0.00034973350307066013,
    },
    {
      currencyCode: "BMD",
      currencyLong: "Bermudan Dollar",
      updatedAt: "2024-06-16T12-05-04Z",
      currencyVsUsd: 1,
    },
    {
      currencyCode: "BND",
      currencyLong: "Brunei Dollar",
      updatedAt: "2024-06-16T12-05-04Z",
      currencyVsUsd: 0.7396449704142012,
    },
    {
      currencyCode: "BOB",
      currencyLong: "Bolivian Boliviano",
      updatedAt: "2024-06-16T12-05-07Z",
      currencyVsUsd: 0.14705882352941177,
    },
    {
      currencyCode: "BRL",
      currencyLong: "Brazilian Real",
      updatedAt: "2024-06-16T12-05-20Z",
      currencyVsUsd: 0.1859427296392711,
    },
    {
      currencyCode: "BSD",
      currencyLong: "Bahamian Dollar",
      updatedAt: "2024-06-16T12-06-28Z",
      currencyVsUsd: 1,
    },
    {
      currencyCode: "BTN",
      currencyLong: "Bhutanese Ngultrum",
      updatedAt: "2024-06-16T12-06-37Z",
      currencyVsUsd: 0.011964584828906437,
    },
    {
      currencyCode: "BWP",
      currencyLong: "Botswanan Pula",
      updatedAt: "2024-06-16T12-06-43Z",
      currencyVsUsd: 0.07332453438920664,
    },
    {
      currencyCode: "BYN",
      currencyLong: "Belarusian Ruble",
      updatedAt: "2024-06-16T12-06-54Z",
      currencyVsUsd: 0.3125,
    },
    {
      currencyCode: "BZD",
      currencyLong: "Belize Dollar",
      updatedAt: "2024-06-16T12-07-03Z",
      currencyVsUsd: 0.5005506056662329,
    },
    {
      currencyCode: "CAD",
      currencyLong: "Canadian Dollar",
      updatedAt: "2024-06-16T12-07-13Z",
      currencyVsUsd: 0.7283851700779372,
    },
    {
      currencyCode: "CDF",
      currencyLong: "Congolese Franc",
      updatedAt: "2024-06-16T12-07-24Z",
      currencyVsUsd: 0.000354924578527063,
    },
    {
      currencyCode: "CHF",
      currencyLong: "Swiss Franc",
      updatedAt: "2024-06-16T12-07-31Z",
      currencyVsUsd: 1.124075447944066,
    },
    {
      currencyCode: "CLF",
      currencyLong: "Chilean Unit of Account (UF)",
      updatedAt: "2024-06-16T12-07-39Z",
      currencyVsUsd: 40.65040650406504,
    },
    {
      currencyCode: "CLP",
      currencyLong: "Chilean Peso",
      updatedAt: "2024-06-16T12-07-42Z",
      currencyVsUsd: 0.00106700810926163,
    },
    {
      currencyCode: "CNY",
      currencyLong: "Chinese Yuan",
      updatedAt: "2024-06-16T12-53-07Z",
      currencyVsUsd: 0.13783217554305877,
    },
    {
      currencyCode: "COP",
      currencyLong: "Colombian Peso",
      updatedAt: "2024-06-16T12-53-24Z",
      currencyVsUsd: 0.00024223632575941,
    },
    {
      currencyCode: "CRC",
      currencyLong: "Costa Rican Colón",
      updatedAt: "2024-06-16T12-53-37Z",
      currencyVsUsd: 0.001921340327012123,
    },
    {
      currencyCode: "CVE",
      currencyLong: "Cape Verdean Escudo",
      updatedAt: "2024-06-16T12-53-48Z",
      currencyVsUsd: 0.009708737864077669,
    },
    {
      currencyCode: "CZK",
      currencyLong: "Czech Koruna",
      updatedAt: "2024-06-16T12-53-56Z",
      currencyVsUsd: 0.04332004851845434,
    },
    {
      currencyCode: "DJF",
      currencyLong: "Djiboutian Franc",
      updatedAt: "2024-06-16T12-54-08Z",
      currencyVsUsd: 0.005624929688378895,
    },
    {
      currencyCode: "DKK",
      currencyLong: "Danish Krone",
      updatedAt: "2024-06-16T12-54-15Z",
      currencyVsUsd: 0.14345987929285758,
    },
    {
      currencyCode: "DOP",
      currencyLong: "Dominican Peso",
      updatedAt: "2024-06-16T12-54-21Z",
      currencyVsUsd: 0.01697792869269949,
    },
    {
      currencyCode: "DZD",
      currencyLong: "Algerian Dinar",
      updatedAt: "2024-06-16T12-54-50Z",
      currencyVsUsd: 0.007439756571164991,
    },
    {
      currencyCode: "EGP",
      currencyLong: "Egyptian Pound",
      updatedAt: "2024-06-16T12-55-00Z",
      currencyVsUsd: 0.020981955518254304,
    },
    {
      currencyCode: "ETB",
      currencyLong: "Ethiopian Birr",
      updatedAt: "2024-06-16T13-00-57Z",
      currencyVsUsd: 0.017516202487300753,
    },
    {
      currencyCode: "EUR",
      currencyLong: "Euro",
      updatedAt: "2024-06-16T13-01-05Z",
      currencyVsUsd: 1.070663811563169,
    },
    {
      currencyCode: "FJD",
      currencyLong: "Fijian Dollar",
      updatedAt: "2024-06-16T13-05-30Z",
      currencyVsUsd: 0.4476275738585497,
    },
    {
      currencyCode: "GBP",
      currencyLong: "Pound sterling",
      updatedAt: "2024-06-16T13-05-41Z",
      currencyVsUsd: 1.268874508311128,
    },
    {
      currencyCode: "GEL",
      currencyLong: "Georgian Lari",
      updatedAt: "2024-06-16T13-05-48Z",
      currencyVsUsd: 0.3497359493582345,
    },
    {
      currencyCode: "GHS",
      currencyLong: "Ghanaian Cedi",
      updatedAt: "2024-06-16T13-06-00Z",
      currencyVsUsd: 0.06666666666666667,
    },
    {
      currencyCode: "GMD",
      currencyLong: "Gambian Dalasi",
      updatedAt: "2024-06-16T13-06-05Z",
      currencyVsUsd: 0.014814814814814815,
    },
    {
      currencyCode: "GNF",
      currencyLong: "Guinean Franc",
      updatedAt: "2024-06-16T13-06-20Z",
      currencyVsUsd: 0.000118581762124985,
    },
    {
      currencyCode: "GTQ",
      currencyLong: "Guatemalan Quetzal",
      updatedAt: "2024-06-16T13-06-28Z",
      currencyVsUsd: 0.1287166945552838,
    },
    {
      currencyCode: "GYD",
      currencyLong: "Guyanaese Dollar",
      updatedAt: "2024-06-16T13-06-35Z",
      currencyVsUsd: 0.004808154630252909,
    },
    {
      currencyCode: "HKD",
      currencyLong: "Hong Kong Dollar",
      updatedAt: "2024-06-16T13-06-41Z",
      currencyVsUsd: 0.12803441565092696,
    },
    {
      currencyCode: "HNL",
      currencyLong: "Honduran Lempira",
      updatedAt: "2024-06-16T13-06-54Z",
      currencyVsUsd: 0.04053391269805883,
    },
    {
      currencyCode: "HTG",
      currencyLong: "Haitian Gourde",
      updatedAt: "2024-06-16T13-47-18Z",
      currencyVsUsd: 0.007559721802237677,
    },
    {
      currencyCode: "HUF",
      currencyLong: "Hungarian Forint",
      updatedAt: "2024-06-16T13-47-23Z",
      currencyVsUsd: 0.002688388848563056,
    },
    {
      currencyCode: "IDR",
      currencyLong: "Indonesian Rupiah",
      updatedAt: "2024-06-16T13-47-31Z",
      currencyVsUsd: 0.000060997926070513,
    },
    {
      currencyCode: "ILS",
      currencyLong: "Israeli New Shekel",
      updatedAt: "2024-06-16T13-47-37Z",
      currencyVsUsd: 0.2688750268875027,
    },
    {
      currencyCode: "INR",
      currencyLong: "Indian Rupee",
      updatedAt: "2024-06-16T13-21-50Z",
      currencyVsUsd: 0.011972176661438816,
    },
    {
      currencyCode: "IQD",
      currencyLong: "Iraqi Dinar",
      updatedAt: "2024-06-16T13-22-05Z",
      currencyVsUsd: 0.000763941940412528,
    },
    {
      currencyCode: "IRR",
      currencyLong: "Iranian Rial",
      updatedAt: "2024-06-16T13-22-15Z",
      currencyVsUsd: 0.000238095238095238,
    },
    {
      currencyCode: "ISK",
      currencyLong: "Icelandic Króna",
      updatedAt: "2024-06-16T13-22-26Z",
      currencyVsUsd: 0.007173601147776184,
    },
    {
      currencyCode: "JMD",
      currencyLong: "Jamaican Dollar",
      updatedAt: "2024-06-16T13-22-40Z",
      currencyVsUsd: 0.006444544692917446,
    },
    {
      currencyCode: "JOD",
      currencyLong: "Jordanian Dinar",
      updatedAt: "2024-06-16T13-22-55Z",
      currencyVsUsd: 1.4128284826222097,
    },
    {
      currencyCode: "JPY",
      currencyLong: "Japanese Yen",
      updatedAt: "2024-06-16T13-23-08Z",
      currencyVsUsd: 0.006356349675508349,
    },
    {
      currencyCode: "KES",
      currencyLong: "Kenyan Shilling",
      updatedAt: "2024-06-16T13-23-20Z",
      currencyVsUsd: 0.00778816199376947,
    },
    {
      currencyCode: "KGS",
      currencyLong: "Kyrgystani Som",
      updatedAt: "2024-06-16T13-23-32Z",
      currencyVsUsd: 0.011385022092635371,
    },
    {
      currencyCode: "KHR",
      currencyLong: "Cambodian Riel",
      updatedAt: "2024-06-16T13-23-40Z",
      currencyVsUsd: 0.000243249817562636,
    },
    {
      currencyCode: "KMF",
      currencyLong: "Comorian Franc",
      updatedAt: "2024-06-16T13-24-59Z",
      currencyVsUsd: 0.00217296827466319,
    },
    {
      currencyCode: "KRW",
      currencyLong: "South Korean Won",
      updatedAt: "2024-06-16T13-25-08Z",
      currencyVsUsd: 0.000723693732812273,
    },
    {
      currencyCode: "KWD",
      currencyLong: "Kuwaiti Dinar",
      updatedAt: "2024-06-16T13-25-17Z",
      currencyVsUsd: 3.2626427406199023,
    },
    {
      currencyCode: "KYD",
      currencyLong: "Cayman Islands Dollar",
      updatedAt: "2024-06-16T13-25-25Z",
      currencyVsUsd: 1.2195121951219512,
    },
    {
      currencyCode: "KZT",
      currencyLong: "Kazakhstani Tenge",
      updatedAt: "2024-06-16T13-25-37Z",
      currencyVsUsd: 0.002213368747233289,
    },
    {
      currencyCode: "LAK",
      currencyLong: "Laotian Kip",
      updatedAt: "2024-06-16T13-25-48Z",
      currencyVsUsd: 0.000045831614647784,
    },
    {
      currencyCode: "LBP",
      currencyLong: "Lebanese Pound",
      updatedAt: "2024-06-16T13-26-01Z",
      currencyVsUsd: 0.000011173184357541,
    },
    {
      currencyCode: "LKR",
      currencyLong: "Sri Lankan Rupee",
      updatedAt: "2024-06-16T13-26-15Z",
      currencyVsUsd: 0.003298261816022956,
    },
    {
      currencyCode: "LRD",
      currencyLong: "Liberian Dollar",
      updatedAt: "2024-06-16T13-26-25Z",
      currencyVsUsd: 0.005178663904712584,
    },
    {
      currencyCode: "LSL",
      currencyLong: "Lesotho Loti",
      updatedAt: "2024-06-16T13-26-31Z",
      currencyVsUsd: 0.0543773790103317,
    },
    {
      currencyCode: "LYD",
      currencyLong: "Libyan Dinar",
      updatedAt: "2024-06-16T13-27-17Z",
      currencyVsUsd: 0.20673116679070536,
    },
    {
      currencyCode: "MAD",
      currencyLong: "Moroccan Dirham",
      updatedAt: "2024-06-16T13-27-22Z",
      currencyVsUsd: 0.10005202705406811,
    },
    {
      currencyCode: "MDL",
      currencyLong: "Moldovan Leu",
      updatedAt: "2024-06-16T13-27-28Z",
      currencyVsUsd: 0.056818181818181816,
    },
    {
      currencyCode: "MGA",
      currencyLong: "Malagasy Ariary",
      updatedAt: "2024-06-16T13-27-33Z",
      currencyVsUsd: 0.000224971878515185,
    },
    {
      currencyCode: "MKD",
      currencyLong: "Macedonian Denar",
      updatedAt: "2024-06-16T13-27-39Z",
      currencyVsUsd: 0.017391304347826087,
    },
    {
      currencyCode: "MMK",
      currencyLong: "Myanmar Kyat",
      updatedAt: "2024-06-16T13-27-46Z",
      currencyVsUsd: 0.000477627908753964,
    },
    {
      currencyCode: "MOP",
      currencyLong: "Macanese Pataca",
      updatedAt: "2024-06-16T13-27-54Z",
      currencyVsUsd: 0.12431626056688214,
    },
    {
      currencyCode: "MUR",
      currencyLong: "Mauritian Rupee",
      updatedAt: "2024-06-16T13-30-45Z",
      currencyVsUsd: 0.02138122728244601,
    },
    {
      currencyCode: "MVR",
      currencyLong: "Maldivian Rufiyaa",
      updatedAt: "2024-06-16T13-30-53Z",
      currencyVsUsd: 0.0648508430609598,
    },
    {
      currencyCode: "MWK",
      currencyLong: "Malawian Kwacha",
      updatedAt: "2024-06-16T13-30-59Z",
      currencyVsUsd: 0.000582502781450781,
    },
    {
      currencyCode: "MXN",
      currencyLong: "Mexican Peso",
      updatedAt: "2024-06-16T13-31-05Z",
      currencyVsUsd: 0.05419466724474311,
    },
    {
      currencyCode: "MYR",
      currencyLong: "Malaysian Ringgit",
      updatedAt: "2024-06-16T13-31-13Z",
      currencyVsUsd: 0.211999152003392,
    },
    {
      currencyCode: "MZN",
      currencyLong: "Mozambican Metical",
      updatedAt: "2024-06-16T13-31-18Z",
      currencyVsUsd: 0.015807777426493835,
    },
    {
      currencyCode: "NAD",
      currencyLong: "Namibian Dollar",
      updatedAt: "2024-06-16T13-31-22Z",
      currencyVsUsd: 0.054564304032302065,
    },
    {
      currencyCode: "NGN",
      currencyLong: "Nigerian Naira",
      updatedAt: "2024-06-16T13-31-27Z",
      currencyVsUsd: 0.000674946004319654,
    },
    {
      currencyCode: "NIO",
      currencyLong: "Nicaraguan Córdoba",
      updatedAt: "2024-06-16T13-31-32Z",
      currencyVsUsd: 0.027304276122683574,
    },
    {
      currencyCode: "NOK",
      currencyLong: "Norwegian Krone",
      updatedAt: "2024-06-16T13-31-37Z",
      currencyVsUsd: 0.09372422583789457,
    },
    {
      currencyCode: "NPR",
      currencyLong: "Nepalese Rupee",
      updatedAt: "2024-06-16T13-31-43Z",
      currencyVsUsd: 0.007481893816962949,
    },
    {
      currencyCode: "NZD",
      currencyLong: "New Zealand Dollar",
      updatedAt: "2024-06-16T13-31-49Z",
      currencyVsUsd: 0.61436382625791,
    },
    {
      currencyCode: "OMR",
      currencyLong: "Omani Rial",
      updatedAt: "2024-06-16T13-31-55Z",
      currencyVsUsd: 2.5974025974025974,
    },
    {
      currencyCode: "PAB",
      currencyLong: "Panamanian Balboa",
      updatedAt: "2024-06-16T13-32-01Z",
      currencyVsUsd: 1,
    },
    {
      currencyCode: "PEN",
      currencyLong: "Peruvian Sol",
      updatedAt: "2024-06-16T13-32-05Z",
      currencyVsUsd: 0.26513243365060846,
    },
    {
      currencyCode: "PGK",
      currencyLong: "Papua New Guinean Kina",
      updatedAt: "2024-06-16T13-32-11Z",
      currencyVsUsd: 0.2604166666666667,
    },
    {
      currencyCode: "PHP",
      currencyLong: "Philippine Peso",
      updatedAt: "2024-06-16T13-32-18Z",
      currencyVsUsd: 0.017064846416382253,
    },
    {
      currencyCode: "PKR",
      currencyLong: "Pakistani Rupee",
      updatedAt: "2024-06-16T13-32-24Z",
      currencyVsUsd: 0.003592470182497485,
    },
    {
      currencyCode: "PLN",
      currencyLong: "Poland Złoty",
      updatedAt: "2024-06-16T13-32-29Z",
      currencyVsUsd: 0.2445944623813717,
    },
    {
      currencyCode: "PYG",
      currencyLong: "Paraguayan Guarani",
      updatedAt: "2024-06-16T13-32-38Z",
      currencyVsUsd: 0.000132930995520225,
    },
    {
      currencyCode: "QAR",
      currencyLong: "Qatari Rial",
      updatedAt: "2024-06-16T13-32-43Z",
      currencyVsUsd: 0.2747252747252747,
    },
    {
      currencyCode: "RON",
      currencyLong: "Romanian Leu",
      updatedAt: "2024-06-16T13-32-48Z",
      currencyVsUsd: 0.21561017680034497,
    },
    {
      currencyCode: "RSD",
      currencyLong: "Serbian Dinar",
      updatedAt: "2024-06-16T13-32-58Z",
      currencyVsUsd: 0.009200800101576832,
    },
    {
      currencyCode: "RUB",
      currencyLong: "Russian Ruble",
      updatedAt: "2024-06-16T13-33-05Z",
      currencyVsUsd: 0.0111617108670417,
    },
    {
      currencyCode: "RWF",
      currencyLong: "Rwandan Franc",
      updatedAt: "2024-06-16T13-33-10Z",
      currencyVsUsd: 0.00077267810230258,
    },
    {
      currencyCode: "SAR",
      currencyLong: "Saudi Riyal",
      updatedAt: "2024-06-16T13-33-15Z",
      currencyVsUsd: 0.26653872807718965,
    },
    {
      currencyCode: "SBD",
      currencyLong: "Solomon Islands Dollar",
      updatedAt: "2024-06-16T13-33-22Z",
      currencyVsUsd: 0.1182284646851576,
    },
    {
      currencyCode: "SCR",
      currencyLong: "Seychellois Rupee",
      updatedAt: "2024-06-16T13-33-27Z",
      currencyVsUsd: 0.06896551724137931,
    },
    {
      currencyCode: "SDG",
      currencyLong: "Sudanese Pound",
      updatedAt: "2024-06-16T13-33-33Z",
      currencyVsUsd: 0.001670425123193852,
    },
    {
      currencyCode: "SEK",
      currencyLong: "Swedish Krona",
      updatedAt: "2024-06-16T13-33-39Z",
      currencyVsUsd: 0.09516739945564248,
    },
    {
      currencyCode: "SGD",
      currencyLong: "Singapore Dollar",
      updatedAt: "2024-06-16T13-33-45Z",
      currencyVsUsd: 0.7392075694855116,
    },
    {
      currencyCode: "SLL",
      currencyLong: "Sierra Leonean Leone",
      updatedAt: "2024-06-16T13-33-51Z",
      currencyVsUsd: 0.000044768769306531,
    },
    {
      currencyCode: "SOS",
      currencyLong: "Somali Shilling",
      updatedAt: "2024-06-16T13-33-57Z",
      currencyVsUsd: 0.001758396342535607,
    },
    {
      currencyCode: "SRD",
      currencyLong: "Surinamese Dollar",
      updatedAt: "2024-06-16T13-34-03Z",
      currencyVsUsd: 0.031928480204342274,
    },
    {
      currencyCode: "SVC",
      currencyLong: "Salvadoran Colón",
      updatedAt: "2024-06-16T13-34-10Z",
      currencyVsUsd: 0.1149425287356322,
    },
    {
      currencyCode: "SZL",
      currencyLong: "Swazi Lilangeni",
      updatedAt: "2024-06-16T13-34-16Z",
      currencyVsUsd: 0.054576215685204384,
    },
    {
      currencyCode: "THB",
      currencyLong: "Thai Baht",
      updatedAt: "2024-06-16T13-34-21Z",
      currencyVsUsd: 0.027292576419213975,
    },
    {
      currencyCode: "TJS",
      currencyLong: "Tajikistani Somoni",
      updatedAt: "2024-06-16T13-34-27Z",
      currencyVsUsd: 0.09297136481963555,
    },
    {
      currencyCode: "TMT",
      currencyLong: "Turkmenistan Manat",
      updatedAt: "2024-06-16T13-34-44Z",
      currencyVsUsd: 0.29850746268656714,
    },
    {
      currencyCode: "TND",
      currencyLong: "Tunisian Dinar",
      updatedAt: "2024-06-16T13-34-49Z",
      currencyVsUsd: 0.31953986259785905,
    },
    {
      currencyCode: "TOP",
      currencyLong: "Tongan Pa'anga",
      updatedAt: "2024-06-16T13-34-57Z",
      currencyVsUsd: 0.42973785990545765,
    },
    {
      currencyCode: "TRY",
      currencyLong: "Turkish Lira",
      updatedAt: "2024-06-16T13-35-02Z",
      currencyVsUsd: 0.03053248656570591,
    },
    {
      currencyCode: "TTD",
      currencyLong: "Trinidad and Tobago Dollar",
      updatedAt: "2024-06-16T13-41-26Z",
      currencyVsUsd: 0.1479946721918011,
    },
    {
      currencyCode: "TWD",
      currencyLong: "New Taiwan Dollar",
      updatedAt: "2024-06-16T13-41-31Z",
      currencyVsUsd: 0.030932937391734715,
    },
    {
      currencyCode: "TZS",
      currencyLong: "Tanzanian Shilling",
      updatedAt: "2024-06-16T13-41-43Z",
      currencyVsUsd: 0.000381825124093165,
    },
    {
      currencyCode: "UAH",
      currencyLong: "Ukrainian hryvnia",
      updatedAt: "2024-06-16T13-41-49Z",
      currencyVsUsd: 0.024576062914721062,
    },
    {
      currencyCode: "UGX",
      currencyLong: "Ugandan Shilling",
      updatedAt: "2024-06-16T13-41-53Z",
      currencyVsUsd: 0.000269614451334591,
    },
    {
      currencyCode: "USD",
      currencyLong: "United States Dollar",
      updatedAt: "2024-06-16T13-42-03Z",
      currencyVsUsd: 1,
    },
    {
      currencyCode: "UYU",
      currencyLong: "Uruguayan Peso",
      updatedAt: "2024-06-16T13-42-03Z",
      currencyVsUsd: 0.02546019298826285,
    },
    {
      currencyCode: "UZS",
      currencyLong: "Uzbekistani Som",
      updatedAt: "2024-06-16T13-42-12Z",
      currencyVsUsd: 0.000079365079365079,
    },
    {
      currencyCode: "VES",
      currencyLong: "Sovereign Bolivar",
      updatedAt: "2024-06-16T13-42-20Z",
      currencyVsUsd: 0.02751940117783037,
    },
    {
      currencyCode: "VND",
      currencyLong: "Vietnamese Dong",
      updatedAt: "2024-06-16T13-42-25Z",
      currencyVsUsd: 0.000392927308447937,
    },
    {
      currencyCode: "XAF",
      currencyLong: "Central African CFA Franc",
      updatedAt: "2024-06-16T13-42-31Z",
      currencyVsUsd: 0.001633186346562143,
    },
    {
      currencyCode: "XCD",
      currencyLong: "East Caribbean Dollar",
      updatedAt: "2024-06-16T13-42-38Z",
      currencyVsUsd: 0.37037037037037035,
    },
    {
      currencyCode: "XOF",
      currencyLong: "West African CFA Franc",
      updatedAt: "2024-06-16T13-42-42Z",
      currencyVsUsd: 0.001633186346562143,
    },
    {
      currencyCode: "XPF",
      currencyLong: "CFP Franc",
      updatedAt: "2024-06-16T13-42-47Z",
      currencyVsUsd: 0.008987148377819719,
    },
    {
      currencyCode: "YER",
      currencyLong: "Yemeni Rial",
      updatedAt: "2024-06-16T13-42-57Z",
      currencyVsUsd: 0.004000320025602048,
    },
    {
      currencyCode: "ZAR",
      currencyLong: "South African Rand",
      updatedAt: "2024-06-16T13-43-02Z",
      currencyVsUsd: 0.05452949227589742,
    },
    {
      currencyCode: "ZMW",
      currencyLong: "Zambian Kwacha",
      updatedAt: "2024-06-16T13-43-08Z",
      currencyVsUsd: 0.038476337052712584,
    },
  ],
  listingsSearchObject: {
    searchText: "who do you need?",
    id: "",
    string: "",
    category: [],
    serviceLocation: "",
    searchLocation: "", // near or country
    searchWithinKm: "20", // if near then use this as a geo
    country: [], // if country then
    limit: 51,
    maxResults: 510,
    filters: {
      rateLower: "", // range
      rateUpper: "", // range
      certification: false,
      verifiedUsers: false,
      reviews: "", // "sort most popular first" "sort least popular first" ascending descending
      ratingLower: 0, // range
      ratingUpper: 5, // range
      age: "sort most active first", // "sort least active first" ascending descending
    },
  },
  selectedChain: {
    chainName: "bsc",
    chainId: "0x38",
    chainType: "main",
    chainInt: "56",
    chainTitle: "Binance Smart Chain",
    nativeToken: "bnb",
    nativeTokenName: "Binance",
    isSupported: true,
  },
  selectedLocation: {
    latitude: 40.7127744,
    longitude: -74.0082477,
    delta: undefined,
    name: undefined,
    address: undefined,
    country: {
      long_name: "United States of America",
      short_name: "US",
    },
  },
  notificationSettings: {
    bookings: {
      id: "bookings",
      name: "Bookings",
      importance: "HIGH",
      sound: "youwhoalert",
      enabled: true,
    },
    general: {
      id: "general",
      name: "General",
      importance: "HIGH",
      sound: "youwhoalert",
      enabled: true,
    },
    messages: {
      id: "messages",
      name: "Messages",
      importance: "HIGH",
      sound: "youwhoalert",
      enabled: true,
    },
    rewards: {
      id: "rewards",
      name: "Rewards",
      importance: "HIGH",
      sound: "youwhoalert",
      enabled: true,
    },
    transfers: {
      id: "transfers",
      name: "Transfers",
      importance: "HIGH",
      sound: "youwhoalert",
      enabled: true,
    },
  },
  url: {
    desktopApp: "https://app.youwho.io",
    // desktop: 'https://why.youwho.io',
    iosApp: "https://ios.youwho.io",
    androidApp: "https://android.youwho.io",
    youwhoApp: "https://youwhoapp.youwho.io",
    tos: "https://why.youwho.io/tos",
    privacy: "https://why.youwho.io/privacy",
    gmapsQuery: "https://maps.google.com/maps?q=",
    discord: "https://discord.youwho.io",
    github: "https://git.youwho.io",
    website: "https://youwho.io",
    howYouwhoWorks: "https://why.youwho.io/#how",
    buyYou: "https://buy.youwho.io",
    moralisIpfsGateway: "https://gateway.moralisipfs.com/ipfs/",
    backupIpfsGateway: "https://ipfs.io/ipfs/",
    accountDeletion: "https://why.youwho.io/account-deletion/",
  },
  categories: [
    "cleaner",
    "nurse",
    "healthcare assistant",
    "landscaper",
    "gardener",
    "vet",
    "plumber",
    "pool cleaner",
    "vehicle road side assistance",
    "mechanic",
    "tutor",
    "teacher",
    "electrician",
    "dog walker",
    "personal trainer",
    "bookkeeper",
    "accountant",
    "hospitality staff",
    "dog trainer",
    "manicurist / pedicurist",
    "mobile phone repairer",
    "personal chef",
    "chef",
    "painter",
    "pet sitter",
    "baby sitter",
    "hair dresser",
    "movers",
    "pest controller",
    "music teacher",
    "interior designer",
    "legal consultant",
    "lawyer",
    "flooring / carpet installer",
    "agricultural hand",
    "general labourer",
    "florist",
    "physiotherapist",
    "chiropractor",
    "tour guide",
    "psychologist",
    "private investigator",
    "mid wife",
    "wet nurse",
    "translator",
    "dj",
    "clown",
    "it specialist",
    "carpenter",
    "programmer",
    "web developer",
    "engineer",
    "maid",
    "other",
    "insurance broker",
    "writer",
    "seo specialist",
    "graphic designer",
    "fashion designer",
    "driver",
    "motorcycle rider",
    "courier",
    "taxi",
    "motorcycle taxi",
  ],
  cat2: [],
  customMapStyle: [
    {
      featureType: "administrative",
      elementType: "all",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "administrative",
      elementType: "geometry.fill",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "administrative",
      elementType: "geometry.stroke",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "administrative",
      elementType: "labels.text",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "landscape",
      elementType: "all",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "all",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [
        {
          lightness: "0",
        },
      ],
    },
    {
      featureType: "poi.medical",
      elementType: "geometry.fill",
      stylers: [
        {
          lightness: "-5",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "geometry.fill",
      stylers: [
        {
          visibility: "on",
        },
        {
          color: "#a7ce95",
        },
        {
          lightness: "45",
        },
      ],
    },
    {
      featureType: "poi.school",
      elementType: "geometry",
      stylers: [
        {
          color: "#be9b7b",
        },
        {
          lightness: "70",
        },
      ],
    },
    {
      featureType: "poi.sports_complex",
      elementType: "geometry",
      stylers: [
        {
          color: "#5d4b46",
        },
        {
          lightness: "60",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "all",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "transit.station",
      elementType: "geometry",
      stylers: [
        {
          saturation: "23",
        },
        {
          lightness: "10",
        },
        {
          gamma: "0.8",
        },
        {
          hue: "#b000ff",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "all",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#a2daf2",
        },
      ],
    },
  ],
  languageList: [
    {
      languageNameEnglish: "Afrikaans",
      languageCode: "af",
      languageNameNative: "Afrikaans",
    },
    {
      languageNameEnglish: "Albanian",
      languageCode: "sq",
      languageNameNative: "shqip / gjuha shqipe",
      languageCodeDesktop: "sq",
    },
    {
      languageNameEnglish: "Amharic",
      languageCode: "am",
      languageNameNative: "ኣማርኛ (amarəñña)",
    },
    {
      languageNameEnglish: "Arabic",
      languageCode: "ar",
      languageNameNative: "(al arabiya) العربية",
      languageCodeDesktop: "ar",
    },
    {
      languageNameEnglish: "Armenian",
      languageCode: "hy",
      languageNameNative: "Հայերէն (Hayeren) / Հայերեն լեզու (Hayeren lezou)",
    },
    {
      languageNameEnglish: "Assamese",
      languageCode: "as",
      languageNameNative: "অসমীয়া (asamīẏa)",
    },
    {
      languageNameEnglish: "Aymara",
      languageCode: "ay",
      languageNameNative: "aymar aru",
    },
    {
      languageNameEnglish: "Azerbaijani",
      languageCode: "az",
      languageNameNative:
        "Azərbaycan dili / aзәp6ajҹaн дили / آذربايجانجا ديلي",
      languageCodeDesktop: "az",
    },
    {
      languageNameEnglish: "Bambara",
      languageCode: "bm",
      languageNameNative: "Bamanankan",
    },
    {
      languageNameEnglish: "Basque",
      languageCode: "eu",
      languageNameNative: "euskara",
    },
    {
      languageNameEnglish: "Belarusian",
      languageCode: "be",
      languageNameNative: "Бeлapycкaя мoвa (Bielaruskaja mova)",
    },
    {
      languageNameEnglish: "Bengali",
      languageCode: "bn",
      languageNameNative: "বাংলা (baɛṅlā)",
      languageCodeDesktop: "bn",
    },
    {
      languageNameEnglish: "Bhojpuri",
      languageCode: "bho",
      languageNameNative: "भोजपुरी (bʰojpurī)",
    },
    {
      languageNameEnglish: "Bosnian",
      languageCode: "bs",
      languageNameNative: "bosanski / 6ocaнcки / بۉسانسقى",
    },
    {
      languageNameEnglish: "Bulgarian",
      languageCode: "bg",
      languageNameNative:
        "6ълrapcки (bãlgarski) 6ълrapcки eзик (bãlgarski ezik)",
      languageCodeDesktop: "bg",
    },
    {
      languageNameEnglish: "Catalan",
      languageCode: "ca",
      languageNameNative: "català",
      languageCodeDesktop: "ca",
    },
    {
      languageNameEnglish: "Cebuano",
      languageCode: "ceb",
      languageNameNative: "Sinugboanon / Sugboanon",
    },
    {
      languageNameEnglish: "Chichewa",
      languageCode: "ny",
      languageNameNative: "Chicheŵa",
    },
    {
      languageNameEnglish: "Chinese (Simplified)",
      languageCode: "zh-CN",
      languageCodeDesktop: "zh",
      languageNameNative: "中国人",
    },
    {
      languageNameEnglish: "Chinese (Traditional)",
      languageCode: "zh-TW",
      languageCodeDesktop: "zt",
      languageNameNative: "中國人",
    },
    {
      languageNameEnglish: "Corsican",
      languageCode: "co",
      languageNameNative: "corsu",
    },
    {
      languageNameEnglish: "Croatian",
      languageCode: "hr",
      languageNameNative: "Hrvatski",
    },
    {
      languageNameEnglish: "Czech",
      languageCode: "cs",
      languageNameNative: "čeština / český jazyk",
      languageCodeDesktop: "cs",
    },
    {
      languageNameEnglish: "Danish",
      languageCode: "da",
      languageNameNative: "dansk",
      languageCodeDesktop: "da",
    },
    {
      languageNameEnglish: "Dhivehi",
      languageCode: "dv",
      languageNameNative: "ދިވެހި",
    },
    {
      languageNameEnglish: "Dogri",
      languageCode: "doi",
      languageNameNative: "डोगरी",
    },
    {
      languageNameEnglish: "Dutch",
      languageCode: "nl",
      languageNameNative: "Nederlands",
      languageCodeDesktop: "nl",
    },
    {
      languageNameEnglish: "English",
      languageCode: "en",
      languageNameNative: "English",
      languageCodeDesktop: "en",
    },
    {
      languageNameEnglish: "Esperanto",
      languageCode: "eo",
      languageNameNative: "Esperanto",
      languageCodeDesktop: "eo",
    },
    {
      languageNameEnglish: "Estonian",
      languageCode: "et",
      languageNameNative: "eesti keel",
      languageCodeDesktop: "et",
    },
    {
      languageNameEnglish: "Ewe",
      languageCode: "ee",
      languageNameNative: "Eʋegbe",
    },
    {
      languageNameEnglish: "Filipino",
      languageCode: "tl",
      languageNameNative: "Filipino",
      languageCodeDesktop: "tl",
    },
    {
      languageNameEnglish: "Finnish",
      languageCode: "fi",
      languageNameNative: "suomi / suomen kieli",
      languageCodeDesktop: "fi",
    },
    {
      languageNameEnglish: "French",
      languageCode: "fr",
      languageNameNative: "français",
      languageCodeDesktop: "fr",
    },
    {
      languageNameEnglish: "Frisian",
      languageCode: "fy",
      languageNameNative: "Frysk",
    },
    {
      languageNameEnglish: "Galician",
      languageCode: "gl",
      languageNameNative: "Galego",
    },
    {
      languageNameEnglish: "Georgian",
      languageCode: "ka",
      languageNameNative: "ქართული (k'art'uli) ქართული ენა (k'art'uli ena)",
    },
    {
      languageNameEnglish: "German",
      languageCode: "de",
      languageNameNative: "Deutsch",
      languageCodeDesktop: "de",
    },
    {
      languageNameEnglish: "Greek",
      languageCode: "el",
      languageNameNative: "ελληνικά (ellēniká)",
      languageCodeDesktop: "el",
    },
    {
      languageNameEnglish: "Guarani",
      languageCode: "gn",
      languageNameNative: "Avañe'ẽ",
    },
    {
      languageNameEnglish: "Gujarati",
      languageCode: "gu",
      languageNameNative: "ગુજરાતી (gujarātī)",
    },
    {
      languageNameEnglish: "Haitian Creole",
      languageCode: "ht",
      languageNameNative: "Kreyòl ayisyen",
    },
    {
      languageNameEnglish: "Hausa",
      languageCode: "ha",
      languageNameNative: "(ḥawsa) حَوْسَ",
    },
    {
      languageNameEnglish: "Hawaiian",
      languageCode: "haw",
      languageNameNative: "'ōlelo Hawai'i",
    },
    {
      languageNameEnglish: "Hebrew",
      languageCode: "iw",
      languageNameNative: "(ivrit) עברית / עִבְרִית",
    },
    {
      languageNameEnglish: "Hebrew",
      languageCode: "he",
      languageNameNative: "(ivrit) עברית / עִבְרִית",
      languageCodeDesktop: "he",
    },
    {
      languageNameEnglish: "Hindi",
      languageCode: "hi",
      languageNameNative: "हिन्दी (hindī)",
      languageCodeDesktop: "hi",
    },
    {
      languageNameEnglish: "Hmong",
      languageCode: "hmn",
      languageNameNative: "Hmoob",
    },
    {
      languageNameEnglish: "Hungarian",
      languageCode: "hu",
      languageNameNative: "magyar / magyar nyelv",
      languageCodeDesktop: "hu",
    },
    {
      languageNameEnglish: "Icelandic",
      languageCode: "is",
      languageNameNative: "Íslenska",
    },
    {
      languageNameEnglish: "Igbo",
      languageCode: "ig",
      languageNameNative: "igbo",
    },
    {
      languageNameEnglish: "Ilocano",
      languageCode: "ilo",
      languageNameNative: "ilokano",
    },
    {
      languageNameEnglish: "Indonesian",
      languageCode: "id",
      languageNameNative: "Bahasa Indonesia",
      languageCodeDesktop: "id",
    },
    {
      languageNameEnglish: "Irish",
      languageCode: "ga",
      languageNameNative: "Gaeilge",
      languageCodeDesktop: "ga",
    },
    {
      languageNameEnglish: "Italian",
      languageCode: "it",
      languageNameNative: "italiano",
      languageCodeDesktop: "it",
    },
    {
      languageNameEnglish: "Japanese",
      languageCode: "ja",
      languageNameNative: "日本語 (nihongo)",
      languageCodeDesktop: "ja",
    },
    {
      languageNameEnglish: "Javanese",
      languageCode: "jw",
      languageNameNative: "basa Jawa",
    },
    {
      languageNameEnglish: "Kannada",
      languageCode: "kn",
      languageNameNative: "ಕನ್ನಡ (kannaḍa)",
    },
    {
      languageNameEnglish: "Kazakh",
      languageCode: "kk",
      languageNameNative: "Қaзaқ тілі / Qazaq tili / قازاق ٴتىلى",
    },
    {
      languageNameEnglish: "Khmer",
      languageCode: "km",
      languageNameNative: "ភាសាខ្មែរ (bhāsā khmɛ̄r)",
    },
    {
      languageNameEnglish: "Kinyarwanda",
      languageCode: "rw",
      languageNameNative: "Ikinyarwanda",
    },
    {
      languageNameEnglish: "Konkani",
      languageCode: "gom",
      languageNameNative: "कोंकणी (kōṅkaṇī) ಕೊಂಕಣಿ (koṅkaṇi)",
    },
    {
      languageNameEnglish: "Korean",
      languageCode: "ko",
      languageNameNative: "한국어 [韓國語] (han-guk-eo)",
      languageCodeDesktop: "ko",
    },
    {
      languageNameEnglish: "Krio",
      languageCode: "kri",
      languageNameNative: "Krio",
    },
    {
      languageNameEnglish: "Kurdish (Kurmanji)",
      languageCode: "ku",
      languageNameNative: "Kurdî",
    },
    {
      languageNameEnglish: "Kurdish (Sorani)",
      languageCode: "ckb",
      languageNameNative: "کوردی",
    },
    {
      languageNameEnglish: "Kyrgyz",
      languageCode: "ky",
      languageNameNative: "قىرعىز (kyrgyz) قىرعىز تىلى (kyrgyz tili)",
    },
    {
      languageNameEnglish: "Lao",
      languageCode: "lo",
      languageNameNative: "ພາສາລາວ (pháasaa láo)",
    },
    {
      languageNameEnglish: "Latin",
      languageCode: "la",
      languageNameNative: "Lingua Latina",
    },
    {
      languageNameEnglish: "Latvian",
      languageCode: "lv",
      languageNameNative: "latviešu valoda",
      languageCodeDesktop: "lv",
    },
    {
      languageNameEnglish: "Lingala",
      languageCode: "ln",
      languageNameNative: "lingála",
    },
    {
      languageNameEnglish: "Lithuanian",
      languageCode: "lt",
      languageNameNative: "lietuvių kalba",
      languageCodeDesktop: "lt",
    },
    {
      languageNameEnglish: "Luganda",
      languageCode: "lg",
      languageNameNative: "Oluganda",
    },
    {
      languageNameEnglish: "Luxembourgish",
      languageCode: "lb",
      languageNameNative: "Lëtzebuergesch",
    },
    {
      languageNameEnglish: "Macedonian",
      languageCode: "mk",
      languageNameNative:
        "мaкeдoнcки (Makedonski) мaкeдoнcки jaзик (makedonski jazik)",
    },
    {
      languageNameEnglish: "Maithili",
      languageCode: "mai",
      languageNameNative: "मैथिली (mɛtʰilī)",
    },
    {
      languageNameEnglish: "Malagasy",
      languageCode: "mg",
      languageNameNative: "Fiteny Malagasy",
    },
    {
      languageNameEnglish: "Malay",
      languageCode: "ms",
      languageNameNative: "Bahasa melayu",
      languageCodeDesktop: "ms",
    },
    {
      languageNameEnglish: "Malayalam",
      languageCode: "ml",
      languageNameNative: "മലയാളം (malayāḷam)",
    },
    {
      languageNameEnglish: "Maltese",
      languageCode: "mt",
      languageNameNative: "Malti",
    },
    {
      languageNameEnglish: "Maori",
      languageCode: "mi",
      languageNameNative: "Maori",
    },
    {
      languageNameEnglish: "Marathi",
      languageCode: "mr",
      languageNameNative: "मराठी (marāṭhī)",
    },
    {
      languageNameEnglish: "Meiteilon (Manipuri)",
      languageCode: "mni-Mtei",
      languageNameNative: "Meiteilon",
    },
    {
      languageNameEnglish: "Mizo",
      languageCode: "lus",
      languageNameNative: "Mizo tawng",
    },
    {
      languageNameEnglish: "Mongolian",
      languageCode: "mn",
      languageNameNative: "мoнroл (mongol) мoнroл xэл (mongol hêl)",
    },
    {
      languageNameEnglish: "Myanmar (Burmese)",
      languageCode: "my",
      languageNameNative: "မြန်မာ",
    },
    {
      languageNameEnglish: "Nepali",
      languageCode: "ne",
      languageNameNative: "नेपाली (nēpālī)",
    },
    {
      languageNameEnglish: "Norwegian",
      languageCode: "no",
      languageCodeDesktop: "nb",
      languageNameNative: "Norsk",
    },
    {
      languageNameEnglish: "Odia (Oriya)",
      languageCode: "or",
      languageNameNative: "ଓଡିଆ",
    },
    {
      languageNameEnglish: "Oromo",
      languageCode: "om",
      languageNameNative: "Afaan Oromo",
    },
    {
      languageNameEnglish: "Pashto",
      languageCode: "ps",
      languageNameNative: "(paṧto) پښتو",
    },
    {
      languageNameEnglish: "Persian",
      languageCode: "fa",
      languageNameNative: "(fārsī) فارسى",
      languageCodeDesktop: "fa",
    },
    {
      languageNameEnglish: "Polish",
      languageCode: "pl",
      languageNameNative: "polski / język polski / polszczyzna",
      languageCodeDesktop: "pl",
    },
    {
      languageNameEnglish: "Portuguese",
      languageCode: "pt",
      languageNameNative: "português",
      languageCodeDesktop: "pt",
    },
    {
      languageNameEnglish: "Punjabi",
      languageCode: "pa",
      languageNameNative: "ਪੰਜਾਬੀ / ﺏﺎﺠﻨﭘ (panjābi)",
    },
    {
      languageNameEnglish: "Quechua",
      languageCode: "qu",
      languageNameNative: "Qhichwa",
    },
    {
      languageNameEnglish: "Romanian",
      languageCode: "ro",
      languageNameNative: "limba română / român",
      languageCodeDesktop: "ro",
    },
    {
      languageNameEnglish: "Russian",
      languageCode: "ru",
      languageNameNative: "pyccкий язык (Russkij jazyk)",
      languageCodeDesktop: "ru",
    },
    {
      languageNameEnglish: "Samoan",
      languageCode: "sm",
      languageNameNative: "Gagana Samoa",
    },
    {
      languageNameEnglish: "Sanskrit",
      languageCode: "sa",
      languageNameNative:
        "संस्कृतम् (saṃskṛtam) संस्कृता भाषा (saṃskṛtā bhāṣā)",
    },
    {
      languageNameEnglish: "Scots Gaelic",
      languageCode: "gd",
      languageNameNative: "Gàidhlig na h-Alba",
    },
    {
      languageNameEnglish: "Sepedi",
      languageCode: "nso",
      languageNameNative: "Sepedi",
    },
    {
      languageNameEnglish: "Serbian",
      languageCode: "sr",
      languageNameNative: "cpпcки (srpski) cpпcки jeзик (srpski jezik)",
      languageCodeDesktop: "sr",
    },
    {
      languageNameEnglish: "Sesotho",
      languageCode: "st",
      languageNameNative: "Sesotho",
    },
    {
      languageNameEnglish: "Shona",
      languageCode: "sn",
      languageNameNative: "chiShona",
    },
    {
      languageNameEnglish: "Sindhi",
      languageCode: "sd",
      languageNameNative: "(sindhī) سنڌي",
    },
    {
      languageNameEnglish: "Sinhala",
      languageCode: "si",
      languageNameNative: "සිංහල (sĩhala)",
    },
    {
      languageNameEnglish: "Slovak",
      languageCode: "sk",
      languageNameNative: "slovenčina",
      languageCodeDesktop: "sk",
    },
    {
      languageNameEnglish: "Slovenian",
      languageCode: "sl",
      languageNameNative: "slovenščina",
      languageCodeDesktop: "sl",
    },
    {
      languageNameEnglish: "Somali",
      languageCode: "so",
      languageNameNative: "af Soomaali",
    },
    {
      languageNameEnglish: "Spanish",
      languageCode: "es",
      languageNameNative: "español / castellano",
      languageCodeDesktop: "es",
    },
    {
      languageNameEnglish: "Sundanese",
      languageCode: "su",
      languageNameNative: "Basa Sunda",
    },
    {
      languageNameEnglish: "Swahili",
      languageCode: "sw",
      languageNameNative: "Kiswahili",
    },
    {
      languageNameEnglish: "Swedish",
      languageCode: "sv",
      languageNameNative: "Svenska",
      languageCodeDesktop: "sv",
    },
    {
      languageNameEnglish: "Tajik",
      languageCode: "tg",
      languageNameNative: "тoҷики / toçikī / تاجيكي",
    },
    {
      languageNameEnglish: "Tamil",
      languageCode: "ta",
      languageNameNative: "தமிழ் (tamiḻ)",
    },
    {
      languageNameEnglish: "Tatar",
      languageCode: "tt",
      languageNameNative: "тaтapчa / tatar tele / تاتارچا (tatarça)",
    },
    {
      languageNameEnglish: "Telugu",
      languageCode: "te",
      languageNameNative: "తెలుగు (telugu)",
    },
    {
      languageNameEnglish: "Thai",
      languageCode: "th",
      languageNameNative: "ภาษาไทย (paasaa-tai)",
      languageCodeDesktop: "th",
    },
    {
      languageNameEnglish: "Tigrinya",
      languageCode: "ti",
      languageNameNative: "ትግርኛ (təgərəña)",
    },
    {
      languageNameEnglish: "Tsonga",
      languageCode: "ts",
      languageNameNative: "xiTsonga",
    },
    {
      languageNameEnglish: "Turkish",
      languageCode: "tr",
      languageNameNative: "Türkçe",
      languageCodeDesktop: "tr",
    },
    {
      languageNameEnglish: "Turkmen",
      languageCode: "tk",
      languageNameNative: "түpкmeнчe (türkmençe) түpкмeн дили (türkmen dili)",
    },
    {
      languageNameEnglish: "Twi",
      languageCode: "ak",
      languageNameNative: "twi",
    },
    {
      languageNameEnglish: "Ukrainian",
      languageCode: "uk",
      languageNameNative: "yкpaїнcькa (Ukrajins'ka)",
      languageCodeDesktop: "uk",
    },
    {
      languageNameEnglish: "Urdu",
      languageCode: "ur",
      languageNameNative: "(urdū) اردو",
      languageCodeDesktop: "ur",
    },
    {
      languageNameEnglish: "Uyghur",
      languageCode: "ug",
      languageNameNative: "yйғyp /ئۇيغۇر (ujġgur / uyghur)",
    },
    {
      languageNameEnglish: "Uzbek",
      languageCode: "uz",
      languageNameNative: "أۇزبېك ﺗﻴﻠی o'zbek tili ўз6eк тили (o'zbek tili)",
    },
    {
      languageNameEnglish: "Vietnamese",
      languageCode: "vi",
      languageNameNative: "tiếng việt (㗂越)",
      languageCodeDesktop: "vi",
    },
    {
      languageNameEnglish: "Welsh",
      languageCode: "cy",
      languageNameNative: "Cymraeg / Y Gymraeg",
    },
    {
      languageNameEnglish: "Xhosa",
      languageCode: "xh",
      languageNameNative: "isiXhosa",
    },
    {
      languageNameEnglish: "Yiddish",
      languageCode: "yi",
      languageNameNative: "(Yidish) ײִדיש",
    },
    {
      languageNameEnglish: "Yoruba",
      languageCode: "yo",
      languageNameNative: "Yorùbá",
    },
    {
      languageNameEnglish: "Zulu",
      languageCode: "zu",
      languageNameNative: "isiZulu",
    },
  ],
  countryCodes: [
    {
      countryName: "Afghanistan",
      countryCode: "AF",
    },
    {
      countryName: "Albania",
      countryCode: "AL",
    },
    {
      countryName: "Algeria",
      countryCode: "DZ",
    },
    {
      countryName: "American Samoa",
      countryCode: "AS",
    },
    {
      countryName: "Andorra",
      countryCode: "AD",
    },
    {
      countryName: "Angola",
      countryCode: "AO",
    },
    {
      countryName: "Anguilla",
      countryCode: "AI",
    },
    {
      countryName: "Antarctica",
      countryCode: "AQ",
    },
    {
      countryName: "Antigua and Barbuda",
      countryCode: "AG",
    },
    {
      countryName: "Argentina",
      countryCode: "AR",
    },
    {
      countryName: "Armenia",
      countryCode: "AM",
    },
    {
      countryName: "Aruba",
      countryCode: "AW",
    },
    {
      countryName: "Australia",
      countryCode: "AU",
    },
    {
      countryName: "Austria",
      countryCode: "AT",
    },
    {
      countryName: "Azerbaijan",
      countryCode: "AZ",
    },
    {
      countryName: "Bahamas",
      countryCode: "BS",
    },
    {
      countryName: "Bahrain",
      countryCode: "BH",
    },
    {
      countryName: "Bangladesh",
      countryCode: "BD",
    },
    {
      countryName: "Barbados",
      countryCode: "BB",
    },
    {
      countryName: "Belarus",
      countryCode: "BY",
    },
    {
      countryName: "Belgium",
      countryCode: "BE",
    },
    {
      countryName: "Belize",
      countryCode: "BZ",
    },
    {
      countryName: "Benin",
      countryCode: "BJ",
    },
    {
      countryName: "Bermuda",
      countryCode: "BM",
    },
    {
      countryName: "Bhutan",
      countryCode: "BT",
    },
    {
      countryName: "Bolivia (Plurinational State of)",
      countryCode: "BO",
    },
    {
      countryName: "Bonaire, Sint Eustatius and Saba",
      countryCode: "BQ",
    },
    {
      countryName: "Bosnia and Herzegovina",
      countryCode: "BA",
    },
    {
      countryName: "Botswana",
      countryCode: "BW",
    },
    {
      countryName: "Bouvet Island",
      countryCode: "BV",
    },
    {
      countryName: "Brazil",
      countryCode: "BR",
    },
    {
      countryName: "British Indian Ocean Territory",
      countryCode: "IO",
    },
    {
      countryName: "Brunei Darussalam",
      countryCode: "BN",
    },
    {
      countryName: "Bulgaria",
      countryCode: "BG",
    },
    {
      countryName: "Burkina Faso",
      countryCode: "BF",
    },
    {
      countryName: "Burundi",
      countryCode: "BI",
    },
    {
      countryName: "Cabo Verde",
      countryCode: "CV",
    },
    {
      countryName: "Cambodia",
      countryCode: "KH",
    },
    {
      countryName: "Cameroon",
      countryCode: "CM",
    },
    {
      countryName: "Canada",
      countryCode: "CA",
    },
    {
      countryName: "Cayman Islands",
      countryCode: "KY",
    },
    {
      countryName: "Central African Republic",
      countryCode: "CF",
    },
    {
      countryName: "Chad",
      countryCode: "TD",
    },
    {
      countryName: "Chile",
      countryCode: "CL",
    },
    {
      countryName: "China",
      countryCode: "CN",
    },
    {
      countryName: "Christmas Island",
      countryCode: "CX",
    },
    {
      countryName: "Cocos (Keeling) Islands",
      countryCode: "CC",
    },
    {
      countryName: "Colombia",
      countryCode: "CO",
    },
    {
      countryName: "Comoros",
      countryCode: "KM",
    },
    {
      countryName: "Congo (the Democratic Republic of the)",
      countryCode: "CD",
    },
    {
      countryName: "Congo",
      countryCode: "CG",
    },
    {
      countryName: "Cook Islands",
      countryCode: "CK",
    },
    {
      countryName: "Costa Rica",
      countryCode: "CR",
    },
    {
      countryName: "Croatia",
      countryCode: "HR",
    },
    {
      countryName: "Cuba",
      countryCode: "CU",
    },
    {
      countryName: "Curaçao",
      countryCode: "CW",
    },
    {
      countryName: "Cyprus",
      countryCode: "CY",
    },
    {
      countryName: "Czechia",
      countryCode: "CZ",
    },
    {
      countryName: "Côte d'Ivoire",
      countryCode: "CI",
    },
    {
      countryName: "Denmark",
      countryCode: "DK",
    },
    {
      countryName: "Djibouti",
      countryCode: "DJ",
    },
    {
      countryName: "Dominica",
      countryCode: "DM",
    },
    {
      countryName: "Dominican Republic",
      countryCode: "DO",
    },
    {
      countryName: "Ecuador",
      countryCode: "EC",
    },
    {
      countryName: "Egypt",
      countryCode: "EG",
    },
    {
      countryName: "El Salvador",
      countryCode: "SV",
    },
    {
      countryName: "Equatorial Guinea",
      countryCode: "GQ",
    },
    {
      countryName: "Eritrea",
      countryCode: "ER",
    },
    {
      countryName: "Estonia",
      countryCode: "EE",
    },
    {
      countryName: "Eswatini",
      countryCode: "SZ",
    },
    {
      countryName: "Ethiopia",
      countryCode: "ET",
    },
    {
      countryName: "Falkland Islands [Malvinas]",
      countryCode: "FK",
    },
    {
      countryName: "Faroe Islands",
      countryCode: "FO",
    },
    {
      countryName: "Fiji",
      countryCode: "FJ",
    },
    {
      countryName: "Finland",
      countryCode: "FI",
    },
    {
      countryName: "France",
      countryCode: "FR",
    },
    {
      countryName: "French Guiana",
      countryCode: "GF",
    },
    {
      countryName: "French Polynesia",
      countryCode: "PF",
    },
    {
      countryName: "French Southern Territories",
      countryCode: "TF",
    },
    {
      countryName: "Gabon",
      countryCode: "GA",
    },
    {
      countryName: "Gambia",
      countryCode: "GM",
    },
    {
      countryName: "Georgia",
      countryCode: "GE",
    },
    {
      countryName: "Germany",
      countryCode: "DE",
    },
    {
      countryName: "Ghana",
      countryCode: "GH",
    },
    {
      countryName: "Gibraltar",
      countryCode: "GI",
    },
    {
      countryName: "Greece",
      countryCode: "GR",
    },
    {
      countryName: "Greenland",
      countryCode: "GL",
    },
    {
      countryName: "Grenada",
      countryCode: "GD",
    },
    {
      countryName: "Guadeloupe",
      countryCode: "GP",
    },
    {
      countryName: "Guam",
      countryCode: "GU",
    },
    {
      countryName: "Guatemala",
      countryCode: "GT",
    },
    {
      countryName: "Guernsey",
      countryCode: "GG",
    },
    {
      countryName: "Guinea",
      countryCode: "GN",
    },
    {
      countryName: "Guinea-Bissau",
      countryCode: "GW",
    },
    {
      countryName: "Guyana",
      countryCode: "GY",
    },
    {
      countryName: "Haiti",
      countryCode: "HT",
    },
    {
      countryName: "Heard Island and McDonald Islands",
      countryCode: "HM",
    },
    {
      countryName: "Holy See",
      countryCode: "VA",
    },
    {
      countryName: "Honduras",
      countryCode: "HN",
    },
    {
      countryName: "Hong Kong",
      countryCode: "HK",
    },
    {
      countryName: "Hungary",
      countryCode: "HU",
    },
    {
      countryName: "Iceland",
      countryCode: "IS",
    },
    {
      countryName: "India",
      countryCode: "IN",
    },
    {
      countryName: "Indonesia",
      countryCode: "ID",
    },
    {
      countryName: "Iran (Islamic Republic of)",
      countryCode: "IR",
    },
    {
      countryName: "Iraq",
      countryCode: "IQ",
    },
    {
      countryName: "Ireland",
      countryCode: "IE",
    },
    {
      countryName: "Isle of Man",
      countryCode: "IM",
    },
    {
      countryName: "Israel",
      countryCode: "IL",
    },
    {
      countryName: "Italy",
      countryCode: "IT",
    },
    {
      countryName: "Jamaica",
      countryCode: "JM",
    },
    {
      countryName: "Japan",
      countryCode: "JP",
    },
    {
      countryName: "Jersey",
      countryCode: "JE",
    },
    {
      countryName: "Jordan",
      countryCode: "JO",
    },
    {
      countryName: "Kazakhstan",
      countryCode: "KZ",
    },
    {
      countryName: "Kenya",
      countryCode: "KE",
    },
    {
      countryName: "Kiribati",
      countryCode: "KI",
    },
    {
      countryName: "Korea (the Democratic People's Republic of)",
      countryCode: "KP",
    },
    {
      countryName: "Korea (the Republic of)",
      countryCode: "KR",
    },
    {
      countryName: "Kuwait",
      countryCode: "KW",
    },
    {
      countryName: "Kyrgyzstan",
      countryCode: "KG",
    },
    {
      countryName: "Lao People's Democratic Republic",
      countryCode: "LA",
    },
    {
      countryName: "Latvia",
      countryCode: "LV",
    },
    {
      countryName: "Lebanon",
      countryCode: "LB",
    },
    {
      countryName: "Lesotho",
      countryCode: "LS",
    },
    {
      countryName: "Liberia",
      countryCode: "LR",
    },
    {
      countryName: "Libya",
      countryCode: "LY",
    },
    {
      countryName: "Liechtenstein",
      countryCode: "LI",
    },
    {
      countryName: "Lithuania",
      countryCode: "LT",
    },
    {
      countryName: "Luxembourg",
      countryCode: "LU",
    },
    {
      countryName: "Macao",
      countryCode: "MO",
    },
    {
      countryName: "Madagascar",
      countryCode: "MG",
    },
    {
      countryName: "Malawi",
      countryCode: "MW",
    },
    {
      countryName: "Malaysia",
      countryCode: "MY",
    },
    {
      countryName: "Maldives",
      countryCode: "MV",
    },
    {
      countryName: "Mali",
      countryCode: "ML",
    },
    {
      countryName: "Malta",
      countryCode: "MT",
    },
    {
      countryName: "Marshall Islands",
      countryCode: "MH",
    },
    {
      countryName: "Martinique",
      countryCode: "MQ",
    },
    {
      countryName: "Mauritania",
      countryCode: "MR",
    },
    {
      countryName: "Mauritius",
      countryCode: "MU",
    },
    {
      countryName: "Mayotte",
      countryCode: "YT",
    },
    {
      countryName: "Mexico",
      countryCode: "MX",
    },
    {
      countryName: "Micronesia (Federated States of)",
      countryCode: "FM",
    },
    {
      countryName: "Moldova (the Republic of)",
      countryCode: "MD",
    },
    {
      countryName: "Monaco",
      countryCode: "MC",
    },
    {
      countryName: "Mongolia",
      countryCode: "MN",
    },
    {
      countryName: "Montenegro",
      countryCode: "ME",
    },
    {
      countryName: "Montserrat",
      countryCode: "MS",
    },
    {
      countryName: "Morocco",
      countryCode: "MA",
    },
    {
      countryName: "Mozambique",
      countryCode: "MZ",
    },
    {
      countryName: "Myanmar",
      countryCode: "MM",
    },
    {
      countryName: "Namibia",
      countryCode: "NA",
    },
    {
      countryName: "Nauru",
      countryCode: "NR",
    },
    {
      countryName: "Nepal",
      countryCode: "NP",
    },
    {
      countryName: "Netherlands",
      countryCode: "NL",
    },
    {
      countryName: "New Caledonia",
      countryCode: "NC",
    },
    {
      countryName: "New Zealand",
      countryCode: "NZ",
    },
    {
      countryName: "Nicaragua",
      countryCode: "NI",
    },
    {
      countryName: "Niger",
      countryCode: "NE",
    },
    {
      countryName: "Nigeria",
      countryCode: "NG",
    },
    {
      countryName: "Niue",
      countryCode: "NU",
    },
    {
      countryName: "Norfolk Island",
      countryCode: "NF",
    },
    {
      countryName: "Northern Mariana Islands",
      countryCode: "MP",
    },
    {
      countryName: "Norway",
      countryCode: "NO",
    },
    {
      countryName: "Oman",
      countryCode: "OM",
    },
    {
      countryName: "Pakistan",
      countryCode: "PK",
    },
    {
      countryName: "Palau",
      countryCode: "PW",
    },
    {
      countryName: "Palestine, State of",
      countryCode: "PS",
    },
    {
      countryName: "Panama",
      countryCode: "PA",
    },
    {
      countryName: "Papua New Guinea",
      countryCode: "PG",
    },
    {
      countryName: "Paraguay",
      countryCode: "PY",
    },
    {
      countryName: "Peru",
      countryCode: "PE",
    },
    {
      countryName: "Philippines",
      countryCode: "PH",
    },
    {
      countryName: "Pitcairn",
      countryCode: "PN",
    },
    {
      countryName: "Poland",
      countryCode: "PL",
    },
    {
      countryName: "Portugal",
      countryCode: "PT",
    },
    {
      countryName: "Puerto Rico",
      countryCode: "PR",
    },
    {
      countryName: "Qatar",
      countryCode: "QA",
    },
    {
      countryName: "Republic of North Macedonia",
      countryCode: "MK",
    },
    {
      countryName: "Romania",
      countryCode: "RO",
    },
    {
      countryName: "Russian Federation",
      countryCode: "RU",
    },
    {
      countryName: "Rwanda",
      countryCode: "RW",
    },
    {
      countryName: "Réunion",
      countryCode: "RE",
    },
    {
      countryName: "Saint Barthélemy",
      countryCode: "BL",
    },
    {
      countryName: "Saint Helena, Ascension and Tristan da Cunha",
      countryCode: "SH",
    },
    {
      countryName: "Saint Kitts and Nevis",
      countryCode: "KN",
    },
    {
      countryName: "Saint Lucia",
      countryCode: "LC",
    },
    {
      countryName: "Saint Martin (French part)",
      countryCode: "MF",
    },
    {
      countryName: "Saint Pierre and Miquelon",
      countryCode: "PM",
    },
    {
      countryName: "Saint Vincent and the Grenadines",
      countryCode: "VC",
    },
    {
      countryName: "Samoa",
      countryCode: "WS",
    },
    {
      countryName: "San Marino",
      countryCode: "SM",
    },
    {
      countryName: "Sao Tome and Principe",
      countryCode: "ST",
    },
    {
      countryName: "Saudi Arabia",
      countryCode: "SA",
    },
    {
      countryName: "Senegal",
      countryCode: "SN",
    },
    {
      countryName: "Serbia",
      countryCode: "RS",
    },
    {
      countryName: "Seychelles",
      countryCode: "SC",
    },
    {
      countryName: "Sierra Leone",
      countryCode: "SL",
    },
    {
      countryName: "Singapore",
      countryCode: "SG",
    },
    {
      countryName: "Sint Maarten (Dutch part)",
      countryCode: "SX",
    },
    {
      countryName: "Slovakia",
      countryCode: "SK",
    },
    {
      countryName: "Slovenia",
      countryCode: "SI",
    },
    {
      countryName: "Solomon Islands",
      countryCode: "SB",
    },
    {
      countryName: "Somalia",
      countryCode: "SO",
    },
    {
      countryName: "South Africa",
      countryCode: "ZA",
    },
    {
      countryName: "South Georgia and the South Sandwich Islands",
      countryCode: "GS",
    },
    {
      countryName: "South Sudan",
      countryCode: "SS",
    },
    {
      countryName: "Spain",
      countryCode: "ES",
    },
    {
      countryName: "Sri Lanka",
      countryCode: "LK",
    },
    {
      countryName: "Sudan",
      countryCode: "SD",
    },
    {
      countryName: "Suriname",
      countryCode: "SR",
    },
    {
      countryName: "Svalbard and Jan Mayen",
      countryCode: "SJ",
    },
    {
      countryName: "Sweden",
      countryCode: "SE",
    },
    {
      countryName: "Switzerland",
      countryCode: "CH",
    },
    {
      countryName: "Syrian Arab Republic",
      countryCode: "SY",
    },
    {
      countryName: "Taiwan (Province of China)",
      countryCode: "TW",
    },
    {
      countryName: "Tajikistan",
      countryCode: "TJ",
    },
    {
      countryName: "Tanzania, United Republic of",
      countryCode: "TZ",
    },
    {
      countryName: "Thailand",
      countryCode: "TH",
    },
    {
      countryName: "Timor-Leste",
      countryCode: "TL",
    },
    {
      countryName: "Togo",
      countryCode: "TG",
    },
    {
      countryName: "Tokelau",
      countryCode: "TK",
    },
    {
      countryName: "Tonga",
      countryCode: "TO",
    },
    {
      countryName: "Trinidad and Tobago",
      countryCode: "TT",
    },
    {
      countryName: "Tunisia",
      countryCode: "TN",
    },
    {
      countryName: "Turkey",
      countryCode: "TR",
    },
    {
      countryName: "Turkmenistan",
      countryCode: "TM",
    },
    {
      countryName: "Turks and Caicos Islands",
      countryCode: "TC",
    },
    {
      countryName: "Tuvalu",
      countryCode: "TV",
    },
    {
      countryName: "Uganda",
      countryCode: "UG",
    },
    {
      countryName: "Ukraine",
      countryCode: "UA",
    },
    {
      countryName: "United Arab Emirates",
      countryCode: "AE",
    },
    {
      countryName: "United Kingdom of Great Britain and Northern Ireland",
      countryCode: "GB",
    },
    {
      countryName: "United States Minor Outlying Islands",
      countryCode: "UM",
    },
    {
      countryName: "United States of America",
      countryCode: "US",
    },
    {
      countryName: "Uruguay",
      countryCode: "UY",
    },
    {
      countryName: "Uzbekistan",
      countryCode: "UZ",
    },
    {
      countryName: "Vanuatu",
      countryCode: "VU",
    },
    {
      countryName: "Venezuela (Bolivarian Republic of)",
      countryCode: "VE",
    },
    {
      countryName: "Viet Nam",
      countryCode: "VN",
    },
    {
      countryName: "Virgin Islands (British)",
      countryCode: "VG",
    },
    {
      countryName: "Virgin Islands (U.S.)",
      countryCode: "VI",
    },
    {
      countryName: "Wallis and Futuna",
      countryCode: "WF",
    },
    {
      countryName: "Western Sahara",
      countryCode: "EH",
    },
    {
      countryName: "Yemen",
      countryCode: "YE",
    },
    {
      countryName: "Zambia",
      countryCode: "ZM",
    },
    {
      countryName: "Zimbabwe",
      countryCode: "ZW",
    },
    {
      countryName: "Åland Islands",
      countryCode: "AX",
    },
  ],
});
