import React, { useEffect, useState } from "react";

import { AnimatePresence } from "framer-motion";
import { Box, Flex, SlideFade } from "@chakra-ui/react";
import padding from "../config/padding";
import { getPx, getSx } from "../utils/helpers";

export default function AppViewLayout({
  children,
  sx,
  style,
  offset = padding.ms,
  // enter = true,
  // exit = true,
  unmountOnExit,
  index = 0,
  reverse,
  noBox,
  fullWidth,
}) {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    setTimeout(() => setIsOpen(true), index * 150);
  }, []);

  return (
    <AnimatePresence>
      <SlideFade
        in={isOpen}
        offsetY={reverse ? getPx(offset) : getPx(-offset)}
        unmountOnExit={unmountOnExit}
        style={fullWidth ? { width: "100%" } : {}}
      >
        {noBox ? (
          <>{children}</>
        ) : (
          <Box sx={getSx([style, sx])}>{children}</Box>
        )}
      </SlideFade>
    </AnimatePresence>
  );
}

// <ReAnimated.View
//   entering={FadeInUp}
//   exiting={FadeOutUp}
//   LinearTransition={LinearTransition.easing()}
//   style={style}
// >
//   {children}
// </ReAnimated.View>

//     <View
//   style={style}
// >
//   {children}
// </View>
