import React, { useEffect, useState, useRef } from "react";
import { Carousel } from "react-responsive-carousel";
import AppImage from "./AppImage";
import AppText from "./AppText";
import AppCarouselDots from "./AppCarouselDots";
import {
  AlertDialog,
  AlertDialogCloseButton,
  AlertDialogOverlay,
  Box,
  Container,
  Flex,
  useDisclosure,
} from "@chakra-ui/react";
import Lightbox from "yet-another-react-lightbox";
import { Fullscreen, Download, Zoom } from "yet-another-react-lightbox/plugins";
import { getPx } from "../utils/helpers";
import { StyleSheet } from "../config/styles";
import padding from "../config/padding";
import { fontConfig as fonts } from "../config/fonts";
import AppSaveImage from "./AppSaveImage";
import colors from "../config/colors";
import AppPressable from "./AppPressable";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { useUser } from "../hooks/useUser";
import { useToast } from "../hooks/useToast";
import { httpToHttps } from "../utils/formatters";
import LoginSignupModal from "./modals/LoginSignupModal";
import { useYouwho } from "../package/react-youwho";
import { useSelector } from "react-redux";
import AppButton from "./AppButton";

const images = [
  { bg: "red" },
  { bg: "green" },
  { bg: "blue" },
  { bg: "purple" },
  { bg: "tomato" },
  { bg: "teal" },
  { bg: "orange" },
  { bg: "pink" },
];

export default function AppImageCarousel({
  height = getPx(250),
  id,
  imgArray,
  imageStyle = {},
  initIndex = 0,
  noSave = true,
  onPress = () => {},
  showDots,
  showFavorite,
  showIndex = true,
  showLightbox,
  width = "100%",
  containerStyle,
  minHeight,
  maxHeight,
  showArrows = false,
  handleRef,
  boxShadow,
}) {
  // const { loginOnOpen } = useYouwho();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { loginModalObject } = useSelector((state) => state.app);
  const [currentIndex, setCurrentIndex] = useState(initIndex);
  const Toast = useToast();
  const { favorite, handlePressFavorite, showFavToast } = useUser(id);
  const listRef = useRef();
  const cancelRef = useRef();
  const fullscreenRef = useRef(null);
  const zoomRef = useRef(null);

  const handleIndicator = (
    clickHandler, // (e: React.MouseEvent | React.KeyboardEvent) => void,
    isSelected, // boolean
    index, // number
    label // string
  ) => {
    if (isSelected) {
      setCurrentIndex(index);
    }
  };

  useEffect(() => {
    if (showFavToast) handleFavToast();
  }, [showFavToast]);

  const handleFavToast = () => {
    Toast.show({
      type: "appToast",
      autoHide: false,
      // And I can pass any custom props I want
      //   onHide: () => setShowFavToast(false),
      // onShow: () => handleShowUnderlay(true),
      props: {
        hideClose: false,
        title: "Login",
        titleRight: "Login",
        subtitle: "Please login to add favorite services to watchlist",
        hideLeft: true,
        // iconRight: "key",
        // fontSize: fonts.size.lg,
        rightButtonVariant: "outline",
        pressRight: () => {
          console.log("onOpenLogin");
          setTimeout(() => {
            loginModalObject?.onOpen();
          }, 400);

          // navigation.navigate(routes.LOGIN_SCREEN.n);
          // Toast.hide();
        },
      },
    });
  };

  //   {
  //     return Toast.show({
  //       type: "appToast",
  //       autoHide: false,
  //       // onHide: () => handleShowUnderlay(false),
  //       // onShow: () => handleShowUnderlay(true),
  //       // And I can pass any custom props I want
  //       props: {
  //         hideClose: false,
  //         title: "Image Options",
  //         subtitle: "Move up | Move down | Delete",
  //         iconLeft: <MdOutlineArrowUpward />,
  //         pressLeft: () => alert("pressLeft"),
  //         iconMiddle: <MdOutlineArrowDownward />,
  //         pressMiddle: () => alert("pressMiddle"),
  //         // iconRight: "trash-2",
  //         rightButtonColor: "red",
  //         pressRight: () => {
  //           Toast.hide();
  //         },
  //       },
  //     });
  //   };

  return (
    <Box
      width={width}
      height={height}
      minHeight={minHeight}
      maxHeight={maxHeight}
      sx={{
        position: "relative",
        //   width: getPx(width),
        // bg: "green",
        ...containerStyle,
      }}
      boxShadow={boxShadow}
    >
      <Carousel
        ref={handleRef ?? listRef}
        ariaLabel="image-carousel"
        swipeable
        emulateTouch
        infiniteLoop
        swipeScrollTolerance={30}
        showThumbs={false}
        useKeyboardArrows
        showArrows={showArrows}
        renderIndicator={handleIndicator}
        showStatus={false}
        onClickItem={() => {
          onPress();
          if (showLightbox) onOpen();
        }}
        // dynamicHeight
      >
        {imgArray.map((x) => (
          <AppImage
            key={x?._name}
            // onClick={onPress}
            source={httpToHttps(x?._url)}
            sx={imageStyle}
            width={width}
            height={height}
            minHeight={minHeight}
            maxHeight={maxHeight}
          />
        ))}
      </Carousel>
      {Boolean(showIndex && imgArray.length > 0) && (
        <Flex sx={styles.index}>
          <AppText variant="sm" style={styles.indexText}>
            {`${currentIndex + 1} / ${imgArray?.length}`}
          </AppText>
        </Flex>
      )}
      {Boolean(showDots && imgArray.length > 1) && (
        <Flex
          sx={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            paddingBottom: getPx(padding.md),
            justifyContent: "center",
          }}
        >
          <AppCarouselDots
            totalImages={imgArray.length}
            current={currentIndex}
          />
        </Flex>
      )}
      {Boolean(!noSave) && (
        <Flex sx={styles.saveButton}>
          <AppSaveImage
            imageUrl={imgArray[currentIndex]?._url}
            imageName={imgArray[currentIndex]._name}
            imageType={imgArray[currentIndex].metadata?.type}
          />
        </Flex>
      )}
      {showFavorite && (
        <AppPressable
          onPress={handlePressFavorite}
          pressScale={0.85}
          pressableStyle={styles.favourites}
        >
          {favorite ? (
            <AiFillHeart size={20} color={colors.info} />
          ) : (
            <AiOutlineHeart size={20} color={colors.white} />
          )}
        </AppPressable>
      )}
      <Toast.AppToast />

      {showLightbox && (
        <>
          <Lightbox
            plugins={[Download, Fullscreen, Zoom]}
            fullscreen={{ ref: fullscreenRef }}
            zoom={{ ref: zoomRef }}
            on={{
              click: () => fullscreenRef.current?.enter(),
            }}
            open={isOpen}
            close={() => onClose()}
            controller={{ closeOnBackdropClick: true }}
            slides={imgArray.map((x) => ({
              src: httpToHttps(x?._url),
              downloadUrl: httpToHttps(x?._url),
              downloadFilename: x?._name,
            }))}
            index={currentIndex}
          />
        </>
      )}
    </Box>
  );
}

const styles = Object.create({
  favourites: {
    height: getPx(padding.xl),
    width: getPx(padding.xl),
    borderRadius: getPx(padding.xl),
    position: "absolute",
    top: getPx(padding.ms),
    right: getPx(padding.ms),
    zIndex: 1000,
    alignItems: "center",
    justifyContent: "center",
  },
  index: {
    alignSelf: "center",
    bottom: getPx(padding.ms),
    right: getPx(padding.ms),
    paddingX: getPx(padding.ms),
    paddingY: getPx(padding.xs),
    borderRadius: getPx(padding.sm),
    backgroundColor: colors.white50,
    position: "absolute",
    zIndex: 100,
  },
  indexText: {
    color: colors.white,
  },
  saveButton: {
    alignSelf: "center",
    bottom: getPx(padding.ms),
    left: getPx(padding.ms),
    paddingX: getPx(padding.sm),
    paddingY: getPx(padding.sm),
    borderRadius: getPx(padding.sm),
    backgroundColor: colors.white50,
    position: "absolute",
    zIndex: 100,
  },
});
