import React from "react";
import colors from "../../config/colors";
import AppTextTranslate from "../AppTextTranslate";
import AppViewLayout from "../AppViewLayout";
import { getSx } from "../../utils/helpers";

export default function ErrorMessage({ error, visible, errorStyle }) {
  if (Boolean(!visible || !error)) return null;

  return (
    <AppViewLayout>
      <AppTextTranslate
        variant="mdb"
        underline
        style={getSx([styles.error, errorStyle])}
      >
        {error}
      </AppTextTranslate>
    </AppViewLayout>
  );
}

const styles = Object.create({
  error: {
    // fontSize: fonts.size.md,
    // paddingLeft: padding.ms,
    // color: colors.black,
    color: colors.danger,
  },
});

