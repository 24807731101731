import React from "react";
import colors from "../config/colors";

import { useAnimate, motion, isValidMotionProp } from "framer-motion";
import { chakra, shouldForwardProp } from "@chakra-ui/react";
import { getPx, getSx } from "../utils/helpers";
import padding from "../config/padding";
import ActivityIndicator from "./rnMimic/ActivityIndicator";
import ActivityIndicator2 from "./rnMimic/ActivityIndicator2";

const ChakraBox = chakra(motion.div, {
  /**
   * Allow motion props and non-Chakra props to be forwarded.
   */
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

export default function AppPressable({
  children,
  cursor = "pointer",
  flexStart,
  isDisabled,
  isLoading,
  loaderColor = "dark",
  loaderSize = "large",
  noAnimate = isDisabled,
  onPress,
  onPressIn,
  onPressOut,
  pressableStyle,
  hoverScale = 1.04,
  pressScale = 0.99,
  style,
  sx,
  useLoader,
  ...otherProps
}) {
  const [scope, animate] = useAnimate();

  const onPressableClick = () => {};
  return (
    <ChakraBox
      cursor={!Boolean(isDisabled) && !Boolean(isLoading) ? cursor : "default"}
      disabled={Boolean(isDisabled)}
      onClick={(e) => {
        if (!Boolean(isDisabled) && !Boolean(isLoading)) {
          if (onPress) onPress(e);
        }
      }}
      onMouseDown={(e) => {
        if (!Boolean(isDisabled) && !Boolean(isLoading)) {
          if (onPressIn) {
            // console.log("onMouseDown");
            onPressIn(e);
          }
        }
      }}
      onMouseUp={(e) => {
        if (!Boolean(isDisabled) && !Boolean(isLoading)) {
          if (onPressOut) {
            // console.log("onMouseUp");
            onPressOut(e);
          }
        }
      }}
      whileHover={{ scale: noAnimate ? 1 : hoverScale }}
      whileTap={{ scale: noAnimate ? 1 : pressScale }}
      transition={{ type: "spring", stiffness: 5000, damping: 200 }}
      sx={getSx([
        {
          display: "flex",
          justifyContent: flexStart ? "flex-start" : "center",
          alignItems: flexStart ? "flex-start" : "center",
          opacity: Boolean(isDisabled) ? 0.6 : 1,
          filter: Boolean(isDisabled) && "grayscale(1)",
          userSelect: "none",
        },
        pressableStyle,
        style,
        sx,
      ])}
      {...otherProps}
    >
      {useLoader && isLoading ? (
        <ActivityIndicator2
          dark={loaderColor === "dark" ? true : false}
          loaderSize={loaderSize}
          isDisabled={isDisabled}
        />
      ) : (
        children
      )}
    </ChakraBox>
  );
}
