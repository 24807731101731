import React, { useEffect, useState } from "react";
import Lottie from "lottie-react";
import AnimationDark from "../../assets/animations/typingDotsLottieDark.json";
import AnimationWhite from "../../assets/animations/typingDotsLottieWhite.json";
import { getPx } from "../../utils/helpers";
import padding from "../../config/padding";
import { Flex } from "@chakra-ui/react";

export default function ActivityIndicator2({
  dark = false,
  loaderSize = "large",
  isDisabled,
}) {
  //   const [ready, setReady] = useState(false);
  //   const [colorState, setColor] = useState(color ?? colors.black);

  //   useEffect(() => {
  //     setTimeout(() => setReady(true), 200);
  //   }, []);

  //   if (!ready) return <></>;

  return (
    <Flex
      style={{
        height: getPx(padding.lg),
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Lottie
        animationData={Boolean(dark) ? AnimationDark : AnimationWhite}
        loop
        style={{
          width: getPx(loaderSize === "large" ? padding.xxxl : padding.xxl),
          height: getPx(
            (loaderSize === "large" ? padding.xxxl : padding.xxl) * 1
          ),
          opacity: isDisabled ? 0.7 : 1,
        }}
      />
    </Flex>
  );
}
