import React, { useCallback, useEffect, useRef, useState } from "react";
import dayjs from "dayjs";

import { fontConfig as fonts } from "../config/fonts";
import colors from "../config/colors";
import padding from "../config/padding";
import AppAvatar from "./AppAvatar";
import AppText from "./AppText";

import AppPressable from "./AppPressable";
import { getPx } from "../utils/helpers";
import { FaUserCheck } from "react-icons/fa";
import AppStar from "./AppStar";
import { useNavigate } from "react-router-dom";
import View from "./rnMimic/View";
import AppTextTranslate from "./AppTextTranslate";
import { Box } from "@chakra-ui/react";
import { useContainerDimensions } from "../hooks/useDimensions";
import { setNaviPublicProfileRedux } from "../store/slices/navi";
import { dispatch } from "../store/store";

export default function AppProviderProfileCard({ info, long }) {
  const navigation = useNavigate();
  const [readMoreProfile, setReadMoreProfile] = useState(1);
  const [pressReadMore, setPressReadMore] = useState(false);

  const containerRef = useRef();

  const { height: containerHeight, width: containerWidth } =
    useContainerDimensions(containerRef);

  // console.log("AppProviderProfileCard info", info);

  const onPress = () => {
    // navigation.navigate(routes.PUBLIC_PROFILE_SCREEN.n, { info });
    dispatch(setNaviPublicProfileRedux(info));
    setTimeout(() => navigation("/profile/" + info.id), 100);
  };

  const handleLongDescription = (height) => {
    // console.log("height", height);
    if (height > 125 && !pressReadMore) {
      setReadMoreProfile(5);
    }
  };

  useEffect(() => {
    if (containerHeight && containerHeight > 0) {
      handleLongDescription(containerHeight);
    }
  }, [containerHeight]);

  return (
    <>
      <View style={styles.container}>
        <View style={styles.detailsContainer} spacing={2}>
          <View
            style={{
              alignItems: "flex-start",
              overflowWrap: "break-word",
            }}
          >
            <AppTextTranslate
              variant="h3"
              sx={{ marginRight: getPx(padding.sm) }}
            >
              Service provided by
            </AppTextTranslate>
            <Box
              sx={{
                width: "100%",
                // background: "red",
                overflowWrap: "break-word",
              }}
            >
              <AppText variant="h2">{info.username}</AppText>
            </Box>
          </View>
          <AppTextTranslate variant="md" style={{ color: colors.gray6 }}>
            {`Joined in ${dayjs(info.createdAt).format("MMMM YYYY")}`}
          </AppTextTranslate>
          <AppTextTranslate variant="md" capitalize>
            {`${info.userType} Provider`}
          </AppTextTranslate>
        </View>
        <AppPressable onPress={onPress}>
          <AppAvatar
            name={info.username}
            url={info.avatar?._url ?? info?.avatarUrl}
            pointer={info}
            // enlarge
          />
        </AppPressable>
      </View>
      {long && (
        <View>
          <View spacing={1} sx={{ marginY: getPx(padding.md) }}>
            {info.reviewsAsProvider === 0 && (
              <AppTextTranslate variant="lg">
                New service provider
              </AppTextTranslate>
            )}
            {info.reviewsAsProvider > 0 && info.ratingAsProvider && (
              <View direction="row" style={{ alignItems: "center" }}>
                {/* <Stars
                  display={info.ratingAsProvider / 5}
                  count={1}
                  fullStar={
                    <FontAwesome
                      name={"star"}
                      style={[
                        styles.ratingStar,
                        {
                          marginRight: info.identityVerified
                            ? padding.md
                            : padding.ms,
                        },
                      ]}
                      size={fonts.size.primary}
                    />
                  }
                  emptyStar={
                    <FontAwesome
                      name={"star-o"}
                      style={[
                        styles.ratingStar,
                        {
                          marginRight: info.identityVerified
                            ? padding.md
                            : padding.ms,
                        },
                      ]}
                      size={fonts.size.primary}
                    />
                  }
                /> */}
                <AppStar
                  sx={{
                    marginBottom: getPx(2),
                    marginRight: getPx(padding.sm),
                    color: colors.black,
                  }}
                  value={
                    info.ratingAsProvider > 0
                      ? Number(info.ratingAsProvider ?? 0) / 5
                      : 0
                  }
                />
                <AppTextTranslate
                  variant="lg"
                  sx={{ marginLeft: getPx(padding.sm) }}
                >
                  {`${info.ratingAsProvider} • ${info.reviewsAsProvider} Reviews`}
                </AppTextTranslate>
              </View>
            )}
            {info.identityVerified && (
              <View direction="row" style={{ alignItems: "center" }}>
                <FaUserCheck
                  style={{ bottom: getPx(2), marginRight: getPx(padding.ms) }}
                  color={colors.black}
                  size={fonts.size.primary}
                />

                <AppTextTranslate variant="lg">
                  Identity Verified
                </AppTextTranslate>
              </View>
            )}
          </View>
          <View spacing={1}>
            <AppTextTranslate
              variant="lg"
              numberOfLines={readMoreProfile}
              textRef={containerRef}
            >
              {info.description}
            </AppTextTranslate>
            {readMoreProfile > 0 && (
              <AppPressable
                onPress={() => {
                  setPressReadMore(true);
                  setReadMoreProfile(0);
                }}
                style={{
                  alignSelf: "flex-end",
                  marginRight: getPx(padding.ms),
                }}
              >
                <AppTextTranslate variant="mdb" underline right>
                  read more
                </AppTextTranslate>
              </AppPressable>
            )}
          </View>
        </View>
      )}
    </>
  );
}

const styles = Object.create({
  container: {
    alignItems: "center",
    flexDirection: "row",
  },
  detailsContainer: {
    alignItems: "flex-start",
    justifyContent: "flex-start",
    // flex: 7,
  },
  ratingStar: {
    color: colors.black,
    bottom: getPx(2),
  },
});
