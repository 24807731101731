import React, { useEffect, useRef, useState } from "react";
import { useSpring, motion, useTransform } from "framer-motion";
import { FiCheck, FiUploadCloud } from "react-icons/fi";

import colors from "../config/colors";
import { getPx, getSx } from "../utils/helpers";
import AppPressable from "./AppPressable";
import { Box, Flex } from "@chakra-ui/react";

const switchSize = {
  sm: { height: 20, width: 30, borderRadius: 10 },
  md: { height: 24, width: 36, borderRadius: 12 },
  lg: { height: 32, width: 48, borderRadius: 16 },
  xl: { height: 40, width: 60, borderRadius: 20 },
};

export default function AppSwitch({
  isLoading = false,
  noToggle,
  onPress = () => {},
  size = "lg",
  switchBodyColor = colors.black,
  switchInitialState,
  switchRef,
  switchState,
  switchToggleColor = colors.white,
  disabled,
}) {
  const [switchOn, setSwitchOn] = useState(
    Boolean(switchState ?? switchInitialState ?? false)
  );

  useEffect(() => {
    if (Boolean(switchState === true || switchState === false))
      setSwitchOn(switchState);
  }, [switchState]);

  const switchValue = useSpring(0, { stiffness: 400, damping: 30 });

  const setSwitchValueOn = () => {
    switchValue.set(1);
  };

  const setSwitchValueOff = () => {
    switchValue.set(0);
  };

  useEffect(() => {
    if (switchOn) setSwitchValueOn();
    if (!switchOn) setSwitchValueOff();
  }, [switchOn]);

  const translateX = useTransform(
    switchValue,
    [0, 1],
    [0, switchSize[size].borderRadius]
  );

  // useEffect(() => {
  //   if (noToggle) {
  //     if (Boolean(switchState === true || switchState === false))
  //     setSwitchOn(switchState);
  //   }
  // }, [switchState, noToggle]);

  return (
    <Flex
      ref={switchRef}
      cursor="pointer"
      disabled={Boolean(isLoading || disabled)}
      onClick={() => {
        onPress(!switchOn);
        if (!noToggle) {
          setSwitchOn(!switchOn);
        }
      }}
      sx={getSx([
        styles.switchBody(disabled, isLoading, switchBodyColor, switchOn),
        {
          flexDirection: "column",
          alignItems: "flex-start",
          height: getPx(switchSize[size].height),
          width: getPx(switchSize[size].width),
          borderRadius: getPx(switchSize[size].borderRadius),
          padding: getPx(2),
        },
      ])}
    >
      <motion.div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: switchToggleColor,
          borderRadius: getPx(switchSize[size].borderRadius),
          height: getPx(switchSize[size].height - 4),
          translateX,
          width: getPx(switchSize[size].height - 4),
        }}
      >
        {isLoading && (
          <FiUploadCloud
            size={getPx(switchSize[size].height * 0.6)}
            color={colors.grayD}
          />
        )}
        {!isLoading && switchOn && (
          <FiCheck
            size={getPx(switchSize[size].height * 0.6)}
            color={isLoading ? null : switchOn ? switchBodyColor : null}
          />
        )}
      </motion.div>
    </Flex>
  );
}

const styles = Object.create({
  switchBody: (disabled, isLoading, switchBodyColor, switchOn) => ({
    backgroundColor: Boolean(isLoading || disabled)
      ? colors.grayD
      : switchOn
      ? switchBodyColor
      : colors.gray7,
    justifyContent: "center",
    // alignItems: switchOn ? "flex-end" : "flex-start",
  }),
});

