import { useEffect, useState } from "react";
// import Toast from "react-native-toast-message";
// import { useDispatch, useSelector } from "react-redux";
// import { useNavigation } from "@react-navigation/native";

import { fontConfig as fonts } from "../config/fonts";
import { setUserFavoritesRedux } from "../store/slices/user";
// import routes from "../navigation/routes";
import { useYouwho } from "../package/react-youwho";
// import AppViewLayout from "../components/AppViewLayout";
// import padding from "../config/padding";
// import AppButton from "../components/AppButton";
// import AppTextTranslate from "../components/AppTextTranslate";
// import AppSwitch from "../components/AppSwitch";
// import ListItemSeparator from "../components/lists/ListItemSeparator";
// import AppPressable from "../components/AppPressable";
// import AppIcon from "../components/AppIcon";
// import AppText from "../components/AppText";
// import { BottomSheetScrollView } from "@gorhom/bottom-sheet";
import { useToast } from "./useToast";

export const useUser = (favoriteListingId) => {
  const { appStore, userStore, dispatch, navigation, user, refetchUserData } =
    useYouwho();
  const { handleShowUnderlay, loginModalObject } = appStore;
  const { userFavorites } = userStore;
  const [favorite, setFavorite] = useState(false);
  const [showFavToast, setShowFavToast] = useState(false);
  const Toast = useToast();

  useEffect(() => {
    if (favoriteListingId && userFavorites && userFavorites.length > 0) {
      const isFav = userFavorites.find((x) => x === favoriteListingId);
      if (isFav) {
        setFavorite(true);
      } else {
        setFavorite(false);
      }
    }
  }, [userFavorites, userFavorites?.length, favoriteListingId]);

  const checkUserInBlockList = (blockedUserId) => {
    const blockListArray = user?.get("blockedUsersArray");
    if (
      blockListArray &&
      Array.isArray(blockListArray) &&
      blockListArray.length > 0
    ) {
      const userBlocked = blockListArray?.find((x) => x?.id === blockedUserId);
      return userBlocked;
    }
  };

  const handleBlockUser = async (value, blockUserId, blockUserUsername) => {
    const blockUserObject = { id: blockUserId, username: blockUserUsername };
    try {
      if (value) {
        user.addUnique("blockedUsersArray", blockUserObject);
      } else {
        user.remove("blockedUsersArray", blockUserObject);
      }
      await user.save();
      await user.fetch();
      // await refetchUserData();
    } catch (error) {
      Toast.show({
        type: "appAlert",
        // autoHide: false,
        // And I can pass any custom props I want
        onHide: () => handleShowUnderlay(false),
        // onShow: () => handleShowUnderlay(true),
        props: {
          title: "Error",
          subtitle: `Error ${
            value ? "blocking" : "unblocking"
          } the user. Try again later.`,
          variant: "error",
        },
      });
    }
  };

  const addFavorite = async () => {
    try {
      user.addUnique("favorites", favoriteListingId);
      await user.save();
      await user.fetch();
      dispatch(setUserFavoritesRedux(user.attributes?.favorites));
    } catch (error) {
      console.log("addFavorites error", error);
    }
  };

  const removeFavorite = async () => {
    try {
      user.remove("favorites", favoriteListingId);
      await user.save();
      await user.fetch();
      dispatch(setUserFavoritesRedux(user.attributes?.favorites));
    } catch (error) {
      console.log("removeFavorite error", error);
    }
  };

  const handleFavToast = () => {
    Toast.show({
      type: "appToast",
      autoHide: false,
      // And I can pass any custom props I want
      //   onHide: () => setShowFavToast(false),
      // onShow: () => handleShowUnderlay(true),
      props: {
        hideClose: false,
        title: "Login",
        titleRight: "Login",
        subtitle: "Please login to add favorite services to watchlist",
        hideLeft: true,
        // iconRight: "key",
        // fontSize: fonts.size.lg,
        rightButtonVariant: "outline",
        pressRight: () => {
          console.log("onOpenLogin");
          setTimeout(() => {
            loginModalObject?.onOpen();
          }, 400);

          // navigation.navigate(routes.LOGIN_SCREEN.n);
          // Toast.hide();
        },
      },
    });
  };

  const handlePressFavorite = async () => {
    if (user) {
      console.log("handlePressFavorite user");
      if (favorite) {
        setFavorite(false);
        removeFavorite(favoriteListingId);
      } else {
        setFavorite(true);
        addFavorite(favoriteListingId);
      }
    } else {
      console.log("handlePressFavorite NO user");
      setShowFavToast(true);
      // handleFavToast();
    }
  };

  return {
    handlePressFavorite,
    handleFavToast,
    favorite,
    showFavToast,
    setShowFavToast,
    handleBlockUser,
    checkUserInBlockList,
    user,
  };
};

// export function EditBlockedUsers({ handlePressModal }) {
//   const [isLoading, setIsLoading] = useState(false);
//   const { handleBlockUser, user } = useUser();

//   const toggleBlockUser = async (value, blockUserId, blockUserUsername) => {
//     try {
//       setIsLoading(true);
//       await handleBlockUser(value, blockUserId, blockUserUsername);
//     } catch (error) {
//       console.log("handleTranslate error: " + error);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   return (
//     <>
//       <AppViewLayout
//         style={{
//           alignItems: "flex-end",
//         }}
//       >
//         <AppPressable
//           onPress={() => {
//             handlePressModal(false);
//           }}
//           pressableStyle={{ width: 26 }}
//           scale={0.95}
//         >
//           <AppIcon close />
//         </AppPressable>
//       </AppViewLayout>
//       <AppTextTranslate variant="h1">Blocked users</AppTextTranslate>

//       <ListItemSeparator style={{ marginTop: padding.lg }} />

//       <BottomSheetScrollView
//         contentContainerStyle={{
//           paddingTop: padding.lg,
//           paddingBottom: padding.xl,
//         }}
//         style={{ flex: 1 }}
//         showsVerticalScrollIndicator={false}
//       >
//         {user?.get("blockedUsersArray") &&
//         Array.isArray(user?.get("blockedUsersArray")) &&
//         user?.get("blockedUsersArray").length > 0 ? (
//           user?.get("blockedUsersArray").map(function (key, index) {
//             return (
//               <AppViewLayout
//                 key={key?.id + index}
//                 index={index}
//                 style={{
//                   flexDirection: "row",
//                   justifyContent: "space-between",
//                   marginY: padding.ms,
//                 }}
//               >
//                 <AppIcon mci name="account-off-outline" iconX={1} size={26} />
//                 <AppText
//                   numberOfLines={1}
//                   style={{ flex: 1, paddingLeft: padding.ms }}
//                   // variant="lg"
//                 >
//                   {key?.username}
//                 </AppText>
//                 <AppSwitch
//                   switchInitialState={key?.id}
//                   isLoading={isLoading}
//                   onPress={(val) =>
//                     toggleBlockUser(val, key?.id, key?.username)
//                   }
//                 />
//               </AppViewLayout>
//             );
//           })
//         ) : (
//           <AppViewLayout
//             style={{
//               marginY: padding.ms,
//             }}
//           >
//             <AppTextTranslate variant="p">
//               You have not blocked any users... yet...
//             </AppTextTranslate>
//           </AppViewLayout>
//         )}
//       </BottomSheetScrollView>
//     </>
//   );
// }
