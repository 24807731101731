import React, { useRef, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  // ModalHeader,
  // ModalFooter,
  // ModalBody,
  ModalCloseButton,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Tabs,
} from "@chakra-ui/react";

import borders from "../../config/borders";
import { arrayExists, getPx } from "../../utils/helpers";
import padding from "../../config/padding";
import View from "../rnMimic/View";
import colors from "../../config/colors";
import { useTranslate, useTranslateText } from "../../hooks/useTranslate";

// const tabs = {title, tabBgColor, Component}

const AppModal = ({
  isOpen,
  onClose,
  Component,
  tabs,
  hideTabs,
  scrollBehavior = "inside",
  buttonTitle,
  buttonStyle,
  buttonIconLeft,
  buttonIconRight,
  buttonIsDisabled,
  isLoading,
  buttonVariant,
  onPress,
}) => {
  const cancelRef = useRef();

  const [dataToSave, setDataToSave] = useState("");
  const [classTitle, setClassTitle] = useState("");

  const handleTranslation = useTranslate();

  const handlePressButton = () => {
    onPress(classTitle, dataToSave);
    onClose();
  };

  return (
    <Modal
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      scrollBehavior={scrollBehavior}
      onClose={onClose}
      isOpen={isOpen}
      isCentered
    >
      <ModalOverlay />

      <ModalContent
        sx={{
          borderRadius: getPx(borders.borderRadius20),
          padding: getPx(padding.xl),
        }}
      >
        <ModalCloseButton
          sx={{
            position: "absolute",
            borderRadius: getPx(borders.borderRadius12),
            right: getPx(padding.md),
            top: getPx(padding.md),
          }}
        />
        <Tabs>
          {!hideTabs && tabs && (
            <TabList>
              {tabs.map((x) => (
                <Tab
                  sx={{
                    borderTopLeftRadius: getPx(borders.borderRadius20),
                    borderTopRightRadius: getPx(borders.borderRadius20),
                    _selected: {
                      bg: x.tabBgColor ?? colors.black,
                      color: colors.white,
                      fontWeight: 600,
                    },
                  }}
                >
                  {handleTranslation(x.title)}
                </Tab>
              ))}
            </TabList>
          )}

          <TabPanels>
            {arrayExists(tabs) ? (
              <>
                {tabs.map((x) => (
                  <TabPanel>
                    {React.cloneElement(x.Component, {
                      handlePressModal: onClose,
                      // setDataToSave: buttonTitle ? setDataToSave : undefined,
                      // setClassTitle: buttonTitle ? setClassTitle : undefined,
                    })}
                  </TabPanel>
                ))}
              </>
            ) : (
              <>
                {React.cloneElement(Component, {
                  handlePressModal: onClose,
                  // setDataToSave: buttonTitle ? setDataToSave : undefined,
                  // setClassTitle: buttonTitle ? setClassTitle : undefined,
                })}
              </>
            )}
          </TabPanels>
        </Tabs>
      </ModalContent>
    </Modal>
  );
};

export default AppModal;
