import React, { useState, useRef } from "react";
import { ScrollMenu } from "react-horizontal-scrolling-menu";

import { DragDealer } from "./DragDealer";
import { LeftArrow, RightArrow } from "./AppScrollMenuArrows";
import { Card } from "./AppScrollMenuCard";

function AppScrollMenu({ children }) {
  // NOTE: for drag by mouse
  const dragState = useRef(new DragDealer());

  const handleDrag =
    ({ scrollContainer }) =>
    (ev) =>
      dragState.current.dragMove(ev, (posDiff) => {
        if (scrollContainer.current) {
          scrollContainer.current.scrollLeft += posDiff;
        }
      });

  const [selected, setSelected] = useState("");
  const handleItemClick = (itemId) => () => {
    if (dragState.current.dragging) {
      return false;
    }
    setSelected(selected !== itemId ? itemId : "");
  };

  return (
    <>
      <div onMouseLeave={dragState.current.dragStop}>
        <ScrollMenu
          LeftArrow={LeftArrow}
          RightArrow={RightArrow}
          onWheel={onWheel}
          onMouseDown={() => dragState.current.dragStart}
          onMouseUp={() => dragState.current.dragStop}
          onMouseMove={handleDrag}
        >
          {/* {items.map(({ id }) => (
            <Card
              title={id}
              // NOTE: itemId is required for track items
              itemId={id}
              key={id}
              onClick={handleItemClick(id)}
              selected={id === selected}
            />
          ))} */}
          {children}
        </ScrollMenu>
      </div>
    </>
  );
}
export default AppScrollMenu;

function onWheel(apiObj, ev) {
  const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

  if (isThouchpad) {
    ev.stopPropagation();
    return;
  }

  if (ev.deltaY < 0) {
    apiObj.scrollNext();
  } else if (ev.deltaY > 0) {
    apiObj.scrollPrev();
  }
}
