import React from "react";
import {
  Box,
  Flex,
  Stack,
  VStack,
  HStack,
  Center,
  chakra,
  shouldForwardProp,
} from "@chakra-ui/react";
import { getSx } from "../../utils/helpers";

const ChakraBox = chakra(Box, {
  shouldForwardProp: (prop) => shouldForwardProp(prop),
});
const ChakraVStack = chakra(VStack, {
  shouldForwardProp: (prop) => shouldForwardProp(prop),
});
const ChakraHStack = chakra(HStack, {
  shouldForwardProp: (prop) => shouldForwardProp(prop),
});
const ChakraCenter = chakra(Center, {
  shouldForwardProp: (prop) => shouldForwardProp(prop),
});

const viewStyle = { width: "100%" };
const flexStartStyle = {
  justifyContent: "flex-start",
  alignItems: "flex-start",
};

const View = ({
  children,
  style,
  sx,
  spacing = 0,
  direction = "column",
  flexStart,
  ...otherProps
}) => {
  switch (direction) {
    case "row":
      return (
        <ChakraHStack
          spacing={spacing}
          sx={getSx([viewStyle, flexStart ? flexStartStyle : {}, style, sx])}
          {...otherProps}
        >
          {children}
        </ChakraHStack>
      );
      break;
    case "center":
      return (
        <ChakraCenter
          sx={getSx([viewStyle, flexStartStyle, style, sx])}
          {...otherProps}
        >
          {children}
        </ChakraCenter>
      );
      break;
    case "column":
      return (
        <ChakraVStack
          spacing={spacing}
          sx={getSx([viewStyle, flexStartStyle, style, sx])}
          {...otherProps}
        >
          {children}
        </ChakraVStack>
      );
      break;
    default:
      return (
        <ChakraVStack
          spacing={spacing}
          sx={getSx([viewStyle, flexStartStyle, style, sx])}
          {...otherProps}
        >
          {children}
        </ChakraVStack>
      );
  }
};

export default View;
